import { API, Auth, Storage } from 'aws-amplify';
import TMOEvent from '../../models/TMOEvent';
import { DateTime } from 'luxon';
import Vue from 'vue';

//Todo Handle users auth store and such more efficiently by implimenting aws-amplify (hub)
const initApi = async () => {
  try {
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    return myInit;
  } catch (error) {
    return error;
  }
};
//Endpoints - todo should seperate legal updates etc.
let apiName = 'TMOAmplifyApi';
const buildPath = (resource = null) => {
  return !resource ? '/event' : `/event/${resource}`;
};

const prepareDateForEditing = (
  theDate = null,
  Timezone = 'America/New_York'
) => {
  if (!theDate) return null;
  return DateTime.fromISO(theDate, {
    zone: Timezone
  });
  // return DateTime.fromISO(theDate);
};
// const prepareDateForEditing = (theDate = null) => {
//   if (!theDate) return null;
//   return DateTime.fromISO(theDate);
// };

/**
 * @function formatTimeSentToTheDB
 * @param {String} theTime = the concatenated String 2021-09-23 and XX:XX:XX;
 * @example
 * const dateOne = "2021-09-23";
 * const DateTwo = "00:00:00";
 * const theDate = combineDateAndTime(dateOne, DateTwo);
 * @param {String} timezone - Default = America/New_York
 * @returns String
 * @description Formats the event object to send to the DB
 * @memberof EventManagerStore
 * @function formatEventForDB
 * @example
 * formatForSaving('2021-09-23T00:00:00', 'America/New_York')
 * @returns
 * 2021-09-22 04:00:00
 **/
let formatTimeSentToTheDB = (
  theDate = null,
  theTimezone = 'America/New_York'
) => {
  if (!theDate) throw 'Missing a date please try again.';
  return DateTime.fromISO(theDate, { zone: theTimezone })
    .setZone('utc')
    .toFormat('yyyy-LL-dd HH:mm:ss');
};

/**
 * @function combineDateAndTime
 * @param {string} theDate
 * @param {string} theTime
 * @returns
 * @description Combines the date and time into a single string
 * @memberof EventManagerStore
 */
let combineDateAndTime = (theDate = null, theTime = '00:00:00') => {
  if (!theDate) throw 'Error combinding the datetime';
  const formatted = theDate + 'T' + theTime;
  return formatted;
};

//Formatting for viewing from engagements
// do we need a separate formatter?
const formatEventForEngagements = ({
  timezone = null,
  description = null,
  eventStartDate = null,
  eventEndDate = null,
  defaultLanguage = null,
  eventLanguage = 'en',
  intervals = []
}) => {
  let neweventObject = {
    eventLanguage: eventLanguage,
    eventDescription: description
  };
  newEventObject.eventLanguage = eventLanguage;
  if (eventStartDate) {
    const convertedStartDateTime = prepareDateForEditing(
      eventStartDate,
      timezone
    );
    const formattedStartTime = convertedStartDateTime.toFormat('TT');
    const formattedStartDate = convertedStartDateTime.toFormat('D');

    neweventObject.eventStartDate = formattedStartDate;
    neweventObject.eventStartTime = formattedStartTime;
    neweventObject.startTime = formattedStartTime;
  }

  if (eventEndDate) {
    const convertedEndDateTime = prepareDateForEditing(eventEndDate, timezone);
    const formattedEndTime = convertedEndDateTime.toFormat('TT');
    const formattedEndDate = convertedEndDateTime.toFormat('D');

    neweventObject.eventEndDate = formattedEndDate;
    neweventObject.eventEndTime = formattedEndTime;

    neweventObject.eventEndTime = formattedEndTime;

    //Additional do we really need all of these?
    neweventObject.entryStartDate = formattedStartDate;
    neweventObject.entryStartTime = formattedStartTime;
    neweventObject.entryEndDate = formattedEndDate;
    neweventObject.entryEndTime = formattedEndTime;
    neweventObject.winnerSelectionDate = winnerSelectionDate;
    neweventObject.winnerSelectionTime = formattedEndTime;
  }
  neweventObject.intervals = [];
  intervals.map(int => {
    let convertedintStartDate = prepareDateForEditing(int.startDate, timezone);
    let convertedintEndDate = prepareDateForEditing(int.endDate, timezone);
    let convertedwinSelectionDate = prepareDateForEditing(
      int.selectionDate,
      timezone
    );
    let completed = int.completed;
    let numberOfWinnersToSelect = int.numberOfWinnersToSelect;
    let event_id = int.event_id;
    let id = int.id;

    const fStartDate = convertedintStartDate.toFormat('D');
    const fStartTime = convertedintStartDate.toFormat('TT');
    const fEndDate = convertedintEndDate.toFormat('D');
    const fEndTime = convertedintEndDate.toFormat('TT');
    const fWSelectionDate = convertedwinSelectionDate.toFormat('D');
    const fWinSelectionTime = convertedwinSelectionDate.toFormat('TT');

    neweventObject.intervals.push({
      completed: completed,
      numberOfWinnersToSelect: numberOfWinnersToSelect,
      event_id: event_id,
      id: id,
      endDate: fEndDate,
      startDate: fStartDate,
      startTime: fStartTime,
      endTime: fEndTime,
      selectionDate: fWSelectionDate,
      winnerSelectionDate: fWSelectionDate,
      winnerSelectionTime: fWinSelectionTime
    });
  });

  return neweventObject;
};

//formatting for editing and saving
const formatEventForEditing = ({
  description = null,
  timezone = null,
  storeNumber = null,
  state = null,
  region = null,
  rankWinners = null,
  published = null,
  numberOfWinners = null,
  excludePreviousParticipants = 0,
  eventType = null,
  organization = null,
  hqSponsorshipSegment = null,
  businessName = null,
  name = null,
  eventStartDate = null,
  eventEndDate = null,
  archived = false,
  defaultLanguage = null,
  eventLanguage = 'en',
  brand = 'T-Mobile',
  dma = null,
  msa = null,
  totalMarketingSpend = null,
  fullPrizeDescription = null,
  abbreviatedLegal = null,
  legalRequired = true,
  legalDetails = null,
  contestType = null,
  status = null,
  message = null,
  contestStatesRequired = false,
  eligibleStates = null,
  winnerSelectionDate = null,
  winnerSelectionTime = null,
  intervals = [],
  attendeeFormBusinessName = null,
  arv = null,
  eventLocation = null,
  address = null,
  district = null,
  postal_code = null,
  sweepstakesTitle = null,
  requiresAutomaticSelection = null,
  experience = null,
  legalPdfUrl = null,
  legalContract = null,
  abbreviatedPrizeDescription = null,
  isTranslated = null
}) => {
  let neweventObject = {
    eventLanguage: eventLanguage,
    eventDescription: description
  };
  if (numberOfWinners)
    neweventObject.numberOfWinners = parseInt(numberOfWinners);
  if (eventStartDate) {
    const convertedStartDateTime = prepareDateForEditing(
      eventStartDate,
      timezone
    );

    neweventObject.startDate = convertedStartDateTime.toISODate();
    neweventObject.startTime = convertedStartDateTime.toFormat('HH:mm:ss');
  }
  if (eventEndDate) {
    const convertedEndDateTime = prepareDateForEditing(eventEndDate, timezone);

    neweventObject.endDate = convertedEndDateTime.toISODate();
    neweventObject.endTime = convertedEndDateTime.toFormat('HH:mm:ss');
  }
  neweventObject.intervals = [];
  intervals.map(int => {
    let convertedintStartDate = prepareDateForEditing(int.startDate, timezone);
    let convertedintEndDate = prepareDateForEditing(int.endDate, timezone);
    let convertedwinSelectionDate = prepareDateForEditing(
      int.selectionDate,
      timezone
    );
    let completed = int.completed;
    let numberOfWinnersToSelect = int.numberOfWinnersToSelect;
    let event_id = int.event_id;
    let id = int.id;

    const fStartDate = convertedintStartDate.toISODate();
    const fStartTime = convertedintStartDate.toFormat('HH:mm:ss');
    const fEndDate = convertedintEndDate.toISODate();
    const fEndTime = convertedintEndDate.toFormat('HH:mm:ss');
    const fWSelectionDate = convertedwinSelectionDate.toISODate();
    const fWinSelectionTime = convertedwinSelectionDate.toFormat('HH:mm:ss');

    neweventObject.intervals.push({
      completed: completed,
      numberOfWinnersToSelect: numberOfWinnersToSelect,
      event_id: event_id,
      id: id,
      endDate: fEndDate,
      startDate: fStartDate,
      startTime: fStartTime,
      endTime: fEndTime,
      selectionDate: fWSelectionDate,
      winnerSelectionDate: fWSelectionDate,
      winnerSelectionTime: fWinSelectionTime
    });
  });

  if (typeof eligibleStates === 'string') {
    neweventObject.eligibleStates = eligibleStates.split(',');
    neweventObject.contestStatesRequired =
      neweventObject.eligibleStates.length < 52;
  }
  neweventObject.physicalEventAddress = address;
  neweventObject.physicalEventCity = district;
  neweventObject.physicalEventZip = postal_code;
  neweventObject.abbreviatedLegal = abbreviatedLegal;
  neweventObject.legalRequired =
    abbreviatedLegal != null || legalPdfUrl != null;
  return neweventObject;
};

// initial state
const state = {
  CURRENT_EVENT_OBJECT: {},
  LOADING: false,
  ERROR: false,
  ERROR_MESSAGE: null,
  POPUP_TITLE: null,
  POPUP_DESCRIPTION: null,
  SHOW_POPUP: false,
  BITLY_LINK: null,
  API_CALL_IN_PROGRESS: false,
  UNTRACKED_CHANGES_TO_EVENT: false,
  CURRENT_PAGE: 1,
  FILTERS: {},
  FILTER_INPUTS: {},
  HAS_MULTIPLE_ENTRY_PERIODS: false,
  HOW_MANY_ENTRY_PERIODS: 1,
  CURRENT_INTERVAL_ERROR_LIST: []
};

const getters = {
  CURRENT_PAGE: state => state.CURRENT_PAGE,
  FILTERS: state => state.FILTERS,
  FILTER_INPUTS: state => state.FILTER_INPUTS
};

const actions = {
  //apply changes to our event object
  async PREPARE_EVENT_OBJECT({ commit }) {
    commit('gotData', await getData());
  },
  async CREATE_EVENT({ commit, state, rootState }) {
    let response = {};
    let newIntervals = [];
    try {
      if (state.TOGGLE_API_CALL_STATE)
        throw new Error(
          'The previous submission has not completed yet. Please try again in a few minutes.'
        );

      commit('TOGGLE_API_CALL_STATE', true);
      let userAttributes = rootState.account.USER_ATTRIBUTES;
      // let userGroups = rootState.account.USER_GROUPS;

      //Set the eligibleStates default if necessary;
      if (state.CURRENT_EVENT_OBJECT.eligibleStates == null) {
        state.CURRENT_EVENT_OBJECT.eligibleStates =
          'AK,AL,AR,AS,AZ,CA,CO,CT,DC,DE,FL,GA,GU,HI,IA,ID,IL,IN,KS,KY,LA,MA,MD,ME,MI,MN,MO,MP,MS,MT,NC,ND,NE,NH,NJ,NM,NV,NY,OH,OK,OR,PA,PR,RI,SC,SD,TN,TX,UM,UT,VA,VI,VT,WA,WI,WV,WY,JLO';
      }
      //Reset the location if necessary;
      if (
        state.CURRENT_EVENT_OBJECT.eventLocation &&
        state.CURRENT_EVENT_OBJECT.eventLocation === 'Online/Virtual Event'
      ) {
        state.CURRENT_EVENT_OBJECT.physicalEventAddress = null;
        state.CURRENT_EVENT_OBJECT.physicalEventCity = null;
        state.CURRENT_EVENT_OBJECT.physicalEventZip = null;
      }
      state.CURRENT_EVENT_OBJECT.createdByFirstName =
        userAttributes.family_name || null;
      state.CURRENT_EVENT_OBJECT.createdByLastName =
        userAttributes.given_name || null;
      state.CURRENT_EVENT_OBJECT.createdByEmail = userAttributes.email || null;
      state.CURRENT_EVENT_OBJECT.status =
        state.CURRENT_EVENT_OBJECT.status || 'Draft';
      state.CURRENT_EVENT_OBJECT.eventDescription =
        state.CURRENT_EVENT_OBJECT.eventDescription || null;
      state.CURRENT_EVENT_OBJECT.name =
        state.CURRENT_EVENT_OBJECT?.name || null;

      //Turn the Start and end dates into Timezone utc dates
      //Necessary to set the evEndTime because not defaulting to 00:00:00;
      if (state.CURRENT_EVENT_OBJECT.startDate) {
        let evEndTime = state.CURRENT_EVENT_OBJECT.endTime || '00:00:00';

        state.CURRENT_EVENT_OBJECT.eventStartDate = formatTimeSentToTheDB(
          combineDateAndTime(
            state.CURRENT_EVENT_OBJECT.startDate,
            state.CURRENT_EVENT_OBJECT.startTime
          ),
          state.CURRENT_EVENT_OBJECT.timezone
        );
        state.CURRENT_EVENT_OBJECT.eventEndDate = formatTimeSentToTheDB(
          combineDateAndTime(state.CURRENT_EVENT_OBJECT.endDate, evEndTime),
          state.CURRENT_EVENT_OBJECT.timezone
        );
      }

      // Format the intervals times;
      // @@TODO move to reusable;
      if (state.CURRENT_EVENT_OBJECT.intervals) {
        await state.CURRENT_EVENT_OBJECT.intervals.map(intrvl => {
          let intvlEndTime = intrvl.endTime || '00:00:00'; //@@TODO find out default end time;
          let intvlSelectionTime = intrvl.winnerSelectionTime || '00:00:00'; //@@TODO find out default selection time;
          let convertedintStartDate = formatTimeSentToTheDB(
            combineDateAndTime(intrvl.startDate, intrvl.startTime),
            state.CURRENT_EVENT_OBJECT.timezone
          );
          let convertedintEndDate = formatTimeSentToTheDB(
            combineDateAndTime(intrvl.endDate, intvlEndTime),
            state.CURRENT_EVENT_OBJECT.timezone
          );
          let convertedwinSelectionDate = formatTimeSentToTheDB(
            combineDateAndTime(intrvl.winnerSelectionDate, intvlSelectionTime),
            state.CURRENT_EVENT_OBJECT.timezone
          );

          let numberOfWinnersToSelect =
            Number(state.CURRENT_EVENT_OBJECT.numberOfWinnersToSelect) || 0;

          newIntervals.push({
            completed: false,
            startDate: convertedintStartDate,
            endDate: convertedintEndDate,
            selectionDate: convertedwinSelectionDate,
            numberOfWinnersToSelect: numberOfWinnersToSelect
          });
        });
      }

      //Verify and format obj;
      let path = buildPath();
      let initAuth = await initApi();
      let postObject = {
        ...initAuth,
        body: {
          ...state.CURRENT_EVENT_OBJECT,
          intervals: newIntervals
        }
      };

      response = await API.post(apiName, path, postObject);
      //Mark the tracked changes;
      commit('SET_CHANGE_TRACKED_STATE', false);
      if (response.status === 200 && response.data && response.data.id) {
        return { id: response.data.id };
      } else {
        throw new Error('Couldnt create the event at this time.');
      }

      //Todo check status codes;
    } catch (err) {
      if (err && err.response && err.response.data) {
        response = err.response.data;
      } else {
        response = {
          status: 'error',
          errorMessage: 'Error creating event.'
        };
      }
      return response;
    } finally {
      commit('TOGGLE_API_CALL_STATE', false);
    }
  },

  async PATCH_EVENT({ commit, state, rootState }, eventId) {
    let response = {};
    let newIntervals = [];
    try {
      if (state.TOGGLE_API_CALL_STATE)
        throw new Error(
          'The previous submission has not completed yet. Please try again in a few minutes.'
        );

      commit('TOGGLE_API_CALL_STATE', true);
      let userAttributes = rootState.account.USER_ATTRIBUTES;
      // let userGroups = rootState.account.USER_GROUPS;
      //Set the eligibleStates default if necessary;
      if (state.CURRENT_EVENT_OBJECT.eligibleStates == null) {
        state.CURRENT_EVENT_OBJECT.eligibleStates =
          'AK,AL,AR,AS,AZ,CA,CO,CT,DC,DE,FL,GA,GU,HI,IA,ID,IL,IN,KS,KY,LA,MA,MD,ME,MI,MN,MO,MP,MS,MT,NC,ND,NE,NH,NJ,NM,NV,NY,OH,OK,OR,PA,PR,RI,SC,SD,TN,TX,UM,UT,VA,VI,VT,WA,WI,WV,WY,JLO';
      }
      //Reset the location if necessary;
      if (
        state.CURRENT_EVENT_OBJECT.eventLocation &&
        state.CURRENT_EVENT_OBJECT.eventLocation === 'Online/Virtual Event'
      ) {
        state.CURRENT_EVENT_OBJECT.physicalEventAddress = null;
        state.CURRENT_EVENT_OBJECT.physicalEventCity = null;
        state.CURRENT_EVENT_OBJECT.physicalEventZip = null;
      }
      // state.CURRENT_EVENT_OBJECT.createdByFirstName =
      //   userAttributes.family_name || null;
      // state.CURRENT_EVENT_OBJECT.createdByLastName =
      //   userAttributes.given_name || null;
      // state.CURRENT_EVENT_OBJECT.createdByEmail = userAttributes.email || null;
      state.CURRENT_EVENT_OBJECT.status =
        state.CURRENT_EVENT_OBJECT.status || 'Draft';
      state.CURRENT_EVENT_OBJECT.eventDescription =
        state.CURRENT_EVENT_OBJECT.eventDescription || null;
      state.CURRENT_EVENT_OBJECT.name =
        state.CURRENT_EVENT_OBJECT?.name || null;

      //Turn the Start and end dates into Timezone utc dates
      //Necessary to set the evEndTime because not defaulting to 00:00:00;
      if (state.CURRENT_EVENT_OBJECT.startDate) {
        let evEndTime = state.CURRENT_EVENT_OBJECT.endTime || '00:00:00';

        state.CURRENT_EVENT_OBJECT.eventStartDate = formatTimeSentToTheDB(
          combineDateAndTime(
            state.CURRENT_EVENT_OBJECT.startDate,
            state.CURRENT_EVENT_OBJECT.startTime
          ),
          state.CURRENT_EVENT_OBJECT.timezone
        );
        state.CURRENT_EVENT_OBJECT.eventEndDate = formatTimeSentToTheDB(
          combineDateAndTime(state.CURRENT_EVENT_OBJECT.endDate, evEndTime),
          state.CURRENT_EVENT_OBJECT.timezone
        );
      }
      // Format the intervals times;
      // @@TODO move to reusable;
      if (state.CURRENT_EVENT_OBJECT.intervals) {
        await state.CURRENT_EVENT_OBJECT.intervals.map(intrvl => {
          let intvlEndTime = intrvl.endTime || '00:00:00'; //@@TODO find out default end time;
          let intvlSelectionTime = intrvl.winnerSelectionTime || '00:00:00'; //@@TODO find out default selection time;
          let convertedintStartDate = formatTimeSentToTheDB(
            combineDateAndTime(intrvl.startDate, intrvl.startTime),
            state.CURRENT_EVENT_OBJECT.timezone
          );
          let convertedintEndDate = formatTimeSentToTheDB(
            combineDateAndTime(intrvl.endDate, intvlEndTime),
            state.CURRENT_EVENT_OBJECT.timezone
          );
          let convertedwinSelectionDate = formatTimeSentToTheDB(
            combineDateAndTime(intrvl.winnerSelectionDate, intvlSelectionTime),
            state.CURRENT_EVENT_OBJECT.timezone
          );

          let numberOfWinnersToSelect =
            Number(state.CURRENT_EVENT_OBJECT.numberOfWinnersToSelect) || 0;

          newIntervals.push({
            completed: false,
            startDate: convertedintStartDate,
            endDate: convertedintEndDate,
            selectionDate: convertedwinSelectionDate,
            numberOfWinnersToSelect: numberOfWinnersToSelect
          });
        });
      }
      //Verify and format obj;
      let path = buildPath(eventId);
      let initAuth = await initApi();
      let postObject = {
        ...initAuth,
        body: {
          ...state.CURRENT_EVENT_OBJECT,
          intervals: newIntervals
        }
      };

      response = await API.patch(apiName, path, postObject);
      //Mark the tracked changes;
      commit('SET_CHANGE_TRACKED_STATE', false);

      if (response.status === 200 && response.data && response.data.id) {
        return { id: response.data.id };
      } else {
        throw new Error('Couldnt create the event at this time.');
      }

      //Todo check status codes;
    } catch (err) {
      if (err && err.response && err.response.data) {
        response = err.response.data;
      } else {
        response = {
          status: 'error',
          errorMessage: 'Error editing event.'
        };
      }
      return response;
    } finally {
      commit('TOGGLE_API_CALL_STATE', false);
    }
  },
  async GET_EVENT_AUTHENTICATED({ commit }, eventId) {
    let response = {};
    try {
      let path = buildPath(eventId);
      let initAuth = await initApi();
      response = await API.get(apiName, path, initAuth);
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async ARCHIVE_EVENT({ commit, dispatch }, archiveObj) {
    try {
      var id = archiveObj.id;
      var archiveMessage = archiveObj.archiveMessage;
      const apiName = 'TMOAmplifyApi';
      const path = `/event/${id}`;
      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          archived: true,
          archiveMessage: archiveMessage
        }
      };
      const response = await API.patch(apiName, path, myInit);
      return response;
    } catch (error) {
      console.log('archive event error', error);
    }
  },
  async LOAD_EVENT({ commit, state, dispatch }, eventId) {
    let hasError = true;
    let response = null;
    let eventObject = {};
    try {
      if (state.LOADING) return console.log('Still Loading Previous Request');
      //If No Event Id is passed in, we will create a new event object and pass it to the state
      commit('SET_LOADING', true);
      if (!eventId) {
        eventObject = new TMOEvent();
        commit('SET_NUMBER_OF_ENTRY_PERIODS', 1);
        commit('SET_MULTIPLE_ENTRY_PERIODS', false);
        hasError = false;
      } else {
        response = await dispatch('GET_EVENT_AUTHENTICATED', eventId);
        if (response.data) {
          const checker = await formatEventForEditing(response.data);
          eventObject = Object.assign(response.data, checker);

          if (eventObject && Array.isArray(eventObject.intervals)) {
            if (eventObject.intervals.length > 1) {
              commit('SET_MULTIPLE_ENTRY_PERIODS', true);
              commit(
                'SET_NUMBER_OF_ENTRY_PERIODS',
                eventObject.intervals.length
              );
            } else {
              commit('SET_NUMBER_OF_ENTRY_PERIODS', 1);
            }
          }
        }
        hasError = false;
      }
      return eventObject;
    } catch (err) {
      hasError = true;
      if (err && err.response && err.response.data) {
        response = err.response.data;
      } else {
        response = {
          status: 'error',
          errorMessage: 'Error loading event.'
        };
      }
      return response;
    } finally {
      if (!hasError) {
        commit('SET_SELECTED_EVENT', eventObject);
      }
      commit('SET_LOADING', false);
    }
  },
  async LOAD_PUBLIC_EVENT({ commit, state, dispatch }, eventId) {
    let hasError = true;
    let response = null;
    let eventObject = {};
    try {
      if (state.LOADING) return console.log('Still Loading Previous Request');
      //If No Event Id is passed in, we will create a new event object and pass it to the state
      commit('SET_LOADING', true);
      const apiName = 'TMOAmplifyApi';
      const path = `/event/${eventId}/public`;
      const myInit = {
        response: true
      };
      const response = await API.get(apiName, path, myInit);
      if (response.data) {
        const checker = await formatEventForEditing(response.data);
        eventObject = Object.assign(response.data, checker);
      }
      hasError = false;
      return eventObject;
    } catch (err) {
      hasError = true;
      if (err && err.response && err.response.data) {
        response = err.response.data;
      } else {
        response = {
          status: 'error',
          errorMessage: 'Error loading event.'
        };
      }
      return response;
    } finally {
      if (!hasError) {
        commit('SET_SELECTED_EVENT', eventObject);
      }
      commit('SET_LOADING', false);
    }
  },
  async LOAD_ALL_EVENTS({ commit, state, rootState }, filters = {}) {
    try {
      if (state.LOADING) return console.log('Still Loading Previous Request');
      commit('SET_LOADING', true);
      let userAttributes = rootState.account.USER_ATTRIBUTES;
      let userGroups = rootState.account.USER_GROUPS;
      if (!filters) {
        filters = state.FILTERS;
      }
      // if not in the admin or legal group, only show events createdByEmail
      if (
        userGroups.indexOf('Admin') === -1 &&
        userGroups.indexOf('Legal') === -1
      ) {
        filters['createdByEmail'] = { values: [userAttributes.email] };
      }
      let apiFilters = '?archived=false&';
      for (var filterName of Object.keys(filters)) {
        // if using sort arrows
        if (
          filterName.indexOf('orderByField') !== -1 &&
          filterName.indexOf('orderByDirection') !== -1
        ) {
          apiFilters += filterName + '&';
        } else if (
          filterName.indexOf('archived') !== -1 &&
          filters[filterName].values[0] === true
        ) {
          // show both archived and non archived
          apiFilters = apiFilters.replace('archived=false&', '');
        } else {
          // using search form or advanced filters
          if (filterName !== 'archived') {
            apiFilters +=
              filterName +
              '=' +
              encodeURIComponent(filters[filterName].values[0]) +
              '&';
          }
        }
      }
      const apiName = 'TMOAmplifyApi';
      const path = '/events' + apiFilters;

      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      };
      const response = await API.get(apiName, path, myInit);
      let events = [];
      if (response && response.data && response.data.length) {
        response.data.forEach(async event => {
          const checker = await formatEventForEditing(event);
          event.brand = event.brand.includes('Metro') ? 'Metro' : 'T-Mobile';
          let eventObject = Object.assign(event, checker);
          events.push(eventObject);
        });
      }
      return events;
    } catch (err) {
      if (err && err.response && err.response.data) {
        return err.response.data;
      } else {
        return {
          status: 'error',
          errorMessage: 'Error loading events.'
        };
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async FETCH_BITLY_LINK({ commit }, id) {
    try {
      const apiName = 'TMOAmplifyApi';
      const path = `/bitly?eventId=${id}&origin=` + window.location.origin;
      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      };
      const response = await API.get(apiName, path, myInit);
      if (response.data.link) {
        commit('SET_BITLY_LINK', response.data.link);
        return response.data.link;
      }
    } catch (error) {
      commit('SET_BITLY_LINK', 'Error Loading Link');
    }
  },
  async PUT_RULES_PDF_IN_STORAGE({ commit, dispatch }, event) {
    //simple random key generation function
    function uuidv4() {
      return 'xxxxxxxx-xxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
    const result = await Storage.put(`rules-${uuidv4()}.pdf`, event.rulesPdf, {
      contentType: 'application/pdf',
      ACL: 'public-read',
      visibility: 'public',
      level: 'public'
    });
    var fullUrl = await Storage.get(result.key);
    return fullUrl.split('?')[0];
  },
  async PUT_CONTRACT_PDF_IN_STORAGE({ commit, dispatch }, event) {
    //simple random key generation function
    function uuidv4() {
      return 'xxxxxxxx-xxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
    const result = await Storage.put(
      `contract-${uuidv4()}.pdf`,
      event.contractPdf,
      {
        contentType: 'application/pdf',
        ACL: 'public-read',
        visibility: 'public',
        level: 'public'
      }
    );
    var fullUrl = await Storage.get(result.key);
    return fullUrl.split('?')[0];
  },
  async FETCH_WINNERS({ commit, dispatch }, id) {
    const apiName = 'TMOAmplifyApi';
    const path = `/event/${id}/winners`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    const response = await API.get(apiName, path, myInit);
    return response;
  },
  async GENERATE_WINNERS({ state, commit, dispatch }) {
    try {
      const apiName = 'TMOAmplifyApi';
      let eventId = state.CURRENT_EVENT_OBJECT.id || null;
      if (!eventId) {
        throw new Error('No event id found. Couldnt generate winners.');
      }
      const path = `/event/${eventId}/winners`;
      let numberOfWinners = parseInt(
        state.CURRENT_EVENT_OBJECT.numberOfWinners
      );
      let rankWinners = state.CURRENT_EVENT_OBJECT.rankWinners || true;
      const myInit = {
        response: true,
        body: {
          numberOfWinners: numberOfWinners,
          excludePreviousParticipants: true,
          rankWinners: rankWinners
        },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      };
      const response = await API.post(apiName, path, myInit);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async GET_ALTERNATE_WINNER({ commit, dispatch }, winnerData) {
    const apiName = 'TMOAmplifyApi';
    const path = `/event/${winnerData.event_id}/winner/replace`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: {
        replacedWinnerId: winnerData.replacedWinnerId,
        event_id: winnerData.event_id,
        interval_id: winnerData.interval_id,
        replaceWinnerRank: winnerData.replaceWinnerRank
      }
    };

    let response = {};
    try {
      response = await API.post(apiName, path, myInit);
    } catch (e) {
      response = e.response.data;
    }
    return JSON.stringify(response);
  },
  async BATCH_INSERT_PARTICIPANTS({ commit, dispatch }, payload) {
    const apiName = 'TMOAmplifyApi';
    const path = `/engagements/${payload.id}`;
    const myInit = {
      response: true,
      body: {
        engagements: payload.participants,
        email: payload.email
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    let response = {};
    try {
      response = await API.post(apiName, path, myInit);
      return response.data;
    } catch (err) {
      if (err && err.response && err.response.data) {
        response = err.response.data;
      } else {
        response = {
          status: 'error',
          errorMessage: 'Error uploading participants.'
        };
      }
      return response;
    }
  },
  async UPDATE_ACCESS_CODE({ commit, dispatch }, id) {
    const apiName = 'TMOAmplifyApi';
    const path = `/event/${id}/code`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    const response = await API.get(apiName, path, myInit);
    commit('UPDATE_EVENT_PROPERTY', {
      key: 'event_code',
      value: response.data[0].code
    });
    return response;
  },
  async FETCH_ZIPS({ commit, dispatch }, id) {
    const apiName = 'TMOAmplifyApi';
    const path = `/event/${id}/zips`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    const response = await API.get(apiName, path, myInit);
    return response;
  },
  async CHECK_IN({ commit, dispatch }, checkInData) {
    const apiName = 'TMOAmplifyApi';
    const path = '/event/' + checkInData.eventId + '/checkin';
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: {
        email: checkInData.email
      }
    };

    let response = {};
    try {
      response = await API.post(apiName, path, myInit);
    } catch (e) {
      response = e.response.data;
    }
    return JSON.stringify(response);
  },
  async FETCH_CHECK_IN_COUNTS({ commit, dispatch }, id) {
    const apiName = 'TMOAmplifyApi';
    const path = `/event/${id}/checkin/count`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    const response = await API.get(apiName, path, myInit);
    return response;
  },
  async SET_MULTIPLE_FILTERS({ commit, dispatch }, additionalFilters) {
    let filters = JSON.parse(JSON.stringify(state.FILTERS));
    for (var filter of additionalFilters) {
      if (filters[filter.name] == null) {
        Vue.set(filters, filter.name, {
          label: filter.label,
          values: [filter.value]
        });
      } else {
        filters[filter.name].values = [filter.value];
      }
    }

    commit('SET_FILTERS', filters);
    commit('SET_CURRENT_PAGE', 1);
  },
  async SET_FILTER({ commit, dispatch }, filter) {
    let filters = JSON.parse(JSON.stringify(state.FILTERS));
    if (filters[filter.name] == null) {
      Vue.set(filters, filter.name, {
        label: filter.label,
        values: [filter.value]
      });
    } else {
      filters[filter.name].values = [filter.value];
    }

    commit('SET_FILTERS', filters);
    commit('SET_CURRENT_PAGE', 1);
  },
  async RESET_FILTER({ commit, dispatch }, filter) {
    let filters = state.FILTERS;
    state.FILTER_INPUTS[filter.name] = null;
    if (filters[filter.name] == null) {
      return;
    } else {
      Vue.delete(filters, filter.name);
    }
    commit('SET_FILTERS', filters);
  },
  UPDATE_CURRENT_PAGE({ commit, dispatch }, page) {
    if (state.CURRENT_PAGE != page) {
      commit('SET_CURRENT_PAGE', page);
      dispatch('LOAD_ALL_EVENTS', {
        orderByField: { values: ['id'] },
        orderByDirection: { values: ['DESC'] }
      });
    }
  },
  FILTER_EVENTS({ commit, dispatch }, fieldData) {
    for (var filterName of Object.keys(state.FILTERS)) {
      if (filterName.indexOf('orderByField') !== -1) {
        delete state.FILTERS[filterName];
      }
    }

    // filter the events based on which filter is clicked
    dispatch('SET_MULTIPLE_FILTERS', [
      {
        label: 'Order By',
        value: fieldData.fieldName,
        name: 'orderByField'
      },
      {
        label: 'Order By Direction',
        value: fieldData.orderByDirection,
        name: 'orderByDirection'
      }
    ]);

    commit('SET_CURRENT_PAGE', 1);
    return commit('LOAD_ALL_EVENTS', state.FILTERS);
  }
};

// // mutations
const mutations = {
  SET_MULTIPLE_ENTRY_PERIODS(state, payload) {
    state.HAS_MULTIPLE_ENTRY_PERIODS = payload;
  },
  SET_BITLY_LINK(state, link) {
    state.BITLY_LINK = link;
  },
  SET_LOADING: (state, payload) => {
    state.LOADING = payload || false;
  },
  SET_SELECTED_EVENT: (state, payload) => {
    let target_event = payload || {};
    state.CURRENT_EVENT_OBJECT = Object.assign(
      {},
      state.CURRENT_EVENT_OBJECT,
      target_event
    );
  },
  SET_RESPONSE_MESSAGE: (state, payload) => {
    let {
      message = 'Internal Error',
      helpText = 'Couldnt perform that action at this time.'
    } = payload;
    state.LOADING = false;
    state.POPUP_TITLE = message;
    state.POPUP_DESCRIPTION = helpText;
    state.SHOW_POPUP = true;
  },
  UPDATE_EVENT_PROPERTY(state, payload) {
    let { key = null, value = null } = payload;
    if (!key) return null;
    let oldValue = state.CURRENT_EVENT_OBJECT[key];
    state.CURRENT_EVENT_OBJECT[key] = value;
    //Flag that there are untracked unsaved changes
    if (oldValue != value) state.UNTRACKED_CHANGES_TO_EVENT = true;
  },
  UPDATE_EVENT_WINNERS(state, payload) {
    let { key = null, value = null } = payload;
    if (!key) return null;
    let winNumber = Number(value) || 0;
    state.CURRENT_EVENT_OBJECT[key] = winNumber;
    if (Array.isArray(state.CURRENT_EVENT_OBJECT.intervals)) {
      let alteredWinnerCountArr = state.CURRENT_EVENT_OBJECT.intervals.map(
        x => {
          let tempObj = { ...x };
          tempObj['numberOfWinnersToSelect'] = winNumber;
          return tempObj;
        }
      );
      state.CURRENT_EVENT_OBJECT.intervals = alteredWinnerCountArr;
    }
    state.UNTRACKED_CHANGES_TO_EVENT = true;
  },
  UPDATE_EVENT_START_DATE_TIME(state, payload) {
    let { key = null, value = null } = payload;
    let newIntervalObject = {};
    if (!key) return null;
    state.CURRENT_EVENT_OBJECT[key] = value;
    if (
      Array.isArray(state.CURRENT_EVENT_OBJECT.intervals) &&
      state.CURRENT_EVENT_OBJECT.intervals.length <= 0 &&
      value
    ) {
      if (key === 'startDate') {
        newIntervalObject = Object.assign(newIntervalObject, {
          completed: 0,
          numberOfWinnersToSelect: state.numberOfWinnersToSelect || 1,
          endDate: state.endDate || null,
          startDate: value,
          startTime: state.startTime || null,
          endTime: state.endTime || null,
          selectionDate: state.endDate || null,
          winnerSelectionDate: state.endDate || null,
          winnerSelectionTime: state.endTime || null
        });
      } else {
        newIntervalObject = Object.assign(newIntervalObject, {
          completed: 0,
          numberOfWinnersToSelect: state.numberOfWinnersToSelect || 1,
          endDate: state.endDate || null,
          startDate: state.startDate || null,
          startTime: value || null,
          endTime: state.endTime || null,
          selectionDate: state.endDate || null,
          winnerSelectionDate: state.endDate || null,
          winnerSelectionTime: state.endTime || null
        });
      }
      state.CURRENT_EVENT_OBJECT.intervals.push(newIntervalObject);
    } else if (state.CURRENT_EVENT_OBJECT.intervals.length >= 1) {
      state.CURRENT_EVENT_OBJECT.intervals[0][key] = value;
    }
    state.UNTRACKED_CHANGES_TO_EVENT = true;
  },
  UPDATE_EVENT_END_DATE_TIME(state, payload) {
    let { key = null, value = null } = payload;
    let newIntervalObject = {};
    if (!key) return null;
    state.CURRENT_EVENT_OBJECT[key] = value;
    if (!state.CURRENT_EVENT_OBJECT.intervals && value !== null) {
      if (key === 'endDate') {
        newIntervalObject = Object.assign(newIntervalObject, {
          completed: 0,
          numberOfWinnersToSelect: state.numberOfWinnersToSelect || 1,
          endDate: value || null,
          startDate: state.startDate,
          startTime: state.startTime || null,
          endTime: state.endTime || null,
          selectionDate: state.endDate || null,
          winnerSelectionDate: state.endDate || null,
          winnerSelectionTime: state.endTime || null
        });
      } else {
        newIntervalObject = Object.assign(newIntervalObject, {
          completed: 0,
          numberOfWinnersToSelect: state.numberOfWinnersToSelect || 1,
          endDate: state.endDate || null,
          startDate: state.startDate || null,
          startTime: state.startTime || null,
          endTime: value || null,
          selectionDate: state.endDate || null,
          winnerSelectionDate: state.endDate || null,
          winnerSelectionTime: state.endTime || null
        });
      }

      state.CURRENT_EVENT_OBJECT.intervals = [];
      state.CURRENT_EVENT_OBJECT.intervals.push(newIntervalObject);
    } else if (state.CURRENT_EVENT_OBJECT.intervals.length >= 1) {
      state.CURRENT_EVENT_OBJECT.intervals[
        state.CURRENT_EVENT_OBJECT.intervals.length - 1
      ][key] = value;
      // also update winner selection end date/time
      if (key === 'endDate') {
        state.CURRENT_EVENT_OBJECT.intervals[
          state.CURRENT_EVENT_OBJECT.intervals.length - 1
        ].winnerSelectionDate = value;
      } else if (key === 'endTime') {
        state.CURRENT_EVENT_OBJECT.intervals[
          state.CURRENT_EVENT_OBJECT.intervals.length - 1
        ].winnerSelectionTime = value;
      }
    }
    state.UNTRACKED_CHANGES_TO_EVENT = true;
  },
  UPDATE_INTERVAL_OBJ(state, payload) {
    if (!payload) return;
    if (
      payload.index == null ||
      !state.CURRENT_EVENT_OBJECT.intervals ||
      payload.index > state.CURRENT_EVENT_OBJECT.intervals.length - 1
    )
      return;
    let tempIntervalStateArr = state.CURRENT_EVENT_OBJECT.intervals;
    let targetIndex = payload['index'];
    let newIntervalObject = { [payload['key']]: payload['value'] };
    let copyObject = Object.assign(
      tempIntervalStateArr[targetIndex],
      newIntervalObject
    );
    tempIntervalStateArr.splice(targetIndex, 1, copyObject);
    state.CURRENT_EVENT_OBJECT.intervals = tempIntervalStateArr;
    state.UNTRACKED_CHANGES_TO_EVENT = true;
  },
  REMOVE_EVENT_INTERVAL(state, payload) {
    if (!payload) return;
    if (
      payload.index == null ||
      !state.CURRENT_EVENT_OBJECT.intervals ||
      payload.index > state.CURRENT_EVENT_OBJECT.intervals.length - 1
    )
      return;
    if (state.CURRENT_EVENT_OBJECT.intervals.length === 1) return;
    let tempIntervalStateArr = state.CURRENT_EVENT_OBJECT.intervals;
    let targetIndex = payload['index'];

    tempIntervalStateArr.splice(targetIndex, 1);

    //Update Number of entry periods;
    state.HOW_MANY_ENTRY_PERIODS = tempIntervalStateArr.length;

    state.CURRENT_EVENT_OBJECT.intervals = tempIntervalStateArr;
    state.UNTRACKED_CHANGES_TO_EVENT = true;
  },
  ADD_EVENT_INTERVAL(state) {
    let baseIntervalObject = {
      completed: 0,
      numberOfWinnersToSelect: state.numberOfWinnersToSelect || 1,
      endDate: null,
      startDate: null,
      startTime: null,
      endTime: null,
      selectionDate: null,
      winnerSelectionDate: null,
      winnerSelectionTime: null
    };
    state.HOW_MANY_ENTRY_PERIODS =
      state.CURRENT_EVENT_OBJECT.intervals.length + 1;
    state.CURRENT_EVENT_OBJECT.intervals.push(baseIntervalObject);
    state.UNTRACKED_CHANGES_TO_EVENT = true;
  },
  TOGGLE_REMOVE_ALL_STATES(state) {
    state.CURRENT_EVENT_OBJECT.eligibleStates = [];
    this.UNTRACKED_CHANGES_TO_EVENT = true;
  },
  RESET_ALL: state => {
    state.LOADING = false;
    state.CURRENT_EVENT_OBJECT = {};
    state.ERROR = false;
    state.ERROR_MESSAGE = null;
    state.POPUP_TITLE = null;
    state.POPUP_DESCRIPTION = null;
    state.SHOW_POPUP = false;
    state.BITLY_LINK = null;
    state.API_CALL_IN_PROGRESS = false;
    state.UNTRACKED_CHANGES_TO_EVENT = false;
    state.HAS_MULTIPLE_ENTRY_PERIODS = false;
    state.HOW_MANY_ENTRY_PERIODS = 1;
    state.CURRENT_INTERVAL_ERROR_LIST = [];
    (state.CURRENT_PAGE = 1), (state.FILTERS = {}), (state.FILTER_INPUTS = {});
  },
  SET_CHANGE_TRACKED_STATE: (state, value) => {
    state.UNTRACKED_CHANGES_TO_EVENT = value;
  },
  RESET_POPUP: state => {
    state.POPUP_TITLE = null;
    state.POPUP_DESCRIPTION = null;
    state.SHOW_POPUP = false;
  },
  TOGGLE_API_CALL_STATE: (state, value) => {
    if (!value) return (state.API_CALL_IN_PROGRESS = false);
    state.API_CALL_IN_PROGRESS = true;
  },
  SET_CURRENT_PAGE: (state, page) => (state.CURRENT_PAGE = page),
  SET_FILTERS: (state, filters) => (state.FILTERS = filters),
  SET_NUMBER_OF_ENTRY_PERIODS: (state, numberOfEntryPeriods) => {
    state.HOW_MANY_ENTRY_PERIODS = Number(numberOfEntryPeriods);
  },
  SET_CURRENT_INTERVAL_ERROR_LIST: (state, payload) => {
    let errList = payload || [];
    state.CURRENT_INTERVAL_ERROR_LIST = errList;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
