import { API, Auth, Storage } from 'aws-amplify';
import Vue from 'vue';
import TMOTranslations from '../../models/TMOTranslations';

const state = {
  translations: new TMOTranslations()
};
const getters = {
  translations: state => state.translations
};

const actions = {
  async approveTranslations({ commit, dispatch }, eventId) {
    let translations = state.translations;
    if (!translations.fullPrizeDescription) {
      translations.fullPrizeDescription == null;
    }
    if (!translations.sweepstakesTitle) {
      translations.sweepstakesTitle == null;
    }
    if (!translations.abbreviatedLegal) {
      translations.abbreviatedLegal == null;
    }
    const apiName = 'TMOAmplifyApi';
    const path = `/event/${eventId}/translate`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: {
        event_name: translations.event_name,
        fullPrizeDescription: translations.fullPrizeDescription,
        sweepstakesTitle: translations.sweepstakesTitle,
        abbreviatedPrizeDescription: translations.abbreviatedPrizeDescription,
        abbreviatedLegal: translations.abbreviatedLegal
      }
    };

    let response = {};
    try {
      response = await API.patch(apiName, path, myInit);
    } catch (e) {
      response = e.response.data;
    }
    return JSON.stringify(response);
  },
  async fetchTranslations({ commit, dispatch }, eventId) {
    const apiName = 'TMOAmplifyApi';
    const path = `/event/${eventId}/translate`;
    const myInit = {
      response: true
    };
    const response = await API.get(apiName, path, myInit).catch(error => {
      return error.response.data.message;
    });
    if (typeof response == 'string') {
      return response;
    }
    const translation = response.data[0];
    if (translation) {
      commit(
        'setTranslations',
        new TMOTranslations({
          event_id: translation.event_id,
          event_name: translation.event_name,
          fullPrizeDescription: translation.fullPrizeDescription,
          sweepstakesTitle: translation.sweepstakesTitle,
          abbreviatedPrizeDescription: translation.abbreviatedPrizeDescription,
          abbreviatedLegal: translation.abbreviatedLegal
        })
      );
    }
    return response;
  }
};
const mutations = {
  setTranslations: (state, translations) => (state.translations = translations),
  UPDATE_TRANSLATION_PROPERTY(state, payload) {
    let { key = null, value = null } = payload;
    if (!key) return null;
    state.translations[key] = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
