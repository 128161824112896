<template>
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Close</title>
    <g
      id="Dev-Pack"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g transform="translate(-643.000000, -577.000000)" id="Group">
        <g transform="translate(639.000000, 573.000000)">
          <path
            d="M14.6378978,31.2029894 L21.3206365,24.4797493 L14.6378978,17.7970106 C13.7873674,16.9464802 13.7873674,15.5289296 14.6378978,14.6378978 C15.5289296,13.7873674 16.9464802,13.7873674 17.7970106,14.6378978 L24.4797493,21.3206365 L31.2029894,14.6378978 C32.0535198,13.7873674 33.4710704,13.7873674 34.3621022,14.6378978 C35.2126326,15.5289296 35.2126326,16.9464802 34.3621022,17.7970106 L27.6388621,24.4797493 L34.3621022,31.2029894 C35.2126326,32.0535198 35.2126326,33.4710704 34.3621022,34.3621022 C33.4710704,35.2126326 32.0535198,35.2126326 31.2029894,34.3621022 L24.4797493,27.6388621 L17.7970106,34.3621022 C16.9464802,35.2126326 15.5289296,35.2126326 14.6378978,34.3621022 C13.7873674,33.4710704 13.7873674,32.0535198 14.6378978,31.2029894 Z"
            id="Path"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CloseX'
};
</script>
