<template>
  <v-card flat>
    <v-container fluid>
      <v-row>
        <v-col cols="10">
          <v-card-title> Photo Uploader </v-card-title>
          <v-card-subtitle> Edit Image </v-card-subtitle>
        </v-col>
        <v-col cols="2">
          <v-btn outlined @click="cancel_new_photo">
            Cancel
          </v-btn>
        </v-col>
      </v-row>
      <v-form v-model="valid">
        <v-row v-for="(editImage) in NEW_IMAGE_UPLOAD_ARR" :key="editImage.id">
          <v-col cols="2">
            <v-card-title> {{editImage.template_id}} </v-card-title>
            <v-card-subtitle> {{editImage.placeholder_image_width}} x {{editImage.placeholder_image_height}} </v-card-subtitle>
          </v-col>
          <v-col cols="5">
            <v-file-input
              label="Upload Image"
              prepend-icon="mdi-camera"
              accept="image/*"
              color="primary"
              :hint="`Image must have a width of ${editImage.placeholder_image_width} and height of ${editImage.placeholder_image_height}`"
              persistent-hint
              v-model="editImage.image"
              required
              :rules="[
                editImage.valid_dimensions || `Image must have a width of ${editImage.placeholder_image_width} and height of ${editImage.placeholder_image_height}`,
                nonEmptyRule('An image is required.')
              ]"
              @change="checkDimensions(editImage)"
            ></v-file-input>
          </v-col>
          <v-col cols="5">
            <v-img
              height="200px"
              :src="editImage.url"
              :lazy-src="editImage.url"
              aspect-ratio="16/9"
              max-height="300"
              contain
              class="grey darken-4"
            >
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="4" >
            <v-btn
              class="v-btn v-btn--is-elevated v-btn--has-bg v-btn--tile theme--light elevation-2 v-size--x-large primary"
              block
              @click="save_new_photos"
              :disabled="!verify_complete_arr"
              :loading="loading"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog v-model="dialog" width="500" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" tile x-large color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import ValidationRules from '@/utility/ValidationRules.js';

export default {
  name: 'AVEEditForm',
  components: {},
  data() {
    return {
      valid: false,
      loading: false,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogAction: null
    };
  },
  methods: {
    ...mapMutations('AVE_CANVAS_STORE', ['RESET_IMAGE_UPLOAD_FORM']),
    ...mapActions('AVE_CANVAS_STORE', ['EDIT_IMAGES']),
    async save_new_photos() {
      this.loading = true;
      try {
        await this.EDIT_IMAGES();
        this.displayDialog('Success', 'Image Edit Successful.', () => {
          this.dialog = false;
          this.$emit('CHANGE_ACTIVE_DIALOG_COMPONENT', 'AVEPhotoManager');
        });
      } catch (e) {
        this.showErrorDialog(e);
      } finally {
        this.loading = false;
      }
    },
    cancel_new_photo() {
      this.RESET_IMAGE_UPLOAD_FORM();
      this.$emit('CHANGE_ACTIVE_DIALOG_COMPONENT', 'AVEPhotoManager');
    },
    async checkDimensions(editImage) {
      editImage.changed = true;
      if (!editImage.image) {
        return;
      }
      // based on https://stackoverflow.com/questions/8903854/check-image-width-and-height-before-upload-with-javascript
      let URL = window.URL || window.webkitURL;
      let tempImage = new Image();
      let objectUrl = URL.createObjectURL(editImage.image);
      let width = editImage.placeholder_image_width;
      let height = editImage.placeholder_image_height;
      tempImage.onload = function() {
        editImage.valid_dimensions =
          this.width == width && this.height == height;

        if (editImage.valid_dimensions) {
          editImage.url = objectUrl;
        } else {
          URL.revokeObjectURL(objectUrl);
        }
      };
      tempImage.src = objectUrl;
      await tempImage.decode();
    },
    nonEmptyRule(error) {
      return ValidationRules.NonEmptyRule(error);
    },
    showErrorDialog(error) {
      this.dialogTitle = 'Error';
      this.dialogMessage = error;
      this.dialogAction = () => (this.dialog = false);
      this.dialog = true;
    },
    displayDialog(dialogTitle, dialogMessage, dialogAction) {
      this.dialogTitle = dialogTitle;
      this.dialogMessage = dialogMessage;
      this.dialogAction = dialogAction;
      this.dialog = true;
    }
  },
  computed: {
    ...mapState('AVE_CANVAS_STORE', ['NEW_IMAGE_UPLOAD_ARR']),
    verify_complete_arr() {
      return (
        this.NEW_IMAGE_UPLOAD_ARR.length > 0 &&
        this.NEW_IMAGE_UPLOAD_ARR.find(img => img.changed) != undefined &&
        this.NEW_IMAGE_UPLOAD_ARR.every(
          img => !img.changed || (img.image != null && img.valid_dimensions)
        )
      );
    }
  }
};
</script>
