<template>
  <div class="app-dashboard">
    <BannerTitle title="Edit User" :loading="userLoading" />
    <div>
      <v-row no-gutters class="black action-bar" justify="start">
        <v-col cols="2" lg="2">
          <router-link :to="'/admin'">
            <v-btn
              block
              color="#000000"
              elevation="0"
              dark
              tile
            >
              <v-icon>
                mdi-chevron-left
              </v-icon>
              Back to User Management
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </div>
    <section class="py-12" v-if="!userLoading">
      <v-container :fluid="!this.lgBreakpoint">
        <v-form ref="form" v-model="valid" @submit.prevent="editUser">
          <v-row>
            <v-col cols="12" md="6" lg="8">
              <h1 class="user-info">User Info</h1>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-row class="user-actions">
                <v-col cols="4" v-if="editing">
                  <v-btn
                    tile
                    outlined
                    color="primary"
                    @click="cancelEdit"
                    :disabled="loading"
                  >
                    Cancel
                  </v-btn>
                </v-col>
                <v-col cols="4" v-if="!editing">
                  <v-btn
                    tile
                    class="btn__primary"
                    @click="editing = true"
                  >
                    Edit
                  </v-btn>
                </v-col>
                <v-col cols="4" v-if="editing">
                  <v-btn
                    tile
                    class="btn__primary"
                    type="submit"
                    :loading="loading"
                  >
                    Save
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="userActionText"
                    @change="userAction"
                    :items="userOptions()"
                    label="Actions"
                    outlined
                    dense
                    append-icon="$Caret"
                    :disabled="loading"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col cols="12" md="4" lg="3">
              <label for="email">Email Address</label>
              <p name="email">{{ user.email }}</p>
            </v-col>
            <v-col cols="6" md="4" lg="3">
              <label for="fname">First Name</label>
              <p name="fname">{{ user.given_name }}</p>
            </v-col>
            <v-col cols="6" md="4" lg="3">
              <label for="lname">Last Name</label>
              <p name="lname">{{ user.family_name }}</p>
            </v-col>
            <v-col cols="6" md="4" lg="3">
              <label for="status">Account Status</label>
              <p name="status">{{ user.Enabled ? user.UserStatus : 'Disabled' }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="4" lg="3">
              <label for="role">Role</label>
              <v-select
                v-if="editing"
                name="role"
                outlined
                required
                append-icon="$Caret"
                v-model="selectedGroup"
                :items="groups"
                :rules="[
                  nonEmptyRule('You must select a role.')
                ]"
              />
              <p v-else name="role">
                {{
                  user.group.join(', ')
                    .replace('Guest', 'General')
                    .replace('SLTranslator', 'Translator')
                    .replace('Ambassador', 'Event Staff')
                }}
              </p>
            </v-col>
            <template v-if="hasTMOEmail()">
              <v-col cols="6" md="4" lg="3">
                <label for="organization">Organization</label>
                <v-select
                  v-if="editing"
                  v-model="selectedOrganization"
                  :items="organizationOptions"
                  name="organization"
                  outlined
                  required
                  append-icon="$Caret"
                  :rules="[
                    nonEmptyRule('You must select an organization.')
                  ]"
                />
                <p v-else name="organization">{{ user.signUpOrganization }}</p>
              </v-col>
              <v-col cols="6" md="4" lg="3">
                <label for="department">Department</label>
                <v-select
                  v-if="editing"
                  v-model="selectedDepartment"
                  :items="departmentOptions"
                  name="department"
                  outlined
                  required
                  append-icon="$Caret"
                  :rules="[
                    nonEmptyRule('You must select a department.')
                  ]"
                />
                <p v-else name="department">{{ user.signupDepartment }}</p>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="6" md="4" lg="3">
                <label for="contactName">T-Mobile Contact</label>
                <p name="contactName">{{ user.refEmployeeName }}</p>
              </v-col>
              <v-col cols="12" md="4" lg="3">
                <label for="contactEmail">T-Mobile Contact Email Address</label>
                <p name="contactEmail">{{ user.refEmployeeEmail }}</p>
              </v-col>
            </template>
          </v-row>
          <v-row v-if="!hasTMOEmail()">
            <v-col cols="12" md="6">
              <label for="signUpMessage">Reason for Access</label>
              <p name="signUpMessage">{{ user.signUpMessage }}</p>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </section>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialog = false" tile outlined color="primary" class="px-6">
            Close
          </v-btn>
          <v-btn @click="dialogAction" tile class="px-6 btn__primary" v-if="dialogAction" :loading="loading">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Footer />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BannerTitle from '@/components/BannerTitle.vue';
import OptionData from '@/utility/OptionData.js';
import ValidationRules from '@/utility/ValidationRules.js';

export default {
  name: 'UserEdit',
  async created() {
    // redirect to /dashboard if not admin
    if (this.USER_GROUPS.indexOf('Admin') === -1) {
      this.$router.push('/dashboard');
      return;
    }
    window.scrollTo(0, 0);
    try {
      this.user = await this.getUser(this.$route.params.username);
      this.user = await this.formatUserData(this.user);
      this.selectedOrganization = this.user.signUpOrganization;
      this.selectedDepartment = this.user.signupDepartment;
      this.selectedGroup = this.user.group[0];
    } catch (e) {
      e = typeof e == 'string' ? JSON.parse(e) : e;
      this.displayDialog('Error', e.message ?? e, '', null);
    }
    this.userLoading = false;
  },
  data() {
    return {
      userLoading: true,
      loading: false,
      editing: false,
      valid: false,
      user: {},
      groups: [
        {
          value: 'Admin',
          text: 'Admin'
        },
        {
          value: 'Guest',
          text: 'General'
        },
        {
          value: 'Legal',
          text: 'Legal'
        },
        {
          value: 'SLTranslator',
          text: 'Translator'
        },
        {
          value: 'Ambassador',
          text: 'Event Staff'
        }
      ],
      organizationOptions: OptionData.employeeOrgOptions(),
      departmentOptions: OptionData.employeeDepartmentOptions(),
      selectedOrganization: null,
      selectedDepartment: null,
      selectedGroup: null,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogAction: null,
      dialogActionText: '',
      userActionText: null
    };
  },
  computed: {
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`,
      FORGOT_PASSWORD_ERROR: 'account/FORGOT_PASSWORD_ERROR'
    }),
    lgBreakpoint: function() {
      return this.$vuetify.breakpoint.lgAndUp;
    }
  },

  methods: {
    ...mapActions({
      getUser: 'admin/getUser',
      listGroups: 'admin/listGroups',
      listGroupsForUser: 'admin/listGroupsForUser',
      addUserToGroup: 'admin/addUserToGroup',
      removeUserFromGroup: 'admin/removeUserFromGroup',
      updateUserAttributes: 'admin/updateUserAttributes',
      sendEmail: 'admin/sendEmail',
      confirmUserSignUp: 'admin/confirmUserSignUp',
      enableUser: 'admin/enableUser',
      disableUser: 'admin/disableUser',
      FORGOT_PASSWORD: 'account/FORGOT_PASSWORD'
    }),
    toCapitalCase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    nonEmptyRule(error) {
      return ValidationRules.NonEmptyRule(error);
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },
    async formatUserData(user) {
      // instead of having an attributes array of objects,
      // put the key value pairs in the top level user object
      // also remove 'custom:'
      let data = {};
      for (let j = 0; j < user.UserAttributes.length; ++j) {
        let attribute = user.UserAttributes[j];
        let name = attribute.Name.replace('custom:', '');
        data[name] = attribute.Value;
      }
      data.Enabled = user.Enabled;
      data.UserCreateDate = user.UserCreateDate;
      data.UserLastModifiedDate = user.UserLastModifiedDate;
      data.UserStatus = this.toCapitalCase(user.UserStatus);
      data.Username = user.Username;
      let groups = await this.listGroupsForUser({ username: data.Username });
      data.group = [];
      for (let i = 0; i < groups.Groups.length; ++i) {
        data.group.push(groups.Groups[i].GroupName);
      }
      return data;
    },
    hasTMOEmail() {
      return this.user.email.indexOf('@t-mobile.com') !== -1;
    },
    userOptions() {
      let status = this.user.Enabled ? this.user.UserStatus : 'Disabled';
      switch (status) {
        case 'Confirmed':
          return ['Suspend', 'Reset Password'];
        case 'Disabled':
          return ['Unsuspend', 'Reset Password'];
        default:
          return ['Confirm', 'Reset Password'];
      }
    },
    async userAction() {
      switch (this.userActionText) {
        case 'Confirm':
          this.displayDialog(
            'Confirm User',
            'Are you sure you want to confirm this user?',
            'Confirm',
            this.confirmUser
          );
          break;
        case 'Suspend':
          this.displayDialog(
            'Suspend User',
            'Are you sure you want to suspend this user?',
            'Suspend',
            this.suspendUser
          );
          break;
        case 'Unsuspend':
          this.displayDialog(
            'Unsuspend User',
            'Are you sure you want to unsuspend this user?',
            'Unsuspend',
            this.unsuspendUser
          );
          break;
        case 'Reset Password':
          this.displayDialog(
            'Reset Password',
            "Are you sure you want to reset this user's password?",
            'Reset Password',
            this.resetPassword
          );
          break;
        default:
          break;
      }
      this.$nextTick(() => (this.userActionText = null));
    },
    async editUser() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.loading = true;
      let username = this.user.Username;
      try {
        // organization
        if (this.selectedOrganization != this.user.signUpOrganization) {
          await this.updateUserAttributes({
            username,
            attrName: 'custom:signUpOrganization',
            attrValue: this.selectedOrganization
          });
          this.user.signUpOrganization = this.selectedOrganization;
        }
        // department
        if (this.selectedDepartment != this.user.signupDepartment) {
          await this.updateUserAttributes({
            username,
            attrName: 'custom:signupDepartment',
            attrValue: this.selectedDepartment
          });
          this.user.signupDepartment = this.selectedDepartment;
        }
        // groups
        if (this.selectedGroup != this.user.group[0]) {
          // remove from group
          for (let i = 0; i < this.user.group.length; ++i) {
            let groupname = this.user.group[i];
            if (this.selectedGroup !== groupname) {
              await this.removeUserFromGroup({ username, groupname });
            }
          }
          // add to group
          let groupname = this.selectedGroup;
          if (!this.user.group.includes(groupname)) {
            await this.addUserToGroup({ username, groupname });
          }
          this.user.group = [this.selectedGroup];
        }
        this.displayDialog('Success', 'User Edit Complete.', '', null);
        this.editing = false;
      } catch (e) {
        e = typeof e == 'string' ? JSON.parse(e) : e;
        this.displayDialog('Error', e.message ?? e, '', null);
      } finally {
        this.loading = false;
      }
    },
    cancelEdit() {
      this.selectedOrganization = this.user.signUpOrganization;
      this.selectedDepartment = this.user.signupDepartment;
      this.selectedGroup = this.user.group[0];
      this.editing = false;
    },
    enabledEmail() {
      let toAddress = this.user.email;
      let givenName = this.user.given_name;
      let templateData = {
        preheader_text: '',
        header_text: 'Congratulations!',
        greeting_text: `Hi, ${givenName},`,
        'body-1_text':
          'Your access to the T-Mobile Data Capture Tool has been enabled. Click the button below to login.',
        'cta-1_href': 'https://www.t-mobileengagementhub.com',
        'cta-1_text': 'Login',
        'body-2_text': '',
        subject_text: 'T-Mobile Data Capture Tool Access Enabled',
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'SignUp <SignUp@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    disabledEmail() {
      let toAddress = this.user.email;
      let givenName = this.user.given_name;
      let templateData = {
        preheader_text: '',
        header_text: '',
        greeting_text: `Hi, ${givenName},`,
        'body-1_text':
          'Your access to the T-Mobile Data Capture Tool has been disabled. Please reach out to the T-Mobile employee you are working with for more information.',
        'body-2_text': '',
        subject_text: 'T-Mobile Data Capture Tool Access Disabled',
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'SignUp <SignUp@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    async confirmUser() {
      let username = this.user.Username;
      this.loading = true;
      try {
        await this.updateUserAttributes({
          username,
          attrName: 'custom:confirmed',
          attrValue: '1'
        });
        await this.confirmUserSignUp(username);
        await this.updateUserAttributes({
          username,
          attrName: 'email_verified',
          attrValue: 'true'
        });
        await this.removeUserFromGroup({ username, groupname: 'Pending' });
        this.user.UserStatus = 'Confirmed';
        this.user.Enabled = true;
        this.user.email_verified = 'true';
        this.user.confirmed = '1';
        this.user.group = ['Guest'];
        this.sendEmail(this.enabledEmail());
        this.displayDialog('Success', 'Confirmed Registration.', '', null);
      } catch (e) {
        e = typeof e == 'string' ? JSON.parse(e) : e;
        this.displayDialog('Error', e.message ?? e, '', null);
      } finally {
        this.loading = false;
      }
    },
    async suspendUser() {
      let username = this.user.Username;
      this.loading = true;
      try {
        await this.disableUser(username);
        this.user.Enabled = false;
        this.sendEmail(this.disabledEmail());
        this.displayDialog('Success', 'Disabled User.', '', null);
      } catch (e) {
        e = typeof e == 'string' ? JSON.parse(e) : e;
        this.displayDialog('Error', e.message ?? e, '', null);
      } finally {
        this.loading = false;
      }
    },
    async unsuspendUser() {
      let username = this.user.Username;
      this.loading = true;
      try {
        await this.enableUser(username);
        this.user.Enabled = true;
        this.sendEmail(this.enabledEmail());
        this.displayDialog('Success', 'Enabled User.', '', null);
      } catch (e) {
        e = typeof e == 'string' ? JSON.parse(e) : e;
        this.displayDialog('Error', e.message ?? e, '', null);
      } finally {
        this.loading = false;
      }
    },
    async resetPassword() {
      let username = this.user.Username;
      this.loading = true;
      try {
        await this.FORGOT_PASSWORD(username);
        if (this.FORGOT_PASSWORD_ERROR) {
          throw this.FORGOT_PASSWORD_ERROR;
        }
        this.displayDialog('Success', 'Sent Reset Password Email.', '', null);
      } catch (e) {
        e = typeof e == 'string' ? JSON.parse(e) : e;
        this.displayDialog('Error', e.message ?? e, '', null);
      } finally {
        this.loading = false;
      }
    }
  },
  components: {
    BannerTitle
  }
};
</script>

<style lang="scss" scoped>
h1.user-info {
  font-size: 3rem;
}
.user-actions {
  justify-content: flex-end;
}
::v-deep .user-actions .v-input,
::v-deep .user-actions .v-btn {
  height: 40px;
  width: 100%;
}
::v-deep .v-select {
  border-radius: 0;
}
::v-deep .user-actions .v-select fieldset {
  border-color: var(--v-primary-base);
}
::v-deep .user-actions .v-select label {
  color: black;
  font-weight: bold;
  font-size: 14px;
}
hr.v-divider {
  background-color: #d8d8d8;
  border-width: 1px;
  margin-bottom: 64px;
}
::v-deep .action-bar .v-btn {
  font-family: 'Tele-Grotesk Next-Ultra', Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  height: 80px !important;
}
::v-deep .action-bar .v-btn::before {
  color: var(--v-primary-base);
}
::v-deep .action-bar .v-btn:hover::before,
::v-deep .action-bar .v-btn.action-active::before {
  opacity: 1;
}
.action-bar a {
  text-decoration: none;
}
.action-bar {
  min-height: 80px;
}
label {
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .user-actions {
    justify-content: center;
  }
  hr.v-divider {
    margin-top: 24px;
  }
  h1.user-info {
    text-align: center;
  }
}
</style>
