<template>
  <section v-if="errorMessage == null">
    <BannerTitle
      :title="updateformattedTitle"
      :loading="
        LOADING &&
          ((selectedLanguage == 'es' && !translations) || !selectedLanguage)
      "
    />
    <v-col
      v-if="
        CURRENT_EVENT_OBJECT.eventLanguage === 'bi' &&
          !CURRENT_EVENT_OBJECT.legalPdfUrl
      "
      xs="12"
      sm="12"
      md="12"
      class="language-buttons"
    >
      <v-radio-group v-model="selectedLanguage" row>
        <v-radio ref="englishLang" label="English" value="en"></v-radio>
        <v-radio ref="spanishLang" label="Español" value="es"></v-radio>
      </v-radio-group>
    </v-col>
    <template v-if="CURRENT_EVENT_OBJECT.legalPdfUrl">
      <embed
        :title="CURRENT_EVENT_OBJECT.sweepstakesTitle + ' Official Rules'"
        class="embedded-rules"
        :src="
          CURRENT_EVENT_OBJECT.legalPdfUrl + '#toolbar=0&navpanes=0&scrollbar=0'
        "
        type="application/pdf"
      />
    </template>
    <template v-else>
      <v-container class="my-4" v-if="errorMessage != null">
        <p>
          <strong>{{ errorMessage }}</strong>
        </p>
      </v-container>
      <v-container
        class="my-4"
        v-if="errorMessage == null && selectedLanguage == 'en'"
      >
        <p>
          <strong>
            No Purchase Or Payment Necessary To Enter To Win. A Purchase Or
            Payment Will Not Enhance Your Chances Of Winning.
          </strong>
        </p>
        <p>
          <strong>
            By participating in these Sweepstakes, you agree to these Official
            Rules, which are a contract, so read them carefully before
            participating. Without limitation, this contract includes
            indemnities to the Sponsor, defined herein, from you, a limitation
            of your rights and remedies, binding arbitration of claims, waiver
            of class action claims, and waiver to the right to trial by jury. To
            enter these Sweepstakes, you must have Internet access.
          </strong>
        </p>
        <ol>
          <li>
            <strong>Timing:</strong> The
            {{ CURRENT_EVENT_OBJECT.sweepstakesTitle }} (the
            “<strong>Sweepstakes</strong>”) begins at
            {{ CURRENT_EVENT_OBJECT.startTime }} {{ formattedTimezone }} on
            {{ CURRENT_EVENT_OBJECT.startDate }} and ends at
            {{ CURRENT_EVENT_OBJECT.endTime }} {{ formattedTimezone }} on
            {{ CURRENT_EVENT_OBJECT.endDate }} (the "
            <strong>
              Promotion Period
            </strong>
            "). Sponsor’s clock will be the official timekeeping device for the
            Sweepstakes. The Promotion Period consists of
            {{
              this.CURRENT_EVENT_OBJECT.intervals == null ||
              1 >= this.CURRENT_EVENT_OBJECT.intervals.length
                ? '1 entry window (the '
                : this.CURRENT_EVENT_OBJECT.intervals.length +
                  ' entry windows (each, an '
            }}
            “<strong>Entry Window</strong>”) with the following time and date
            details{{
              this.CURRENT_EVENT_OBJECT.intervals == null ||
              1 >= this.CURRENT_EVENT_OBJECT.intervals.length
                ? ':'
                : ' per Entry Window:'
            }}
          </li>
        </ol>
        <PastelTable
          class="my-4"
          :rows="CURRENT_EVENT_OBJECT.intervals"
          :columns="[
            {
              source: 'startDate',
              label: 'Start Date'
            },
            {
              source: 'startTime',
              label: 'Start Time'
            },
            {
              source: 'endDate',
              label: 'End Date'
            },
            {
              source: 'endTime',
              label: 'End Time'
            },
            {
              source: 'winnerSelectionDate',
              label: 'Drawing Date'
            },
            {
              source: 'winnerSelectionTime',
              label: 'Drawing Time'
            }
          ]"
        />
        <ol start="2">
          <li>
            <strong>Eligibility:</strong> The sponsor of this Sweepstakes is
            T-Mobile USA, Inc. (“<strong>Sponsor</strong>”) 12920 SE 38th
            Street, Bellevue, WA 98006. Sweepstakes is open to legal residents
            of
            <template
              v-if="
                CURRENT_EVENT_OBJECT.eligibleStates == null ||
                  CURRENT_EVENT_OBJECT.eligibleStates.length == 0 ||
                  CURRENT_EVENT_OBJECT.eligibleStates.length >= 52
              "
            >
              all fifty (50) United States, the District of Columbia, and Puerto
              Rico
            </template>
            <template v-else>
              <template v-if="CURRENT_EVENT_OBJECT.eligibleStates.length == 1">
                {{ CURRENT_EVENT_OBJECT.eligibleStates[0] }}
              </template>
              <template v-if="CURRENT_EVENT_OBJECT.eligibleStates.length == 2">
                {{ CURRENT_EVENT_OBJECT.eligibleStates[0] }} and
                {{ CURRENT_EVENT_OBJECT.eligibleStates[1] }}
              </template>
              <template v-if="CURRENT_EVENT_OBJECT.eligibleStates.length > 2">
                <template
                  v-for="(state,
                  stateIndex) in CURRENT_EVENT_OBJECT.eligibleStates"
                >
                  {{
                    CURRENT_EVENT_OBJECT.eligibleStates.length - 1 > stateIndex
                      ? state + ', '
                      : ' and ' + state
                  }}
                </template>
              </template>
            </template>
            (the “<strong>Territory</strong>”) who are 18 years of age or older
            as of date of entry (“<strong>Eligible Participant</strong>”). Void
            outside the Territory and where prohibited. Employees, officers, or
            directors of Sponsor or any other company involved in the promotion
            or execution of the Sweepstakes, and each of their respective
            affiliates or subsidiaries (together, the “
            <strong>
              Released Parties
            </strong>
            ”), as well as the immediate family members (defined as parents,
            spouse, children, siblings and grandparents) and those living in the
            same household of each such employee, officer, or director are not
            eligible to enter. By participating, each entrant agrees to abide by
            these Official Rules and by the decisions of Sponsor, which are
            final and binding in all respects. These Official Rules and the
            decisions of Sponsor are final and binding in all respects.
          </li>
          <li>
            <strong>Entry:</strong> Eligible Participants will need to submit an
            entry
            <template
              v-if="
                CURRENT_EVENT_OBJECT.eventLocation == 'Online/Virtual Event'
              "
            >
              at
              <a
                :href="
                  host + '/#/engagements/register/' + CURRENT_EVENT_OBJECT.id
                "
                target="_blank"
                >{{
                  host + '/#/engagements/register/' + CURRENT_EVENT_OBJECT.id
                }}
              </a>
              .
            </template>
            <template
              v-if="
                CURRENT_EVENT_OBJECT.eventLocation == 'Physical/In Person Event'
              "
            >
              in person at {{ CURRENT_EVENT_OBJECT.physicalEventAddress }},
              {{ CURRENT_EVENT_OBJECT.physicalEventCity }},
              {{ CURRENT_EVENT_OBJECT.state }},
              {{ CURRENT_EVENT_OBJECT.physicalEventZip }}.
            </template>
            <template
              v-if="
                CURRENT_EVENT_OBJECT.eventLocation ==
                  'Combination Online/In Person'
              "
            >
              at
              <a
                :href="
                  host + '/#/engagements/register/' + CURRENT_EVENT_OBJECT.id
                "
                target="_blank"
                >{{
                  host + '/#/engagements/register/' + CURRENT_EVENT_OBJECT.id
                }}</a>
              or in person at {{ CURRENT_EVENT_OBJECT.physicalEventAddress }},
              {{ CURRENT_EVENT_OBJECT.physicalEventCity }},
              {{ CURRENT_EVENT_OBJECT.state }},
              {{ CURRENT_EVENT_OBJECT.physicalEventZip }}.
            </template>
            (“<strong>Entry</strong>”).
            <strong>
              Standard data rates apply. Limit one (1) entry per person.
            </strong>
            The use of automated devices to enter this Sweepstakes is
            prohibited. Sponsor and its agents are not responsible for lost,
            late, damaged, misdirected, illegible or incomplete entries.
          </li>
          <li>
            <strong>Additional Terms:</strong> Sponsor may prohibit an entrant
            from participating in the Sweepstakes if, in its sole discretion, it
            determines that said entrant is attempting to undermine the
            legitimate operation of the Sweepstakes by cheating, hacking,
            deception or other unfair practices (including the use of automated
            quick entry programs) or intending to annoy, abuse, threaten or
            harass any other entrants or Sponsor or its agents, or if entrant
            has or has attempted to submit malicious code, .exe files or any
            file that contains malicious code.
          </li>
          <li>
            <strong>Winner Selection:</strong> For each Entry Window, the
            Sponsor will randomly draw
            {{ CURRENT_EVENT_OBJECT.numberOfWinners }} potential prize winner(s)
            out of all eligible entries on each applicable Drawing Date.
            Eligible entries will not roll over to any subsequent Entry Window.
            The potential prize winner’s assessment will be under supervision of
            Sponsor, whose decisions are final and binding on all matters
            relating to the Sweepstakes. The selected potential prize winner(s)
            will be notified via email or phone call from Sponsor on or promptly
            following the Drawing Date. Subject to verification of eligibility
            and compliance with these Official Rules, the potential winner(s)
            will be declared official winner(s) of the Sweepstakes (each a
            “<strong>Prize Winner</strong>”). If any selected potential winner
            does not respond to Sponsor within 24 hours after being notified, is
            found to be ineligible, is not able to receive the Sponsor’s
            notification, or cannot or does not comply with these Official
            Rules, such potential winner may be disqualified and Sponsor may
            select an alternate potential winner from among all remaining
            eligible entries. Odds of winning depend on the actual number of
            eligible entries received as of the conclusion of the Promotion
            Period. Prize Winner will be subject to verification.
          </li>
          <li>
            <strong>Prize; Approximate Retail Value (“ARV”):</strong> The
            prize(s) will consist of the following:
            <p v-html="formattedPrizeDescription"></p>

            The total ARV for the prize(s) is ${{
              CURRENT_EVENT_OBJECT.arv != null
                ? CURRENT_EVENT_OBJECT.arv
                : '0.00'
            }}.
          </li>
          <li>
            <strong>Additional Prize Terms:</strong> All Prize details not
            stated in the above prize description will be determined by Sponsor
            in its sole discretion. Prize Winner will be solely responsible for
            all costs, fees and expenses not expressly stated in the prize
            description. Any difference between the approximate retail value and
            the actual value will not be awarded and any difference will not be
            refunded. Without limiting the generality of the foregoing, the
            Prize Winner is responsible for all federal, state and local taxes
            and any other taxes associated with award, acceptance and/or use of
            the prize excluding taxes included in the cost of the prize. Actual
            value of prize may vary. No refunds or credit for changes are
            allowed. Prize Winner is strictly prohibited from selling,
            auctioning, trading or otherwise transferring the prize (or portion
            thereof) unless Sponsor consents in writing. Prize is
            nontransferable and no substitution or cash equivalent is allowed,
            except in Sponsor’s sole discretion. Sponsor reserves the right to
            substitute a prize or prizes of the same approximate retail value
            for the prize (or portion thereof). For any prize consisting of
            access to or participation in an event, if Prize Winner is not
            available during the scheduled time, or if such event does not take
            place for any reason, no additional prize will be awarded. The
            Released Parties, individually and collectively, are not responsible
            for any injuries or loss to Prize Winner or guest in using the
            prize.
          </li>
          <li>
            <strong>Claiming Prize; Likeness Release:</strong> Eligibility, age,
            and all claims made by potential Prize Winner(s) are subject to
            verification. Sponsor is not responsible for fraudulent
            calls/mail/e-mails made by any party or sent to entrants not by
            Sponsor. Acceptance of the prize constitutes Prize Winner’s
            permission for Sponsor and its designees to use Prize Winner’s name,
            photograph, likeness, voice, biographical information, statements,
            and city and state of residence for advertising and publicity
            purposes worldwide and in all forms of media now known or hereafter
            developed (including, but not limited to publishing the Prize
            Winner’s name on Sponsor’s Instagram, Facebook, and Twitter pages
            and other social media accounts), in perpetuity, without further
            compensation, except in Tennessee and where prohibited by law.
          </li>
          <li>
            <strong>Limitations Of Liability:</strong> Entrants agree that the
            Released Parties are not responsible for, and specifically disclaim
            any liability for or arising out of, any of the following regardless
            of cause, in connection with the Sweepstakes: (1) any incorrect or
            inaccurate information, whether caused by entrant wireless carriers,
            Internet Service Providers (“<strong>ISP</strong>”) or unauthorized
            human intervention; (2) technical difficulties or failures of any
            kind including, but not limited to, malfunctions, interruptions or
            disconnections in transmissions or connections, phone lines, network
            hardware or software, computers, equipment, programming errors,
            cable, satellite, cellular tower or ISP or wireless carriers; (3)
            bugs, viruses, worms, Trojan horses or similar malicious attacks;
            (4) typographical, printing, network, mechanical, electronic,
            technical, human or other errors or malfunctions; (5) any
            responsibility and/or liability with respect to the Sweepstakes
            and/or the prize (including any property loss, damage, personal
            injury or death) in connection with participation in this
            Sweepstakes, the offering or announcement of the prize, or the
            acceptance/possession, use/misuse and/or defects of the prize
            awarded herein; (6) lost, incomplete, late, misdirected, garbled,
            undelivered, incomplete, stolen or mutilated transactions or
            entries; or garbled, lost, misrouted or scrambled transmissions; or
            (7) unauthorized human and/or mechanical intervention in the
            Sweepstakes or any other part of the participation process in this
            Sweepstakes. <strong>CAUTION:</strong> Any attempt to damage any
            website, app or platform or undermine the legitimate operation of
            this Sweepstakes may be a violation of criminal and/or civil laws
            and, should such an attempt be made, Sponsor reserves the right to
            seek damages and/or other remedies (including attorneys’ fees) from
            any individual(s) or entity(ies) responsible for the attempt to the
            fullest extent permitted by law.
          </li>
          <li>
            <strong>Agreement; Release of Claims:</strong> By entering, entrant
            warrants that they have read and understand these Official Rules and
            agrees to accept and abide by the Official Rules of this
            Sweepstakes. All decisions by Sponsor regarding this Sweepstakes are
            final. By entering, entrant agrees to be notified using the contact
            information provided as part of entry by Sponsor for the purposes of
            the administration of this Sweepstakes. By entering and/or accepting
            a prize, entrant agrees to release, defend, indemnify, and hold
            harmless the Released Parties from any and all injury, loss or
            damage to person, including death or property due, in-whole or
            in-part, directly or indirectly, to the acceptance or use/misuse of
            a prize, participation in the Sweepstakes and any related activity.
            Neither Sponsor nor any Released Party is responsible for any
            typographical or other error in the printing of these Official
            Rules, administration of the Sweepstakes, or in the announcement or
            distribution of any prize or prize element. In no event will more
            than the stated number of prizes be awarded. If Sponsor is prevented
            from continuing with this Sweepstakes, or the integrity and/or
            feasibility of the Sweepstakes is undermined by any event including,
            but not limited to, fire, flood, epidemic, earthquake, explosion,
            labor dispute or strike, act of God or public enemy, satellite or
            equipment failure, riot or civil disturbance, war (declared or
            undeclared), terrorist threat or activity, or any federal, state or
            local government law, order or regulation, order of any court or
            jurisdiction, or by other cause not reasonably within Sponsor’s
            control (each a “<strong>Force Majeure</strong>” event), Sponsor
            shall have the right, in its discretion, to abbreviate, modify,
            suspend, cancel, or terminate the Sweepstakes without further
            obligation. In no event will more than the stated number of prizes
            be awarded. If Sponsor, in its sole discretion, elects to abbreviate
            the Sweepstakes as a result of a Force Majeure event, Sponsor
            reserves the right, but shall have no obligation, to award the
            prizes from among all valid and eligible entries received up to the
            time of such Force Majeure event. Entrant acknowledges that nothing
            herein shall constitute an employment, joint venture or partnership
            relationship between entrant and Sponsor. In no way is entrant to be
            construed as the agent or to be acting as the agent of Sponsor in
            any respect.
          </li>
          <li>
            <strong>Governing Law:</strong> Except where prohibited, entrants
            agree that: (1) any and all disputes, claims and causes of action
            arising out of or connected with the Sweepstakes (including any and
            all disputes, claims and causes of action arising out of or
            connected with the prize, the availability or use or same; or the
            conduct of the Sweepstakes), shall be resolved individually, without
            resorting to any form of class action, and exclusively by
            arbitration pursuant to the Rules of the American Arbitration
            Association, then effective (provided that, if for any reason AAA
            declines to administer the arbitration, the dispute may be
            arbitrated before JAMS, Inc. or another mutually agreed upon
            arbitration service pursuant to its own rules for expedited consumer
            arbitration); (2) they waive any right to seek or obtain injunctive
            or other equitable relief (provided that nothing in these Official
            Rules will restrict a California resident’s right under applicable
            law (if any) to seek public injunctive relief otherwise in
            accordance with the requirements of this provision); (3) any and all
            claims, judgments, or awards shall be limited to actual
            out-of-pocket costs incurred, including costs associated with
            entering Sweepstakes (if any), but in no event attorneys’ fees; and
            (4) under no circumstances will an entrant be permitted to obtain
            awards for, and entrant hereby waives all rights to claim, punitive,
            incidental, special, exemplary, or consequential damages, any other
            damages, other than for actual out-of-pocket expenses (if any), or
            any and all rights to have damages multiplied or otherwise
            increased. All issues and questions concerning the construction,
            validity, interpretation and enforceability of these Official Rules,
            or the rights and obligations of entrant or a Released Party in
            connection with the Sweepstakes, shall be governed by, and construed
            in accordance with, the laws of the State of Washington, without
            regard for conflicts of law doctrine of Washington or any other
            jurisdiction, and all proceedings shall take place in King County,
            Washington (except where otherwise required by the applicable
            arbitration rules).
          </li>
          <li>
            <strong>Winners List:</strong> For a list of winners (available
            thirty (30) days after the Promotion Period), send a self-addressed,
            stamped envelope specifying the name, date, and location of the
            Sweepstakes to: T-Mobile Sweepstakes, 12920 SE 38th St, Bellevue, WA
            98006. All requests must be received by sixty (60) days after the
            end of the Promotion Period.
          </li>
          <li>
            <strong>Privacy Policy:</strong> Please see Sponsor’s privacy policy
            located at
            <a
              href="http://www.t-mobile.com/company/website/privacypolicy.aspx"
              target="_blank"
              >http://www.t-mobile.com/company/website/privacypolicy.aspx</a>
            for details of Sponsor’s policy regarding the use of personal
            information collected in connection with this Sweepstakes.
          </li>
          <li>
            <strong>Sponsor &amp; Administrator:</strong> The Sponsor of this
            Sweepstakes is T-Mobile USA, Inc., 12920 SE 38th Street, Bellevue,
            WA 98006
          </li>
          <li>
          In exchange for prizes offered through T-Mobile Sweepstakes, T-Mobile
          receives value from the collection of your personal data. Under California law,
          T-Mobile Sweepstakes may be considered a financial incentive program where T-Mobile
           provides an incentive in exchange for your personal data.  We do not assign a
           monetary value to the personal information we receive through T-Mobile Sweepstakes,
          but based on our reasonable estimate, the value of the prizes offered through T-Mobile
          Sweepstakes is reasonably equal to or greater than the value of personal data we collect.
          We use the data we collect through T-Mobile Sweepstakes to facilitate the program, market
          directly to you, and advertise our services more broadly.  See our Privacy Notice for details.
          To exercise personal data choices, visit the <a href="https://www.t-mobile.com/privacy-center/dashboard" target="_blank">T-Mobile Privacy Center</a>.
          </li>
        </ol>
      </v-container>
      <v-container
        class="my-4"
        v-if="errorMessage == null && selectedLanguage == 'es'"
      >
        <p>
          <strong>
            No se requiere compra o pago de ningún tipo para participar o ganar.
            Una compra o un pago no mejorarán sus posibilidades de ganar.
          </strong>
        </p>
        <p>
          <strong>
            Al participar en este Sorteo, usted acepta este Reglamento oficial,
            el cual es un contrato, de modo que debe leerlo con detenimiento
            antes de participar. Con carácter meramente enunciativo, el presente
            contrato incluye indemnizaciones al Patrocinador, según se definen
            en el presente documento, por parte suya, una limitación de sus
            derechos y compensaciones, arbitraje vinculante de reclamaciones,
            renuncia a demandas colectivas y renuncia al derecho a juicio con
            jurado. Para participar en este Sorteo, deberá tener acceso a
            Internet.
          </strong>
        </p>
        <ol>
          <li>
            <strong>Plazos:</strong> El
            {{ spanishSweepstakes }}
            (el “<strong>Sorteo</strong>”) comienza a las
            {{ CURRENT_EVENT_OBJECT.startTime }} {{ formattedTimezone }} del día
            {{ CURRENT_EVENT_OBJECT.startDate }} y termina a las
            {{ CURRENT_EVENT_OBJECT.endTime }} {{ formattedTimezone }} del día
            {{ CURRENT_EVENT_OBJECT.endDate }} (el "<strong>Período de la Promoción</strong>"). El reloj del Patrocinador será el dispositivo que marcará el
            horario oficial del Sorteo. El Período de Promoción consta de
            {{
              this.CURRENT_EVENT_OBJECT.intervals == null ||
              1 >= this.CURRENT_EVENT_OBJECT.intervals.length
                ? '1 intervalo de participación (el '
                : this.CURRENT_EVENT_OBJECT.intervals.length +
                  '  intervalo de participación  (cada uno representa un '
            }}
            “<strong>Intervalo de Participación</strong>”) con los horarios y
            fechas detallados a continuación:
          </li>
        </ol>
        <PastelTable
          class="my-4"
          :rows="CURRENT_EVENT_OBJECT.intervals"
          :columns="[
            {
              source: 'startDate',
              label: 'Fecha de inicio'
            },
            {
              source: 'startTime',
              label: 'Hora de inicio'
            },
            {
              source: 'endDate',
              label: 'Fecha de finalización'
            },
            {
              source: 'endTime',
              label: 'Hora de finalización'
            },
            {
              source: 'winnerSelectionDate',
              label: 'Fecha del Sorteo'
            },
            {
              source: 'winnerSelectionTime',
              label: 'Hora del Sorteo'
            }
          ]"
        />
        <ol start="2">
          <li>
            <strong>Elegibilidad:</strong> El patrocinador de este Sorteo es
            T-Mobile USA, Inc. (el "<strong>Patrocinador</strong>”) 12920 SE
            38th Street, Bellevue, WA 98006. El Sorteo está abierto a residentes
            legales de
            <template
              v-if="
                CURRENT_EVENT_OBJECT.eligibleStates == null ||
                  CURRENT_EVENT_OBJECT.eligibleStates.length == 0 ||
                  CURRENT_EVENT_OBJECT.eligibleStates.length >= 52
              "
            >
              los cincuenta (50) estados de Estados Unidos, el Distrito de
              Columbia y Puerto Rico]
            </template>
            <template v-else>
              <template v-if="CURRENT_EVENT_OBJECT.eligibleStates.length == 1">
                {{ CURRENT_EVENT_OBJECT.eligibleStates[0] }}
              </template>
              <template v-if="CURRENT_EVENT_OBJECT.eligibleStates.length == 2">
                {{ CURRENT_EVENT_OBJECT.eligibleStates[0] }} y
                {{ CURRENT_EVENT_OBJECT.eligibleStates[1] }}
              </template>
              <template v-if="CURRENT_EVENT_OBJECT.eligibleStates.length > 2">
                <template
                  v-for="(state,
                  stateIndex) in CURRENT_EVENT_OBJECT.eligibleStates"
                >
                  {{
                    CURRENT_EVENT_OBJECT.eligibleStates.length - 1 > stateIndex
                      ? state + ', '
                      : ' y ' + state
                  }}
                </template>
              </template>
            </template>
            (el “<strong>Territorio</strong>”) que tengan al menos dieciocho
            (18) años de edad al momento de participar (“<strong>Participante elegible</strong>”). Nulo fuera del Territorio y donde esté prohibido. No son
            elegibles para participar los empleados, funcionarios o directores
            del Patrocinador ni de ninguna otra empresa involucrada en la
            promoción o ejecución del Sorteo o sus respectivas afiliadas y
            subsidiarias (que en conjunto conforman las "<strong>Partes eximidas”</strong>”), así como tampoco los familiares directos (definidos como
            padres, cónyuge, hijos, hermanos y abuelos) y quienes vivan en el
            mismo domicilio de cada uno de dichos empleados, funcionarios y
            directores. Al participar, cada participante acepta regirse por este
            Reglamento Oficial y por las decisiones del Patrocinador, que son
            definitivas y vinculantes en todos los aspectos. Este Reglamento
            Oficial y las decisiones del Patrocinador son definitivas y
            vinculantes en todos los aspectos.
          </li>
          <li>
            <strong>Participación:</strong> Los participantes elegibles deberán
            enviar una participación a través de
            <template
              v-if="
                CURRENT_EVENT_OBJECT.eventLocation == 'Online/Virtual Event'
              "
            >
              <a
                :href="
                  host + '/#/engagements/register/' + CURRENT_EVENT_OBJECT.id
                "
                target="_blank"
                >{{
                  host + '/#/engagements/register/' + CURRENT_EVENT_OBJECT.id
                }}</a>.
            </template>
            <template
              v-if="
                CURRENT_EVENT_OBJECT.eventLocation == 'Physical/In Person Event'
              "
            >
              enviarla en persona a
              {{ CURRENT_EVENT_OBJECT.physicalEventAddress }},
              {{ CURRENT_EVENT_OBJECT.physicalEventCity }},
              {{ CURRENT_EVENT_OBJECT.state }},
              {{ CURRENT_EVENT_OBJECT.physicalEventZip }}.
            </template>
            <template
              v-if="
                CURRENT_EVENT_OBJECT.eventLocation ==
                  'Combination Online/In Person'
              "
            >
              <a
                :href="
                  host + '/#/engagements/register/' + CURRENT_EVENT_OBJECT.id
                "
                target="_blank"
                >{{
                  host + '/#/engagements/register/' + CURRENT_EVENT_OBJECT.id
                }}</a>
              o enviarla en persona a
              {{ CURRENT_EVENT_OBJECT.physicalEventAddress }},
              {{ CURRENT_EVENT_OBJECT.physicalEventCity }},
              {{ CURRENT_EVENT_OBJECT.state }},
              {{ CURRENT_EVENT_OBJECT.physicalEventZip }}.
            </template>
            (“<strong>Participación</strong>”).
            <strong>
              Se aplican las tarifas estándar de datos. Límite de una (1)
              participación por persona.
            </strong>
            Se prohíbe el uso de dispositivos automatizados para participar en
            este Sorteo. Ni el Patrocinador ni sus agentes serán responsables
            por participaciones extraviadas, tardías, dañadas, mal dirigidas,
            ilegibles o incompletas.
          </li>
          <li>
            <strong>Términos adicionales:</strong> el Patrocinador podría
            prohibir que un participante participe en el Sorteo si, según su
            exclusivo criterio, determinara que dicho participante estuviera
            intentando socavar el legítimo funcionamiento del Sorteo al hacer
            trampa, hackear, engañar o utilizar alguna otra práctica injusta
            (incluido el uso de programas automatizados de entrada rápida) o
            tuviera la intención de molestar, maltratar, amenazar u hostigar a
            cualquier otro participante o al Patrocinador o sus agentes, o si el
            participante hubiera enviado o intentado enviar un código malicioso,
            archivos .exe o cualquier archivo que contenga un código malicioso.
          </li>
          <li>
            <strong>Selección del ganador:</strong> FEn cada intervalo de
            participación, el Patrocinador sorteará al azar
            {{ CURRENT_EVENT_OBJECT.numberOfWinners }} ganador(es) potencial(es)
            del total de participaciones elegibles en cada Fecha de Sorteo
            aplicable. Las participaciones elegibles no se transfieren a ningún
            Intervalo de Participación subsecuente. La evaluación del posible
            ganador del premio se realizará bajo la supervisión del
            Patrocinador, cuyas decisiones son definitivas y vinculantes en
            todos los aspectos relacionados con el Sorteo. El ganador o
            ganadores potenciales recibirán una notificación de parte del
            Patrocinador por email o llamada telefónica el día del Sorteo o a
            los pocos días. Sujeto a la verificación de elegibilidad y
            cumplimiento de este Reglamento oficial, los potenciales ganadores
            se declararán como ganadores oficiales del Sorteo (cada uno de
            ellos, un "<strong>PGanador del premio</strong>”). Si cualquier
            posible ganador seleccionado no envía su respuesta al Patrocinador
            dentro de las 24 horas de haber sido notificado, o bien si se
            determina que no es elegible, si no puede recibir la notificación
            del Patrocinador o no puede cumplir o no cumple con este Reglamento
            oficial, dicho posible ganador podrá ser descalificado y el
            Patrocinador podrá seleccionar a otro posible ganador de entre todas
            las participaciones elegibles restantes. Las probabilidades de ganar
            dependen de la cantidad concreta de participaciones elegibles
            recibidas al momento de finalizar el Período de la promoción. El
            Ganador del premio estará sujeto a verificación.
          </li>
          <li>
            <strong>Premio; valor de venta al público (“VVP”) aproximado:</strong>
            El premio o los premios constarán de lo siguiente:
            <p v-html="formattedSpanishPrizeDescription"></p>

            El VVP total del premio es ${{
              CURRENT_EVENT_OBJECT.arv != null
                ? CURRENT_EVENT_OBJECT.arv
                : '0.00'
            }}.
          </li>
          <li>
            <strong>Términos adicionales del Premio:</strong> : todos los
            detalles del Premio no especificados en la precedente descripción
            serán determinados por el Patrocinador a su exclusivo criterio. El
            Ganador del premio será el único responsable de todos los costos,
            cargos y gastos no indicados expresamente en la descripción del
            premio. No se otorgará ni reembolsará ninguna diferencia entre el
            valor de venta al público aproximado y el valor real del premio. Sin
            limitar la generalidad de lo antedicho, el Ganador del premio será
            responsable de todos los impuestos federales, estatales y locales y
            cualquier otro impuesto relacionado con la adjudicación, aceptación
            o uso del premio, excepto los impuestos incluidos en el costo del
            premio. El valor real del premio puede variar. No se permiten
            reembolsos ni crédito por cambios. El Ganador del premio tendrá
            estrictamente prohibido vender, subastar, intercambiar o, de
            cualquier otra manera, transferir el premio (o una parte del
            premio), a menos que el Patrocinador lo autorizara por escrito. El
            premio no podrá transferirse, y no se permite ninguna sustitución o
            equivalente en efectivo, excepto según exclusivo criterio del
            Patrocinador. El Patrocinador se reserva el derecho de sustituir el
            premio (o una parte del premio) por un premio o premios del mismo
            valor de venta al público aproximado. En caso de que el premio
            conste de acceso o participación en algún evento y el ganador no se
            encuentre disponible durante el horario programado, o si el evento
            en cuestión se cancela por cualquier motivo, no se otorgará ningún
            premio adicional. Las Partes eximidas, de manera individual y
            colectiva, no serán responsables por ninguna lesión o pérdida que
            sufra el Ganador del premio o su invitado al utilizar el premio.
          </li>
          <li>
            <strong>Cómo reclamar el premio/Exención de responsabilidad por uso de
              imagen:</strong>
            La elegibilidad, la edad y todas las afirmaciones realizadas por el
            o los posibles Ganadores del premio, quedarán sujetas a
            verificación. El Patrocinador no será responsable por
            llamadas/correspondencia/correos electrónicos fraudulentos
            provenientes de cualquier tercero o recibidos por los participantes
            por alguien que no fuera el Patrocinador. Al aceptar el premio, el
            Ganador del premio otorga su permiso al Patrocinador y a sus
            representantes para que utilicen el nombre, fotografía, imagen, voz,
            información biográfica, afirmaciones y la ciudad y estado de
            residencia del Ganador del premio para fines de anuncio o publicidad
            en todo el mundo y en todas las formas de medios de difusión
            conocidos actualmente o que se desarrollen en el futuro (incluidos,
            entre otros, la publicación del nombre del Ganador del premio en las
            páginas de Instagram, Facebook, Twitter y otras redes sociales del
            Patrocinador), a perpetuidad, sin compensación adicional, excepto en
            Tennessee y donde estuviera prohibido por la ley.
          </li>
          <li>
            <strong>Limitaciones de responsabilidad:</strong>
            Los participantes aceptan que las Partes eximidas no asumen ninguna
            responsabilidad, y específicamente renuncian a cualquier
            responsabilidad por cualquiera de las siguientes circunstancias o
            que surgiera de cualquiera de las siguientes circunstancias,
            independientemente de la causa, en relación con el Sorteo: (1)
            información incorrecta o inexacta, ya sea por responsabilidad del
            participante, proveedores de servicio móvil, proveedores de servicio
            de Internet (“<strong>ISP</strong>”) o intervención humana no
            autorizada; (2) dificultades técnicas o fallas de cualquier tipo
            incluidas, entre otras, averías, interrupciones o desconexiones en
            las transmisiones o conexiones, líneas telefónicas, hardware o
            software de las redes, computadoras, equipos, errores de
            programación, servicios de cable, satélites, torres celulares o ISP
            o proveedores de servicio móvil; (3) errores, virus, gusanos,
            troyanos o ataques maliciosos similares; (4) errores o fallas
            tipográficas, de impresión, de red, mecánicas, electrónicas,
            técnicas, humanas o de otro tipo; (5) cualquier obligación o
            responsabilidad con respecto al Sorteo o el premio (incluidos daños
            a la propiedad, daños y perjuicios, lesiones personales o muerte) en
            relación con la participación en este Sorteo, el ofrecimiento o
            anuncio del premio, o la aceptación y posesión, uso, mal uso o
            defectos del premio otorgado conforme al presente; (6) transacciones
            o participaciones extraviadas, incompletas, tardías, dirigidas a
            otro destinatario, distorsionadas, no entregadas, incompletas,
            robadas o dañadas; o transmisiones distorsionadas, extraviadas, mal
            encauzadas o cifradas; o (7) intervención humana o mecánica no
            autorizada en el Sorteo o en cualquier otra porción del proceso de
            participación en este Sorteo.
            <strong>ADVERTENCIA:</strong> Acualquier intento de dañar cualquier
            sitio web, aplicación o plataforma o de menoscabar el legítimo
            funcionamiento de este Sorteo puede constituir una infracción de las
            leyes civiles o penales y, en caso de producirse tal intento, el
            Patrocinador se reserva el derecho de exigir compensación por daños
            o cualquier otra compensación (incluidos los honorarios de abogados)
            a cualquier individuo o entidad responsable por el intento, en la
            medida en que lo permita la ley.
          </li>
          <li>
            <strong>Acuerdo/Exención de responsabilidad en caso de reclamos:</strong>
            Al participar, el participante da fe de haber leído y entendido el
            Reglamento oficial y acepta acatar el Reglamento oficial de este
            Sorteo. Todas las decisiones tomadas por el Patrocinador con
            respecto a este Sorteo son definitivas. Al participar, el
            participante acepta que el Patrocinador le envíe notificaciones
            utilizando la información de contacto proporcionada como parte de la
            participación para fines de administración de este Sorteo. Al
            participar o aceptar un premio, el participante acepta liberar,
            defender, exonerar y eximir a las Partes eximidas de cualquier tipo
            de lesión, pérdida o daño a la persona, incluida la muerte, o la
            propiedad como resultado total o parcial, directo o indirecto, de la
            aceptación o uso/uso indebido de un premio, la participación en el
            Sorteo y cualquier actividad relacionada. Ni el Patrocinador ni
            ninguna de las partes eximidas será responsable por ningún error
            tipográfico o de otro tipo en la impresión de este Reglamento
            oficial, la administración del Sorteo o en el anuncio o la
            distribución de cualquier premio o elemento del premio. En ningún
            caso se adjudicarán más premios que la cantidad establecida. En caso
            de que el Patrocinador no pueda continuar con el Sorteo o que la
            integridad o viabilidad del Sorteo se vea socavada a causa de
            cualquier acontecimiento, incluidos, entre otros, incendios,
            inundaciones, epidemias, terremotos, explosiones, conflictos
            laborales o huelgas, casos fortuitos o de enemigo público, fallas en
            los satélites o equipos, protestas o disturbios civiles, guerra
            (declarada o no), amenazas o actividad terrorista, o una ley,
            ordenanza o norma de cualquier gobierno local, provincial, estatal o
            federal, mandato de cualquier tribunal o jurisdicción, u otra causa
            que no esté bajo el control razonable del Patrocinador (un
            acontecimiento de “<strong>Fuerza mayor</strong>” event), el
            Patrocinador tendrá derecho, a su propio arbitrio, de acortar,
            modificar, suspender, cancelar o finalizar el Sorteo sin más
            obligación. En ningún caso se adjudicarán más premios que la
            cantidad establecida. Si el Patrocinador, a su propio arbitrio,
            elige acortar el Sorteo como consecuencia de un acontecimiento de
            Fuerza mayor, el Patrocinador se reserva el derecho, pero no tendrá
            obligación, de adjudicar los premios entre todas las participaciones
            válidas y elegibles recibidas hasta el momento de dicho
            acontecimiento de Fuerza mayor. El participante ratifica que nada de
            lo contenido en el presente documento constituirá una relación de
            empleo, empresa conjunta o asociación entre el participante y el
            Patrocinador. De ninguna manera se interpretará que el participante
            sea el agente o que actúe como agente del Patrocinador en ningún
            aspecto.
          </li>
          <li>
            <strong>Ley de aplicación:</strong> Excepto donde esté prohibido,
            los participantes aceptan que: (1) todas y cada una de las disputas,
            demandas y acciones generadas por el Sorteo o relacionadas con este
            (incluidas todas las disputas, demandas y acciones que surjan del
            premio o en relación con este, su disponibilidad o uso; o con el
            desarrollo del Sorteo) se resolverán en forma individual, sin
            recurrir a ningún tipo de demanda colectiva, y exclusivamente
            mediante arbitraje de conformidad con las normas de la Asociación
            Americana de Arbitraje, vigentes en ese momento; (2) renunciarán a
            todo derecho a demandar u obtener desagravios por orden judicial o
            similar; (3) todos y cada uno de los reclamos, fallos o laudos
            estarán limitados a los gastos menores reales incurridos, por
            ejemplo, los costos relacionados con la participación en el Sorteo
            (si los hubiera), pero no se incluirán bajo ninguna circunstancia
            los honorarios de abogados; y (4) bajo ninguna circunstancia el
            participante podrá obtener compensaciones por daños punitivos,
            accidentales, especiales, ejemplares o consecuentes y otros daños y,
            por el presente, el participante renuncia a todos los derechos de
            alegar dichos daños, a excepción de gastos menores reales (si los
            hubiera), y a todos los derechos de multiplicar los daños o
            aumentarlos de otro modo. Cualquier cuestión o duda respecto del
            efecto legal, la validez, la interpretación y la capacidad de exigir
            el cumplimiento de este Reglamento oficial, o los derechos y las
            obligaciones del participante o de una de las partes eximidas en
            relación con el Sorteo, se regirá y se interpretará exclusivamente
            conforme a las leyes del Estado de Washington (sin dar efecto a
            ninguna norma o disposición sobre elección de derecho o conflicto de
            leyes) y todos los procesos legales tendrán lugar en el condado de
            King, Washington.
          </li>
          <li>
            <strong>. Lista de ganadores:</strong> para obtener una lista de
            ganadores (disponible treinta (30) días después del Período de la
            Promoción), envíe un sobre estampillado con su dirección que
            especifique el nombre, la fecha y la ubicación del Sorteo a:
            T-Mobile Sweepstakes, 12920 SE 38th St, Bellevue, WA 98006. Todas
            las solicitudes se deberán recibir dentro de los sesenta (60) días
            posteriores al término del Período de la Promoción.
          </li>
          <li>
            <strong>Política de privacidad:</strong> Pconsulte la política de
            privacidad del Patrocinador en
            <a
              href="http://es.t-mobile.com/company/website/privacypolicy.aspx"
              target="_blank"
              >http://es.t-mobile.com/company/website/privacypolicy.aspx</a>
            para conocer detalles de la política del Patrocinador sobre el uso
            de la información personal recopilada en relación con este Sorteo.
          </li>
          <li>
            <strong>Patrocinador y administrador:</strong> el Patrocinador de
            este Sorteo es T-Mobile USA, Inc., 12920 SE 38th Street, Bellevue,
            WA 98006
          </li>
        </ol>
      </v-container>
    </template>
  </section>
  <section v-else>
    <BannerTitle :title="errorMessage" />
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import OptionData from '@/utility/OptionData';
import ThemeSwitcher from '@/utility/ThemeSwitcher';
import BannerTitle from '@/components/BannerTitle.vue';
import PastelTable from '@/components/PastelTable.vue';
import HelperFunctions from '@/utility/HelperFunctions';

export default {
  name: 'GeneratedRules',
  components: {
    BannerTitle,
    PastelTable
  },
  computed: {
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      translations: 'translations'
    }),
    ...mapState('EVENT_MANAGER_STORE', [
      'CURRENT_EVENT_OBJECT',
      'LOADING',
      'API_CALL_IN_PROGRESS'
    ]),
    brandWatcher() {
      return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.brand
        ? this.CURRENT_EVENT_OBJECT.brand
        : 'T-Mobile';
    },
    updateformattedTitle() {
      let title = this.CURRENT_EVENT_OBJECT.name + ' Official Rules';
      if (this.selectedLanguage == 'es') {
        if (this.translations.sweepstakesTitle) {
          title = this.translations.sweepstakesTitle + ' Reglamento Oficial';
        } else if (this.translations.event_name) {
          title = this.translation.event_name + ' Reglamento Oficial';
        } else {
          if (this.CURRENT_EVENT_OBJECT.sweepstakesTitle) {
            title =
              this.CURRENT_EVENT_OBJECT.sweepstakesTitle +
              ' Reglamento Oficial';
          } else {
            title = this.CURRENT_EVENT_OBJECT.name + ' Reglamento Oficial';
          }
        }
      } else if (this.selectedLanguage == 'en') {
        if (this.CURRENT_EVENT_OBJECT.sweepstakesTitle) {
          title =
            this.CURRENT_EVENT_OBJECT.sweepstakesTitle + ' Official Rules';
        }
      }
      return title;
    },
    selectedLanguage: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.defaultLanguage || 'en';
      },
      set(value) {
        // set on event object for footer language
        this.CURRENT_EVENT_OBJECT.defaultLanguage = value;
      }
    }
  },
  methods: {
    ...mapActions(['fetchTranslations']),
    changeTheme(brand) {
      ThemeSwitcher.ChangeTheme(this, brand);
    }
  },
  data() {
    return {
      errorMessage: null,
      formattedTimezone: null,
      host: null,
      formattedPrizeDescription: null,
      formattedSpanishPrizeDescription: null,
      spanishSweepstakes: null
    };
  },
  watch: {
    brandWatcher: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.changeTheme(newVal);
        }
      }
    }
  },
  async created() {
    this.host = window.origin;
    let eventId = this.$route.params.id;

    if (this.userAttributes != null) {
      var loggedInResponse = await this.$store.dispatch(
        'EVENT_MANAGER_STORE/LOAD_EVENT',
        eventId
      );
      if (!loggedInResponse.id) {
        this.errorMessage =
          loggedInResponse.errorMessage || loggedInResponse.message;
        return;
      }
    } else {
      var publicResponse = await this.$store.dispatch(
        'EVENT_MANAGER_STORE/LOAD_PUBLIC_EVENT',
        eventId
      );
      if (!publicResponse.id) {
        this.errorMessage =
          publicResponse.errorMessage || publicResponse.message;
        return;
      }
      // rules should not be publicly available if archived
      if (this.CURRENT_EVENT_OBJECT.archived) {
        this.errorMessage = 'This event has been archived.';
        return;
      }
    }

    if (this.CURRENT_EVENT_OBJECT.eventLanguage === 'bi') {
      await this.fetchTranslations(this.$route.params.id);

      this.selectedLanguage = this.CURRENT_EVENT_OBJECT.defaultLanguage;
    } else {
      this.selectedLanguage = 'en';
    }

    this.formattedTimezone =
      OptionData.humanReadableTimeZoneFromValue(
        this.CURRENT_EVENT_OBJECT.timezone
      ) + ' Time';

    // generated rules are only for enter to win events
    if (this.CURRENT_EVENT_OBJECT.contestType !== 'Enter for a Chance to Win') {
      this.$router.push('/dashboard');
    }
    this.formattedTimezone =
      OptionData.humanReadableTimeZoneFromValue(
        this.CURRENT_EVENT_OBJECT.timezone
      ) + ' Time';

    if (this.translations.sweepstakesTitle) {
      this.spanishSweepstakes = this.translations.sweepstakesTitle;
    } else if (this.CURRENT_EVENT_OBJECT.sweepstakesTitle) {
      this.spanishSweepstakes = this.CURRENT_EVENT_OBJECT.sweepstakesTitle;
    } else {
      this.spanishSweepstakes = this.CURRENT_EVENT_OBJECT.name;
    }

    if (this.translations.fullPrizeDescription) {
      this.formattedSpanishPrizeDescription = this.translations.fullPrizeDescription
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
        .replace(/\n/g, '<br />')
        .trim();
    } else if (this.CURRENT_EVENT_OBJECT.fullPrizeDescription) {
      this.formattedSpanishPrizeDescription = this.CURRENT_EVENT_OBJECT.fullPrizeDescription
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
        .replace(/\n/g, '<br />')
        .trim();
    } else {
      this.formattedSpanishPrizeDescription = 'No premio';
    }
    if (this.CURRENT_EVENT_OBJECT.fullPrizeDescription) {
      this.formattedPrizeDescription = this.CURRENT_EVENT_OBJECT.fullPrizeDescription
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
        .replace(/\n/g, '<br />')
        .trim();
    } else {
      this.formattedPrizeDescription = 'No Prize.';
    }
  },
  updated() {
    // log the event before the user makes changes to state
    if (this.originalEvent && this.CURRENT_EVENT_OBJECT) {
      if (
        Object.keys(this.originalEvent).length === 0 &&
        this.CURRENT_EVENT_OBJECT.engagements !== undefined
      ) {
        this.originalEvent = JSON.stringify(this.CURRENT_EVENT_OBJECT);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.embedded-rules {
  width: 100%;
  height: 100vh;
}

#sidebarContainer {
  display: none;
}

.language-buttons ::v-deep .v-input--radio-group__input {
  justify-content: center;
}

.language-buttons
  ::v-deep
  .v-input--radio-group.v-input--radio-group--row
  .v-radio {
  border: 1px solid;
  color: var(--v-primary-base);
  padding: 0 30px;
  border: 1px solid var(--v-primary-base);

  .v-input--selection-controls__input {
    display: none;
  }
  label {
    color: var(--v-primary-base);
  }

  &.v-item--active {
    background: var(--v-primary-base);

    label {
      color: white;
    }
  }
}
</style>
