import { render, staticRenderFns } from "./TMO_SP_970x250_ES.vue?vue&type=template&id=4d4e4c2c&"
import script from "./TMO_SP_970x250_ES.vue?vue&type=script&lang=js&"
export * from "./TMO_SP_970x250_ES.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports