<template>
  <div
    class="app-engagement-form"
    v-if="duringAnEntryPeriod"
    :class="
      !CURRENT_EVENT_OBJECT || !CURRENT_EVENT_OBJECT.brand
        ? 'solid'
        : CURRENT_EVENT_OBJECT.brand.includes('Texture')
        ? CURRENT_EVENT_OBJECT.brand == 'T-Mobile Texture'
          ? 'tmobile__textured'
          : 'metro__textured'
        : 'solid'
    "
  >
    <v-container v-if="CURRENT_EVENT_OBJECT.id == $route.params.id">
      <v-row justify="center">
        <v-col xs="12" sm="9" md="8">
          <div class="logo-wrapper">
            <router-link to="/dashboard">
              <img
                :src="
                  logoUrl(
                    !CURRENT_EVENT_OBJECT || !CURRENT_EVENT_OBJECT.brand
                      ? 't-mobile-logo-tight--white-v2.png'
                      : CURRENT_EVENT_OBJECT.brand.includes('T-Mobile')
                      ? 't-mobile-logo-tight--white-v2.png'
                      : 'metro-logo-tight--white.png'
                  )
                "
                class="brand-logo"
                :alt="
                  !CURRENT_EVENT_OBJECT || !CURRENT_EVENT_OBJECT.brand
                    ? 'T-Mobile'
                    : CURRENT_EVENT_OBJECT.brand.includes('T-Mobile')
                    ? 'T-Mobile'
                    : 'Metro by T-Mobile'
                "
              />
            </router-link>
          </div>
          <div class="mt-6 text-center app-engagement-form__title">
            <h1 class="event-title">
              {{
                engagement.language === 'es'
                  ? translation.event_name && translation.event_name != ''
                    ? translation.event_name
                    : CURRENT_EVENT_OBJECT.name
                  : CURRENT_EVENT_OBJECT.name
              }}
            </h1>
          </div>
          <v-form v-model="valid" ref="form" @submit="attemptSubmit($event)">
            <div class="py-12 app-engagement-form__wrap px-15 elevation-10">
              <div class="app-engagement-form__label">
                <span
                  :class="
                    preview && CURRENT_EVENT_OBJECT.defaultLanguage == null
                      ? 'language--error'
                      : ''
                  "
                  >{{
                    engagement.language === 'es'
                      ? 'Idioma de preferencia'
                      : 'Preferred Language'
                  }}
                </span>
                <v-tooltip
                  right
                  v-if="preview && CURRENT_EVENT_OBJECT.defaultLanguage == null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="info-icon"
                      color="#fc9e03"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>This field cannot be left blank.</span>
                </v-tooltip>
              </div>
              <v-radio-group
                v-model="engagement.language"
                row
                @change="forceRevalidate()"
              >
                <v-radio ref="englishLang" label="English" value="en"></v-radio>
                <v-radio ref="spanishLang" label="Español" value="es"></v-radio>
              </v-radio-group>
              <v-text-field
                ref="firstName"
                append-icon="mdi-asterisk"
                :rules="[
                  v =>
                    !!v ||
                    (engagement.language === 'es'
                      ? 'El nombre es requisito'
                      : 'First name is required')
                ]"
                v-model="engagement.firstName"
                :label="engagement.language === 'es' ? 'Nombre' : 'First Name'"
              ></v-text-field>
              <v-text-field
                ref="lastName"
                append-icon="mdi-asterisk"
                :rules="[
                  v =>
                    !!v ||
                    (engagement.language === 'es'
                      ? 'El apellido es requisito'
                      : 'Last name is required')
                ]"
                v-model="engagement.lastName"
                :label="engagement.language === 'es' ? 'Apellido' : 'Last Name'"
              ></v-text-field>
              <v-text-field
                ref="email"
                type="email"
                append-icon="mdi-asterisk"
                :rules="emailRules"
                v-model="engagement.email"
                label="Email"
              ></v-text-field>
              <!-- TODO: Business name Spanish translation -->
              <v-text-field
                v-if="CURRENT_EVENT_OBJECT.attendeeFormBusinessName"
                ref="businessName"
                append-icon="mdi-asterisk"
                :rules="[
                  v =>
                    !!v ||
                    (engagement.language === 'es'
                      ? 'Business name is required'
                      : 'Business name is required')
                ]"
                v-model="engagement.businessName"
                :label="
                  engagement.language === 'es'
                    ? 'Business Name'
                    : 'Business Name'
                "
              ></v-text-field>
              <v-text-field
                ref="phone"
                type="tel"
                append-icon="mdi-asterisk"
                :rules="[
                  v =>
                    !!v ||
                    (engagement.language === 'es'
                      ? 'El número de teléfono es requisito'
                      : 'Phone is required'),
                  v =>
                    /^[\d\(\)\-\s]+$/.test(v) ||
                    (engagement.language === 'es'
                      ? 'El número de teléfono solo puede estar compuesto por números, guiones y paréntesis'
                      : 'Phone can only be composed of numbers, hyphens, and parentheses')
                ]"
                v-model="engagement.phone"
                :label="
                  engagement.language === 'es'
                    ? 'Teléfono celular'
                    : 'Mobile Phone'
                "
              ></v-text-field>
              <v-text-field
                ref="addressLine1"
                v-model="engagement.addressLine1"
                append-icon="mdi-asterisk"
                :rules="[
                  v =>
                    !!v ||
                    (engagement.language === 'es'
                      ? 'Dirección Línea 1 es necesaria'
                      : 'Address Line 1 is required.')
                ]"
                :label="
                  engagement.language === 'es'
                    ? 'Dirección Línea 1'
                    : 'Address line 1'
                "
              ></v-text-field>
              <v-text-field
                ref="addressLine2"
                v-model="engagement.addressLine2"
                :label="
                  engagement.language === 'es'
                    ? 'Dirección Línea 2'
                    : 'Address line 2'
                "
              ></v-text-field>
              <v-text-field
                ref="city"
                v-model="engagement.city"
                :label="engagement.language === 'es' ? 'Ciudad' : 'City'"
              ></v-text-field>
              <v-select
                :rules="[stateRule()]"
                ref="state"
                v-model="engagement.state"
                :items="states"
                :label="engagement.language === 'es' ? 'Estado' : 'State'"
                :append-icon="
                  CURRENT_EVENT_OBJECT.contestStatesRequired
                    ? 'mdi-asterisk'
                    : ''
                "
              ></v-select>
              <v-text-field
                ref="zip"
                append-icon="mdi-asterisk"
                :rules="[
                  v =>
                    !!v ||
                    (engagement.language === 'es'
                      ? 'El código postal es requisito'
                      : 'Zip code is required'),
                  v =>
                    v == null ||
                    v.length <= 9 ||
                    (engagement.language === 'es'
                      ? 'El código postal no puede tener más de 9 caracteres'
                      : 'Zip code cannot have more than 9 characters'),
                  v =>
                    /^[\d\-]+$/.test(v) ||
                    (engagement.language === 'es'
                      ? 'El código postal solo puede estar compuesto por números, guiones y paréntesis'
                      : 'Zip code can only be composed of numbers, hyphens, and parentheses')
                ]"
                v-model="engagement.zip"
                :label="
                  engagement.language === 'es' ? 'Código postal' : 'Zip Code'
                "
              ></v-text-field>
              <v-select
                ref="currentProvider"
                v-model="engagement.currentProvider"
                append-icon="mdi-asterisk"
                :rules="[
                  v =>
                    !!v ||
                    (engagement.language === 'es'
                      ? 'El servicio móvil es requisito'
                      : 'Wireless provider is required')
                ]"
                :items="WirelessProviders"
                :label="
                  engagement.language === 'es'
                    ? 'Compañía actual de servicio móvil'
                    : 'Current Wireless Provider'
                "
              ></v-select>
              <!-- <div class="app-engagement-form__label">
                {{
                  engagement.language === 'es'
                    ? 'Mantente informado de nuestras noticias y ofertas más recientes.'
                    : 'Stay connected with our latest news &amp; offers?'
                }}
              </div> -->
              <v-checkbox
                ref="contactAllowed"
                :label="engagement.language === 'es' ? 'Al aceptar, [1] estás aceptando las Reglas Oficiales y [2] que podemos informarte sobre futuras ofertas.  No te preocupes, puedes cancelar tu suscripción en cualquier momento.' : 'By accepting, [1] you’re agreeing to the Official Rules and [2] that we can tell you about future offers.  Don’t worry, you can opt out at any time.'"
                v-model="engagement.contactAllowed"
                :rules="[
                  nonEmptyRule(
                    engagement.language === 'es'
                      ? 'Debes marcar esta casilla para continuar.'
                      : 'You must check this box to proceed.'
                    ),
                    v =>
                      v == true ||
                      (engagement.language === 'es'
                        ? 'Debes marcar esta casilla para continuar.'
                        : 'You must check this box to proceed.'),
                ]"
              ></v-checkbox>
              <!-- <v-radio-group
                ref="contactAllowed"
                v-model="engagement.contactAllowed"
                :rules="[
                  nonEmptyRule(
                    engagement.language === 'es'
                      ? 'Debe elegir si desea o no permanecer conectado.'
                      : 'You must choose whether or not you wish to stay connected.'
                  )
                ]"
                row
              >
                <v-radio
                  :label="
                    engagement.language === 'es' ? 'Inscríbanme' : 'Sign Up'
                  "
                  :value="true"
                ></v-radio>
                <v-radio
                  :label="
                    engagement.language === 'es' ? 'No, gracias' : 'No Thanks'
                  "
                  :value="false"
                ></v-radio>
              </v-radio-group> -->
              <p>
                {{ getPolicyText(CURRENT_EVENT_OBJECT) }}
                <a
                  :href="
                    engagement.language === 'es'
                      ? 'https://es.t-mobile.com/privacy-center/our-practices/privacy-policy'
                      : 'https://www.t-mobile.com/privacy-center/our-practices/privacy-policy'
                  "
                  target="_blank"
                  >{{
                    engagement.language === 'es'
                      ? 'Política de Privacidad.'
                      : 'Privacy Notice.'
                  }}
                </a>
              </p>
            </div>
            <div class="my-6 text-center">
              <GoogleRecaptcha
                v-if="userAttributes == null"
                :on-verify="captchaOnVerify"
                :on-expired="captchaOnExpired"
                ref="recaptcha"
              ></GoogleRecaptcha>
              <v-btn
                v-if="!preview"
                class="btn__primary app-engagement-form__submit"
                type="submit"
                :disabled="
                  !valid || (userAttributes == null && captchaToken == null)
                "
                :loading="engagementPostOut"
                block
              >
                {{ engagement.language === 'es' ? 'Enviar' : 'Complete' }}
              </v-btn>
              <v-btn
                v-if="preview"
                class="btn__primary app-engagement-form__submit"
                type="submit"
                :disabled="
                  !valid || (userAttributes == null && captchaToken == null)
                "
                :loading="engagementPostOut"
                block
              >
                {{
                  engagement.language === 'es'
                    ? 'No se puede enviar: solo la vista previa del evento'
                    : 'Cannot Submit: Event Preview Only'
                }}
              </v-btn>
            </div>

            <div
              v-if="!CURRENT_EVENT_OBJECT.abbreviatedLegal"
              class="app-engagement-form__legal"
            >
              <p
                class="px-2 text-center"
                v-html="
                  helperFunctions.OutputLegal(
                    CURRENT_EVENT_OBJECT,
                    engagement.language
                  )
                "
              ></p>
            </div>

            <div class="text-center">
              <p v-if="CURRENT_EVENT_OBJECT.abbreviatedLegal">
                <v-tooltip
                  right
                  v-if="
                    preview &&
                      (CURRENT_EVENT_OBJECT.abbreviatedLegal == null ||
                        CURRENT_EVENT_OBJECT.abbreviatedLegal == '')
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="info-icon"
                      color="#fc9e03"
                      v-bind="attrs"
                      v-on="on"
                      style="visibility: hidden"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>This field cannot be left blank.</span>
                </v-tooltip>
                <span
                  :class="
                    preview &&
                    (CURRENT_EVENT_OBJECT.abbreviatedLegal == null ||
                      CURRENT_EVENT_OBJECT.abbreviatedLegal == '')
                      ? 'legal--error'
                      : 'legal'
                  "
                  v-html="
                    helperFunctions.OutputLegal(
                      CURRENT_EVENT_OBJECT,
                      engagement.language
                    )
                  "
                >
                </span>
                <v-tooltip
                  right
                  v-if="
                    preview &&
                      (CURRENT_EVENT_OBJECT.abbreviatedLegal == null ||
                        CURRENT_EVENT_OBJECT.abbreviatedLegal == '')
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="info-icon"
                      color="#fc9e03"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>You have indicated that custom legal is required, but have
                    not supplied any.</span>
                </v-tooltip>
              </p>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="submitted"
      persistent
      max-width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center text-center grey lighten-2 dialogResponseBox" v-html="dialogTitle"></v-card-title>
        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="px-6 modal-button-outlined"
            color="primary"
            outlined
            tile
            @click="
              submitted = false;
              if (isCheckIn) {
                returnToCheckInForm();
              }
            "
            >Dismiss
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      class="loading-hider"
      v-if="CURRENT_EVENT_OBJECT.id != $route.params.id"
    >
      <v-progress-circular
        v-if="errorMessage == null"
        :size="70"
        color="white"
        indeterminate
      ></v-progress-circular>
      <h1 v-if="errorMessage" class="event-error">
        {{ errorMessage }}
      </h1>
    </div>
  </div>
  <div class="no-entries-allowed" v-else>
    <div class="mt-6 text-center app-engagement-form__title">
      <h1 v-if="engagement.language == 'es'" class="event-title">
        La participación está cerrada ahora, vuelve más tarde.
      </h1>
      <h1 class="event-title" v-else>
        Entries are not currently allowed at this time, please check back.
      </h1>
    </div>
  </div>
</template>

<script>
import ValidationRules from '@/utility/ValidationRules';
import OptionData from '@/utility/OptionData';
import ThemeSwitcher from '@/utility/ThemeSwitcher';
import HelperFunctions from '@/utility/HelperFunctions';
import TMOEngagement from '../models/TMOEngagement';
import { mapGetters, mapActions, mapState } from 'vuex';
import GoogleRecaptcha from '@/components/GoogleRecaptcha.vue';
import { DateTime } from 'luxon';

export default {
  name: 'EngagementForm',
  components: {
    GoogleRecaptcha
  },
  props: {
    preview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCheckIn: this.$route.query.checkIn,
      errorMessage: null,
      engagementPostOut: false,
      dialogTitle: '',
      dialogMessage: '',
      valid: false,
      submitted: false,
      states: OptionData.States(),
      WirelessProviders: OptionData.WirelessProviders(),
      captchaToken: null,
      duringAnEntryPeriod: true,
      emailRules: [
        v =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          (this.engagement.language === 'es'
            ? 'El email no es válido'
            : 'Not a valid email')
      ]
    };
  },
  watch: {
    brandWatcher: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.changeTheme(newVal);
        }
      }
    }
  },
  async created() {
    if (this.userAttributes != null) {
      var loggedInResponse = await this.$store.dispatch(
        'EVENT_MANAGER_STORE/LOAD_EVENT',
        this.$route.params.id
      );
      if (!loggedInResponse.id) {
        this.errorMessage =
          loggedInResponse.errorMessage || loggedInResponse.message;
        return;
      }
    } else {
      var publicResponse = await this.$store.dispatch(
        'EVENT_MANAGER_STORE/LOAD_PUBLIC_EVENT',
        this.$route.params.id
      );
      if (!publicResponse.id) {
        this.errorMessage =
          publicResponse.errorMessage || publicResponse.message;
        return;
      }
    }
    if (!this.preview) {
      // if not logged in and event is not published, don't render the form
      if (!this.userAttributes && !this.CURRENT_EVENT_OBJECT.published) {
        this.duringAnEntryPeriod = false;
        return;
      }
      let intervals = this.CURRENT_EVENT_OBJECT.intervals;
      let timezone = this.CURRENT_EVENT_OBJECT.timezone || 'America/New_York';
      let now = DateTime.now().toISO();
      now = DateTime.fromISO(now, { zone: timezone });

      let curInterval = intervals.filter(interval => {
        /* eslint-disable-next-line */
        let start = DateTime.fromISO(
          interval.startDate + 'T' + interval.startTime,
          { zone: timezone }
        );
        /* eslint-disable-next-line */
        let end = DateTime.fromISO(interval.endDate + 'T' + interval.endTime, {
          zone: timezone
        });
        return start <= now && now <= end;
      });

      // cannot register for batch import events
      let isBatchEvent =
        this.CURRENT_EVENT_OBJECT.contestType === 'Batch Import Event';
      if (curInterval.length < 1 || isBatchEvent) {
        // logged in users can still see preview
        if (this.userAttributes) {
          this.$router.push(
            '/engagements/preview/' + this.CURRENT_EVENT_OBJECT.id
          );
        } else {
          this.duringAnEntryPeriod = false;
        }
      }
    }
    await this.fetchTranslations(this.$route.params.id);
    this.engagement.language = this.CURRENT_EVENT_OBJECT.defaultLanguage;
    // check if now is during an entry period
  },

  methods: {
    ...mapActions([
      'submitEngagement',
      'submitEngagementWithToken',
      'resetEngagement',
      'fetchTranslations'
    ]),
    logoUrl(logo) {
      return require('../assets/' + logo);
    },
    forceRevalidate() {
      for (var key of Object.keys(this.engagement)) {
        if (this.$refs[key]) {
          this.$refs[key].validate();
        }
      }
    },
    nonEmptyRule(error) {
      return ValidationRules.NonEmptyRule(error);
    },
    stateRule() {
      return (
        !this.CURRENT_EVENT_OBJECT.contestStatesRequired ||
        this.nonEmptyRule('State is required.')
      );
    },
    getPolicyText(event) {
      let policyText = '';
      switch (this.engagement.language) {
        case 'en':
          policyText =
            "By registering you confirm you are 18 years or older and acknowledge T-Mobile's ";
          break;
        case 'es':
          policyText =
            'Al inscribirte, confirmas que tienes al menos 18 años de edad y que aceptas nuestra ';
          break;
        default:
          policyText =
            "By registering you confirm you are 18 years or older and acknowledge T-Mobile's ";
      }
      return policyText;
    },
    async attemptSubmit(e) {
      e.preventDefault();
      this.engagement.eventId = this.$route.params.id;
      this.engagement.contestType = this.CURRENT_EVENT_OBJECT.contestType;
      this.validate();
      if (!this.valid) {
        return;
      }
      if (this.userAttributes != null) {
        if (
          this.userAttributes.given_name != null &&
          this.userAttributes.given_name != null
        ) {
          this.engagement.createdByFirstName = this.userAttributes.given_name;
        } else {
          this.engagement.createdByFirstName = 'WEB';
        }
        if (
          this.userAttributes.family_name != null &&
          this.userAttributes.family_name != null
        ) {
          this.engagement.createdByLastName = this.userAttributes.family_name;
        } else {
          this.engagement.createdByLastName = 'WEB';
        }
        if (
          this.userAttributes.email != null &&
          this.userAttributes.email != null
        ) {
          this.engagement.createdByEmail = this.userAttributes.email;
        } else {
          this.engagement.createdByEmail = 'WEB';
        }
      }
      try {
        this.engagementPostOut = true;
        if (this.userAttributes != null) {
          await this.submitEngagement(this.engagement);
        } else {
          await this.submitEngagementWithToken(
            this.engagement,
            this.captchaToken
          );
        }
        if (this.$route.query.checkIn) {
          await this.$store.dispatch('EVENT_MANAGER_STORE/CHECK_IN', {
            eventId: this.CURRENT_EVENT_OBJECT.id,
            email: this.engagement.email
          });
        }
        this.engagementPostOut = false;
        this.dialogTitle =
          this.engagement.language === 'es'
            ? 'Registro exitoso'
            : 'You\'ve registered for<br>T-Mobile\'s Sweepstakes!';
        this.dialogMessage =
          this.engagement.language === 'es' ? 'Gracias' : 'Thank you.';
        this.submitted = true;
        this.$refs.form.resetValidation();
        this.resetEngagement();
        if (this.$refs.recaptcha) {
          this.$refs.recaptcha.reset();
        }
        this.engagement.language = this.CURRENT_EVENT_OBJECT.defaultLanguage;
        window.scrollTo(0, 0);
      } catch (e) {
        //  console.log(e);
        this.dialogTitle = 'ERROR';
        this.dialogMessage =
          e.response !== undefined
            ? e.response.data.errorMessage
            : this.engagement.language === 'es'
            ? 'No se pudo actualizar la interacción.'
            : 'Interaction could not be updated.';
        this.submitted = true;
        this.engagementPostOut = false;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    captchaOnVerify(token) {
      this.captchaToken = token;
      return;
    },
    captchaOnExpired() {
      this.captchaToken = null;
      return;
    },
    returnToCheckInForm() {
      this.$router.push('/checkin/' + this.CURRENT_EVENT_OBJECT.id);
    },
    changeTheme(brand) {
      ThemeSwitcher.ChangeTheme(this, brand);
    }
  },
  computed: {
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      engagement: 'engagement',
      translation: 'translations'
    }),
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    firstNameRules: function() {
      if (this.engagement.language === 'es') {
        return [v => !!v || 'El nombre es requisito'];
      } else {
        return [v => !!v || 'First name is required'];
      }
    },
    brandWatcher() {
      return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.brand
        ? this.CURRENT_EVENT_OBJECT.brand
        : 'T-Mobile';
    },
    helperFunctions: function() {
      return HelperFunctions;
    }
  }
};
</script>

<style scoped>
.app-engagement-form {
  background: linear-gradient(
    to bottom,
    var(--v-primary-base) 50%,
    var(--v-registerBackground-base) 50%
  );
}

.tmobile__textured {
  background-image: url('~@/assets/tmo_texture.jpg');
  background-repeat: repeat-x;
}

.metro__textured {
  background-image: url('~@/assets/metro_texture.jpg');
  background-repeat: repeat-x;
}

.app-engagement-form ::v-deep .v-text-field .v-icon.v-icon,
.app-engagement-form ::v-deep .v-select .v-icon.v-icon {
  color: var(--v-error-base);
  font-size: 10px;
}
.app-engagement-form__wrap {
  background: white;
}
.app-engagement-form__img {
  max-width: 440px;
  margin: 0 auto;
}
.app-engagement-form__title h1 {
  font-weight: 700;
  font-size: 3.4em;
  margin: 0;
  margin-bottom: 60px;
  color: white;
}

.app-engagement-form__title p {
  margin-bottom: 48px;
}
.app-engagement-form__label {
  font-weight: 700;
}
.app-engagement-form__submit {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  font-weight: bold;
}
.logo-wrapper {
  width: 100%;
  text-align: center;
  margin-top: 60px;
}
.brand-logo {
  width: 250px;
  max-width: 90%;
  margin: auto;
}

.language--error,
.legal--error {
  background-color: #fc9e03;
  padding: 5px;
}

.info-icon {
  margin-left: 5px;
  margin-right: 10px;
  margin-top: -20px;
}

.loading-hider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--v-primary-base);
}

.event-error {
  color: white;
  text-align: center;
  max-width: 90%;
  margin: auto;
}

.no-entries-allowed {
  background: var(--v-primary-base);
  min-height: 80vh;
  display: flex;
  align-items: center;
}
.theme--light.v-btn.v-btn--has-bg.inactive {
  background: #757575 !important;
}

.dialogResponseBox{
  font-size: .9em !important;
  line-height: 1rem;
}
</style>
