<template>
  <div>
    <BannerTitle title="Thank you!" />

    <section class="py-12">
      <v-container>
        <v-row justify="center">
          <v-col lg="8" md="10" sm="12">
            <p v-if="!verifyError" class="text-center">
              Click the button below to verify your E-Mail address.<br />
            </p>
            <p class="text-center">
              {{ verifyError }}
            </p>
            <br />
            <div class="d-flex">
              <app-button
                class="mx-auto"
                v-if="!showResend"
                :disabled="!this.verificationCode || !this.decodedData"
                :on-click="this.verifyEmail"
              >
                <div v-if="loading" class="loading-overlay">
                  <v-progress-circular
                    class="loading-spinner my-10"
                    :size="25"
                    color="white"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <span v-else>
                  Verify
                </span>
              </app-button>
              <app-button
                class="mx-auto"
                v-if="showResend"
                :on-click="this.resendConfirmationCode"
              >
                <div v-if="loading" class="loading-overlay">
                  <v-progress-circular
                    class="loading-spinner my-10"
                    :size="25"
                    color="white"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <span v-else>Resend Confirmation</span>
              </app-button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import BannerTitle from '@/components/BannerTitle.vue';
import { mapActions } from 'vuex';
import AppButton from '../components/AppButton.vue';
import { Auth } from 'aws-amplify';

export default {
  name: 'EmailVerified',
  components: {
    BannerTitle,
    AppButton
  },
  data() {
    return { verifyError: '', loading: false, showResend: false };
  },
  async created() {
    // console.log(this.$route.query.data);
  },
  computed: {
    decodedData: function() {
      if (this.$route.query.data === undefined) {
        return undefined;
      }
      const encoded = this.$route.query.data;
      try {
        const decoded = JSON.parse(atob(encoded));

        const { userName, redirectUrl, clientId, region } = decoded;
        return { userName, redirectUrl, clientId, region };
      } catch (error) {
        return undefined;
      }
    },
    verificationCode: function() {
      if (this.$route.query.code === undefined) {
        return undefined;
      }
      return this.$route.query.code;
    }
  },
  methods: {
    ...mapActions({
      verifyAccount: 'account/VERIFY_EMAIL'
    }),
    async resendConfirmationCode() {
      try {
        this.loading = true;
        await Auth.resendSignUp(this.decodedData.userName);
        this.loading = false;
        this.$router.push('login');
        // this.verifyError =
        //   'Please check your email for your new verification link.';
      } catch (err) {
        this.verifyError = 'Please wait until an Admin grants you access.';
        this.$router.push('login');
      }
    },
    async verifyEmail() {
      // No data or verification code
      if (
        this.decodedData === undefined ||
        this.verificationCode === undefined
      ) {
        // maybe set an error here
        return;
      }
      let userName = this.decodedData.userName;
      let code = this.verificationCode;
      this.loading = true;
      let results = await this.verifyAccount({
        userName: userName,
        code
      });
      this.loading = false;
      if (results.code === 'ExpiredCodeException') {
        this.verifyError = results.message;
        this.showResend = true;
        // change button action
        return;
      }

      if (results.code === 'NotAuthorizedException') {
        window.location.href = this.decodedData.redirectUrl;
        // this.verifyError = 'Please wait until an Admin grants you access.';
        return;
      }
      if (results.code === 'LimitExceededException') {
        this.verifyError = results.message;
        return;
      }
      window.location.href = this.decodedData.redirectUrl;
    }
  }
};
</script>

<style scoped>
.v-btn {
  font-weight: bold;
}
</style>
