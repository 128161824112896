<template>
  <div class="web-qr-brand-bg" :class="'brand-bg--' + CURRENT_EVENT_OBJECT.brand">
    <template v-if="loaded">
      <h1>
        {{
          CURRENT_EVENT_OBJECT.contestType == 'Sign Up/Check In'
            ? selectedLanguage == 'es'
              ? 'Incríbete y Gana un Regalo'
              : 'Sign Up for a Free Gift'
            : CURRENT_EVENT_OBJECT.contestType == 'Enter for a Chance to Win'
            ? selectedLanguage == 'es'
              ? 'Participa y Gana'
              : 'Enter for a Chance to Win'
            : selectedLanguage == 'es'
            ? 'Gana al Instante'
            : 'Instant Win'
        }}
      </h1>
      <h2 style="margin-top: 50px;">
        <v-icon
          v-if="
            preview &&
              (CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription == null ||
                CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription == '')
          "
          small
          class="info-icon"
          color="#fc9e03"
          style="visibility: hidden"
        >
          mdi-information
        </v-icon>
        <span
          :class="
            !preview ||
            (CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription != null &&
              CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription != '')
              ? 'prize'
              : 'prize--error'
          "
        >
          {{
            !preview ||
            (CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription != null &&
              CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription != '')
              ? selectedLanguage == 'es' &&
                translations.abbreviatedPrizeDescription &&
                translations.abbreviatedPrizeDescription != ''
                ? translations.abbreviatedPrizeDescription
                : CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription
              : selectedLanguage == 'en'
              ? 'PRIZE DESCRIPTION GOES HERE'
              : 'PRIZE DESCRIPTION GOES HERE'
          }}
        </span>

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="
                preview &&
                  (CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription == null ||
                    CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription == '')
              "
              small
              class="info-icon"
              color="#fc9e03"
              v-bind="attrs"
              v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span v-if="selectedLanguage == 'es'">
            This field cannot be left blank.
          </span>
          <span v-else> This field cannot be left blank.</span>
        </v-tooltip>
      </h2>
      <img v-if="bitlyLink" :src="qrURL()" class="qr-image qr-image--actual" />
      <v-skeleton-loader
        v-else
        class="qr-image skeleton-qr"
        type="image"
      ></v-skeleton-loader>
      <h2 class="scan">
        <span v-if="selectedLanguage == 'es'">
          ESCANEA PARA PARTICIPAR O VISITA
        </span>
        <span v-else>Scan to enter or Visit</span><br />
        <v-skeleton-loader
          v-if="bitlyLink == null"
          type="text"
          max-width="200"
          class="mx-auto bitly-placeholder"
        ></v-skeleton-loader><span class="short-url">{{ bitlyLink }}</span>
      </h2>
      <img :src="logoUrl(CURRENT_EVENT_OBJECT)" class="logo-image" />
      <p
        class="legal mt-4"
        v-html="helperFunctions.OutputLegal(CURRENT_EVENT_OBJECT, selectedLanguage)"
      ></p>
      <v-col
        v-if="(CURRENT_EVENT_OBJECT.eventLanguage = 'bi')"
        xs="12"
        sm="12"
        md="12"
        class="language-buttons"
      >
        <v-radio-group v-model="selectedLanguage" row>
          <v-radio ref="englishLang" label="English" value="en"></v-radio>
          <v-radio ref="spanishLang" label="Español" value="es"></v-radio>
        </v-radio-group>
      </v-col>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import HelperFunctions from '../utility/HelperFunctions.js';

export default {
  name: 'WebQR',
  props: {
    preview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bitlyLink: null,
      loaded: false,
      qrImageLoaded: false,
      selectedLanguage: ''
    };
  },
  computed: {
    ...mapGetters(['translations']),
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT', 'BITLY_LINK']),
    helperFunctions: function() {
      return HelperFunctions;
    }
  },
  async created() {
    this.loaded = false;
    let eventId = this.$route.params.id;
    await this.$store.dispatch('EVENT_MANAGER_STORE/LOAD_EVENT', eventId);
    this.loaded = true;
    await this.$store.dispatch('EVENT_MANAGER_STORE/FETCH_BITLY_LINK', eventId);
    this.bitlyLink = this.BITLY_LINK;
  },
  mounted() {
    this.selectedLanguage = this.CURRENT_EVENT_OBJECT.defaultLanguage;
  },
  methods: {
    registerAddress() {
      var rootAddress = window.location.protocol + '//' + window.location.host;
      var registerAddress =
        rootAddress + '/#/engagements/register/' + this.CURRENT_EVENT_OBJECT.id;
      return registerAddress;
    },
    qrURL() {
      var escapedRegisterAddress = encodeURIComponent(this.registerAddress());
      return (
        'https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=' +
        this.bitlyLink
      );
    },
    logoUrl(curEvent) {
      if (typeof curEvent === 'undefined') {
        return;
      }
      if (curEvent.brand.includes('Metro')) {
        return require('../assets/metro-logo-tight--white.png');
      } else {
        return require('../assets/t-mobile-logo-tight--white-v2.png');
      }
    },
    renderLinks(text) {
      if (text) {
        let regex = new RegExp(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
        );
        return text.replace(regex, match => {
          return `<a href="${match}" target="_blank" style="color: white;">${match}</a>`;
        });
      } else {
        if (this.selectedLanguage == 'es') {
          return 'Sin obligación de compra. Abierto para mayores de 18 años en los 50 estados de EE. UU., D.C. y Puerto Rico. Detalles en las reglas impresas. Nulo donde esté prohibido.';
        } else {
          return 'No purchase necessary. Open to 50 U.S., D.C., & Puerto Rico 18+. See printed rules for details. Void where prohibited.';
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
p.legal {
  margin: 0;
  color: white !important;
}

::v-deep a {
  color: white !important;
}
.web-qr-brand-bg {
  text-align: center;
  color: white;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    font-family: 'Tele-Grotesk Next-Ultra';
    text-transform: uppercase;
    font-size: 45px;
    line-height: 1em;
  }
  h2 {
    font-family: 'Tele-Grotesk Next-Medium';
    text-transform: uppercase;
    font-size: 23px;
  }
  h2 div {
    display: inline;
  }
  .scan {
    font-family: 'Tele-Grotesk Next-Bold';
  }
  .short-url {
    text-transform: none;
  }
  p {
    font-family: 'Tele-Grotesk Next-Regular';
  }
  .qr-image {
    width: 50vw;
    max-width: 200px;
    border: 6px solid white;
    background-color: white;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .logo-image {
    width: 70%;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.web-qr-brand-bg {
  background-color: var(--v-primary-base);
}

.brand-bg--T-Mobile,
.brand-bg--T-MOBILE {
  background-color: #e20074;
}

.brand-bg--Metro,
.brand-bg--METRO {
  background-color: #46196e;
  h1 {
    font-family: 'TT-Norms Bold';
    text-transform: uppercase;
  }
  h2 {
    font-family: 'TT-Norms Medium';
    text-transform: uppercase;
  }
  .scan {
    font-family: 'TT-Norms Bold';
  }
  p {
    font-family: 'TT-Norms Regular';
  }
}

.prize,
.contest-type {
  text-transform: uppercase;
  font-weight: normal;
  color: white;
}

.prize--error,
.legal--error {
  background-color: #fc9e03;
  padding: 5px;
  color: white;
}

.info-icon {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -28px;
}

.legal-info-icon {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -14px;
}

::v-deep .skeleton-qr > div,
.qr-image--actual {
  height: 50vw;
  max-height: 200px;
}

.skeleton-qr {
  border-radius: 0;
}

.bitly-placeholder {
  width: 200px;
  display: block !important;
  margin-top: 5px;
}

.language-buttons
  ::v-deep
  .v-input
  .v-input__slot
  .v-input--radio-group__input {
  height: 62px;
  justify-content: center;
}

.language-buttons
  ::v-deep
  .v-input--radio-group.v-input--radio-group--row
  .v-radio {
  color: white;
  padding: 0 30px;

  .v-input--selection-controls__input {
    display: none;
  }
  label {
    color: white;
  }

  &.v-item--active {
    label {
      font-weight: bold;
    }
  }
}

.scan {
  color: white;
}
</style>
