<template>
  <div>
    <div>
      <v-data-table
        :headers="headers"
        :items="tableData"
        class="app-events-table "
        :loading="LOADING"
        :items-per-page="rowsPerPage"
        @page-count="pageCount = $event"
        :page.sync="C_PAGE"
      >
        <template v-slot:no-data>
          No results match your current filters, please try again.
        </template>
        <template #loading>
          <v-progress-circular
            class="centered-circle"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
        <template v-slot:item.eventStartDate="{ item }">
          <span>{{
            item.eventStartDate | humanTableTimeFormat(item.timezone)
          }}</span>
        </template>
        <template v-slot:item.eventEndDate="{ item }">
          <span>{{
            item.eventEndDate | humanTableTimeFormat(item.timezone)
          }}</span>
        </template>

        <template v-slot:item.organization="{ item }">
          <span v-show="!item.organization || !item.organization.length">-</span>
          <span v-for="(o, idx) in item.organization" :key="o.id">
            {{ o | humanOrganizationFilter
            }}<template v-if="idx < item.organization.length - 1">,<br/></template>
          </span>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link class="event-name" :to="'/legal/' + item.id">{{
            item.name
          }}</router-link>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="flex-wrap justify-center d-flex flex-lg-nowrap">
            <app-button :to="`/legal/${item.id}`" class="ma-1 flex-grow-1">
              View Details
            </app-button>
          </div>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetFilters()">
            No results found, reset filters.
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppButton from '../AppButton.vue';

export default {
  name: 'LegalEventsTable',
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    rowsPerPage: {
      type: Number,
      default: 25
    }
  },
  components: {
    AppButton
  },
  data() {
    return {
      headers: [
        {
          text: 'Start',
          align: 'cell',

          value: 'eventStartDate'
        },
        { text: 'End', value: 'eventEndDate', align: 'cell' },
        { text: 'Event ID', value: 'id', align: 'cell' },
        { text: 'Event Name', value: 'name', align: 'cell' },
        { text: 'First Name', value: 'createdByFirstName', align: 'cell' },
        { text: 'Last Name', value: 'createdByLastName', align: 'cell' },
        { text: 'TMUS Org', value: 'organization', align: 'cell' },
        // { text: 'Brand', value: 'brand', align: 'cell' },
        { text: 'Region', value: 'region', align: 'cell' },
        { text: 'State', value: 'state', align: 'cell' },
        { text: 'DMA', value: 'dma', align: 'cell' },
        { text: 'Interactions', value: 'engagements', align: 'cell' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ]
    };
  },
  methods: {
    displayViewButton: event => {
      // if today's date is before the event start date or after the event end date, show the preview button
      if (
        !event.published ||
        event.eventStartDate == null ||
        event.eventStartDate == '' ||
        event.eventEndDate == null ||
        event.eventEndDate == ''
      ) {
        return false;
      } else {
        return true;
      }
    },
    async resetFilters() {
      await this.RESET_FILTERS();
    },
    ...mapActions('EVENT_TABLE_STORE', ['RESET_FILTERS', 'APPLY_FILTER'])
  },
  computed: {
    ...mapState('EVENT_TABLE_STORE', [
      'RESULTS_PER_PAGE',
      'CURRENT_PAGE',
      'NUMBER_OF_PAGES',
      'LOADING'
    ]),
    C_PAGE: {
      get() {
        return this.CURRENT_PAGE;
      },
      set(value) {
        this.$store.commit('EVENT_TABLE_STORE/SET_CURRENT_PAGE', value);
      }
    }
  }
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
.app-events-table {
  border: 1px solid var(--v-greyLighten3-base);
  -webkit-box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.1);
}

.app-events-table thead tr {
  background: black;
}

.app-events-table thead th {
  color: white !important;
  white-space: nowrap;
}
.v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light {
  color: white !important;

  opacity: 1 !important;
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: white !important;
  opacity: 1 !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: white !important;

  opacity: 1 !important;
}

.app-events-table tbody a {
  font-weight: bold;
}

.loading-row {
  padding: 13px;
  text-align: center;
  background-color: var(--v-light-base);
}
.text-cell {
  font-size: 14px !important;
  line-height: 18px;
}
.text-cell-heading {
  font-size: 12px !important;
  line-height: 12px;
}
.app-events-table {
  td {
    padding: 2px !important;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      padding: 10px !important;
    }
  }
}

.archived-event,
.archived-event td .event-name {
  color: var(--v-darkGrey-base);
}

::v-deep .archived-event .v-btn {
  background-color: var(--v-darkGrey-base) !important;
}
.v-data-table-header {
  background: black;
}

tbody tr:nth-child(even) {
  background-color: var(--v-light-base);
  color: var(--v-darkGrey-base);
}

.theme--light.v-chip:not(.v-chip--active) {
  background: var(--v-magenta-base) !important;
  color: white !important;
}

.v-data-table-header-mobile__wrapper {
  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: white;
  }

  .v-icon
    .notranslate
    .v-data-table-header__icon
    .mdi
    .mdi-arrow-up
    .theme--light {
    color: white !important;
  }
  .v-label theme--light {
    color: white !important;
  }

  .v-icon .notranslate .mdi .mdi-menu-down .theme--light .primary--text {
    color: white !important;
  }

  .v-chip .v-icon {
    font-size: 16px;
    color: white !important;
  }

  .v-text-field .v-label {
    color: white !important;
  }

  .v-data-table-header th.active .v-data-table-header__icon,
  .v-data-table-header th:hover .v-data-table-header__icon {
    color: white !important;
  }

  .v-input__append-inner {
    .v-input__icon v-input__icon--append {
      background: var(--v-magenta-base) !important;
      .v-icon notranslate mdi mdi-menu-down theme--light {
        color: white !important;
      }
    }
  }
}

.mdi-arrow-up::before {
  content: '\F0140';
}
</style>
