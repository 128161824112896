<template>
  <div class="app-login">
    <BannerImage
      image="svgs/tmo-metro-banner-white.svg"
      alt="T-Mobile Metro Login Banner"
    />
    <v-container>
      <v-row no-gutters justify="center">
        <v-col cols="12" sm="10" lg="6">
          <h1 class="app-login__title text-h1 text-center black--text mt-6">
            Sign In
          </h1>
          <p class="text-body text-center mt-5 black--text">
            Welcome! Any issues with sign-in, please contact
            <a href="mailto:smrastrategyandcommunications@t-mobile.com">
              smrastrategyandcommunications@t-mobile.com
            </a>
            with a screen shot. Thank you!
            <!-- <BannerImage image="login-banner.png" alt="T-Mobile Metro Login Banner" />
    <v-container fluid>
      <v-row justify="center">
        <v-col xs="12" sm="9" md="7" lg="5">
          <h1 class="app-login__title text-h1 mt-6">Sign In</h1>
          <p class="mt-5">
            Welcome! Any issues with sign-in, please contact
            <a href="mailto:smrastrategyandcommunications@t-mobile.com">smrastrategyandcommunications@t-mobile.com</a>
            with a screen shot. Thank you! -->
          </p>
          <LoginForm class="mt-7" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import { onAuthUIStateChange } from '@aws-amplify/ui-components';

import LoginForm from '@/components/Account/LoginForm.vue';
import BannerImage from '@/components/BannerImage.vue';

export default {
  name: 'Login',
  components: {
    LoginForm,
    BannerImage
  },
  created() {
    /*     this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    }); */
  }
};
</script>

<style></style>
