<template>
  <div class="event-action--wrapper">
    <div
      class="event-action elevation-12"
      :class="{ 'event-action--active': curAction != null }"
    >
      <GenerateWinnersAction
        v-if="curAction == 'generateWinner'"
        @changeAction="changeAction($event)"
        @scrollToWinners="scrollToWinners"
      />
      <GenerateWinnersAction
        v-if="curAction == 'generateAltWinner'"
        alt="true"
        @changeAction="changeAction($event)"
        @scrollToWinners="scrollToWinners"
      />
      <ViewWinnersAction
        v-if="curAction == 'viewWinner'"
        @changeAction="changeAction($event)"
      />
      <StaffViewWinnersAction
        v-if="curAction == 'staffViewWinner'"
        @changeAction="changeAction($event)"
      />
      <DownloadQRAction v-if="curAction == 'downloadQR'" />
      <RunReportAction v-if="curAction == 'runReports'" />
      <EventCollateralAction
        v-if="curAction == 'EventCollateralAction'"
        @changeAction="changeAction($event)"
      />
      <ArchiveEventAction
        v-if="curAction == 'archiveEvent'"
        @changeAction="changeAction($event)"
      />
      <ViewProxyAction
        v-if="curAction == 'viewProxy'"
        @changeAction="changeAction($event)"
      />
      <DuplicateEventAction
        v-if="curAction == 'DuplicateEventAction'"
        @changeAction="changeAction($event)"
      />
      <AccessCodeDescription
        v-if="curAction == 'viewAccessCodeDescription'"
        @changeAction="changeAction($event)"
      />
      <AccessCodeAction
        v-if="curAction == 'viewAccessCodeAction'"
        @changeAction="changeAction($event)"
      />
      <BatchUploadAction
        v-if="curAction == 'batchUpload'"
        @changeAction="changeAction($event)"
      />
      <OverlayCloseButton
        v-if="curAction != null"
        @changeAction="changeAction($event)"
      />
    </div>
  </div>
</template>

<script>
import TMOEvent from '../../models/TMOEvent.js';
import ViewWinnersAction from '../EventForm/ViewWinnersAction.vue';
import StaffViewWinnersAction from '../EventForm/StaffViewWinnersAction.vue';
import DownloadQRAction from '../EventForm/DownloadQRAction.vue';
import RunReportAction from '../EventForm/RunReportAction.vue';
import ArchiveEventAction from '../EventForm/ArchiveEventAction.vue';
import OverlayCloseButton from '../EventForm/OverlayCloseButton.vue';
import ViewProxyAction from '@/components/EventForm/ViewProxyAction';
import DuplicateEventAction from '@/components/EventForm/DuplicateEventAction';
import AccessCodeAction from '@/components/EventForm/AccessCodeAction';
import AccessCodeDescription from '@/components/EventForm/AccessCodeDescription';
import BatchUploadAction from '@/components/EventForm/BatchUploadAction';
import EventCollateralAction from '@/components/EventForm/EventCollateralAction';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'EventActionOverlay',
  components: {
    ViewWinnersAction,
    StaffViewWinnersAction,
    DownloadQRAction,
    RunReportAction,
    ArchiveEventAction,
    OverlayCloseButton,
    ViewProxyAction,
    DuplicateEventAction,
    AccessCodeAction,
    AccessCodeDescription,
    BatchUploadAction,
    EventCollateralAction
  },
  props: {
    curAction: {
      type: String,
      default: null
    }
  },

  methods: {
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    },
    scrollToWinners() {
      this.$emit('scrollToWinners');
    }
  }
};
</script>

<style lang="scss" scoped>
.event-action--wrapper {
  position: relative;
}

.event-action {
  position: absolute;
  display: none;
  background-color: white;
  width: 100%;
  z-index: 10;
}

.event-action--active {
  display: block;
}
</style>
