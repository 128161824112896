<template>
  <div id="experiences" style="display: none">
    <v-row>
      <v-col cols="12" :md="wide ? 4 : 12">
        <div class="app-tooltip-textfield">
          <TMOToolTip
            text="Only select “yes” if you are part of the SMRA Marketing Team that is using one of our Game Time or Bobbleheads experience for this event. This question is for reporting purposes."
          >
            <div class="d-flex">
              <v-icon medium color="secondary">
                mdi-information
              </v-icon>
            </div>
          </TMOToolTip>
          <label class="form-label">Does your event connect with an experience?</label>
          <v-radio-group
            :value="doesEventConnectWithExperience"
            hide-details="auto"
            append-icon="mdi-alert-circle"
            row
            @change="toggleExperienceOption"
          >
            <v-radio :value="true" hide-details="auto">
              <span slot="label" class="black--text">Yes</span>
            </v-radio>
            <v-radio :value="false" hide-details="auto">
              <span slot="label" class="black--text">No</span>
            </v-radio>
          </v-radio-group>
        </div>
      </v-col>
      <v-col
        cols="12"
        :md="wide ? 4 : 12"
        v-if="doesEventConnectWithExperience"
      >
        <label class="form-label">Indicate the experience:</label>
        <div class="app-tooltip-textfield">
          <TMOToolTip
            text="Reminder – Game Time requires you to provide your rules to them upon requesting your game."
          >
            <div class="d-flex">
              <v-icon medium color="secondary">
                mdi-information
              </v-icon>
            </div>
          </TMOToolTip>
          <v-select
            v-model="eventExperience"
            label="Experience"
            :items="eventExperienceOptions"
            hide-details="auto"
            append-icon="$Caret"
          ></v-select>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TMOToolTip from '@/components/TMOToolTip.vue';
import { mapState } from 'vuex';
export default {
  name: 'ExperienceFields',
  props: {
    wide: {
      type: Boolean,
      default: false
    },
    isEventArchived: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.eventExperience) {
      this.toggleExperienceOption(true);
    }
  },
  data() {
    return {
      doesEventConnectWithExperience: false
    };
  },
  methods: {
    toggleExperienceOption(value) {
      this.doesEventConnectWithExperience = value;
      if (!value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'eventExperience',
          value: null
        });
      }
    }
  },
  components: {
    TMOToolTip
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    eventExperienceOptions() {
      return this.$OptionData.Experiences();
    },
    eventExperience: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.eventExperience || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'eventExperience',
          value: value
        });
      }
    }
  }
};
</script>
