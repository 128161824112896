<template>
  <div class="vcanvas">
    <v-container>
      <AVEOptions />
      <AVERenderer />
      <AVEDialog />
    </v-container>
  </div>
</template>

<script>
import AVEOptions from '@/components/AVECanvas/AVEOptions';
import AVEDialog from '@/components/AVECanvas/AVEDialog';
import AVERenderer from '@/components/AVECanvas/AVERenderer';
export default {
  name: 'AVEEditor',
  components: {
    AVEOptions,
    AVEDialog,
    AVERenderer
  }
};
</script>
