<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="t_output_height * 2"
    :width="t_output_width * 2"
    viewBox="0 0 350 244"
  >
    <!-- <defs>
      <pattern
        id="a"
        width="100%"
        height="100%"
        preserveAspectRatio="xMidYMid slice"
        viewBox="0 0 1920 1080"
      >
        <image :href="SELECTED_IMAGE" width="1920" height="1080" />
      </pattern>
    </defs> -->
    <path fill="#e20074" d="M0 0h350v244H0z" />

    <text
      fill="#fff"
      font-family="SegoeUI, Segoe UI"
      font-size="12"
      transform="translate(9 56)"
    >
      <tspan x="0" y="0">ENTER</tspan>
      <tspan
        y="0"
        font-family="SansSerifCollection, Sans Serif Collection"
      ></tspan>
      <tspan y="0">FOR</tspan>
      <tspan
        y="0"
        font-family="SansSerifCollection, Sans Serif Collection"
      ></tspan>
      <tspan y="0">A</tspan>
      <tspan
        y="0"
        font-family="SansSerifCollection, Sans Serif Collection"
      ></tspan>
      <tspan y="0">CHANCE</tspan>
      <tspan
        y="0"
        font-family="SansSerifCollection, Sans Serif Collection"
      ></tspan>
      <tspan y="0">TO</tspan>
      <tspan
        y="0"
        font-family="SansSerifCollection, Sans Serif Collection"
      ></tspan>
      <tspan y="0">WIN</tspan>
    </text>
    <text
      fill="#fff"
      font-family="HelveticaNeue-Bold, Helvetica Neue"
      font-size="27"
      font-weight="700"
      transform="translate(9 89)"
    >
      <tspan x="0" y="0">THE ULTIMATE</tspan>
      <tspan x="0" y="32">FAMILY PRIZE</tspan>
    </text>
    <text
      fill="#fff"
      font-family="InkFree, Ink Free"
      font-size="14"
      transform="translate(9 144)"
    >
      <tspan x="0" y="0">{{templateAbbrPrizeDescription}}</tspan>
    </text>
    <text
      fill="#fff"
      font-family="SegoeUI, Segoe UI"
      font-size="5"
      transform="translate(9 225)"
    >
      <tspan x="0" y="0">ADDITIONAL</tspan>
      <tspan
        y="0"
        font-family="SansSerifCollection, Sans Serif Collection"
      ></tspan>
      <tspan y="0">TERMS</tspan>
      <tspan
        y="0"
        font-family="SansSerifCollection, Sans Serif Collection"
      ></tspan>
      <tspan y="0">AND</tspan>
      <tspan
        y="0"
        font-family="SansSerifCollection, Sans Serif Collection"
      ></tspan>
      <tspan y="0">CONDITIONS</tspan>
      <tspan
        y="0"
        font-family="SansSerifCollection, Sans Serif Collection"
      ></tspan>
      <tspan y="0">APPLY</tspan>
    </text>
    <path d="M9 175h71v16H9z" data-name="Rectangle 5" />
    <text
      fill="#fff"
      data-name="ENTER HERE"
      font-family="HelveticaNeue-Bold, Helvetica Neue"
      font-size="8"
      font-weight="700"
      transform="translate(19 186)"
    >
      <tspan x="0" y="0">ENTER HERE</tspan>
    </text>
    <!-- <path fill="url(#a)" d="M222 23h122v162H222z" /> -->
        <!-- replace this image with x y width height and :href="SELECTED_IMAGE" -->
    <image
x="222" y="23" width="122" height="162"
         :href="selectedImage"
></image>
  </svg>
</template>

<script>
export default {
  name: 'TmoDemo300x244',
  inheritAttrs: false,
  props: {
    selectedImage: {
      type: String,
      default: ''
    },
    templateAbbrPrizeDescription: {
      type: String,
      default: ''
    },
    t_output_height: {
      type: [Number, String],
      default: 300
    },
    t_output_width: {
      type: [Number, String],
      default: 600
    }
  }
};
</script>
