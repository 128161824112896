<template>
  <v-row justify="center" class="my-0">
    <!-- <v-col cols="11" :md="wide ? 12 : 8" :class="{ 'elevation-6': card }"> -->
    <v-col cols="9" :sm="wide ? 12 : 9" :class="{ 'elevation-6 my-10': card }">
      <section class="form-section" :class="{ 'form-section--spaced': card }">
        <div :class="{ 'form-inner': !compact }">
          <v-row justify="center">
            <v-col cols="11" md="12" class="px-0">
              <div class="event-organization">
                <h2 v-if="$slots['section-header']" class="gutter-padding">
                  <slot name="section-header">Section Header</slot>
                </h2>
                <v-divider v-if="$slots['section-header']"></v-divider>
                <div class="gutter-padding">
                  <slot name="section-content">Section Content</slot>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </section>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'LegalFormSection',
  components: {},
  props: {
    card: {
      type: Boolean,
      default: true
    },
    wide: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.form-section {
  &.form-section--spaced {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .form-inner {
    padding-top: 0;
    padding-bottom: 0;
  }

  .event-organization {
    border: 0;
    padding: 0;
  }

  .event-organization h2 {
    font-size: 30px;
    width: 100%;
    font-weight: 700;
  }

  hr.v-divider {
    background-color: var(--v-primary-base);
    border-width: 1px;
    margin-bottom: 20px;
    &.gray-divider {
      background-color: var(--v-grey-lighten1);
      border-width: 1px;
      margin-bottom: 10px;
    }
  }

  .gutter-padding {
    padding: 0 $grid-gutter / 2;
  }

  .form-label {
    display: block;
    font-weight: 700;
    transform-origin: top left;
  }
  .form-label:not(:first-child) {
    margin-top: 20px;
  }

  .v-input--radio-group + .v-text-field {
    margin-top: 20px;
  }

  .v-input .v-input__icon--append .v-icon {
    color: var(--v-primary-base);
  }

  .v-input .mdi-alert-circle {
    display: none;
  }

  .error--text .mdi-alert-circle {
    display: block;
  }
  .v-input--checkbox .v-icon {
    color: var(--v-unselected-base);
  }
}
</style>
