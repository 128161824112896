import { Auth } from 'aws-amplify';

/**
 * @typedef {import('vuex').Commit} Commit
 */

/**
 * Email Reset Object
 * @typedef {Object} resetParams
 * @property {string} username - The user's email address
 * @property {string} code - Confirmation code from email
 * @property {number} password - New password
 */

/**
 * @param  {Commit} commit - VUEX Commit - pass this in to issue mutations
 * @param  {resetParams} email - Account email
 */
export default async function ResetPasswordWithCode({ commit }, resetParams) {
  // Check for required params
  if (
    !resetParams ||
    !resetParams.username ||
    !resetParams.code ||
    !resetParams.new_password
  ) {
    return Promise.reject(
      new Error('ERROR::RESET_PASSWORD_WITH_CODE: No parameters provided.')
    );
  }

  return Auth.forgotPasswordSubmit(
    resetParams.username,
    resetParams.code,
    resetParams.new_password
  )
    .then(data => {
      return { error: false, response: data };
    })
    .catch(err => {
      return { error: true, response: err };
    });
}
