var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-navigation"},[_c('nav',{staticClass:"justify-end app-navigation__nav d-flex"},[_c('ul',{staticClass:"d-none d-md-none d-md-flex"},[(!_vm.isAmbassadorRoute)?_c('li',[_c('router-link',{class:_vm.currentRoutePath == '/dashboard' ||
            _vm.currentRoutePath == '/legaldashboard' ||
            _vm.currentRoutePath == '/translationdashboard'
              ? 'active'
              : '',attrs:{"to":_vm.checkGroup()}},[_vm._v(" My Dashboard ")])],1):_vm._e(),(_vm.checkGroup() === '/dashboard')?_c('li',[_c('router-link',{class:_vm.currentRoutePath == '/allevents' ? 'active' : '',attrs:{"to":"/allevents"}},[_vm._v(" All Events ")])],1):_vm._e(),(_vm.checkGroup() === '/dashboard')?_c('li',[_c('router-link',{class:_vm.currentRoutePath == '/event/create' ? 'active' : '',attrs:{"to":"/event/create"}},[_vm._v(" Create Event ")])],1):_vm._e(),(!_vm.isAmbassadorRoute)?_c('li',[(!_vm.isTranslator)?_c('router-link',{class:_vm.currentRoutePath == '/resources' ? 'active' : '',attrs:{"to":"/resources"}},[_vm._v(" Resources ")]):_vm._e()],1):_vm._e(),(_vm.isInAdminGroup)?_c('li',[_c('router-link',{class:_vm.currentRoutePath == '/admin' ? 'active' : '',attrs:{"to":"/admin"}},[_vm._v(" Admin ")])],1):_vm._e(),_c('li',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pl-0 pr-0 app-navigation__user",attrs:{"color":"transparent"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-account-circle ")])],1)]}}])},[_c('v-list',_vm._l((_vm.currentProfileDropdownMenu),function(item){return _c('v-list-item',{key:item.id},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":item.uri}},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)]),_c('v-menu',{attrs:{"offset-y":"","min-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pl-0 pr-0 app-navigation__mobile-menu d-md-none",attrs:{"color":"transparent"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":"","color":"#E44475"}},[_vm._v(" mdi-menu ")])],1)]}}])},[_c('v-list',[(!_vm.isAmbassadorRoute)?_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{class:_vm.currentRoutePath == '/dashboard' ||
                '/legaldashboard' ||
                '/translationdashboard'
                  ? 'active'
                  : '',attrs:{"to":_vm.checkGroup()}},[_vm._v(" My Dashboard ")])],1)],1):_vm._e(),(_vm.checkGroup() === '/dashboard')?_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{class:_vm.currentRoutePath == '/allevents' ? 'active' : '',attrs:{"to":"/allevents"}},[_vm._v(" All Events ")])],1)],1):_vm._e(),(_vm.checkGroup() === '/dashboard')?_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{class:_vm.currentRoutePath == '/event/create' ? 'active' : '',attrs:{"to":"/event/create"}},[_vm._v(" Create Event ")])],1)],1):_vm._e(),(!_vm.isAmbassadorRoute)?_c('v-list-item',[(!_vm.isTranslator)?_c('v-list-item-title',[_c('router-link',{class:_vm.currentRoutePath == '/resources' ? 'active' : '',attrs:{"to":"/resources"}},[_vm._v(" Resources ")])],1):_vm._e()],1):_vm._e(),(_vm.isInAdminGroup)?_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{class:_vm.currentRoutePath == '/admin' ? 'active' : '',attrs:{"to":"/admin"}},[_vm._v(" Admin ")])],1)],1):_vm._e(),_c('v-list-item',[(!_vm.isAmbassadorRoute)?_c('v-list-item-title',[_c('router-link',{class:_vm.currentRoutePath == '/profile' ? 'active' : '',attrs:{"to":"/profile"}},[_vm._v(" Profile ")])],1):_vm._e()],1),_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{class:_vm.currentRoutePath == '/logout' ? 'active' : '',attrs:{"to":"/logout"}},[_vm._v(" Sign Out ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }