<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>4b.Checkbox/Deselected Copy</title>
    <g
      id="Dev-Pack"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="empty-outline"
        transform="translate(-100.000000, -262.000000)"
        fill="transparent"
        id="Group"
        stroke="#861B54"
        stroke-width="2"
      >
        <g transform="translate(60.000000, 262.000000)">
          <g transform="translate(40.000000, 0.000000)" id="Rectangle">
            <rect x="1" y="1" width="18" height="18"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TMobileEmptyCheckbox'
};
</script>
<style lang="scss" scoped>
.empty-outline {
  stroke: currentColor;
}
</style>
