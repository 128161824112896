<template>
  <div>
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
      class="app-reset-password-form pa-6"
    >
      <v-text-field
        v-if="!username"
        @keyup="isFormValid"
        :rules="emailRules"
        v-model="email"
        label="Email"
      ></v-text-field>
      <v-text-field
        v-if="!passcode"
        @keyup="isFormValid"
        :rules="[v => !!v || 'Code is required']"
        v-model="code"
        label="Enter Code"
      ></v-text-field>
      <v-text-field
        @keyup="isFormValid"
        :rules="[v => !!v || 'Password is required']"
        v-model="password"
        label="Password"
        type="password"
      ></v-text-field>
    </v-form>
    <v-snackbar
      top
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      v-model="snackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" width="500" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="grey lighten-2 justify-center">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="text-center mt-2">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            v-if="closeDialogAction !== null"
            @click="closeDialogAction"
            class="modal-button-outlined px-6"
            color="primary"
            outlined
            tile
          >
            Close
          </v-btn>
          <v-btn
            v-if="submitDialogAction !== null"
            @click="submitDialogAction"
            class="btn__primary mb-4 v-btn--has-bg"
            text
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ResetPasswordForm',
  computed: {
    ...mapGetters({
      AUTH_MODULE_STATE: `account/AUTH_MODULE_STATE`,
      AUTH_ERROR_MESSAGE: `account/AUTH_ERROR_MESSAGE`
    }),
    passcode: function() {
      return this.$route.query.code || null;
    },
    username: function() {
      return this.$route.query.username || null;
    }
  },
  props: {
    setValid: {
      type: Function,
      required: true
    }
  },
  created() {
    if (this.passcode) {
      this.code = this.passcode;
    }
    if (this.username) {
      this.email = this.username;
    }
  },
  methods: {
    ...mapActions({
      RESET_PASSWORD_W_CODE: 'account/RESET_PASSWORD_W_CODE'
    }),
    isFormValid() {
      let isValid =
        this.valid &&
        this.email !== '' &&
        this.code !== '' &&
        this.password !== '';
      this.setValid(isValid);
    },
    resetPasswordWithCode() {
      const resetParams = {
        username: this.email,
        code: this.code,
        new_password: this.password
      };
      this.RESET_PASSWORD_W_CODE(resetParams)
        .then(response => this.processResetPasswordResponse(response))
        .catch();
    },
    processResetPasswordResponse(response) {
      if (response.error) {
        this.dialogTitle = 'ERROR';
        this.dialogMessage = response.response.message;
        this.closeDialogAction = () => {
          this.dialog = false;
        };
      } else {
        this.dialogTitle = 'Success';
        this.dialogMessage = 'Your password has been changed.';
        this.closeDialogAction = () => {
          this.dialog = false;
          this.$router.push('/login');
        };
      }

      this.dialog = true;
    },
    toggleSnackbar(message, type) {
      this.snackbarText = message;
      this.snackbarColor = type == 'error' ? '#f03710' : '#0da149';
      this.snackbar = true;
    }
  },
  data: () => {
    return {
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      snackbarTimeout: 3000,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      closeDialogAction: null,
      submitDialogAction: null,
      valid: false,
      email: '',
      code: '',
      password: '',
      emailRules: [
        v =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Not a valid email'
      ]
    };
  }
};
</script>

<style></style>
