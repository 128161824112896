import { API, Auth, Storage } from 'aws-amplify';
import Vue from 'vue';

const state = {
  EXPANSION_PANEL_ACTIVE: null,
  SETTINGS: {
    alert_message: ''
  }
};
const getters = {
  SETTINGS: state => state.SETTINGS
};

const actions = {
  async fetchSettings({ commit, dispatch }, settings) {
    const apiName = 'TMOAmplifyApi';
    const path = `/appsettings`;
    const payload = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: {
        settings
      }
    };
    const response = await API.get(apiName, path, payload).catch(error => {
      return error.response.data.message;
    });
    console.log(response);
    commit('SET_SETTINGS', {
      data: response.data || []
    });
  },
  async setSettings({ commit, dispatch }, settings) {
    const apiName = 'TMOAmplifyApi';
    const path = `/appsettings`;
    const payload = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: {
        settings
      }
    };
    const response = await API.patch(apiName, path, payload).catch(error => {
      return error.response.data.message;
    });
    commit('SET_SETTINGS', {
      data: response.data || {}
    });
  }
};
const mutations = {
  SET_SETTINGS: (state, settings) => (state.SETTINGS = settings),
  TOGGLE_EXPANSION_PANEL: (state, payload) => {
    if (payload && payload === 'hide')
      return (state.EXPANSION_PANEL_ACTIVE = null);

    state.EXPANSION_PANEL_ACTIVE =
      state.EXPANSION_PANEL_ACTIVE === 0 ? null : 0;
  }
};

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
