<template>
  <div>
    <v-form ref="form" v-model="valid" class="event-form">
      <div class="grey">
        <div class="wide-container">
          <LegalFormSection :card="false" wide compact>
            <div slot="section-content">
              <v-container>
                <LegalFormSection :card="false" compact>
                  <div slot="section-content">
                    <v-row>
                      <v-col cols="12" md="6">
                        <label class="event-link">Event ID: </label>
                        {{ CURRENT_EVENT_OBJECT.id }}
                      </v-col>
                      <v-col cols="12" md="6">
                        <label class="event-link">Event Status: </label>
                        {{
                          CURRENT_EVENT_OBJECT.archived
                            ? 'Archived'
                            : CURRENT_EVENT_OBJECT.status
                        }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <label class="event-link">Public Event URL: </label>
                        <v-skeleton-loader
                          v-if="!BITLY_LINK"
                          class="bitly-skeleton"
                          max-width="300"
                          type="text"
                        ></v-skeleton-loader>
                        <a :href="BITLY_LINK" target="_blank">{{
                          BITLY_LINK
                        }}</a>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          CURRENT_EVENT_OBJECT.contestType ===
                            'Enter for a Chance to Win'
                        "
                      >
                        <label class="event-link">
                          Public Rules URL:
                        </label>
                        <router-link
                          :to="'/event/rules/' + $route.params.id"
                          target="_blank"
                        >
                          {{
                            urlRoot +
                              '/#/event/rules/' +
                              CURRENT_EVENT_OBJECT.id
                          }}
                        </router-link>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="CURRENT_EVENT_OBJECT.status == 'Denied'"
                      >
                        <label class="event-link">Denied Reason: </label>
                        {{
                          CURRENT_LEGAL_OBJECT
                            ? CURRENT_LEGAL_OBJECT.legal_message
                            : ''
                        }}
                      </v-col>
                    </v-row>
                  </div>
                </LegalFormSection>
              </v-container>
            </div>
          </LegalFormSection>
        </div>
        <v-container class="wide-container">
          <v-expansion-panels>
            <v-expansion-panel :active-class="'active'">
              <v-expansion-panel-header style="background:black;color:white;">
                <span>Event History </span>
                <template v-slot:actions>
                  <v-icon x-large color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <PastelTable
                  class="my-4"
                  :rows="HISTORY_TABLE_DATA"
                  :columns="[
                    {
                      source: 'type',
                      label: 'Transaction Type'
                    },
                    {
                      source: 'action',
                      label: 'Transaction Action'
                    },
                    {
                      source: 'timestamp',
                      label: 'Timestamp'
                    },
                    {
                      source: 'description',
                      label: 'Description'
                    },
                    {
                      source: 'user',
                      label: 'User'
                    }
                  ]"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </div>
      <div class="wide-container">
        <LegalFormSection :card="false">
          <div slot="section-content">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <LegalFormSection :card="false" compact>
                    <span slot="section-header">Brand Selection</span>
                    <div
                      slot="section-content"
                      v-if="CURRENT_EVENT_OBJECT.brand.includes('T-Mobile')"
                    >
                      T-Mobile
                    </div>
                    <div slot="section-content" v-else>
                      Metro
                    </div>
                  </LegalFormSection>
                </v-col>
                <v-col cols="0" md="1" class="py-0 my-0"> </v-col>
                <v-col cols="12" md="12">
                  <LegalFormSection :card="false" compact>
                    <span slot="section-header">Organization</span>
                    <div slot="section-content">
                      <span
                        v-show="
                          !CURRENT_EVENT_OBJECT.organization ||
                            !CURRENT_EVENT_OBJECT.organization.length
                        "
                        >-</span>
                      <span
                        v-for="(o, idx) in CURRENT_EVENT_OBJECT.organization"
                        :key="o.id"
                      >
                        {{ o | humanOrganizationFilter
                        }}<template
                          v-if="
                            idx < CURRENT_EVENT_OBJECT.organization.length - 1
                          "
                          >,<br/></template>
                      </span>
                    </div>
                  </LegalFormSection>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </LegalFormSection>
        <LegalFormSection :card="false">
          <div slot="section-content">
            <v-container>
              <LegalFormSection :card="false" compact>
                <span slot="section-header">Basic Info</span>
                <div slot="section-content">
                  <div>
                    <v-row>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Default Language Selection:
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.defaultLanguage == 'es'
                            ? 'Spanish'
                            : 'English'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Event Name:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.name }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Promotion Type:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.contestType }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Event Segment / Audience:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.eventType }}
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        v-if="CURRENT_EVENT_OBJECT.eventType == 'TFB'"
                      >
                        <label class="event-link">
                          Business Name:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.businessName }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Do you want to include a field to collect 'Business
                          Name' on Attendee Form?
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.attendeeFormBusinessName
                            ? 'Yes'
                            : 'No'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Store Number:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.storeNumber }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Total Marketing Spend:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.totalMarketingSpend }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Market Type:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.marketType }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Event Description:
                        </label>
                        <br />
                        {{ CURRENT_EVENT_OBJECT.eventDescription }}
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </LegalFormSection>
            </v-container>
          </div>
        </LegalFormSection>
        <LegalFormSection :card="false">
          <div slot="section-content">
            <v-container>
              <LegalFormSection :card="false" compact>
                <span slot="section-header">Date &amp; Location</span>
                <div slot="section-content">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <label class="event-link">
                          Event Start Date:
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.startDate
                            | humanTimeFormat(CURRENT_EVENT_OBJECT.timezone)
                        }}
                      </v-col>
                      <v-col cols="12" md="6">
                        <label class="event-link">
                          Event Start Time:
                        </label>
                        {{
                          helperFunctions.FormatTime(
                            CURRENT_EVENT_OBJECT.startTime
                          )
                        }}
                      </v-col>
                      <v-col cols="12" md="6">
                        <label class="event-link">
                          Event End Date:
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.endDate
                            | humanTimeFormat(CURRENT_EVENT_OBJECT.timezone)
                        }}
                      </v-col>
                      <v-col cols="12" md="6">
                        <label class="event-link">
                          Event End Time:
                        </label>
                        {{
                          helperFunctions.FormatTime(
                            CURRENT_EVENT_OBJECT.endTime
                          )
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Event Location:
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.eventLocation != null
                            ? CURRENT_EVENT_OBJECT.eventLocation
                            : 'No Event Location Set'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Physical Event Address:
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.physicalEventAddress != null
                            ? CURRENT_EVENT_OBJECT.physicalEventAddress
                            : 'No Physical Event Address Set.'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Physical Event City:
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.physicalEventCity != null
                            ? CURRENT_EVENT_OBJECT.physicalEventCity
                            : 'No Physical Event City Set.'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Physical Event Zip Code:
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.physicalEventZip != null
                            ? CURRENT_EVENT_OBJECT.physicalEventZip
                            : 'No Physical Event Zip Set.'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Time Zone:
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.timezone != null
                            ? this.formattedTimezone
                            : 'No Timezone Set'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Region:
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.region != null
                            ? this.CURRENT_EVENT_OBJECT.region
                            : 'No Region Set'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          State:
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.state != null
                            ? this.CURRENT_EVENT_OBJECT.state
                            : 'No State Set'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          DMA:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.dma }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          MSA:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.msa }}
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </LegalFormSection>
            </v-container>
          </div>
        </LegalFormSection>
        <LegalFormSection :card="false">
          <div slot="section-content">
            <v-container>
              <LegalFormSection :card="false" compact>
                <span slot="section-header">Winner Selection</span>
                <div slot="section-content">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Number of Winners:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.numberOfWinners }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Rank Winners?
                        </label>
                        {{ CURRENT_EVENT_OBJECT.rankWinners ? 'Yes' : 'No' }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Does your event require winners to be in certain
                          state(s) or territories?
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.contestStatesRequired
                            ? 'Yes'
                            : 'No'
                        }}
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        v-if="CURRENT_EVENT_OBJECT.contestStatesRequired"
                      >
                        <label class="event-link">
                          States or Territories:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.eligibleStates.toString() }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Does your event have multiple entry periods?
                        </label>
                        {{ multipleEntryPeriodsCheck ? 'Yes' : 'No' }}
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        class="entry-period"
                        v-for="(period,
                        index) in CURRENT_EVENT_OBJECT.intervals"
                        :key="index"
                      >
                        <div class="gray-divider-wrapper">
                          <v-divider class="gray-divider"></v-divider>
                        </div>
                        <v-col cols="12" md="12">
                          <label class="event-link">
                            Entry Period {{ index + 1 }} Start Date/Time:
                          </label>

                          {{
                            (period.startDate + ' ' + period.startTime)
                              | humanDateTimeAsSelected
                          }}
                        </v-col>
                        <v-col cols="12" md="12">
                          <label class="event-link">
                            Entry Period {{ index + 1 }} End Date/Time:
                          </label>

                          {{
                            (period.endDate + ' ' + period.endTime)
                              | humanDateTimeAsSelected
                          }}
                        </v-col>
                        <v-col cols="12" md="12">
                          <label class="event-link">
                            Winner {{ index + 1 }} Selection Date/Time:
                          </label>

                          {{
                            (period.winnerSelectionDate +
                              ' ' +
                              period.winnerSelectionTime)
                              | humanDateTimeAsSelected
                          }}
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </LegalFormSection>
            </v-container>
          </div>
        </LegalFormSection>
        <!-- <LegalFormSection :card="false">
          <div slot="section-content">
            <v-container>
              <LegalFormSection :card="false" compact>
                <span slot="section-header">Experiences</span>
                <div slot="section-content">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Does your event connect to an experience?
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.experience != null ? 'Yes' : 'No'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Indicate the experience you are working with:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.experience }}
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </LegalFormSection>
            </v-container>
          </div>
        </LegalFormSection> -->
        <LegalFormSection :card="false">
          <div slot="section-content">
            <v-container>
              <LegalFormSection :card="false" compact>
                <span slot="section-header">Promotion &amp; Legal</span>
                <div slot="section-content">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Sweepstakes Title:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.sweepstakesTitle }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Abbreviated Prize Description:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Full Prize Description:
                        </label>
                        <p v-html="formattedPrizeDescription" class="ma-0"></p>
                      </v-col>

                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Approximate Retail Value (ARV) of Prize/Giveaway:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.arv }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          What are your Sweepstakes Rules Requirements?
                        </label>
                        {{
                          CURRENT_EVENT_OBJECT.abbreviatedLegal
                            ? 'I have received custom rules from the legal team and will upload them'
                            : 'Generate custom legal rules based on my event details.'
                        }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Upload your Sweepstakes Rules:
                        </label>
                        <button
                          class="legal-link"
                          v-if="CURRENT_EVENT_OBJECT.legalPdfUrl"
                          type="button"
                          @click="legalDialog = true"
                        >
                          Open Legal Rules PDF
                        </button>
                        <p v-else>No legal rules PDF uploaded.</p>
                        <v-dialog
                          v-if="CURRENT_EVENT_OBJECT.legalPdfUrl"
                          v-model="legalDialog"
                          fullscreen
                          hide-overlay
                          transition="dialog-bottom-transition"
                        >
                          <v-card>
                            <v-toolbar dark color="primary">
                              <v-btn icon dark @click="legalDialog = false">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                              <v-toolbar-title>Legal Rules PDF</v-toolbar-title>
                              <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-divider></v-divider>
                            <embed
                              :title="
                                CURRENT_EVENT_OBJECT.name + ' Official Rules'
                              "
                              class="embedded-rules"
                              :src="
                                CURRENT_EVENT_OBJECT.legalPdfUrl +
                                  '#toolbar=0&navpanes=0&scrollbar=0'
                              "
                              type="application/pdf"
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Please describe the event contract details here:
                        </label>
                        {{ CURRENT_EVENT_OBJECT.legalDetails }}
                      </v-col>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          If available, upload a copy of the contract here:
                        </label>
                        <button
                          class="legal-link"
                          v-if="CURRENT_EVENT_OBJECT.legalContract"
                          type="button"
                          @click="contractDialog = true"
                        >
                          Open Contract Document PDF
                        </button>
                        <p v-else>No contract document PDF provided.</p>
                        <v-dialog
                          v-if="CURRENT_EVENT_OBJECT.legalContract"
                          v-model="contractDialog"
                          fullscreen
                          hide-overlay
                          transition="dialog-bottom-transition"
                        >
                          <v-card>
                            <v-toolbar dark color="primary">
                              <v-btn icon dark @click="contractDialog = false">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                              <v-toolbar-title>Contract Document PDF</v-toolbar-title>
                              <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-divider></v-divider>
                            <embed
                              :title="
                                CURRENT_EVENT_OBJECT.name + ' Official Rules'
                              "
                              class="embedded-rules"
                              :src="
                                CURRENT_EVENT_OBJECT.legalContract +
                                  '#toolbar=0&navpanes=0&scrollbar=0'
                              "
                              type="application/pdf"
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12" md="12" class="my-10 elevation-6">
                        <label class="event-link">
                          Abbreviated Legal:
                        </label>
                        <br />
                        <p
                          v-html="
                            helperFunctions.OutputLegal(
                              CURRENT_EVENT_OBJECT,
                              CURRENT_EVENT_OBJECT.defaultLanguage
                            )
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </LegalFormSection>
            </v-container>
          </div>
        </LegalFormSection>
        <v-container>
          <v-row class="justify-center cta-buttons">
            <v-col
              cols="10"
              md="3"
              lg="2"
              v-show="CURRENT_EVENT_OBJECT.status === 'Legal Pending'"
            >
              <v-btn
                elevation="2"
                tile
                block
                x-large
                color="primary"
                outlined
                :disabled="this.USER_GROUPS.indexOf('Legal') === -1"
                @click="showDenyReasonDialog = true"
                class="exit-button"
              >
                <span class="black--text button-text">
                  Deny
                </span>
              </v-btn>
            </v-col>
            <v-col
              cols="10"
              md="3"
              lg="2"
              v-show="CURRENT_EVENT_OBJECT.status === 'Legal Pending'"
            >
              <v-btn
                elevation="2"
                @click="approveLegalAction()"
                tile
                block
                x-large
                :loading="submitLoading"
                :disabled="this.USER_GROUPS.indexOf('Legal') === -1"
                color="primary"
              >
                <span class="white--text button-text">
                  {{
                    CURRENT_EVENT_OBJECT.eventLanguage === 'bi' &&
                    !CURRENT_EVENT_OBJECT.isTranslated
                      ? '  Approve &amp; Send for Translations'
                      : 'Approve'
                  }}
                </span>
              </v-btn>
            </v-col>
            <!-- <v-col
              cols="10"
              md="3"
              lg="2"
              v-show="
                CURRENT_EVENT_OBJECT.eventLanguage === 'bi' &&
                  !CURRENT_EVENT_OBJECT.isTranslated
              "
            >
              <v-btn
                elevation="2"
                tile
                block
                x-large
                :loading="submitLoading"
                :disabled="
                  CURRENT_EVENT_OBJECT.status != 'Legal Pending' ||
                    validateSubmitToTranslations.length > 0 ||
                    this.USER_GROUPS.indexOf('Legal') === -1
                "
                :color="
                  CURRENT_EVENT_OBJECT.published ? 'primary' : 'secondary'
                "
                @click="approveLegalAction()"
              >
                <span class="white--text button-text">
                  Approve &amp; Send for Translations
                </span>
              </v-btn>
            </v-col> -->
          </v-row>
        </v-container>
      </div>
    </v-form>
    <v-dialog
      v-model="showWarningDialog"
      width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Event Status Changed
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          This event's status has changed to {{ CURRENT_EVENT_OBJECT.status }}.
          An event can only be Approved or Denied when it is in Legal Pending
          status.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="px-6 modal-button-outlined"
            color="primary"
            outlined
            tile
            x-large
            @click="showWarningDialog = false"
          >
            <span class="black--text button-text">
              Close
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDenyReasonDialog"
      width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Event Denied
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          Please describe why this event was denied and list the actions the
          event creator should take to rectify.
        </v-card-text>

        <v-form v-model="denyReasonValid">
          <v-textarea
            v-model="denyReason"
            auto-grow
            class="deny-textarea"
            :rules="[
              nonEmptyRule('You must provide a denial reason'),
              v => v.length <= 1000 || 'Max 1000 characters'
            ]"
          ></v-textarea>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="px-6 btn__primary"
            color="primary"
            tile
            x-large
            :disabled="!denyReasonValid"
            :loading="submitDenyLoading"
            @click="denyLegalAction()"
          >
            <span class="white--text button-text">
              Submit
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDenyDialog"
      width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Thank You!
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          The Event Manager will be notified.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="px-6 modal-button-outlined"
            color="primary"
            outlined
            tile
            x-large
            @click="$router.push('/legaldashboard')"
          >
            <span class="black--text button-text">
              Close
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showApproveDialog"
      width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ approveDialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          Thank you! The Event Manager will be notified of their event status
          change.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="px-6 modal-button-outlined"
            color="primary"
            outlined
            tile
            x-large
            @click="$router.push('/dashboard')"
          >
            <span class="black--text button-text">
              Close
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" text class="px-6 btn__primary">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ThemeSwitcher from '@/utility/ThemeSwitcher';
import OptionData from '@/utility/OptionData';
import HelperFunctions from '@/utility/HelperFunctions';
import LegalFormSection from './LegalFormSection.vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import { SubmitToTranslationSchema } from '@/utility/ValidationSchemas';
import PastelTable from '@/components/PastelTable.vue';

export default {
  name: 'LegalSummaryForm',
  components: {
    LegalFormSection,
    PastelTable
  },
  async mounted() {
    try {
      if (this.USER_GROUPS.indexOf('Admin') !== -1) {
        this.displayDialog(
          'Admin Notice',
          'An event can only be Approved or Denied by Legal users.',
          'Close',
          () => (this.dialog = false)
        );
      } else if (this.CURRENT_EVENT_OBJECT.status !== 'Legal Pending') {
        this.showWarningDialog = true;
      }
    } catch (e) {
      console.log(e);
    }
  },

  async created() {
    try {
      if (
        this.USER_GROUPS.indexOf('Legal') === -1 &&
        this.USER_GROUPS.indexOf('Admin') === -1
      ) {
        this.$router.push('/dashboard');
      }
      this.formattedTimezone =
        OptionData.humanReadableTimeZoneFromValue(
          this.CURRENT_EVENT_OBJECT.timezone
        ) + ' Time';

      if (this.CURRENT_EVENT_OBJECT.fullPrizeDescription == null) {
        this.formattedPrizeDescription = 'No Prize.';
      } else {
        this.formattedPrizeDescription = this.CURRENT_EVENT_OBJECT.fullPrizeDescription
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#039;')
          .replace(/\n/g, '<br />')
          .trim();
      }
      let historyPayload = {
        eventId: this.$route.params.id,
        timezone: this.CURRENT_EVENT_OBJECT.timezone || 'America/New_York'
      };
      await this.GET_ALL_HISTORY(historyPayload);
    } catch (e) {}
  },
  data() {
    return {
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null,
      valid: false,
      denyReasonValid: false,
      showDialog: false,
      showWarningDialog: false,
      showDenyReasonDialog: false,
      showDenyDialog: false,
      showApproveDialog: false,
      approveDialogTitle: 'Event Approved',
      denyReason: '',
      validationAttempted: false,
      submitLoading: false,
      submitDenyLoading: false,
      loading: false,
      formattedTimezone: null,
      legalDialog: false,
      contractDialog: false,
      formattedPrizeDescription: null,
      nonEmptyRule(inputError) {
        return v => v === 0 || v === false || !!v || inputError;
      },
      checkboxGroupRule(checkboxGroupObj, inputError) {
        return () =>
          Object.values(checkboxGroupObj).some(k => k === true) || inputError;
      }
    };
  },

  watch: {
    brandWatcher: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.changeTheme(newVal);
        }
      }
    }
  },
  methods: {
    ...mapActions({
      sendEmail: 'admin/sendEmail'
    }),
    ...mapActions('EVENT_HISTORY_STORE', ['GET_ALL_HISTORY']),
    changeTheme(brand) {
      ThemeSwitcher.ChangeTheme(this, brand);
    },
    approveLegalEmail(toAddress, eventName) {
      let eventId = this.$route.params.id;
      let given_name = this.CURRENT_EVENT_OBJECT.createdByFirstName;

      let templateData = {
        preheader_text: '',
        header_text: 'Your Event Has Been Approved',
        greeting_text: `Hi, ${given_name},`,
        'body-1_text': `Your event: ${eventName}, has been reviewed and approved by the legal team.`,
        'body-2_text': '',
        'cta-1_href':
          'https://www.t-mobileengagementhub.com/#/event/edit/' + eventId,
        'cta-1_text': 'Review Event',
        subject_text: 'Legal Approval Received for: ' + eventName,
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'Legal Review <donotreply@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    denyLegalEmail(toAddress, eventName) {
      let eventId = this.$route.params.id;
      let given_name = this.CURRENT_EVENT_OBJECT.createdByFirstName;
      let denyReason = this.denyReason;

      let templateData = {
        preheader_text: '',
        header_text: 'Your Event Has Not Been Approved',
        greeting_text: `Hi, ${given_name},`,
        'body-1_text': `Your event: ${eventName}, has been reviewed by the legal team. Your event cannot be approved for the reason(s) listed below.
                        Please make any appropriate updates to your event and re-submit to legal for approval.
                        For any questions or assistance, please reach out to <a href="mailto:MarketingContracts@T-Mobile.com">MarketingContracts@T-Mobile.com</a>.`,
        'body-2_text': `Legal Review Notes: ${denyReason}`,
        'cta-1_href':
          'https://www.t-mobileengagementhub.com/#/event/edit/' + eventId,
        'cta-1_text': 'Update Event',
        subject_text: 'Legal Status Update for: ' + eventName,
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'Legal Review <donotreply@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    approvedSentToTranslationEmail(toAddress, eventName) {
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.CURRENT_EVENT_OBJECT.createdByFirstName;
      let family_name = this.CURRENT_EVENT_OBJECT.createdByLastName;
      let email = this.CURRENT_EVENT_OBJECT.createdByEmail;
      let legalDetails = this.CURRENT_EVENT_OBJECT.legalDetails;
      let contract = this.CURRENT_EVENT_OBJECT.legalContract
        ? this.CURRENT_EVENT_OBJECT.legalContract
        : 'N/A';

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Event Translation',
        greeting_text: `Hi ${given_name},`,
        'body-1_text':
          'Your event has been reviewed and approved by the legal team. Your event has been sent to the translation team. You will receive an email once translations have been completed and your event is published.',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>' +
          'Legal Details: ' +
          legalDetails +
          '<br>' +
          'Contract, if provided: ' +
          contract,
        subject_text: `Status Changed for Event ID ${this.CURRENT_EVENT_OBJECT.id}`,
        'cta-1_href': `https://www.t-mobileengagementhub.com/#/event/edit/${this.CURRENT_EVENT_OBJECT.id}`,
        'cta-1_text': 'View',
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'Legal Review <donotreply@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    async approveLegalAction() {
      try {
        if (this.CURRENT_EVENT_OBJECT.status !== 'Legal Pending') {
          this.showWarningDialog = true;
          return;
        }
        this.submitLoading = true;

        //Update the event Status;
        let response = await this.$store.dispatch(
          'LEGAL_STORE/APPROVE_LEGAL',
          this.$route.params.id
        );

        // response = JSON.parse(response);
        if (response.errorMessage !== undefined) {
          this.displayDialog(
            'Error',
            response.errorMessage,
            'Close',
            () => (this.dialog = false)
          );
          return;
        }
        this.submitLoading = false;
        let email = this.CURRENT_EVENT_OBJECT.createdByEmail;
        let eventName = this.CURRENT_EVENT_OBJECT.name;
        // show dialog
        if (
          this.CURRENT_EVENT_OBJECT.eventLanguage === 'bi' &&
          !this.CURRENT_EVENT_OBJECT.isTranslated
        ) {
          this.approveDialogTitle = 'Event Approved & Sent For Translations';
          this.sendEmail(this.approvedSentToTranslationEmail(email, eventName));
          this.sendEmail(
            this.translationReviewEmail(
              process.env.VUE_APP_TRANSLATION_TO_EMAIL,
              eventName
            )
          );
        } else {
          this.approveDialogTitle = 'Event Approved';

          this.sendEmail(this.approveLegalEmail(email, eventName));
        }
        this.showApproveDialog = true;
        // send legal approved email
      } catch (error) {
        console.log('ApproveLegalAction Error: ', error);
      }
    },
    validateSubmitToTranslations() {
      let errorArr = [];
      let enterToWin =
        this.CURRENT_EVENT_OBJECT.contestType === 'Enter for a Chance to Win';
      const errors = validate(
        this.CURRENT_EVENT_OBJECT,
        enterToWin ? EnterToWinEventSchema : SubmitToTranslationSchema
      );

      if (errors.length > 0) {
        for (const { message } of errors) {
          errorArr.push(message);
        }
      } else {
        errorArr = [];
      }
      return errorArr;
    },

    async denyLegalAction() {
      try {
        if (this.CURRENT_EVENT_OBJECT.status !== 'Legal Pending') {
          this.showWarningDialog = true;
          return;
        }
        this.submitDenyLoading = true;

        //Update the event Status;
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: 'Denied'
        });
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'published',
          value: false
        });

        let response = await this.$store.dispatch('LEGAL_STORE/DENY_LEGAL', {
          id: this.$route.params.id,
          message: this.denyReason
        });

        if (response.errorMessage !== undefined) {
          this.displayDialog(
            'Error',
            response.errorMessage,
            'Close',
            () => (this.dialog = false)
          );
          return;
        }
        this.submitDenyLoading = false;
        // show dialog
        this.showDenyDialog = true;
        // send legal approved email
        let email = this.CURRENT_EVENT_OBJECT.createdByEmail;
        let eventName = this.CURRENT_EVENT_OBJECT.name;
        this.sendEmail(this.denyLegalEmail(email, eventName));
      } catch (error) {
        console.log('denyLegalActionAction Error: ', error);
      }
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },

    translationReviewEmail(toAddress, eventName) {
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.CURRENT_EVENT_OBJECT.createdByFirstName;
      let family_name = this.CURRENT_EVENT_OBJECT.createdByLastName;
      let email = this.CURRENT_EVENT_OBJECT.createdByEmail;

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Translation Request',
        greeting_text: `Greetings,`,
        'body-1_text':
          'An event is ready to be translated. Click the button below to review the event details and submit the translations.',
        'cta-1_href':
          // ** new route needed **
          'https://www.t-mobileengagementhub.com/#/translate/' +
          this.$route.params.id,
        'cta-1_text': 'Translate Event',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email,
        subject_text:
          'Spanish Translation Request for Event ID: ' + this.$route.params.id,
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'Translation Request <donotreply@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    userEventSubmittedTranslationEmail(toAddress, eventName) {
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.userAttributes.given_name;
      let family_name = this.userAttributes.family_name;
      let email = this.userAttributes.email;
      let legalDetails = this.CURRENT_EVENT_OBJECT.legalDetails;
      let contract = this.CURRENT_EVENT_OBJECT.legalContract
        ? this.CURRENT_EVENT_OBJECT.legalContract
        : 'N/A';

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Translation Request',
        greeting_text: `Greetings,`,
        'body-1_text': 'Your event has been sent to be translated.',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>' +
          'Legal Details: ' +
          legalDetails +
          '<br>' +
          'Contract, if provided: ' +
          contract,
        subject_text:
          'Spanish Translation Request Receipt for Event ID: ' +
          this.$route.params.id,
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'Translation Review <donotreply@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    }
  },
  computed: {
    optionData: function() {
      return OptionData;
    },
    helperFunctions: function() {
      return HelperFunctions;
    },
    urlRoot: function() {
      return window.location.origin;
    },
    multipleEntryPeriodsCheck() {
      return (
        this.CURRENT_EVENT_OBJECT['intervals'] &&
        Array.isArray(this.CURRENT_EVENT_OBJECT['intervals']) &&
        this.CURRENT_EVENT_OBJECT['intervals'].length > 1
      );
    },
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`
    }),
    ...mapState('EVENT_MANAGER_STORE', [
      'CURRENT_EVENT_OBJECT',
      'LOADING',

      'BITLY_LINK',
      'API_CALL_IN_PROGRESS'
    ]),
    ...mapState('LEGAL_STORE', ['CURRENT_LEGAL_OBJECT']),
    ...mapState('EVENT_HISTORY_STORE', ['HISTORY_TABLE_DATA']),
    brandWatcher() {
      return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.brand
        ? this.CURRENT_EVENT_OBJECT.brand
        : 'T-Mobile';
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .event-brand .v-input--selection-controls__input {
  display: none;
}

::v-deep .v-input--checkbox {
  margin-top: 0;
}

.date-time-picker-wrap .v-text-field {
  margin-top: 0px;
}

::v-deep .v-input--radio-group {
  margin-top: 0;
  padding-top: 0;
}

.fixed-buttons {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #9b9b9b;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 1;
}

.exit-button {
  background-color: white;
}

.button-text {
  font-weight: bold;
}

::v-deep .v-btn--outlined {
  border-width: 2px !important;
}

.wide-container {
  // max-width: 1442px;
  margin: auto;
}

@media (max-width: 1470px) {
  // .wide-container {
  //   // width: 90%;
  // }
}

.event-link {
  font-weight: bold;
}

.bitly-skeleton {
  display: inline-block;
  width: 100px;
  margin-top: 5px;
}
.entry-period {
  padding: 0;
}
.gray-divider-wrapper {
  width: 100%;
  padding: 0 13.5px;
}
.cta-buttons {
  padding-bottom: 50px;
}
::v-deep .v-textarea textarea {
  border: 1px solid var(--v-grey-base);
}

.embedded-rules {
  width: 100%;
  height: 100vh;
  display: block;
}

.legal-link {
  color: var(--v-primary-base);
}
</style>
