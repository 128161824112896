const DraftEventSchema = {
  eventLanguage: value => /^(\w)/.test(value),
  name: value => {
    if (!value) return false;
    return true;
  },
  contestType: value => {
    if (!value) return false;
    return true;
  },
  startDate: value => {
    if (!value) return false;
    return true;
  },
  startTime: value => {
    if (!value) return false;
    return true;
  },
  endTime: value => {
    if (!value) return false;
    return true;
  },
  timezone: value => {
    if (!value) return false;
    return true;
  },
  endDate: value => {
    if (!value) return false;
    return true;
  },
  intervals: value => {
    //console.log("interv")
    if (!value || !Array.isArray(value) || !value.length) return false;
    for (var i = 0; i < value.length; i++) {
      if (
        value[i].startDate == null ||
        value[i].startTime == null ||
        value[i].endDate == null ||
        value[i].endTime == null ||
        value[i].winnerSelectionDate == null ||
        value[i].winnerSelectionTime == null
      ) {
        return false;
      }
      return true;
    }
  }
};
const SubmitToLegalEventSchema = {
  eventLanguage: value => /^(\w)/.test(value),
  name: value => {
    if (!value) return false;
    return true;
  },
  startDate: value => {
    if (!value) return false;
    return true;
  },
  startTime: value => {
    if (!value) return false;
    return true;
  },
  endTime: value => {
    if (!value) return false;
    return true;
  },
  totalMarketingSpend: value => {
    if (!value) return false;
    return true;
  },
  contestType: value => {
    if (!value) return false;
    return true;
  },
  timezone: value => {
    if (!value) return false;
    return true;
  },
  endDate: value => {
    if (!value) return false;
    return true;
  },

  eventDescription: value => {
    if (!value) return false;
    return true;
  },
  brand: value => {
    if (!value) return false;
    return true;
  },
  eventLocation: value => {
    if (!value) return false;
    return true;
  },
  storeNumber: value => {
    if (!value) return false;
    return true;
  },
  eventType: value => {
    if (!value) return false;
    return true;
  },
  legalDetails: value => {
    if (!value) return false;
    return true;
  },
  organization: value => {
    if (!value || !value.length) return false;
    return true;
  },
  intervals: value => {
    //console.log("interv")
    if (!value || !Array.isArray(value) || !value.length) return false;
    for (var i = 0; i < value.length; i++) {
      if (
        value[i].startDate == null ||
        value[i].startTime == null ||
        value[i].endDate == null ||
        value[i].endTime == null ||
        value[i].winnerSelectionDate == null ||
        value[i].winnerSelectionTime == null
      ) {
        return false;
      }
      return true;
    }
  }
};

const SubmitToTranslationSchema = {
  eventLanguage: value => /^(\w)/.test(value),
  name: value => {
    if (!value) return false;
    return true;
  },
  startDate: value => {
    if (!value) return false;
    return true;
  },
  startTime: value => {
    if (!value) return false;
    return true;
  },
  endTime: value => {
    if (!value) return false;
    return true;
  },
  totalMarketingSpend: value => {
    if (!value) return false;
    return true;
  },
  contestType: value => {
    if (!value) return false;
    return true;
  },
  timezone: value => {
    if (!value) return false;
    return true;
  },
  endDate: value => {
    if (!value) return false;
    return true;
  },

  eventDescription: value => {
    if (!value) return false;
    return true;
  },
  brand: value => {
    if (!value) return false;
    return true;
  },
  eventLocation: value => {
    if (!value) return false;
    return true;
  },
  storeNumber: value => {
    if (!value) return false;
    return true;
  },
  eventType: value => {
    if (!value) return false;
    return true;
  },
  legalDetails: value => {
    if (!value) return false;
    return true;
  },
  organization: value => {
    if (!value || !value.length) return false;
    return true;
  },
  intervals: value => {
    //console.log("interv")
    if (!value || !Array.isArray(value) || !value.length) return false;
    for (var i = 0; i < value.length; i++) {
      if (
        value[i].startDate == null ||
        value[i].startTime == null ||
        value[i].endDate == null ||
        value[i].endTime == null ||
        value[i].winnerSelectionDate == null ||
        value[i].winnerSelectionTime == null
      ) {
        return false;
      }
      return true;
    }
  }
};

const EnterToWinEventSchema = {
  eventLanguage: value => /^(\w)/.test(value),
  name: value => {
    if (!value) return false;
    return true;
  },
  startDate: value => {
    if (!value) return false;
    return true;
  },
  startTime: value => {
    if (!value) return false;
    return true;
  },
  endTime: value => {
    if (!value) return false;
    return true;
  },
  totalMarketingSpend: value => {
    if (!value) return false;
    return true;
  },
  eligibleStates: value => {
    if (value.length < 1) return false;
    return true;
  },
  abbreviatedPrizeDescription: value => {
    if (!value) return false;
    return true;
  },
  contestType: value => {
    if (!value) return false;
    return true;
  },
  timezone: value => {
    if (!value) return false;
    return true;
  },
  endDate: value => {
    if (!value) return false;
    return true;
  },

  eventDescription: value => {
    if (!value) return false;
    return true;
  },
  brand: value => {
    if (!value) return false;
    return true;
  },
  eventLocation: value => {
    if (!value) return false;
    return true;
  },
  storeNumber: value => {
    if (!value) return false;
    return true;
  },
  eventType: value => {
    if (!value) return false;
    return true;
  },
  legalDetails: value => {
    if (!value) return false;
    return true;
  },
  organization: value => {
    if (!value || !value.length) return false;
    return true;
  },
  intervals: value => {
    //console.log("interv")
    if (!value || !Array.isArray(value) || !value.length) return false;
    for (var i = 0; i < value.length; i++) {
      if (
        value[i].startDate == null ||
        value[i].startTime == null ||
        value[i].endDate == null ||
        value[i].endTime == null ||
        value[i].winnerSelectionDate == null ||
        value[i].winnerSelectionTime == null
      ) {
        return false;
      }
      return true;
    }
  }
};

const BatchImportEventSchema = {
  name: value => {
    if (!value) return false;
    return true;
  },
  startDate: value => {
    if (!value) return false;
    return true;
  },
  startTime: value => {
    if (!value) return false;
    return true;
  },
  endTime: value => {
    if (!value) return false;
    return true;
  },
  contestType: value => {
    if (!value) return false;
    return true;
  },
  timezone: value => {
    if (!value) return false;
    return true;
  },
  endDate: value => {
    if (!value) return false;
    return true;
  },
  brand: value => {
    if (!value) return false;
    return true;
  },
  eventLocation: value => {
    if (!value) return false;
    return true;
  },
  organization: value => {
    if (!value || !value.length) return false;
    return true;
  },
  intervals: value => {
    //console.log("interv")
    if (!value || !Array.isArray(value) || !value.length) return false;
    for (var i = 0; i < value.length; i++) {
      if (
        value[i].startDate == null ||
        value[i].startTime == null ||
        value[i].endDate == null ||
        value[i].endTime == null ||
        value[i].winnerSelectionDate == null ||
        value[i].winnerSelectionTime == null
      ) {
        return false;
      }
      return true;
    }
  }
};

// export all the functions from this file
export {
  SubmitToTranslationSchema,
  SubmitToLegalEventSchema,
  DraftEventSchema,
  EnterToWinEventSchema,
  BatchImportEventSchema
};
