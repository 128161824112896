import { render, staticRenderFns } from "./PastelTable.vue?vue&type=template&id=8e6c141e&scoped=true&"
import script from "./PastelTable.vue?vue&type=script&lang=js&"
export * from "./PastelTable.vue?vue&type=script&lang=js&"
import style0 from "./PastelTable.vue?vue&type=style&index=0&id=8e6c141e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e6c141e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VProgressCircular,VSimpleTable})
