<template>
  <v-card flat>
    <v-container fluid>
      <v-row>
        <v-col cols="10">
          <v-card-title> Delete Image </v-card-title>
          <v-card-subtitle> <b>This will delete all of the following images</b> </v-card-subtitle>
        </v-col>
        <v-col cols="2">
          <v-btn outlined @click="cancelDelete">
            Cancel
          </v-btn>
        </v-col>
      </v-row>
      <v-form v-model="valid">
        <v-row v-for="(editImage) in NEW_IMAGE_UPLOAD_ARR" :key="editImage.id">
          <v-col cols="2">
            <v-card-title> {{editImage.template_id}} </v-card-title>
            <v-card-subtitle> {{editImage.placeholder_image_width}} x {{editImage.placeholder_image_height}} </v-card-subtitle>
          </v-col>
          <v-col cols="5">
            <v-img
              height="200px"
              :src="editImage.url"
              :lazy-src="editImage.url"
              aspect-ratio="16/9"
              max-height="300"
              contain
              class="grey darken-4"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="4" >
            <v-btn  
              class="v-btn v-btn--is-elevated v-btn--has-bg v-btn--tile theme--light elevation-2 v-size--x-large primary"
              block
              @click="deleteImages"
              :loading="loading"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog v-model="dialog" width="500" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" tile x-large color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'AVEDeleteForm',
  components: {},
  data() {
    return {
      valid: false,
      loading: false,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogAction: null
    };
  },
  methods: {
    ...mapMutations('AVE_CANVAS_STORE', ['RESET_IMAGE_UPLOAD_FORM']),
    ...mapActions('AVE_CANVAS_STORE', ['DELETE_IMAGES']),
    async deleteImages() {
      this.loading = true;
      try {
        await this.DELETE_IMAGES();
        this.displayDialog('Success', 'Image Delete Successful.', () => {
          this.dialog = false;
          this.$emit('CHANGE_ACTIVE_DIALOG_COMPONENT', 'AVEPhotoManager');
        });
      } catch (e) {
        this.showErrorDialog(e);
      } finally {
        this.loading = false;
      }
    },
    cancelDelete() {
      this.RESET_IMAGE_UPLOAD_FORM();
      this.$emit('CHANGE_ACTIVE_DIALOG_COMPONENT', 'AVEPhotoManager');
    },
    showErrorDialog(error) {
      this.dialogTitle = 'Error';
      this.dialogMessage = error;
      this.dialogAction = () => (this.dialog = false);
      this.dialog = true;
    },
    displayDialog(dialogTitle, dialogMessage, dialogAction) {
      this.dialogTitle = dialogTitle;
      this.dialogMessage = dialogMessage;
      this.dialogAction = dialogAction;
      this.dialog = true;
    }
  },
  computed: {
    ...mapState('AVE_CANVAS_STORE', ['NEW_IMAGE_UPLOAD_ARR'])
  }
};
</script>
