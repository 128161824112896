<template>
  <v-row justify="center">
    <v-dialog
      v-model="IS_FLYOUT_OPEN"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="TOGGLE_AVE_FLYOUT(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Radio Assets</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="TOGGLE_AVE_FLYOUT(false)">
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider></v-divider>
        <AVEEditor />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import AVEEditor from '@/components/AVECanvas/AVEEditor';

export default {
  name: 'AVEFlyout',
  components: {
    AVEEditor
  },
  methods: {
    ...mapMutations('AVE_CANVAS_STORE', ['TOGGLE_AVE_FLYOUT'])
  },
  computed: {
    ...mapState('AVE_CANVAS_STORE', ['FLYOUT_OPEN']),
    IS_FLYOUT_OPEN: {
      get() {
        return this.FLYOUT_OPEN;
      },
      set(value) {
        this.TOGGLE_AVE_FLYOUT(value);
      }
    }
  }
};
</script>
