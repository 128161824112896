<template>
  <div></div>
</template>

<script>
import jsPDF from 'jspdf';
import TMOEvent from '../models/TMOEvent.js';
import HelperFunctions from '../utility/HelperFunctions.js';
import '../assets/fonts/TeleGroteskNext-Bold-normal.js';
import '../assets/fonts/TeleGroteskNext-Ultra-normal.js';
import '../assets/fonts/TeleGroteskNext-Medium-normal.js';
import '../assets/fonts/TeleGroteskNextRegular-normal.js';
import '../assets/fonts/tt-norms-bold-normal.js';
import '../assets/fonts/tt-norms-medium-normal.js';
import '../assets/fonts/tt-norms-regular-normal.js';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'PDFDownloadGenerator',
  props: {
    curEvent: {
      type: Object,
      default() {
        return new TMOEvent();
      }
    }
  },
  computed: {
    ...mapGetters({ translations: 'translations' }),
    ...mapState('EVENT_MANAGER_STORE', ['BITLY_LINK']),
    helperFunctions: function() {
      return HelperFunctions;
    }
  },
  methods: {
    async createFlyerPDF(language) {
      console.log('flyer pdf creation firing');
      // no idea why, but if this fetch bitly isn't called here
      // then the entire function becomes synchronous
      await this.$store.dispatch(
        'EVENT_MANAGER_STORE/FETCH_BITLY_LINK',
        this.curEvent.id
      );

      if (!language) {
        language = 'en';
      }

      let pdfName;
      if (
        language === 'es' &&
        this.translations.event_name &&
        this.translations.event_name != ''
      ) {
        let name =
          typeof this.translations.event_name == 'string'
            ? this.translations.event_name
            : this.curEvent.name;
        pdfName = name + 'QRFlyer';
      } else {
        pdfName = this.curEvent.name + 'QRFlyer';
      }

      var doc = new jsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'letter'
      });
      doc.setTextColor('#ffffff');
      doc.setFillColor('#ffffff');
      //background layer
      if (this.curEvent.brand.includes('Metro')) {
        doc.addImage(
          require('../assets/metroPDFbg.jpg'),
          'JPEG',
          0,
          0,
          8.5,
          11
        );
      } else {
        doc.addImage(require('../assets/tmoPDFbg.jpg'), 'JPEG', 0, 0, 8.5, 11);
      }
      //qr backdrop
      var offsetQR = this.curEvent.contestType == 'Instant Win' ? 0.4 : 0;
      doc.rect(
        3.14,
        this.curEvent.brand.includes('Metro') ? 4.62 + offsetQR : 4.82,
        2.22,
        2.22,
        'F'
      );
      var rootAddress = window.location.protocol + '//' + window.location.host;
      var registerAddress =
        rootAddress + '/#/engagements/register/' + this.curEvent.id;
      var escapedRegisterAddress = encodeURIComponent(registerAddress);
      //actual qr code
      doc.addImage(
        'https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=' +
          this.BITLY_LINK,
        'PNG',
        3.225,
        this.curEvent.brand.includes('Metro') ? 4.705 + offsetQR : 4.905,
        2.05,
        2.05
      );
      //headline text
      var fontSize = this.curEvent.brand.includes('Metro') ? 68 : 80;
      var contestType = this.curEvent.contestType.toUpperCase();
      var headline = contestType;
      if (contestType == 'SIGN UP/CHECK IN') {
        language == 'es'
          ? ((headline = 'INSCRÍBETE Y GANA UN REGALO'), (fontSize -= 10))
          : ((headline = 'SIGN UP FOR A FREE GIFT'), (fontSize += 0));
      } else if (contestType == 'INSTANT WIN') {
        language == 'es'
          ? ((headline = 'GANA AL INSTANTE'), (fontSize += 10))
          : ((headline = 'INSTANT WIN'), (fontSize += 0));
      } else if (language == 'es') {
        headline = 'PARTICIPA Y GANA';
        fontSize -= 30;
      }
      if (this.curEvent.brand.includes('Metro')) {
        doc.setFont('tt-norms-bold', 'normal');
        doc.setFontSize(fontSize);
        // ticket #251 reduce letter spacing only for instant win
        // center alignment doesn't work with charSpace
        // so have to separate lines and adjust position
        if (contestType == 'INSTANT WIN') {
          let text1 = language === 'es' ? 'GANA' : 'INSTANT';
          let text2 = language === 'es' ? 'AL INSTANTE' : 'WIN';
          if (language == 'es') {
            doc.text(text1, 4.31, 0.55, {
              align: 'center',
              maxWidth: 8.25,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.05'
            });
            doc.text(text2, 4.52, 1.47, {
              align: 'center',
              maxWidth: 8.25,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.05'
            });
          } else {
            doc.text(text1, 4.4, 0.55, {
              align: 'center',
              maxWidth: 8.25,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.05'
            });
            doc.text(text2, 4.3, 1.37, {
              align: 'center',
              maxWidth: 8.25,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.05'
            });
          }
        } else if (contestType == 'SIGN UP/CHECK IN') {
          let text1 = language === 'es' ? 'INSCRÍBETE Y' : 'SIGN UP FOR';
          let text2 = language === 'es' ? 'GANA UN REGALO' : 'A FREE GIFT';
          if (language == 'es') {
            doc.text(text1, 4.2, 0.55, {
              align: 'center',
              maxWidth: 8.25,
              baseline: 'top',
              lineHeightFactor: 1.01
            });
            doc.text(text2, 4.25, 1.37, {
              align: 'center',
              maxWidth: 8.25,
              baseline: 'top',
              lineHeightFactor: 1.01
            });
          } else {
            doc.text(headline, 4.25, 0.55, {
              align: 'center',
              maxWidth: 8.25,
              baseline: 'top',
              lineHeightFactor: 1.01
            });
          }
        } else if (language === 'es') {
          doc.text(headline, 4.25, 0.55, {
            align: 'center',
            maxWidth: 8,
            baseline: 'top',
            lineHeightFactor: 1.01
          });
        } else {
          doc.text(headline, 4.25, 0.55, {
            align: 'center',
            maxWidth: 8.25,
            baseline: 'top',
            lineHeightFactor: 1.01
          });
        }
      } else {
        doc.setFont('TeleGroteskNext-Ultra', 'normal');
        doc.setFontSize(fontSize);
        if (contestType == 'INSTANT WIN') {
          let text1 = language === 'es' ? 'GANA' : 'INSTANT';
          let text2 = language === 'es' ? 'AL INSTANTE' : 'WIN';
          console.log('Check point 1');
          doc.text(text1, 4.31, 0.075, {
            align: 'center',
            maxWidth: 8.25,
            baseline: 'top',
            lineHeightFactor: 0.92,
            charSpace: '-0.05'
          });
          doc.text(text2, 4.52, 1.1, {
            align: 'center',
            maxWidth: 8.25,
            baseline: 'top',
            lineHeightFactor: 0.92,
            charSpace: '-0.05'
          });
        } else {
          doc.text(headline, 4.25, 0.075, {
            align: 'center',
            maxWidth: 8.25,
            baseline: 'top',
            lineHeightFactor: 0.92
          });
        }
      }
      //prize text
      var y = 2.97;
      if (contestType === 'INSTANT WIN') {
        if (this.curEvent.brand.includes('Metro')) {
          // y += fontSize / 110.0;
        } else {
          //y += fontSize / 130.0;
        }
      } else if (contestType === 'SIGN UP/CHECK IN') {
        //y += fontSize / 200.0;
      }
      if (this.curEvent.abbreviatedPrizeDescription != null) {
        if (this.curEvent.brand.includes('Metro')) {
          doc.setFont('tt-norms-medium', 'normal');
          doc.setFontSize(24);
          if (
            language === 'es' &&
            this.translations.abbreviatedPrizeDescription &&
            this.translations.abbreviatedPrizeDescription != ''
          ) {
            doc.text(
              this.translations.abbreviatedPrizeDescription.toUpperCase(),
              4.25,
              y,
              {
                align: 'center',
                maxWidth: 8.1,
                baseline: 'top'
              }
            );
          } else {
            doc.text(
              this.curEvent.abbreviatedPrizeDescription.toUpperCase(),
              4.25,
              y,
              {
                align: 'center',
                maxWidth: 8.1,
                baseline: 'top'
              }
            );
          }
        } else {
          doc.setFont('TeleGroteskNext-Medium', 'normal');
          doc.setFontSize(28);
          if (
            language === 'es' &&
            this.translations.abbreviatedPrizeDescription &&
            this.translations.abbreviatedPrizeDescription != ''
          ) {
            doc.text(
              this.translations.abbreviatedPrizeDescription.toUpperCase(),
              4.25,
              y,
              {
                align: 'center',
                maxWidth: 8.05,
                baseline: 'top'
              }
            );
          } else {
            doc.text(
              this.curEvent.abbreviatedPrizeDescription.toUpperCase(),
              4.25,
              y,
              {
                align: 'center',
                maxWidth: 8.05,
                baseline: 'top'
              }
            );
          }
        }
      }
      //scan CTA text
      let scantext =
        language === 'es'
          ? 'ESCANEA PARA PARTICIPAR O VISITA\n'
          : 'SCAN TO ENTER OR VISIT\n';
      if (this.curEvent.brand.includes('Metro')) {
        doc.setFont('tt-norms-bold', 'normal');
        doc.setFontSize(18);
        doc.text(
          scantext + this.BITLY_LINK.replace('https://', ''),
          4.25,
          7 + offsetQR,
          {
            align: 'center',
            baseline: 'top'
          }
        );
      } else {
        doc.setFont('TeleGroteskNext-Bold', 'normal');
        doc.setFontSize(18);
        doc.text(
          scantext + this.BITLY_LINK.replace('https://', ''),
          4.25,
          7.17,
          {
            align: 'center',
            baseline: 'top'
          }
        );
      }
      //legal text
      var outputLegal = this.helperFunctions
        .OutputLegal(this.curEvent, language)
        .replace(/<a[^>]*>/g, '')
        .replace(/<\/a>/g, '');
      if (this.curEvent.brand.includes('Metro')) {
        doc.setFont('tt-norms-regular', 'normal');
        doc.setFontSize(10);

        outputLegal +=
          language == 'es'
            ? ' T-Mobile, el logotipo de la T, Magenta y el color magenta son marcas comerciales registradas de Deutsche Telekom AG. © 2021 T-Mobile USA, Inc.'
            : ' Metro by T-Mobile is a registered trademark of T-Mobile USA, Inc. (c) 2021 T-Mobile USA, Inc.';
        doc.text(outputLegal, 4.25, 10, {
          align: 'center',
          maxWidth: 8.05,
          baseline: 'top'
        });
      } else {
        doc.setFont('TeleGroteskNextRegular', 'normal');
        doc.setFontSize(10);

        outputLegal +=
          language == 'es'
            ? ' T-Mobile, el logotipo de la T, Magenta y el color magenta son marcas comerciales registradas de Deutsche Telekom AG. © 2021 T-Mobile USA, Inc.'
            : ' T-Mobile, the T logo, Magenta and the magenta color are registered trademarks of Deutsche Telekom AG. © 2021 T-Mobile USA, Inc.';
        doc.text(outputLegal, 4.25, 10, {
          align: 'center',
          maxWidth: 8.05,
          baseline: 'top'
        });
      }
      doc.save(pdfName + '.pdf');
      this.$emit('flyerGenerationComplete');
    },
    async createAFramePDF(language) {
      // no idea why, but if this fetch bitly isn't called here
      // then the entire function becomes synchronous
      await this.$store.dispatch(
        'EVENT_MANAGER_STORE/FETCH_BITLY_LINK',
        this.curEvent.id
      );

      let pdfName;
      if (
        language == 'es' &&
        this.translations.event_name &&
        this.translations.event_name != ''
      ) {
        let name =
          typeof this.translations.event_name == 'string'
            ? this.translations.event_name
            : this.curEvent.name;
        pdfName = name + 'QRAFrame';
      } else {
        pdfName = this.curEvent.name + 'QRAFrame';
      }

      var doc = new jsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: [36, 24]
      });
      doc.setTextColor('#ffffff');
      //background layer
      if (this.curEvent.brand.includes('Metro')) {
        doc.setFillColor('#46196e');
      } else {
        doc.setFillColor('#e20074');
      }
      doc.rect(0, 0, 24, 36, 'F');
      doc.setFillColor('#ffffff');
      //qr backdrop
      doc.rect(
        8.86736,
        this.curEvent.brand.includes('Metro') ? 15.11 : 15.76,
        6.26928,
        6.26928,
        'F'
      );
      var rootAddress = window.location.protocol + '//' + window.location.host;
      var registerAddress =
        rootAddress + '/#/engagements/register/' + this.curEvent.id;
      var escapedRegisterAddress = encodeURIComponent(registerAddress);
      //actual qr code
      doc.addImage(
        'https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=' +
          this.BITLY_LINK,
        'PNG',
        9.1074,
        this.curEvent.brand.includes('Metro') ? 15.39 : 16.04,
        5.7892,
        5.7892
      );
      //headline text
      var fontSize = this.curEvent.brand.includes('Metro') ? 180 : 220;
      var contestType = this.curEvent.contestType.toUpperCase();
      var headline = contestType;
      if (contestType == 'SIGN UP/CHECK IN') {
        if (language == 'en') {
          headline = 'SIGN UP FOR A FREE GIFT';
          fontSize += 60;
        } else {
          headline = 'INSCRÍBETE Y GANA UN REGALO';
          fontSize += 0;
        }
      } else if (contestType == 'INSTANT WIN') {
        if (language === 'es') {
          fontSize += 50;
        } else {
          fontSize += 120;
        }
      } else if (language == 'es') {
        headline = 'PARTICIPA Y GANA';
        fontSize -= 40;
      }
      if (this.curEvent.brand.includes('Metro')) {
        doc.setFont('tt-norms-bold', 'normal');
        doc.setFontSize(fontSize);
        // ticket #251 reduce letter spacing only for instant win
        // center alignment doesn't work with charSpace
        // so have to separate lines and adjust position
        if (contestType == 'INSTANT WIN') {
          let text1 = language == 'es' ? 'GANA ' : 'INSTANT';
          let text2 = language == 'es' ? 'AL INSTANTE' : 'WIN';
          if (language === 'es') {
            doc.text(text1, 12.7, 4.3719, {
              align: 'center',
              maxWidth: 23.3,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.2'
            });
            doc.text(text2, 13, 7.3719, {
              align: 'center',
              maxWidth: 23.3,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.2'
            });
          } else {
            doc.text(text1, 12.7, 3.1719, {
              align: 'center',
              maxWidth: 23.3,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.2'
            });
            doc.text(text2, 12, 7.3719, {
              align: 'center',
              maxWidth: 23.3,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.2'
            });
          }
        } else if (contestType == 'SIGN UP/CHECK IN') {
          let text1 = language === 'es' ? 'INSCRÍBETE Y' : 'SIGN UP FOR';
          let text2 = language === 'es' ? 'GANA UN REGALO' : 'A FREE GIFT';
          if (language == 'es') {
            doc.text(text1, 13.3, 4.1719, {
              align: 'center',
              maxWidth: 30.3,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.2'
            });
            doc.text(text2, 13.3, 6.3719, {
              align: 'center',
              maxWidth: 30.3,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.2'
            });
          } else {
            doc.text(headline, 12, 0.97, {
              align: 'center',
              maxWidth: 23.25,
              baseline: 'top',
              lineHeightFactor: 1.01
            });
          }
        } else {
          doc.text(headline, 12, 3.1719, {
            align: 'center',
            maxWidth: 23.3,
            baseline: 'top',
            lineHeightFactor: 1.01
          });
        }
      } else {
        doc.setFont('TeleGroteskNext-Ultra', 'normal');
        doc.setFontSize(fontSize);

        if (contestType == 'INSTANT WIN') {
          let text1 = language == 'es' ? 'GANA' : 'INSTANT';
          let text2 = language == 'es' ? 'AL INSTANTE' : 'WIN';
          if (language == 'es') {
            doc.text(text1, 12.3, 2.4852, {
              align: 'center',
              maxWidth: 23,
              baseline: 'top',
              lineHeightFactor: 0.92,
              charSpace: '-0.2'
            });
            doc.text(text2, 13, 6.6852, {
              align: 'center',
              maxWidth: 23,
              baseline: 'top',
              lineHeightFactor: 0.92,
              charSpace: '-0.2'
            });
          } else {
            doc.text(text1, 12.7, 2.4852, {
              align: 'center',
              maxWidth: 23,
              baseline: 'top',
              lineHeightFactor: 0.92,
              charSpace: '-0.2'
            });
            doc.text(text2, 12, 6.6852, {
              align: 'center',
              maxWidth: 23,
              baseline: 'top',
              lineHeightFactor: 0.92,
              charSpace: '-0.2'
            });
          }
        } else if (contestType == 'SIGN UP/CHECK IN') {
          let text1 = language === 'es' ? 'INSCRÍBETE Y' : 'SIGN UP FOR';
          let text2 = language === 'es' ? 'GANA UN REGALO' : 'A FREE GIFT';
          if (language == 'es') {
            doc.text(text1, 13.35, 3.1719, {
              align: 'center',
              maxWidth: 25.3,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.2'
            });
            doc.text(text2, 13.35, 6.3719, {
              align: 'center',
              maxWidth: 25.3,
              baseline: 'top',
              lineHeightFactor: 1.01,
              charSpace: '-0.2'
            });
          } else {
            doc.text(headline, 12, 2.4852, {
              align: 'center',
              maxWidth: 23,
              baseline: 'top',
              lineHeightFactor: 0.92
            });
          }
        } else {
          doc.text(headline, 12, 3.4852, {
            align: 'center',
            maxWidth: 24,
            baseline: 'top',
            lineHeightFactor: 0.92
          });
        }
      }
      //prize text
      var y = 8.39;
      if (contestType === 'INSTANT WIN') {
        if (this.curEvent.brand.includes('Metro')) {
          y += fontSize / 100.0;
        } else {
          // y += fontSize / 120.0;
        }
      } else if (contestType === 'SIGN UP/CHECK IN') {
        y += fontSize / 175.0;
      }
      if (this.curEvent.abbreviatedPrizeDescription != null) {
        if (this.curEvent.brand.includes('Metro')) {
          doc.setFont('tt-norms-medium', 'normal');
          doc.setFontSize(72);
          if (
            language === 'es' &&
            this.translations.abbreviatedPrizeDescription &&
            this.translations.abbreviatedPrizeDescription != ''
          ) {
            console.log('A');
            doc.text(
              this.translations.abbreviatedPrizeDescription.toUpperCase(),
              12,
              y,
              {
                align: 'center',
                maxWidth: 22.8744,
                baseline: 'top'
              }
            );
          } else {
            console.log('B');
            doc.text(
              this.curEvent.abbreviatedPrizeDescription.toUpperCase(),
              12,
              y,
              {
                align: 'center',
                maxWidth: 22.8744,
                baseline: 'top'
              }
            );
          }
        } else {
          doc.setFont('TeleGroteskNext-Medium', 'normal');
          doc.setFontSize(80);

          if (
            language === 'es' &&
            this.translations.abbreviatedPrizeDescription &&
            this.translations.abbreviatedPrizeDescription != ''
          ) {
            console.log('C');
            doc.text(
              this.translations.abbreviatedPrizeDescription.toUpperCase(),
              12,
              y,
              {
                align: 'center',
                maxWidth: 22.7332,
                baseline: 'top'
              }
            );
          } else {
            console.log('D');
            doc.text(
              this.curEvent.abbreviatedPrizeDescription.toUpperCase(),
              12,
              y + 1.5,
              {
                align: 'center',
                maxWidth: 22.7332,
                baseline: 'top'
              }
            );
          }
        }
      }
      let scantext =
        language === 'es'
          ? 'ESCANEA PARA PARTICIPAR O VISITA\n'
          : 'SCAN TO ENTER OR VISIT\n';
      //scan CTA text
      if (this.curEvent.brand.includes('Metro')) {
        doc.setFont('tt-norms-regular', 'normal');
        doc.setFontSize(41);
        doc.text(
          scantext + this.BITLY_LINK.replace('https://', ''),
          12,
          21.89,
          {
            align: 'center',
            baseline: 'top'
          }
        );
      } else {
        doc.setFont('TeleGroteskNext-Bold', 'normal');
        doc.setFontSize(41);
        doc.text(
          scantext + this.BITLY_LINK.replace('https://', ''),
          12,
          22.45,
          {
            align: 'center',
            baseline: 'top'
          }
        );
      }
      // brand logo
      if (this.curEvent.brand.includes('Metro')) {
        doc.addImage(
          require('../assets/metro-banner.png'),
          'PNG',
          7,
          26.5,
          10,
          4.5
        );
      } else {
        doc.addImage(
          require('../assets/t-mobile-banner-white--no-tm.png'),
          'PNG',
          6,
          28,
          12,
          2.5
        );
      }
      //legal text
      var outputLegal = this.helperFunctions
        .OutputLegal(this.curEvent, language)
        .replace(/<a[^>]*>/g, '')
        .replace(/<\/a>/g, '');
      if (this.curEvent.brand.includes('Metro')) {
        doc.setFont('tt-norms-regular', 'normal');
        doc.setFontSize(22);
        doc.text(outputLegal, 12, 34.681, {
          align: 'center',
          maxWidth: 21.47,
          baseline: 'top'
        });
      } else {
        doc.setFont('TeleGroteskNextRegular', 'normal');
        doc.setFontSize(22);
        doc.text(outputLegal, 12, 34.681, {
          align: 'center',
          maxWidth: 21.47,
          baseline: 'top'
        });
      }
      doc.save(pdfName + '.pdf');
      this.$emit('aFrameGenerationComplete');
    }
  }
};
</script>

<style></style>
