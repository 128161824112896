import { API, Auth } from 'aws-amplify';
import { DateTime } from 'luxon';
const prepateTimeForViewing = (
  theDate = null,
  Timezone = 'America/New_York'
) => {
  if (!theDate) return null;
  if (typeof theDate === 'string') {
    theDate = new Date(theDate);
  }
  return theDate.toLocaleString(DateTime.DATETIME_MED, {
    timeZone: Timezone
  });
};

const formatForViewing = ({
  type = 'Lifecycle',
  action = null,
  timestamp = null,
  description = null,
  event_id = null,
  user = null,
  timezone = 'America/New_York'
}) => {
  let neweventObject = {
    type: type,
    action: action,
    timestamp: prepateTimeForViewing(timestamp, timezone),
    description: description,
    event_id: event_id,
    user: user
  };

  return neweventObject;
};

// initial state
const state = {
  HISTORY_TABLE_DATA: [],
  HISTORY_LOADING: false
};

const actions = {
  async GET_ALL_HISTORY({ commit, state }, payload) {
    try {
      let historyArray = [];
      //Prevent from calling the api twice while HISTORY_LOADING
      let { eventId = null, timezone = null } = payload;
      if (!eventId) return;
      if (state.HISTORY_LOADING) return;
      commit('SET_HISTORY_LOADING', true);
      //Preform api request;
      const apiName = 'TMOAmplifyApi';
      const path = `/event/${eventId}/history`;

      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      };

      const response = await API.get(apiName, path, myInit);
      if (!response.data) return;

      if (response && response.data && response.data.length) {
        response.data.forEach(async item => {
          let newItem = {
            timezone: timezone,
            ...item
          };
          const checker = await formatForViewing(newItem);
          let historyObject = Object.assign(item, checker);
          historyArray.push(historyObject);
        });
      }

      commit('SET_HISTORY_TABLE_DATA', {
        data: historyArray
      });
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_HISTORY_LOADING', false);
    }
  },
  async GET_LEGAL_HISTORY({ commit, state }, payload) {
    try {
      let historyArray = [];
      //Prevent from calling the api twice while HISTORY_LOADING
      let { eventId = null, timezone = null } = payload;
      //Prevent from calling the api twice while HISTORY_LOADING
      if (!eventId) return;
      if (state.HISTORY_LOADING) return;
      commit('SET_HISTORY_LOADING', true);
      //Preform api request;
      const apiName = 'TMOAmplifyApi';
      const path = `/event/${eventId}/history?type=Legal`;

      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      };

      const response = await API.get(apiName, path, myInit);
      if (!response.data) return;
      if (response && response.data && response.data.length) {
        response.data.forEach(async item => {
          let newItem = {
            timezone: timezone,
            ...item
          };
          const checker = await formatForViewing(newItem);
          let historyObject = Object.assign(item, checker);
          historyArray.push(historyObject);
        });
      }
      commit('SET_HISTORY_TABLE_DATA', {
        data: historyArray
      });
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_HISTORY_LOADING', false);
    }
  }
};
// // mutations
const mutations = {
  SET_HISTORY_LOADING: (state, payload) => {
    state.HISTORY_LOADING = payload || false;
  },
  SET_HISTORY_TABLE_DATA: (state, payload) => {
    if (
      state.HISTORY_TABLE_DATA &&
      Array.isArray(state.HISTORY_TABLE_DATA) &&
      state.HISTORY_TABLE_DATA.length > 0
    ) {
      state.HISTORY_TABLE_DATA = [];
    }
    state.HISTORY_TABLE_DATA = payload.data;
  },
  RESET_HISTORY_TABLE: state => {
    state.HISTORY_LOADING = false;
    state.HISTORY_TABLE_DATA = [];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
