<template>
  <div>
    <v-form v-model="valid" ref="form" class="app-sign-up-form pa-6">
      <v-text-field
        v-model="given_name"
        :rules="nameRules"
        label="First Name"
        required
        class="mt-4"
      ></v-text-field>
      <v-text-field
        v-model="family_name"
        :rules="nameRules"
        label="Last Name"
        required
        class="mt-4"
      ></v-text-field>
      <v-text-field
        :rules="emailRules"
        v-model="email"
        label="Email Address"
        required
        class="mt-4"
      ></v-text-field>
      <v-text-field
        v-model="password"
        :rules="[
          v => v.length >= 8 || 'Password must be at least 8 characters.'
        ]"
        validate-on-blur
        label="Password"
        required
        type="password"
        class="mt-4"
      ></v-text-field>
      <v-text-field
        :rules="[v => v === password || 'Passwords do not match.']"
        v-model="verifyPassword"
        label="Verify"
        required
        type="password"
        class="mt-4"
      ></v-text-field>
      <GoogleRecaptcha
        class="mt-4"
        :on-verify="captchaOnVerify"
        :on-expired="captchaOnExpired"
        ref="recaptcha"
      ></GoogleRecaptcha>
      <div class="app-sign-up-form__submit mt-4">
        <v-row align="center" class="flex-column">
          <v-col cols="5">
            <v-btn
              :disabled="!enableSignUpButton"
              class="btn__primary"
              block
              x-large
              @click="accountSignUp"
            >
              Sign Up
            </v-btn>
          </v-col>
        </v-row>
        <p class="mt-3">
          Already have an account?
          <router-link to="/login">Sign In</router-link>
        </p>
      </div>
    </v-form>
    <div class="app-sign-up-form__support mt-4 text-center">
      <v-btn text plain href="mailto:smrastrategyandcommunications@t-mobile.com">
        Support
        <v-icon medium color="#E44475">
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
    <v-snackbar
      top
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      v-model="snackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="nonEmployeeDialog"
      width="500"
      content-class="app-non-employee-dialog app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="grey lighten-2 justify-center">
          {{ nonEmployeeDialogTitle }}
        </v-card-title>

        <v-card-text class="text-center mt-2">
          {{ nonEmployeeDialogMessage }}
          <v-form
            v-if="nonEmployeeDialogAction"
            v-model="validNonEmployeeForm"
            ref="form"
          >
            <label for="accessReason">
              Are you requesting Event Staff access to the T-Mobile Engagement Hub, or do you need Full Access for another reason?
            </label>
            <v-select
              v-model="nonEmployeeAccessReason"
              :rules="ambassadorSelectRules"
              :items="[
                { value: 'Event Staff', text: 'Event Staff' },
                { value: 'Full Access', text: 'Full Access' }
              ]"
              name="accessReason"
              label="Access Reason"
              required
              class="mt-12"
            />
            <v-text-field
              v-if="nonEmployeeAccessReason == 'Full Access'"
              v-model="employee_name"
              :rules="nameRules"
              label="Employee Name"
              required
              class="mt-12"
            ></v-text-field>
            <v-text-field
              v-if="nonEmployeeAccessReason == 'Full Access'"
              v-model="employee_email"
              :rules="emailRules"
              label="Employee Email"
              required
              class="mt-12"
            ></v-text-field>
            <v-text-field
              v-if="nonEmployeeAccessReason == 'Full Access'"
              v-model="employee_message"
              label="Reason for Access Request"
              required
              class="mt-12"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            v-if="closeDialogAction !== null"
            @click="closeDialogAction"
            class="modal-button-outlined px-6"
            color="primary"
            outlined
            tile
          >
            Close
          </v-btn>
          <v-btn
            :loading="signupSubmitted"
            v-if="nonEmployeeDialogAction !== null"
            :disabled="!validNonEmployeeForm"
            @click="nonEmployeeDialogAction"
            class="btn__primary px-6"
            tile
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="employeeDialog"
      width="500"
      content-class="app-employee-dialog app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="grey lighten-2 justify-center">
          {{ employeeDialogTitle }}
        </v-card-title>

        <v-card-text class="text-center mt-2">
          <p>{{ employeeDialogMessage }}</p>
          <v-form
            v-if="employeeDialogAction"
            v-model="validEmployeeForm"
            ref="form"
          >
            <div class="mt-4">
              <strong>What department do you sit under?</strong>
            </div>
            <v-select
              v-model="employee_department"
              :rules="nameRules"
              :items="employeeDepartmentOptions"
              hide-details="auto"
              label="Department"
              class="mt-2"
              required
            ></v-select>
            <div>
              <strong>What organization do you sit under?</strong>
            </div>
            <v-select
              v-model="employee_organization"
              :rules="nameRules"
              :items="employeeOrgOptions"
              hide-details="auto"
              label="Organization"
              class="mt-2"
              required
            ></v-select>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center">
          <v-btn
            v-if="closeDialogAction !== null"
            @click="closeDialogAction"
            class="modal-button-outlined px-6"
            color="primary"
            outlined
            tile
          >
            Close
          </v-btn>
          <v-btn
            :loading="signupSubmitted"
            v-if="employeeDialogAction !== null"
            :disabled="!validEmployeeForm"
            @click="employeeDialogAction"
            class="btn__primary px-6"
            tile
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GoogleRecaptcha from '@/components/GoogleRecaptcha.vue';
import { mapActions, mapGetters } from 'vuex';
import OptionData from '../../utility/OptionData.js';

export default {
  name: 'SignUpForm',
  components: {
    GoogleRecaptcha
  },
  data() {
    return {
      nonEmployeeDialog: false,
      employeeDialog: false,
      closeDialogAction: null,
      nonEmployeeDialogAction: null,
      nonEmployeeDialogTitle: '',
      nonEmployeeDialogMessage: '',
      employeeDialogAction: null,
      employeeDialogTitle: '',
      employeeDialogMessage: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      snackbarTimeout: 4000,
      captchaVerfied: false,
      captchaToken: null,
      disableSignUpButton: false,
      signupSubmitted: false,
      valid: false,
      validNonEmployeeForm: false,
      validEmployeeForm: false,
      employeeOrgOptions: OptionData.employeeOrgOptions(),
      employeeDepartmentOptions: OptionData.employeeDepartmentOptions(),
      email: '',
      given_name: '',
      employee_name: '',
      employee_email: '',
      employee_message: undefined,
      employee_organization: '',
      employee_department: '',
      family_name: '',
      password: '',
      verifyPassword: '',
      nameRules: [v => !!v || 'Name cannot be empty.'],
      emailRules: [
        v =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Not a valid email'
      ],
      passwordRules: [''],
      ambassadorSelectRules: [v => !!v || 'You must select an option.'],
      nonEmployeeAccessReason: null
    };
  },
  computed: {
    ...mapGetters({
      AUTH_MODULE_STATE: `account/AUTH_MODULE_STATE`,
      AUTH_ERROR_MESSAGE: `account/AUTH_ERROR_MESSAGE`
    }),
    enableSignUpButton: function() {
      return (
        this.password === this.verifyPassword &&
        this.valid &&
        this.captchaToken &&
        !this.disableSignUpButton
      );
    }
  },
  created() {},
  methods: {
    ...mapActions({
      SIGN_UP: 'account/ACCOUNT_SIGN_UP'
    }),
    captchaOnVerify(token) {
      this.captchaToken = token;
      this.captchaVerfied = true;
      return;
    },
    captchaOnExpired() {
      this.captchaVerfied = false;
      this.captchaToken = null;
      // close modals
      this.employeeDialog = false;
      this.nonEmployeeDialog = false;
      return;
    },
    accountSignUp() {
      const signUpParams = {
        username: this.email.toLowerCase(),
        password: this.password,
        attributes: {
          email: this.email.toLowerCase(),
          family_name: this.family_name,
          given_name: this.given_name
        },
        clientMetadata: {
          token: this.captchaToken
        }
      };
      if (!this.isTMOAccount()) {
        // show non employee response dialog
        this.nonEmployeeDialogTitle = 'Non Employee Access Request';
        this.nonEmployeeDialogMessage =
          'Please describe why you require access to this system and list the name and e-mail address of the T-Mobile employee you are working with.';
        this.nonEmployeeDialogAction = null;
        this.closeDialogAction = () => {
          this.nonEmployeeDialog = false;
        };
        this.nonEmployeeDialogAction = () => {
          if (this.nonEmployeeAccessReason == 'Event Staff') {
            signUpParams.attributes['custom:ambassador'] = '1';
            signUpParams.attributes['custom:confirmed'] = '1';
          } else {
            signUpParams.attributes[
              'custom:signUpMessage'
            ] = this.employee_message;
            signUpParams.attributes[
              'custom:refEmployeeName'
            ] = this.employee_name;
            signUpParams.attributes[
              'custom:refEmployeeEmail'
            ] = this.employee_email.toLowerCase();
            signUpParams.attributes['custom:confirmed'] = '0';
          }
          this.signupSubmitted = true;
          this.SIGN_UP(signUpParams)
            .then(response => this.processSignUpResponse(response))
            .catch();
        };
        this.nonEmployeeDialog = true;
      } else {
        // show employee response dialog
        this.employeeDialogTitle = 'Employee Access Request';
        this.employeeDialogMessage = 'Please answer the following questions:';
        this.employeeDialogAction = null;
        this.closeDialogAction = () => {
          this.employeeDialog = false;
        };
        this.employeeDialogAction = () => {
          signUpParams.attributes[
            'custom:signUpOrganization'
          ] = this.employee_organization;
          signUpParams.attributes[
            'custom:signupDepartment'
          ] = this.employee_department;
          this.signupSubmitted = true;
          this.SIGN_UP(signUpParams)
            .then(response => this.processSignUpResponse(response))
            .catch();
        };
        this.employeeDialog = true;
      }
    },
    processSignUpResponse(response) {
      if (response.user !== undefined) {
        this.nonEmployeeDialogTitle = 'Email Verification';
        this.nonEmployeeDialogMessage =
          this.isTMOAccount() || this.nonEmployeeAccessReason == 'Event Staff'
            ? 'Please check your inbox to confirm your email address.'
            : 'Please check your inbox to confirm your email address. Once confirmed, your request will be sent to a system administrator for access approval.';
        this.nonEmployeeDialogAction = null;
        this.closeDialogAction = () => {
          this.nonEmployeeDialog = false;
          this.$router.push('/login');
        };
        this.nonEmployeeDialog = true;
      } else {
        this.toggleSnackbar(response.message, 'error');
      }
      this.signupSubmitted = false;
    },
    toggleSnackbar(message, type) {
      this.snackbarText = message;
      this.snackbarColor = type == 'error' ? '#f03710' : '#0da149';
      this.snackbar = true;
    },
    isTMOAccount() {
      return (
        this.email.toLowerCase().indexOf('t-mobile') !== -1 ||
        this.email.toLowerCase().indexOf('160over90') !== -1
      );
    }
  }
};
</script>

<style scoped>
.app-sign-up-form {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-sign-up-form a {
  text-decoration: none;
}
.app-sign-up-form__submit {
  text-align: center;
}
.app-sign-up-form__signup {
  width: 80%;
}
::v-deep .app-sign-up-form__forgotpassword.v-btn--plain .v-btn__content,
::v-deep .app-sign-up-form__support .v-btn__content {
  opacity: 1 !important;
}
.v-btn--outlined {
  border-width: 2px;
}
.app-sign-up-form__submit .v-btn,
.app-sign-up-form__support .v-btn {
  font-weight: 700;
}

.v-input::placeholder {
  color: green;
}

.v-card__title {
  font-size: 1.5rem !important;
}
.v-card form {
  color: black;
  text-align: left;
  margin-top: 32px;
}
</style>
