import TMOEngagement from '../../models/TMOEngagement';
import { API, Auth } from 'aws-amplify';

const requestHeaders = async () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`
  };
};
const state = {
  engagement: new TMOEngagement()
};
const getters = {
  engagement: state => state.engagement
};
const actions = {
  async resetEngagement({ commit, dispatch }) {
    commit('setEngagement', new TMOEngagement());
  },
  async submitEngagement({ commit, dispatch }, engagement) {
    const apiName = 'TMOAmplifyApi';
    const path = `/engagement/${engagement.eventId}`;
    const myInit = {
      response: true,
      headers: await requestHeaders(),
      body: {
        contestType: engagement.contestType,
        addressLine1: engagement.addressLine1,
        addressLine2: engagement.addressLine2,
        city: engagement.city,
        contactAllowed: engagement.contactAllowed,
        createdByFirstName: engagement.createdByFirstName,
        createdByEmail: engagement.createdByEmail,
        createdByLastName: engagement.createdByLastName,
        currentProvider: engagement.currentProvider,
        email: engagement.email,
        eventId: engagement.eventId,
        firstName: engagement.firstName,
        language: engagement.language,
        lastName: engagement.lastName,
        phone: engagement.phone,
        state: engagement.state,
        zip: engagement.zip,
        businessName: engagement.businessName
      }
    };
    const response = await API.post(apiName, path, myInit);
    return response;
  },
  async submitEngagementWithToken(
    { commit, dispatch },
    engagement,
    captchaToken
  ) {
    const apiName = 'TMOAmplifyApi';
    const path = `/engagement/${engagement.eventId}`;
    const myInit = {
      response: true,
      body: {
        addressLine1: engagement.addressLine1,
        addressLine2: engagement.addressLine2,
        city: engagement.city,
        contactAllowed: engagement.contactAllowed,
        createdByFirstName: engagement.createdByFirstName,
        createdByEmail: engagement.createdByEmail,
        createdByLastName: engagement.createdByLastName,
        currentProvider: engagement.currentProvider,
        email: engagement.email,
        eventId: engagement.eventId,
        firstName: engagement.firstName,
        language: engagement.language,
        lastName: engagement.lastName,
        phone: engagement.phone,
        state: engagement.state,
        zip: engagement.zip,
        token: captchaToken
      }
    };
    const response = await API.post(apiName, path, myInit);
    return response;
  },
  async submitEngagementWithToken(
    { commit, dispatch },
    engagement,
    captchaToken
  ) {
    const apiName = 'TMOAmplifyApi';
    const path = `/engagement/${engagement.eventId}`;
    const myInit = {
      response: true,
      body: {
        addressLine1: engagement.addressLine1,
        addressLine2: engagement.addressLine2,
        city: engagement.city,
        contactAllowed: engagement.contactAllowed,
        createdByFirstName: engagement.createdByFirstName,
        createdByEmail: engagement.createdByEmail,
        createdByLastName: engagement.createdByLastName,
        currentProvider: engagement.currentProvider,
        email: engagement.email,
        eventId: engagement.eventId,
        firstName: engagement.firstName,
        language: engagement.language,
        lastName: engagement.lastName,
        phone: engagement.phone,
        state: engagement.state,
        zip: engagement.zip,
        token: captchaToken
      }
    };
    const response = await API.post(apiName, path, myInit);
    return response;
  }
};
const mutations = {
  setEngagement: (state, engagement) => (state.engagement = engagement)
};

export default {
  state,
  getters,
  actions,
  mutations
};
