<template>
  <div class="app-all-events">
    <AlertBar></AlertBar>
    <BannerTitle
      :title="`Welcome, ${given_name} ${family_name}!`"
      description="The Engagement Hub is our most powerful lead capture tool to date! Below are all events Pending Translation Review."
      :loading="given_name === ''"
    />

    <section class="py-12">
      <v-container :fluid="!this.lgBreakpoint">
        <v-row align="center" class="flex-column">
          <v-col cols="12">
            <v-radio-group
              v-model="filterUpdate"
              row
              class="app-dashboard__filters"
            >
              <v-radio label="Translation Pending" value="Pending"></v-radio>
              <v-radio
                label="Translated"
                value="Translated"
                class="ml-3"
              ></v-radio>
            </v-radio-group>
            <TranslationEventsTable
              :table-data="TABLE_DATA"
              :rows-per-page="RESULTS_PER_PAGE"
              :filter="filter"
            />
            <div class="app-all-events__pagination-wrap">
              <AllEventsPagination
                class="mt-6"
                :data="TABLE_DATA"
                :max-pages="Math.ceil(TABLE_DATA.length / RESULTS_PER_PAGE)"
                :items-per-page="RESULTS_PER_PAGE"
              />
              <div class="app-all-events__itemsperpage">
                <div>View:</div>
                <div>
                  <a
                    v-for="option in rowsPerPageOptions"
                    :key="option.id"
                    :class="[option.value === RESULTS_PER_PAGE ? 'active' : '']"
                    @click.prevent="CHANGE_ITEMS_PER_PAGE(option.value)"
                  >
                    {{ option.text }}
                  </a>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import AlertBar from '@/components/AlertBar.vue';
import BannerTitle from '@/components/BannerTitle.vue';
import AllEventsPagination from '@/components/AllEventsPagination';
import AllEventsSearchForm from '@/components/Search/AllEventsSearchForm';
import AllEventsAdvancedSearchOptions from '@/components/Search/AllEventsAdvancedSearchOptions';
import AllEventsActiveSearchFilters from '@/components/Search/AllEventsActiveSearchFilters';
import TranslationEventsTable from '@/components/TranslationEventsTable';

export default {
  name: 'TranslationDashboard',
  components: {
    TranslationEventsTable,
    BannerTitle,
    AlertBar,
    // AllEventsSearchForm,
    AllEventsPagination
    //AllEventsAdvancedSearchOptions,
    //AllEventsActiveSearchFilters
  },
  data() {
    return {
      advancedSearchActive: false,
      rowsPerPageOptions: [
        {
          text: '25',
          value: 25
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ],
      filter: 'Pending'
    };
  },
  async created() {
    if (
      this.USER_GROUPS.indexOf('SLTranslator') === -1 &&
      this.USER_GROUPS.indexOf('Admin') === -1
    ) {
      this.$router.push('/dashboard');
      return;
    }
    this.given_name = this.userAttributes.given_name;
    this.family_name = this.userAttributes.family_name;
    this.APPLY_TRANSLATION(true);
    this.GET_EVENTS();
  },
  computed: {
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`
    }),
    ...mapState('EVENT_TABLE_STORE', [
      'TABLE_DATA',
      'LOADING',
      'RESULTS_PER_PAGE'
    ]),
    lgBreakpoint: function() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    filterUpdate: {
      get() {
        return this.filter;
      },
      set(value) {
        this.filter = value;
      }
    }
  },
  methods: {
    ...mapActions('EVENT_TABLE_STORE', [
      'GET_EVENTS',
      'CHANGE_ITEMS_PER_PAGE',
      'APPLY_TRANSLATION'
    ]),

    next(page) {
      this.$store.commit('EVENT_TABLE_STORE/SET_CURRENT_PAGE', page);
    },
    toggleAdvancedSearchForm() {
      this.advancedSearchActive = !this.advancedSearchActive;
      if (
        !document
          .getElementsByClassName('app-events-advanced-search')[0]
          .classList.contains('active')
      ) {
        document
          .getElementsByClassName('app-events-advanced-search')[0]
          .classList.add('active');
        document
          .getElementsByClassName('app-all-events__showsearch')[0]
          .getElementsByTagName('span')[0].innerHTML = 'Hide Advanced Search';
      } else {
        document
          .getElementsByClassName('app-events-advanced-search')[0]
          .classList.remove('active');
        document
          .getElementsByClassName('app-all-events__showsearch')[0]
          .getElementsByTagName('span')[0].innerHTML = 'Show Advanced Search';
      }
    }
  }
};
</script>

<style scoped lang="scss">
// .container--fluid {
//   // max-width: 1200px;
// }
.app-all-events__pagination-wrap {
  display: flex;
  justify-content: space-between;
}
.app-all-events__itemsperpage {
  display: flex;
  margin-top: 32px;
}
.app-all-events__itemsperpage a {
  text-decoration: none;
  font-weight: 700;
  color: #000;
  margin-left: 5px;
  margin-right: 5px;
}
.app-all-events__itemsperpage a.active {
  color: var(--v-primary-base);
}
.app-all-events__showsearch > a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.v-pagination {
  align-items: flex-start !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 48px;
}
</style>
