<template>
  <v-card flat>
    <v-container fluid>
      <v-row>
        <v-col cols="10">
          <v-card-title> Photo Uploader </v-card-title>
          <v-card-subtitle> Add a new Image </v-card-subtitle>
        </v-col>
        <v-col cols="2">
          <v-btn outlined @click="cancel_new_photo">
            Cancel
          </v-btn>
        </v-col>
      </v-row>
      <v-form v-model="valid">
        <v-row>
          <v-col cols="12" >
            <v-select
              :items="PROMOTION_TYPES"
              item-text="text"
              item-value="value"
              v-model="SELECTED_PROMOTION_TYPE"
              label="Select Radio Promotion Type"
              required
              :rules="[nonEmptyRule('You must select a promotion type.')]"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-for="(newImage) in NEW_IMAGE_UPLOAD_ARR" :key="newImage.id">
          <v-col cols="2">
            <v-card-title> {{newImage.template_id}} </v-card-title>
            <v-card-subtitle> {{newImage.placeholder_image_width}} x {{newImage.placeholder_image_height}} </v-card-subtitle>
          </v-col>
          <v-col cols="10">
            <v-file-input
              label="Upload Image"
              prepend-icon="mdi-camera"
              accept="image/*"
              color="primary"
              :hint="`Image must have a width of ${newImage.placeholder_image_width} and height of ${newImage.placeholder_image_height}`"
              persistent-hint
              v-model="newImage.url"
              required
              :rules="[
                newImage.valid_dimensions || `Image must have a width of ${newImage.placeholder_image_width} and height of ${newImage.placeholder_image_height}`,
                nonEmptyRule('An image is required.')
              ]"
              @change="checkDimensions(newImage)"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="4" >
            <v-btn
              class="v-btn v-btn--is-elevated v-btn--has-bg v-btn--tile theme--light elevation-2 v-size--x-large primary"
              block
              @click="save_new_photos"
              :disabled="!verify_complete_arr"
              :loading="loading"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog v-model="dialog" width="500" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" tile x-large color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import ValidationRules from '@/utility/ValidationRules.js';

export default {
  name: 'AVEUploadForm',
  components: {},
  data() {
    return {
      valid: false,
      loading: false,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogAction: null
    };
  },
  methods: {
    ...mapMutations('AVE_CANVAS_STORE', [
      'UPDATE_IMAGE_PROMO_TYPE',
      'RESET_IMAGE_UPLOAD_FORM'
    ]),
    ...mapActions('AVE_CANVAS_STORE', [
      'CHANGE_IMAGE_UPLOAD_PROMO_TYPE',
      'UPLOAD_IMAGES'
    ]),
    async save_new_photos() {
      this.loading = true;
      try {
        await this.UPLOAD_IMAGES();
        this.displayDialog('Success', 'Image Upload Successful.', () => {
          this.dialog = false;
          this.$emit('CHANGE_ACTIVE_DIALOG_COMPONENT', 'AVEPhotoManager');
        });
      } catch (e) {
        this.showErrorDialog(e);
      } finally {
        this.loading = false;
      }
    },
    cancel_new_photo() {
      this.RESET_IMAGE_UPLOAD_FORM();
      this.$emit('CHANGE_ACTIVE_DIALOG_COMPONENT', 'AVEPhotoManager');
    },
    async checkDimensions(image) {
      if (!image.url) {
        return;
      }
      // based on https://stackoverflow.com/questions/8903854/check-image-width-and-height-before-upload-with-javascript
      let URL = window.URL || window.webkitURL;
      let tempImage = new Image();
      let objectUrl = URL.createObjectURL(image.url);
      let width = image.placeholder_image_width;
      let height = image.placeholder_image_height;
      tempImage.onload = function() {
        image.valid_dimensions = this.width == width && this.height == height;
        URL.revokeObjectURL(objectUrl);
      };
      tempImage.src = objectUrl;
      await tempImage.decode();
    },
    nonEmptyRule(error) {
      return ValidationRules.NonEmptyRule(error);
    },
    showErrorDialog(error) {
      this.dialogTitle = 'Error';
      this.dialogMessage = error;
      this.dialogAction = () => (this.dialog = false);
      this.dialog = true;
    },
    displayDialog(dialogTitle, dialogMessage, dialogAction) {
      this.dialogTitle = dialogTitle;
      this.dialogMessage = dialogMessage;
      this.dialogAction = dialogAction;
      this.dialog = true;
    }
  },
  computed: {
    ...mapState('AVE_CANVAS_STORE', [
      'NEW_IMAGE_PROMO_TYPE',
      'PROMOTION_TYPES',
      'NEW_IMAGE_UPLOAD_ARR'
    ]),
    SELECTED_PROMOTION_TYPE: {
      get() {
        return this.NEW_IMAGE_PROMO_TYPE;
      },
      set(value) {
        this.CHANGE_IMAGE_UPLOAD_PROMO_TYPE(value);
      }
    },
    verify_complete_arr() {
      return (
        this.NEW_IMAGE_UPLOAD_ARR.length > 0 &&
        this.NEW_IMAGE_UPLOAD_ARR.every(
          img => img.url != null && img.valid_dimensions
        )
      );
    }
  }
};
</script>
