import { Auth, API } from 'aws-amplify';

const requestHeaders = async () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`
  };
};

const listUsers = async function({ dispatch, getters, commit }, payload) {
  let path = '/listUsers';
  let limit = payload.limit;
  let token = payload.token;
  try {
    let payload = {
      queryStringParameters: {
        limit,
        token
      },
      headers: await requestHeaders()
    };
    let req = await API.get('AdminQueries', path, payload);
    if (req && req.Users) {
      return req;
    }
    throw Error('Error listing users.');
  } catch (error) {
    throw Error(error);
  }
};

export default listUsers;
