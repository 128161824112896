import { Auth } from 'aws-amplify';

function actionSuccess(data) {
  return data;
}

function actionFailure(err) {
  return err;
}

/**
 * @typedef {import('vuex').Commit} Commit
 */

/**
 * @param  {Commit} commit - VUEX Commit - pass this in to issue mutations
 * @param  {string} params -
 */
//  user: CognitoUser | any,
//  oldPassword: string,
//  newPassword: string,
//  clientMetadata: ClientMetaData = this._config.clientMetadata

export default async function ChangePassword({ commit }, params) {
  if (!params || !params.oldPassword || !params.newPassword) {
    return Promise.reject(
      new Error('ERROR::CHANGE_PASSWORD: No parameters provided.')
    );
  }
  const user = await Auth.currentAuthenticatedUser();
  return Auth.changePassword(user, params.oldPassword, params.newPassword);
}
