<template>
  <v-container class="py-0 view-winners">
    <h2 class="pb-0">Event Proxies</h2>
    <p class="pb-0">
      Assigning an Event Proxy will grant another user privileges to this event.
      You may assign up to 3 event proxies total. An event proxy will have full
      access to this event and all actions, with the exception of 'Archive
      Event'. To assign a proxy, they must have an active, valid T-Mobile
      Engagement Hub Account.
    </p>
    <div class="pb-2">
      <form novalidate="novalidate" class="v-form">
        <div
          class="row events-search-form pa-2 no-gutters row--dense align-center justify-space-between"
        >
          <div class="col-sm-8 col-md-9 col-12">
            <div
              class="v-input d-flex align-center justify-sm-space-between v-input--hide-details v-input--dense theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--solo-flat v-text-field--is-booted v-text-field--enclosed"
            >
              <div class="v-input__control">
                <div class="v-input__slot">
                  <div class="v-text-field__slot">
                    <v-text-field
                      label="Enter the email of the person you would like to add as a
                      proxy."
                      :rules="rules"
                      type="email"
                      v-model="proxy_email"
                      hide-details="auto"
                      :disabled="PROXY_LIMIT_REACHED"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-3 col-lg-2 col-12">
            <button
              :disabled="PROXY_LIMIT_REACHED"
              type="submit"
              @click.prevent="addNewProxy"
              class="px-5 px-12 flex-grow-2 btn__primary events-search-form__submit v-btn v-btn--block v-btn--is-elevated v-btn--has-bg v-btn--tile theme--light v-size--default magenta app-button text-heading white--text"
              style="height: 50px;"
            >
              <span class="v-btn__content">Add </span>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="v-data-table app-events-table elevation-0 theme--light">
      <v-data-table
        :headers="columns"
        :items="TABLE_DATA"
        class="elevation-0"
        hide-default-footer
        :loading="LOADING"
        loading-text="Loading... Please wait"
      >
        <template v-slot:header.name="{ header }">
          <span class="whiteText"> {{ header.text.toUpperCase() }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="flex-wrap justify-center d-flex flex-lg-nowrap">
            <v-btn icon @click="removeProxy(item.users_email)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:no-data>
          No proxies found for that event.
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="SHOW_POPUP" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ POPUP_TITLE }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ POPUP_DESCRIPTION }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="resetDialog" text class="px-6 btn__primary">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import PastelTable from '@/components/PastelTable.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
/* eslint-disable-next-line */
const generateProxyAdditionEmail = (toAddresses, eventData, eventId, proxies) => {
  let data_table_rows = proxies.map(i => {
    return ` <tr>
              <th>${i.users_firstName} ${i.users_lastName}</th>
                <td data-th="Email">
                  <div>${i.users_email}</div>
                </td>
            </tr>`;
  });
  let data_table_template = proxies.length
    ? `
            <table class="datatable">
              <caption>
                Event Proxies
              </caption>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                 ${data_table_rows}
              </tbody>
            </table>`
    : '';

  let templateData = {
    preheader_text: '',
    header_text: `T-Mobile Engagement Hub Event Proxy Added`,
    greeting_text: `Greetings,`,
    'body-1_text':
      'A new proxy has been added to an event you created or are a proxy for. See below for details.',
    'body-2_text': `
    Event Name : ${eventData.name} <br>
    Event Start Date : ${eventData.startDate} ${eventData.startTime} <br>
    Event End Date : ${eventData.endDate} ${eventData.endTime} <br>
    Event Created By : ${eventData.createdByFirstName} ${eventData.createdByLastName} ${eventData.createdByEmail} <br>`,
    data_table: data_table_template,
    'cta-1_href':
      'https://www.t-mobileengagementhub.com/#/event/edit/' + eventId,
    'cta-1_text': 'View Event',
    subject_text: `Event Proxy Added for Event ID: ${eventId}`,
    'text-part_text': ''
  };
  let emailTemplate = {
    Source: 'Proxy Added <donotreply@t-mobileengagementhub.com>',
    Template: 'proxy-addition-template',
    ConfigurationSetName: 'ConfigSet',
    Destination: {
      ToAddresses: toAddresses
    },
    TemplateData: JSON.stringify(templateData, null, 2)
  };
  return emailTemplate;
};

const generateProxyRemoved = (toAddresses, eventData, eventId, proxies) => {
  let data_table_rows = proxies.map(i => {
    return ` <tr>
              <th>${i.users_firstName} ${i.users_lastName}</th>
                <td data-th="Email">
                  <div>${i.users_email}</div>
                </td>
            </tr>`;
  });
  let data_table_template = proxies.length
    ? `
            <table class="datatable">
              <caption>
                Event Proxies
              </caption>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                 ${data_table_rows}
              </tbody>
            </table>`
    : '';
  let templateData = {
    preheader_text: '',
    header_text: `T-Mobile Engagement Hub Event Proxy Removed`,
    greeting_text: `Greetings,`,
    'body-1_text':
      'A proxy has been removed from an event you created or are a proxy for. See below for details.',
    'body-2_text': `
    Event Name : ${eventData.name} <br>
    Event Start Date : ${eventData.startDate} ${eventData.startTime} <br>
    Event End Date : ${eventData.endDate} ${eventData.endTime} <br>
    Event Created By : ${eventData.createdByFirstName} ${eventData.createdByLastName} ${eventData.createdByEmail} <br>`,
    data_table: data_table_template,
    'cta-1_href':
      'https://www.t-mobileengagementhub.com/#/event/edit/' + eventId,
    'cta-1_text': 'View Event',
    subject_text: `Event Proxy Removed for Event ID: ${eventId}`,
    'text-part_text': ''
  };
  let emailTemplate = {
    Source: 'Proxy Removed <donotreply@t-mobileengagementhub.com>',
    Template: 'proxy-addition-template',
    ConfigurationSetName: 'ConfigSet',
    Destination: {
      ToAddresses: toAddresses
    },
    TemplateData: JSON.stringify(templateData, null, 2)
  };
  return emailTemplate;
};

const removedProxyEmail = (toAddress, eventData, eventId) => {
  let templateData = {
    preheader_text: '',
    header_text: `T-Mobile Engagement Hub Event Proxy Status Removed`,
    greeting_text: `Greetings,`,
    'body-1_text':
      'You have been removed as a proxy for an event. See below for details.',
    'body-2_text': `
    Event Name : ${eventData.name} <br>
    Event Start Date : ${eventData.startDate} ${eventData.startTime} <br>
    Event End Date : ${eventData.endDate} ${eventData.endTime} <br>
    Event Created By : ${eventData.createdByFirstName} ${eventData.createdByLastName} ${eventData.createdByEmail} <br>`,
    subject_text: `Proxy Status Removed for Event ID: ${eventId}`,
    'text-part_text': ''
  };
  let emailTemplate = {
    Source: 'Proxy Status Removed <donotreply@t-mobileengagementhub.com>',
    Template: 'no-cta-template',
    ConfigurationSetName: 'ConfigSet',
    Destination: {
      ToAddresses: [`${toAddress}`]
    },
    TemplateData: JSON.stringify(templateData, null, 2)
  };
  return emailTemplate;
};

export default {
  name: 'ViewProxyAction',
  async created() {
    try {
      await this.$store.commit('PROXY_STORE/RESET_ALL');
      await this.GET_PROXIES(this.$route.params.id);
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      columns: [
        {
          text: 'First Name',
          value: 'users_firstName',
          align: 'center',
          sortable: false
        },
        {
          text: 'Last Name',
          value: 'users_lastName',

          align: 'center',
          sortable: false
        },
        {
          text: 'Email',
          value: 'users_email',

          align: 'center',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],
      rules: [
        value => (value || '').length <= 35 || 'Max 35 characters',
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        }
      ]
    };
  },
  methods: {
    ...mapActions('PROXY_STORE', ['GET_PROXIES', 'ADD_PROXY', 'REMOVE_PROXY']),
    ...mapActions({
      sendEmail: 'admin/sendEmail'
    }),
    resetDialog() {
      this.$store.commit('PROXY_STORE/RESET_POPUP');
    },
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    },
    async addNewProxy() {
      try {
        let addNewProxy = await this.ADD_PROXY(this.$route.params.id);
        if (this.HAS_ERROR) throw new Error('No new proxy added.');
        await this.GET_PROXIES(this.$route.params.id);
        if (this.HAS_ERROR) throw new Error('No new proxy added.');
        await this.sendAdditionEmail(this.CURRENT_EVENT_OBJECT.createdByEmail);
      } catch (error) {
        console.log(error);
      }
    },
    async removeProxy(selected_email) {
      try {
        const payload = {
          event_id: this.$route.params.id,
          remove_email: selected_email
        };
        // send email to proxy being removed
        await this.sendEmail(
          removedProxyEmail(
            selected_email,
            this.CURRENT_EVENT_OBJECT,
            this.$route.params.id
          )
        );
        await this.REMOVE_PROXY(payload);
        if (this.HAS_ERROR) throw new Error('No new proxy added.');
        await this.GET_PROXIES(this.$route.params.id);
        if (this.HAS_ERROR) throw new Error('No Proxy removed.');
        this.$store.commit('PROXY_STORE/SET_PROXY_EMAIL', null);
        await this.sendRemovedEmail(this.CURRENT_EVENT_OBJECT.createdByEmail);
      } catch (error) {
        console.log(error);
      }
    },
    async sendRemovedEmail(users_email) {
      try {
        let proxies = !this.TABLE_DATA.length ? [] : this.TABLE_DATA;
        let emails = proxies.map(proxy => `${proxy.users_email}`);
        emails.push(`${users_email}`);
        await this.sendEmail(
          generateProxyRemoved(
            emails,
            this.CURRENT_EVENT_OBJECT,
            this.$route.params.id,
            proxies
          )
        );
      } catch (error) {
        console.log(error);
      }
    },
    async sendAdditionEmail(users_email) {
      try {
        let proxies = !this.TABLE_DATA.length ? [] : this.TABLE_DATA;
        let emails = proxies.map(proxy => `${proxy.users_email}`);
        emails.push(`${users_email}`);
        await this.sendEmail(
          generateProxyAdditionEmail(
            emails,
            this.CURRENT_EVENT_OBJECT,
            this.$route.params.id,
            proxies
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
  },
  computed: {
    ...mapState('PROXY_STORE', [
      'LOADING',
      'TABLE_DATA',
      'ERROR',
      'HAS_ERROR',
      'ERROR_MESSAGE',
      'POPUP_TITLE',
      'POPUP_DESCRIPTION',
      'SHOW_POPUP'
    ]),
    ...mapGetters('PROXY_STORE', ['PROXY_LIMIT_REACHED']),

    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    proxy_email: {
      get() {
        return this.$store.state.PROXY_STORE.NEW_PROXY_EMAIL;
      },
      set(value) {
        this.$store.commit('PROXY_STORE/SET_PROXY_EMAIL', value);
      }
    },
    SHOW_POPUP: {
      get() {
        return this.$store.state.PROXY_STORE.SHOW_POPUP || false;
      },
      set(value) {
        if (!value) {
          this.$store.commit('PROXY_STORE/RESET_POPUP');
        }
      }
    }
  }
};
</script>
<style lang="scss">
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 30px;
  margin-top: 50px;
}

.view-winners {
  margin-bottom: 50px;
}

.button-text {
  font-weight: bold;
}

.whiteText {
  color: white !important;
}
</style>
