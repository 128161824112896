<template>
  <v-card flat>
    <v-card-title> Photo Library </v-card-title>
    <v-card-subtitle>
      Add an image to your template by clicking the 'Select Image' button.
    </v-card-subtitle>
    <v-container fluid>
      <v-row dense>
        <v-col
          v-for="photo in GET_IMAGES_BY_PROMO_TYPE"
          :key="photo.title"
          cols="4"
        >
          <v-card tile :class="[ SELECTED_IMAGE === photo.url ? 'selectedImageCard' : '']">
            <v-img
              height="200px"
              :src="photo.url"
              :lazy-src="photo.url"
              aspect-ratio="16/9"
              max-height="300"
              contain
              class="grey darken-4"
            >
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn :disabled="SELECTED_IMAGE === photo.url" class="btn__primary v-btn v-btn--text theme--light v-size--default v-btn  v-btn--has-bg theme--light v-size--default" outlined @click="setChoosenImage(photo.url)">
                {{SELECTED_IMAGE === photo.url ? 'Active' : 'Select'}} Image
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
export default {
  name: 'AVEPhotoLibrary',
  methods: {
    ...mapMutations('AVE_CANVAS_STORE', [
      'SET_SELECTED_IMAGE',
      'TOGGLE_AVE_DIALOG'
    ]),
    setChoosenImage(imageUrl) {
      this.SET_SELECTED_IMAGE(imageUrl);
      this.TOGGLE_AVE_DIALOG(false);
    }
  },
  computed: {
    ...mapGetters('AVE_CANVAS_STORE', ['GET_IMAGES_BY_PROMO_TYPE']),
    ...mapState('AVE_CANVAS_STORE', ['SELECTED_IMAGE'])
  }
};
</script>


<style>
.selectedImageCard {
  border: 5px solid;
  border-color: var(--v-primary-base) !important;
}
</style>