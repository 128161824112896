<template>
  <div></div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'LogOut',
  computed: {},
  created() {
    this.SIGN_OUT()
      .then(() => {
        this.$router.push('/login');
      })
      .catch(() => {
        this.$router.push('/login');
      });
    // localStorage.removeItem('userAttributes');
    // this.$router.push('/login');
  },
  methods: {
    ...mapActions({
      SIGN_OUT: `account/ACCOUNT_SIGN_OUT`
    })
  }
};
</script>
