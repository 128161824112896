<template>
    <WebQR :preview="true" />
</template>

<script>
import WebQR from '@/views/WebQR.vue';

export default {
  name: 'WebQRPreview',
  components: {
    WebQR
  }
};
</script>
