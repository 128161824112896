<template>
  <v-dialog v-model="dialog" width="500" content-class="app-modal-dialog" persistent>
    <v-card class="pa-8">
      <v-card-title class="grey lighten-2 justify-center">
        Change Password
      </v-card-title>

      <v-card-text class="text-center mt-2">
        Please create a new password in order to continue

        <v-form
          ref="form"
          class="app-profile-form"
          v-model="valid"
        >
          <v-text-field
            v-model="password"
            :rules="[
              v =>
                v.length >= 8 ||
                'New password must be at least 8 characters.'
            ]"
            validate-on-blur
            label="New Password"
            hide-details="auto"
            required
            class="mt-8"
            type="password"
          ></v-text-field>
          <v-text-field
            :rules="[v => v === password || 'Passwords do not match.']"
            validate-on-blur
            label="Confirm Password"
            hide-details="auto"
            required
            class="mt-8"
            type="password"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-center pt-8">
        <v-btn
          :class="{'btn__primary px-6': valid}"
          :disabled="!valid || loading"
          @click="handleChangePassword"
        >
          CHANGE PASSWORD
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ChangePasswordForm',
  props: {
    open: {
      type: Boolean,
      required: true
    },
    user: {
      type: [Object, null],
      default: null
    }
  },
  computed: {
    dialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit('update:open', value);
      }
    }
  },
  data: () => ({
    loading: false,
    password: '',
    valid: false
  }),
  methods: {
    ...mapActions({
      COMPLETE_PASSWORD_CHALLENGE: 'account/COMPLETE_PASSWORD_CHALLENGE'
    }),
    handleChangePassword() {
      this.loading = true;
      this.COMPLETE_PASSWORD_CHALLENGE({
        newPassword: this.password,
        user: this.user
      }).then(response => {
        this.loading = false;
        this.$emit('handleSuccess', response);
        this.dialog = false;
      });
    }
  }
};
</script>

<style scoped></style>
