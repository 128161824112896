<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-for="(opt, index) in orgOptions"
            :key="opt.id"
            class="py-2"
          >
            <v-checkbox
              :false-value="Number(index)"
              :true-value="opt.value"
              :readonly="isEventArchived"
              v-model="organization"
              :value="opt.value"
              :label="opt.text"
              off-icon="$TMobileEmptyCheckbox"
              :hide-details="$vuetify.breakpoint.mdAndUp ? 'auto' : 'true'"
              :error-messages="organizationErrors"
              :on-icon="isTMO() ? '$TMobileFullCheckbox' : '$MetroFullCheckbox'"
              :append-icon="
                organizationErrors.length == 0 || $vuetify.breakpoint.mdAndUp
                  ? ''
                  : 'mdi-alert-circle'
              "
            >
              <span slot="label" class="black--text">{{ opt.text }}</span>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-col>

      <!-- end updated-->
    </v-row>
  </div>
</template>
<script>
import ValidationRules from '../../utility/ValidationRules.js';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'OrganizationFields',
  props: {
    isEventArchived: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      organizationErrors: []
    };
  },
  methods: {
    nonEmptyRule(error) {
      return ValidationRules.NonEmptyRule(error);
    },
    isTMO() {
      return this.$vuetify.theme.themes.light.primary == '#e20074';
    }
  },
  computed: {
    orgOptions() {
      return this.$OptionData.Organizations();
    },

    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),

    organization: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.organization || [];
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'organization',
          value: value
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .v-input--selection-controls__input {
  position: absolute;
  top: 0;
}

::v-deep .v-input--selection-controls .v-input__slot > .v-label,
::v-deep .v-input--selection-controls .v-radio > .v-label {
  position: relative;
  left: 32px !important;
  margin-right: 32px;
}
</style>
