<template>
  <div class="app-resources">
    <AlertBar></AlertBar>
    <BannerTitle title="Resources" :loading="resourcesLoading" />
    <v-container class="card-container" v-if="!resourcesLoading">
      <h2 v-show="isTranslator" class="text-center text-h2">
        Resource Page is unavailable
      </h2>
      <v-row justify="center" class="my-2" v-show="!isTranslator">
        <v-col cols="12" sm="10" class="flex-wrap justify-center d-flex">
          <v-card
            v-for="resource in resources"
            :key="resource.id"
            class="py-3 mx-3 my-3 text-left d-flex flex-column justify-space-between align-start"
            elevation="11"
          >
            <v-card-title>
              <h2 class="text-left text-h2">
                {{ resource.title }}
              </h2>
            </v-card-title>
            <v-card-subtitle class="text-left text-h3" v-if="resource.subtitle">
              {{ resource.subtitle }}
            </v-card-subtitle>
            <v-card-text color="white">
              <p class="resource--description">
                {{ resource.description }}
              </p>
            </v-card-text>
            <v-card-actions class="justify-center flex-column align-center">
              <v-card-actions class="justify-center pt-8">
                <v-btn
                  @click="viewDocument(resource.documentUrl)"
                  elevation="2"
                  tile
                  block
                  x-large
                  color="primary"
                >
                  View PDF
                </v-btn>
              </v-card-actions>
              <v-card-actions v-if="isAdmin" class="justify-center pt-8">
                <v-btn
                  @click="showPatchDialog(resource)"
                  elevation="2"
                  tile
                  x-large
                  color="primary"
                >
                  Edit
                </v-btn>
                <v-btn
                  @click="showDeleteDialog(resource.id)"
                  elevation="2"
                  tile
                  outlined
                  x-large
                  color="primary"
                >
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card-actions>
          </v-card>
          <v-card
            v-if="isAdmin"
            class="justify-start py-3 mx-3 my-3 text-left d-flex flex-column align-center outlined"
            elevation="11"
            @click="showPostDialog"
          >
            <v-card-title>
              <h2 class="text-left text-h2">
                Add New Resource
              </h2>
            </v-card-title>
            <v-icon>mdi-plus-thick</v-icon>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="postDialog" width="500" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Add Resource
        </v-card-title>

        <v-form v-model="postValid">
          <v-text-field
            v-model="title"
            label="Resource Title"
            hide-details="auto"
            append-icon="mdi-alert-circle"
            required
            counter="50"
            :rules="[
              nonEmptyRule('You must provide a resource title.'),
              v =>
                v == null ||
                v.length <= 50 ||
                'Resource title cannot be greater than 50 characters.'
            ]"
          >
          </v-text-field>
          <v-text-field
            v-model="subtitle"
            label="Resource Subtitle (Optional)"
            hide-details="auto"
            required
            counter="50"
            :rules="[
              v =>
                v == null ||
                v.length <= 50 ||
                'Resource subtitle cannot be greater than 50 characters.'
            ]"
          >
          </v-text-field>
          <v-textarea
            v-model="description"
            label="Resource Description"
            :rules="[
              nonEmptyRule('You must a resource description.'),
              v =>
                v == null ||
                v.length <= 500 ||
                'Resource description cannot be greater than 500 characters.'
            ]"
            rows="1"
            auto-grow
            hide-details="auto"
            append-icon="mdi-alert-circle"
            counter="500"
            required
          ></v-textarea>
          <v-file-input
            accept=".pdf"
            v-model="resourcePDF"
            label="Resource File"
            append-icon="mdi-alert-circle"
            required
            :rules="[nonEmptyRule('A resource file is required.')]"
          ></v-file-input>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            @click="postDialog = false"
            tile
            outlined
            x-large
            color="primary"
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="addResource"
            :loading="loading"
            tile
            x-large
            color="primary"
            :disabled="!postValid || !resourcePDF"
          >
            Publish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="patchDialog"
      width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Edit Resource
        </v-card-title>

        <v-form v-model="patchValid">
          <v-text-field
            v-model="title"
            label="Resource Title"
            hide-details="auto"
            append-icon="mdi-alert-circle"
            required
            counter="50"
            :rules="[
              nonEmptyRule('You must provide a resource title.'),
              v =>
                v == null ||
                v.length <= 50 ||
                'Resource title cannot be greater than 50 characters.'
            ]"
          >
          </v-text-field>
          <v-text-field
            v-model="subtitle"
            label="Resource Subtitle (Optional)"
            hide-details="auto"
            required
            counter="50"
            :rules="[
              v =>
                v == null ||
                v.length <= 50 ||
                'Resource subtitle cannot be greater than 50 characters.'
            ]"
          >
          </v-text-field>
          <v-textarea
            v-model="description"
            label="Resource Description"
            :rules="[
              nonEmptyRule('You must a resource description.'),
              v =>
                v == null ||
                v.length <= 500 ||
                'Resource description cannot be greater than 500 characters.'
            ]"
            rows="1"
            auto-grow
            hide-details="auto"
            append-icon="mdi-alert-circle"
            counter="500"
            required
          ></v-textarea>
          <v-file-input
            accept=".pdf"
            label="New Resource File (Optional)"
            v-model="resourcePDF"
          ></v-file-input>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            @click="patchDialog = false"
            tile
            outlined
            x-large
            color="primary"
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="editResource"
            :loading="loading"
            tile
            x-large
            color="primary"
            :disabled="!patchValid"
          >
            Publish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Delete Resource
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          This action is not reversible. Are you sure you want to delete this
          resource card?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            @click="deleteDialog = false"
            tile
            outlined
            x-large
            color="primary"
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="removeResource"
            :loading="loading"
            tile
            x-large
            color="primary"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="500" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialog = false" tile x-large color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AlertBar from '@/components/AlertBar.vue';
import BannerTitle from '@/components/BannerTitle.vue';
import ValidationRules from '../utility/ValidationRules.js';
import { API, Auth, Storage } from 'aws-amplify';
import { mapGetters } from 'vuex';

export default {
  name: 'Resources',
  computed: {
    ...mapGetters({
      USER_ATTRIBUTES: 'account/USER_ATTRIBUTES',
      USER_GROUPS: 'account/USER_GROUPS'
    })
  },
  components: {
    AlertBar,
    BannerTitle
  },
  data() {
    return {
      isAdmin: false,
      postValid: false,
      patchValid: false,
      loading: false,
      resourcesLoading: false,
      dialog: false,
      postDialog: false,
      patchDialog: false,
      deleteDialog: false,
      errorDialog: false,
      dialogTitle: '',
      dialogMessage: '',
      resources: [],
      resourcePDF: null,
      title: null,
      subtitle: null,
      description: null,
      documentUrl: null,
      isTranslator: false
    };
  },
  async created() {
    this.isAdmin = this.USER_GROUPS.indexOf('Admin') !== -1;
    this.isTranslator = this.USER_GROUPS.indexOf('SLTranslator') !== -1;
    this.resources = await this.getResources();
  },
  methods: {
    async viewDocument(url) {
      try {
        // url in form of {bucket}/protected/{user_identity_id}/{key}
        url = url.split('protected/')[1].split('/');
        let identity = decodeURIComponent(url[0]);
        let key = url[1];
        url = await Storage.get(key, {
          level: 'protected',
          identityId: identity
        });
        window.open(url);
      } catch (e) {
        this.showErrorDialog(e);
      }
    },
    isInAdminGroup() {
      return this.USER_GROUPS.indexOf('Admin') !== -1;
    },
    nonEmptyRule(error) {
      return ValidationRules.NonEmptyRule(error);
    },
    async putResourcePDFInStorage(pdf) {
      //simple random key generation function
      function uuidv4() {
        return 'xxxxxxxx-xxxx'.replace(/[xy]/g, function(c) {
          var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
      }
      const result = await Storage.put(`resource-${uuidv4()}.pdf`, pdf, {
        contentType: 'application/pdf',
        ACL: 'protected-read',
        visibility: 'protected',
        level: 'protected'
      });
      var fullUrl = await Storage.get(result.key, {
        level: 'protected'
      });
      return fullUrl.split('?')[0];
    },
    async getResources() {
      this.resourcesLoading = true;
      const apiName = 'TMOAmplifyApi';
      const path = '/resources';

      const payload = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      };
      try {
        let req = await API.get(apiName, path, payload);
        req = typeof req == 'string' ? JSON.parse(req) : req;
        return req.data;
      } catch (e) {
        throw JSON.parse(e.response.data).errorMessage;
      } finally {
        this.resourcesLoading = false;
      }
    },
    async postResource() {
      const apiName = 'TMOAmplifyApi';
      const path = '/resource';

      const payload = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          title: this.title,
          subtitle: this.subtitle,
          description: this.description,
          documentUrl: this.documentUrl
        }
      };
      try {
        await API.post(apiName, path, payload);
      } catch (e) {
        throw JSON.parse(e.response.data).errorMessage;
      }
    },
    async patchResource() {
      const apiName = 'TMOAmplifyApi';
      const path = `/resource/${this.resourceId}`;

      const payload = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          title: this.title,
          subtitle: this.subtitle,
          description: this.description,
          documentUrl: this.documentUrl
        }
      };
      try {
        await API.patch(apiName, path, payload);
      } catch (e) {
        throw JSON.parse(e.response.data).errorMessage;
      }
    },
    async deleteResource() {
      const apiName = 'TMOAmplifyApi';
      const path = `/resource/${this.resourceId}`;

      const payload = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      };
      try {
        await API.del(apiName, path, payload);
      } catch (e) {
        this.showErrorDialog(JSON.parse(e.response.data).errorMessage);
      }
    },
    async addResource() {
      if (!this.resourcePDF) {
        this.showErrorDialog('Resource file is required.');
        return;
      }
      this.loading = true;
      try {
        this.documentUrl = await this.putResourcePDFInStorage(this.resourcePDF);
        await this.postResource();
        this.resources = await this.getResources();
        this.showSuccessDialog('Resource Added.');
      } catch (e) {
        this.showErrorDialog(e);
      } finally {
        this.loading = false;
        this.postDialog = false;
      }
    },
    async editResource() {
      this.loading = true;
      try {
        if (this.resourcePDF) {
          /* eslint-disable-next-line */
          this.documentUrl = await this.putResourcePDFInStorage(
            this.resourcePDF
          );
        }
        await this.patchResource();
        this.resources = await this.getResources();
        this.showSuccessDialog('Resource Edited.');
      } catch (e) {
        this.showErrorDialog(e);
      } finally {
        this.loading = false;
        this.patchDialog = false;
      }
    },
    async removeResource() {
      this.loading = true;
      try {
        await this.deleteResource();
        this.resources = await this.getResources();
        this.showSuccessDialog('Resource Deleted.');
      } catch (e) {
        this.showErrorDialog(e);
      } finally {
        this.loading = false;
        this.deleteDialog = false;
      }
    },
    showPostDialog() {
      this.resourcePDF = null;
      this.resourceId = null;
      this.title = null;
      this.subtitle = null;
      this.description = null;
      this.documentUrl = null;
      this.postDialog = true;
    },
    showPatchDialog(resource) {
      this.resourcePDF = null;
      this.resourceId = resource.id;
      this.title = resource.title;
      this.subtitle = resource.subtitle;
      this.description = resource.description;
      this.documentUrl = resource.documentUrl;
      this.patchDialog = true;
    },
    showDeleteDialog(id) {
      this.resourceId = id;
      this.deleteDialog = true;
    },
    showSuccessDialog(message) {
      this.dialogTitle = 'Success';
      this.dialogMessage = message;
      this.dialog = true;
    },
    showErrorDialog(error) {
      this.dialogTitle = 'Error';
      this.dialogMessage = error;
      this.dialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
  min-height: 175px;
}

.v-card {
  width: 350px;
  max-width: 100%;
  min-height: 400px;
}

.v-card.outlined {
  outline-style: dashed;
  outline-color: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  box-shadow: none !important;
  .v-icon {
    font-size: 5rem;
    color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.v-dialog .v-card {
  width: 100%;
}

::v-deep .v-card__actions {
  align-self: center;
}

::v-deep .v-input .mdi-alert-circle {
  display: none;
}

::v-deep .error--text .mdi-alert-circle {
  display: block;
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}
</style>
