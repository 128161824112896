import { Auth, API } from 'aws-amplify';

const requestHeaders = async () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`
  };
};

const disableUser = async function({ dispatch, getters, commit }, username) {
  if (!username) {
    throw Error('ERROR::ACCOUNT_SIGN_IN: No parameters provided.');
  }
  let path = '/disableUser';
  try {
    let payload = {
      body: {
        username
      },
      headers: await requestHeaders()
    };
    let req = await API.post('AdminQueries', path, payload);
    return req;
  } catch (error) {
    throw Error(error);
  }
};

export default disableUser;
