<template>
  <div class="app-login">
    <AlertBar></AlertBar>
    <BannerTitle title="Edit Profile" />
    <v-container :fluid="!this.lgBreakpoint">
      <v-row justify="center">
        <v-col col="12" sm="10" md="8" lg="7">
          <v-sheet elevation="11">
            <v-form
              ref="userInfoForm"
              class="app-profile-form px-6 py-12 my-10"
            >
              <section>
                <h2 class="text-center">
                  <strong>Account Information cannot be changed.</strong>
                </h2>
                <v-text-field
                  v-model.lazy="attributes.given_name"
                  label="First Name"
                  hide-details="auto"
                  required
                  :rules="nameRules"
                  readonly
                  @change="attributeUpdate"
                ></v-text-field>
                <v-text-field
                  v-model.lazy="attributes.family_name"
                  label="Last Name"
                  hide-details="auto"
                  :rules="nameRules"
                  readonly
                  required
                  class="mt-8"
                  @change="attributeUpdate"
                ></v-text-field>
                <v-text-field
                  v-model.lazy="attributes.email"
                  label="Email"
                  hide-details="auto"
                  :rules="emailRules"
                  readonly
                  required
                  class="mt-8"
                  @change="attributeUpdate"
                ></v-text-field>

                <!-- <v-btn
                  tile
                  block
                  x-large
                  color="primary"
                  :disabled="!infoValid || !infoUpdated"
                  @click="changeUserAttributes"
                >
                  <span>
                    Update
                  </span>
                </v-btn> -->
              </section>
              <!-- <div class="form-buttons">
                <v-row justify="center">
                  <v-col cols="12" md="6">
                    <app-button
                      color="primary"
                      :disabled="!infoValid || !infoUpdated"
                      :on-click="changeUserAttributes"
                      block
                      class="mt-10"
                    >
                      Update
                    </app-button>
                  </v-col>
                </v-row>
              </div> -->
            </v-form>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col col="12" sm="10" md="8" lg="7">
          <v-sheet elevation="11">
            <v-form
              ref="form"
              class="app-profile-form pa-6 my-10"
              v-model="infoValid"
            >
              <section>
                <div>
                  <div class="mt-5">
                    <strong>
                      To change your password, enter your current password
                      below, followed by your new password.
                    </strong>
                  </div>
                  <v-text-field
                    v-model="currentPassword"
                    label="Current Password"
                    hide-details="auto"
                    required
                    type="password"
                    @change="attributeUpdate"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :rules="[
                      v =>
                        v.length >= 8 ||
                        'New password must be at least 8 characters.'
                    ]"
                    validate-on-blur
                    label="New Password"
                    hide-details="auto"
                    required
                    class="mt-8"
                    type="password"
                  ></v-text-field>
                  <v-text-field
                    :rules="[v => v === password || 'Passwords do not match.']"
                    validate-on-blur
                    label="Confirm Password"
                    hide-details="auto"
                    required
                    class="mt-8"
                    type="password"
                  ></v-text-field>
                </div>
              </section>
              <div class="form-buttons">
                <v-row justify="center">
                  <v-col cols="12" md="6">
                    <!-- <v-btn
                      elevation="2"
                      tile
                      x-large
                      color="primary"
                      :disabled="!valid"
                    >
                      <span>
                        Update
                      </span>
                    </v-btn> -->
                    <app-button
                      color="primary"
                      :disabled="!infoValid || !infoUpdated"
                      :on-click="changePasswordFromProfile"
                      block
                      class="mt-10"
                    >
                      Update
                    </app-button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" width="500" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="grey lighten-2 justify-center">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="text-center mt-2">
          {{ dialogText }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn class="btn__primary px-6" text @click="dialogAction">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AlertBar from '@/components/AlertBar.vue';
import BannerTitle from '@/components/BannerTitle.vue';
import AppButton from '../components/AppButton.vue';

export default {
  name: 'Profile',
  components: {
    AlertBar,
    BannerTitle,
    AppButton
  },
  data() {
    return {
      attributes: {},
      currentPassword: '',
      password: '',
      valid: false,
      dialog: false,
      dialogTitle: '',
      dialogText: '',
      dialogAction: null,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      snackbarTimeout: 4000,
      infoValid: false,
      userAttributes: null,
      infoUpdated: false,
      nameRules: [v => !!v || 'Name cannot be empty.'],
      emailRules: [
        v =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Not a valid email'
      ]
    };
  },
  created() {
    // let userAttributes = localStorage.getItem('userAttributes');
    // if (userAttributes !== null) {
    //   this.userAttributes = JSON.parse(userAttributes);
    // }
    this.updateUser().then(data => {
      this.attributes = this.USER_ATTRIBUTES;
    });
  },
  computed: {
    ...mapGetters({
      USER: 'account/USER',
      USER_ATTRIBUTES: 'account/USER_ATTRIBUTES'
    }),
    lgBreakpoint: function() {
      return this.$vuetify.breakpoint.lgAndUp;
    }
  },
  methods: {
    ...mapActions({
      CHANGE_PASSWORD: 'account/CHANGE_PASSWORD',
      SET_USER: 'account/SET_USER',
      UPDATE_USER_ATTRIBUTES: 'account/UPDATE_USER_ATTRIBUTES',
      updateUser: 'account/UPDATE_USER_INFO'
    }),
    attributeUpdate(event) {
      this.infoUpdated = true;
    },
    changeUserAttributes() {
      this.UPDATE_USER_ATTRIBUTES({
        given_name: this.attributes.given_name,
        family_name: this.attributes.family_name,
        email: this.attributes.email
      })
        .then(response => {
          this.infoUpdated = false;
          this.processUserAttributeUpdate(response, 'Success');
        })
        .catch(response => this.processUserAttributeUpdate(response, 'ERROR'));
    },
    changePasswordFromProfile() {
      const resetParams = {
        // user: this.USER,
        oldPassword: this.currentPassword,
        newPassword: this.password
      };
      this.CHANGE_PASSWORD(resetParams)
        .then(response =>
          this.processResetPasswordResponse(response, 'Success')
        )
        .catch(response =>
          this.processResetPasswordResponse(response, 'ERROR')
        );
    },
    processResetPasswordResponse(response, type) {
      this.dialogTitle = type;
      this.dialogText =
        type === 'Success'
          ? 'Your password has been changed.'
          : response.message;
      this.dialog = true;
      this.dialogAction =
        type === 'Success'
          ? () => {
              this.$router.push('/login');
            }
          : () => {
              this.dialog = false;
            };
    },
    processUserAttributeUpdate(response, type) {
      this.dialogTitle = type;
      this.dialogText =
        type === 'Success'
          ? 'Your user info has been updated.'
          : response.message;
      this.dialog = true;
      this.dialogAction = () => {
        this.dialog = false;
      };
    }
  }
};
</script>
<style scoped>
/* .app-profile-form {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
} */
/* .form-buttons {
  margin-top: 40px;
  margin-bottom: 40px;
} */
</style>
