<template>
  <div class="app-all-events">
    <AlertBar></AlertBar>
    <BannerTitle
      :title="`Welcome, ${given_name} ${family_name}!`"
      description="The Engagement Hub is our most powerful lead capture tool to date! Below are all events Pending Legal Review."
      :loading="given_name === ''"
    />
    <section class="pt-10 pb-8 lightGrey">
      <v-container :fluid="!this.lgBreakpoint">
        <AllEventsSearchForm />
        <v-row justify="start">
          <v-col cols="12">
            <div class="mt-3 app-all-events__showsearch">
              <button href="#" @click.prevent="toggleAdvancedSearchForm()">
                <span>Show Advanced Search</span>
                <v-icon medium color="#E44475">
                  {{
                    advancedSearchActive ? 'mdi-chevron-up' : 'mdi-chevron-down'
                  }}
                </v-icon>
              </button>
            </div>
          </v-col>
        </v-row>
        <AllEventsAdvancedSearchOptions />
      </v-container>
    </section>
    <section class="py-12">
      <v-container :fluid="!this.lgBreakpoint">
        <v-row>
          <v-col cols="12">
            <EventReportGenerator ref="eventReportGenerator" />
            <h2>Legal Report</h2>
            <p>Select the dates you would like your report to reflect.</p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="startDateField"
                        v-model="startDate"
                        label="Begin report as of Midnight, UTC:"
                        :rules="[startDateRule]"
                        append-icon="$Calendar"
                        readonly
                        v-bind="attrs"
                        hide-details="auto"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="endDateField"
                        v-model="endDate"
                        label="End report as of Midnight, UTC:"
                        :rules="[endDateRule]"
                        append-icon="$Calendar"
                        readonly
                        v-bind="attrs"
                        hide-details="auto"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      no-title
                      @input="endDateMenu = false"
                      :min="startDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="6">
                  <v-btn
                    color="primary"
                    elevation="2"
                    tile
                    block
                    x-large
                    :loading="loading"
                    :disabled="!startDate || !endDate || !valid"
                    @click="generateEventReport('Legal')"
                  >
                    Legal Report
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row align="center" class="flex-column">
          <v-col cols="12">
            <v-radio-group
              v-model="filterEventsBy"
              row
              class="app-dashboard__filters"
            >
              <v-radio label="Legal Pending" value="Legal Pending"></v-radio>
              <v-radio
                label="Legal Reviewed"
                value="Legal Reviewed"
                class="ml-3"
              ></v-radio>
            </v-radio-group>
            <AllEventsActiveSearchFilters />
            <LegalEventsTable
              :table-data="tableData"
              :rows-per-page="RESULTS_PER_PAGE"
            />
            <div class="app-all-events__pagination-wrap">
              <AllEventsPagination
                class="mt-6"
                :data="tableData"
                :max-pages="Math.ceil(tableData.length / RESULTS_PER_PAGE)"
                :items-per-page="RESULTS_PER_PAGE"
              />
              <div class="app-all-events__itemsperpage">
                <div>View:</div>
                <div>
                  <a
                    v-for="option in rowsPerPageOptions"
                    :key="option.id"
                    :class="[option.value === RESULTS_PER_PAGE ? 'active' : '']"
                    @click.prevent="CHANGE_ITEMS_PER_PAGE(option.value)"
                  >
                    {{ option.text }}
                  </a>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
          <v-card class="pa-8">
            <v-card-title class="justify-center grey lighten-2">
              {{ dialogTitle }}
            </v-card-title>

            <v-card-text class="mt-2 text-center">
              {{ dialogMessage }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="justify-center pt-8">
              <v-btn @click="dialogAction" text class="px-6 btn__primary">
                {{ dialogActionText }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import BannerTitle from '@/components/BannerTitle.vue';
import AllEventsPagination from '@/components/AllEventsPagination';
import AllEventsSearchForm from '@/components/Search/AllEventsSearchForm';
import AllEventsAdvancedSearchOptions from '@/components/Search/AllEventsAdvancedSearchOptions';
import AllEventsActiveSearchFilters from '@/components/Search/AllEventsActiveSearchFilters';
import LegalEventsTable from '@/components/Legal/LegalEventsTable';
import EventReportGenerator from '@/components/EventReportGenerator';
import AlertBar from '@/components/AlertBar.vue';

export default {
  name: 'LegalDashboard',
  components: {
    LegalEventsTable,
    BannerTitle,
    AllEventsSearchForm,
    AllEventsPagination,
    AllEventsAdvancedSearchOptions,
    AllEventsActiveSearchFilters,
    EventReportGenerator,
    AlertBar
  },
  data() {
    return {
      advancedSearchActive: false,
      valid: false,
      loading: false,
      startDateMenu: null,
      endDateMenu: null,
      startDate: null,
      endDate: null,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null,
      filterEventsBy: 'Legal Pending',
      pendingEvents: [],
      reviewedEvents: [],
      tableData: [],
      rowsPerPageOptions: [
        {
          text: '25',
          value: 25
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ]
    };
  },
  async created() {
    if (
      this.USER_GROUPS.indexOf('Legal') === -1 &&
      this.USER_GROUPS.indexOf('Admin') === -1
    ) {
      this.$router.push('/dashboard');
      return;
    }
    this.given_name = this.userAttributes.given_name;
    this.family_name = this.userAttributes.family_name;
    await this.GET_EVENTS();
  },
  computed: {
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`
    }),
    ...mapState('EVENT_TABLE_STORE', [
      'TABLE_DATA',
      'LOADING',
      'RESULTS_PER_PAGE'
    ]),
    lgBreakpoint: function() {
      return this.$vuetify.breakpoint.lgAndUp;
    }
  },
  methods: {
    ...mapActions('EVENT_TABLE_STORE', [
      'GET_EVENTS',
      'CHANGE_ITEMS_PER_PAGE',
      'APPLY_LEGAL',
      'APPLY_FILTER'
    ]),

    next(page) {
      this.$store.commit('EVENT_TABLE_STORE/SET_CURRENT_PAGE', page);
    },
    toggleAdvancedSearchForm() {
      this.advancedSearchActive = !this.advancedSearchActive;
      if (
        !document
          .getElementsByClassName('app-events-advanced-search')[0]
          .classList.contains('active')
      ) {
        document
          .getElementsByClassName('app-events-advanced-search')[0]
          .classList.add('active');
        document
          .getElementsByClassName('app-all-events__showsearch')[0]
          .getElementsByTagName('span')[0].innerHTML = 'Hide Advanced Search';
      } else {
        document
          .getElementsByClassName('app-events-advanced-search')[0]
          .classList.remove('active');
        document
          .getElementsByClassName('app-all-events__showsearch')[0]
          .getElementsByTagName('span')[0].innerHTML = 'Show Advanced Search';
      }
    },
    generateEventReport(type = 'Legal') {
      if (!this.startDate || !this.endDate) {
        return;
      }
      this.loading = true;
      this.$nextTick(async function() {
        let res = await this.$refs.eventReportGenerator.createEventReportCSV(
          this.startDate + ' 00:00:00',
          this.endDate + ' 00:00:00',
          type
        );
        this.loading = false;
        if (res === 'Success') {
          this.dialogTitle = 'Success';
          this.dialogMessage = 'Report Generation Successful.';
        } else if (res === 'No Results') {
          this.dialogTitle = 'No Results in Date Range';
          this.dialogMessage =
            'There are no events with activity in this date range.';
        } else if (res && res.errorMessage) {
          if (res.errorMessage === 'File Too Large') {
            this.dialogTitle = 'File Too Large';
            this.dialogMessage =
              'The file generated for the dates you selected is too large to download. Please limit your dates, or reach out for a custom report.';
          } else {
            this.dialogTitle = 'Error';
            this.dialogMessage = res.errorMessage;
          }
        } else {
          this.dialogTitle = 'Error';
          this.dialogMessage = 'An unknown error occurred.';
        }
        this.displayDialog(
          this.dialogTitle,
          this.dialogMessage,
          'Close',
          () => (this.dialog = false)
        );
      });
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },
    startDateRule(date) {
      if (this.startDateMenu) {
        return true;
      }
      if (!date) {
        return 'You must select a start date.';
      }
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        return 'Beginning date of report cannot be greater than ending date of report.';
      }
      return true;
    },
    endDateRule(date) {
      if (this.endDateMenu) {
        return true;
      }
      if (!date) {
        return 'You must select an end date.';
      }
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        return 'Beginning date of report cannot be greater than ending date of report.';
      }
      return true;
    }
  },
  watch: {
    startDateMenu: function(val) {
      this.$refs.startDateField.validate();
      this.$refs.endDateField.validate();
    },
    endDateMenu: function(val) {
      this.$refs.startDateField.validate();
      this.$refs.endDateField.validate();
    },
    filterEventsBy: function(val) {
      // applying filter will reload TABLE_DATA
      if (val == 'Legal Pending') {
        this.APPLY_FILTER({ filter_key: 'archived', filter_value: '0' });
      } else {
        // include archived events
        this.APPLY_FILTER({ filter_key: 'archived', filter_value: null });
      }
    },
    TABLE_DATA: function(val) {
      this.pendingEvents = this.TABLE_DATA.filter(
        event => event.status === 'Legal Pending'
      );
      this.reviewedEvents = this.TABLE_DATA.filter(event => {
        return (
          event.status === 'Published' ||
          event.status === 'Translation Pending' ||
          event.status === 'Denied' ||
          event.archived == 1
        );
      });
      if (this.filterEventsBy === 'Legal Pending') {
        this.tableData = this.pendingEvents;
      } else if (this.filterEventsBy === 'Legal Reviewed') {
        this.tableData = this.reviewedEvents;
      } else {
        this.tableData = this.TABLE_DATA;
      }
    }
  }
};
</script>

<style scoped lang="scss">
// .container--fluid {
//   // max-width: 1200px;
// }
.app-all-events__pagination-wrap {
  display: flex;
  justify-content: space-between;
}
.app-all-events__itemsperpage {
  display: flex;
  margin-top: 32px;
}
.app-all-events__itemsperpage a {
  text-decoration: none;
  font-weight: 700;
  color: #000;
  margin-left: 5px;
  margin-right: 5px;
}
.app-all-events__itemsperpage a.active {
  color: var(--v-primary-base);
}
.app-all-events__showsearch > a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.v-pagination {
  align-items: flex-start !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 48px;
}
</style>
