export default class ThemeSwitcher {
  static CurTheme = 'T-Mobile';
  static ChangeTheme(vueInstance, themeName = '') {
    if (themeName.toLowerCase().includes('t-mobile')) {
      vueInstance.$vuetify.theme.themes.light.primary = '#e20074';
      vueInstance.$vuetify.theme.themes.light.secondary = '#861b54';
      vueInstance.$vuetify.theme.themes.light.tertiary = '#861b54';
      vueInstance.$vuetify.theme.themes.light.unselected = '#861b54';
      vueInstance.$vuetify.theme.themes.light.selected = '#e20074';
      vueInstance.$vuetify.theme.themes.light.tag = '#861b54';
      vueInstance.$vuetify.theme.themes.light.registerBackground = '#ffffff';
    } else if (themeName.toLowerCase().includes('metro')) {
      vueInstance.$vuetify.theme.themes.light.primary = '#46196e';
      vueInstance.$vuetify.theme.themes.light.secondary = '#6e32a0';
      vueInstance.$vuetify.theme.themes.light.tertiary = '#9646c3';
      vueInstance.$vuetify.theme.themes.light.unselected = '#46196e';
      vueInstance.$vuetify.theme.themes.light.selected = '#9646c3';
      vueInstance.$vuetify.theme.themes.light.tag = '#46196e';
      vueInstance.$vuetify.theme.themes.light.registerBackground = '#e0e0e0';
    }
    this.CurTheme = themeName;
  }

  static GetCurrentTheme() {
    return this.CurTheme;
  }
}
