<template>
  <v-container class="py-0">
    <EventReportGenerator ref="eventReportGenerator" />
    <h2>Download Your Report</h2>
    <div class="download-buttons">
      <v-btn
        class="mr-2"
        color="primary"
        elevation="0"
        dark
        tile
        :loading="loading.Zips"
        @click="exportReport"
      >
        Interactions by Zip Code
      </v-btn>
      <v-btn
        v-if="CURRENT_EVENT_OBJECT.intervals.length > 1"
        class="mr-2"
        color="primary"
        elevation="0"
        dark
        tile
        :loading="loading.Periods"
        @click="generateEventReport('Periods')"
      >
        Interactions by Entry Period
      </v-btn>
      <v-btn
        class="mr-2"
        color="primary"
        elevation="0"
        dark
        tile
        @click="showInteractionsForm = !showInteractionsForm"
      >
        Interactions by Custom Date Range
      </v-btn>
      <v-btn
        class="mr-2"
        color="primary"
        elevation="0"
        dark
        tile
        :loading="loading.Daily"
        @click="generateEventReport('Daily')"
      >
        Interactions Per Day
      </v-btn>
      <v-btn
        v-if="CURRENT_EVENT_OBJECT.contestType == 'Sign Up/Check In'"
        class="mr-2"
        color="primary"
        elevation="0"
        dark
        tile
        :loading="loading.CheckIn"
        @click="exportCheckInReport"
      >
        Download Sign Up/Check In Report
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" v-show="showInteractionsForm">
      <div>
        <h3>Select Report Date Range</h3>
        <p>Enter the dates and times you would like your report to reflect.</p>
        <v-row>
          <v-col cols="12" md="6" lg="3">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="startDateField"
                  v-model="startDate"
                  label="Report Beginning Date"
                  :rules="[startDateRule]"
                  append-icon="$Calendar"
                  readonly
                  v-bind="attrs"
                  hide-details="auto"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                @input="startDateMenu = false"
                :min="CURRENT_EVENT_OBJECT.startDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-select
              v-model="startTime"
              append-icon="$Clock"
              :items="timePickerItems"
              hide-details="auto"
              label="Start Time"
              :rules="[v => !!v || 'Event start time is required.']"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="endDateField"
                  v-model="endDate"
                  label="Report Ending Date"
                  :rules="[endDateRule]"
                  append-icon="$Calendar"
                  readonly
                  v-bind="attrs"
                  hide-details="auto"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                @input="endDateMenu = false"
                :min="startDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-select
              ref="endTimeField"
              v-model="endTime"
              append-icon="$Clock"
              :items="endTimeRule"
              hide-details="auto"
              label="End Time"
              :rules="[v => !!v || 'Event end time is required.']"
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <v-row class="download-buttons" justify="center">
        <v-col cols="12" md="6" lg="3">
          <v-btn
            color="primary"
            elevation="2"
            tile
            block
            x-large
            :loading="loading.Event"
            :disabled="!startDate || !startTime || !endDate || !endTime || !valid"
            @click="generateEventReport('Event')"
          >
            Download Report
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" text class="px-6 btn__primary">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ExportTools from '../../utility/ExportTools.js';
import EventReportGenerator from '@/components/EventReportGenerator.vue';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'RunReportAction',
  data() {
    return {
      loading: {
        Zips: false,
        Event: false,
        Periods: false,
        Daily: false,
        CheckIn: false
      },
      showInteractionsForm: false,
      valid: false,
      startDateMenu: null,
      endDateMenu: null,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null
    };
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    timePickerItems() {
      return this.$OptionData.TimepickerItems();
    },
    timePickerEndItems() {
      let endTimeOptions = this.$OptionData.TimepickerEndItems();
      // if event ends on same day as start, make sure end time is
      // after event start time
      if (this.startDate && this.startTime && this.endDate) {
        if (this.startDate === this.endDate) {
          endTimeOptions = endTimeOptions.filter(item => {
            return (
              new Date(this.endDate + ' ' + item.value) >=
              new Date(this.startDate + ' ' + this.startTime)
            );
          });
        }
      }
      let currentEndTime = this.endTime;
      let checkTimeOccurance = endTimeOptions.filter(
        item => item.value === currentEndTime
      ).length;
      if (!checkTimeOccurance && currentEndTime) {
        let twelveHourTime = this.$options.filters
          .UTCtoAMPM(currentEndTime)
          .toLowerCase();
        endTimeOptions.push({ text: twelveHourTime, value: currentEndTime });
      }

      return endTimeOptions;
    },
    startDateRule(date) {
      if (this.startDateMenu) {
        return true;
      }
      if (!date) {
        return 'You must select a start date.';
      }
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        if (this.startDate > this.endDate) {
          return 'Beginning date of report cannot be greater than ending date of report.';
        }
        if (this.startDate == this.endDate) {
          if (this.startTime && this.endTime && this.startTime > this.endTime) {
            return 'Beginning time of report cannot be greater than ending time of report.';
          }
        }
      }
      return true;
    },
    endDateRule(date) {
      if (this.endDateMenu) {
        return true;
      }
      if (!date) {
        return 'You must select an end date.';
      }
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        if (this.startDate > this.endDate) {
          return 'Beginning date of report cannot be greater than ending date of report.';
        }
        if (this.startDate == this.endDate) {
          if (this.startTime && this.endTime && this.startTime > this.endTime) {
            return 'Beginning time of report cannot be greater than ending time of report.';
          }
        }
      }
      return true;
    },
    endTimeRule() {
      let startDate = this.startDate || null;
      let endDate = this.endDate || null;
      let startTime = this.startTime || '00:00:00';
      let endTimeOpts = this.timePickerEndItems || [];
      if (!startDate || startDate !== endDate) {
        return this.timePickerEndItems;
      }
      endTimeOpts = this.timePickerEndItems.filter(x => x.value > startTime);

      return endTimeOpts;
    }
  },
  methods: {
    async exportReport() {
      this.loading.Zips = true;
      this.showInteractionsForm = false;
      let eventName = this.CURRENT_EVENT_OBJECT.name;
      let dma = this.CURRENT_EVENT_OBJECT.dma;
      let createdByEmail = this.CURRENT_EVENT_OBJECT.createdByEmail;
      let storeNumber = this.CURRENT_EVENT_OBJECT.storeNumber;
      let eventStartDate = new Date(this.CURRENT_EVENT_OBJECT.eventStartDate);
      let eventEndDate = new Date(this.CURRENT_EVENT_OBJECT.eventEndDate);
      let zipsResponse;
      try {
        zipsResponse = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/FETCH_ZIPS',
          this.$route.params.id
        );
      } catch {
        this.displayDialog(
          'Error',
          'This event does not have any data yet.',
          'Close',
          () => (this.dialog = false)
        );
        this.loading.Zips = false;
        return;
      }
      let excelZips = [];
      for (var zipObj of zipsResponse.data) {
        excelZips.push([zipObj.postal_code, zipObj.count]);
      }

      eventStartDate =
        eventStartDate.getMonth() +
        1 +
        '/' +
        eventStartDate.getDate() +
        '/' +
        eventStartDate.getFullYear();

      eventEndDate =
        eventEndDate.getMonth() +
        1 +
        '/' +
        eventEndDate.getDate() +
        '/' +
        eventEndDate.getFullYear();

      ExportTools.exportToCsv('event-zip-export.csv', [
        ['Event Name', eventName],
        ['DMA', dma],
        ['Event Creator', createdByEmail],
        ['Store Number', storeNumber],
        ['Event Dates', eventStartDate + '-' + eventEndDate],
        ['', ''],
        ['Zip Code', 'Qty'],
        ...excelZips
      ]);
      this.loading.Zips = false;
    },
    async exportCheckInReport() {
      this.loading.CheckIn = true;
      this.showInteractionsForm = false;
      let eventName = this.CURRENT_EVENT_OBJECT.name;
      let dma = this.CURRENT_EVENT_OBJECT.dma;
      let createdByEmail = this.CURRENT_EVENT_OBJECT.createdByEmail;
      let storeNumber = this.CURRENT_EVENT_OBJECT.storeNumber;
      let eventStartDate = new Date(this.CURRENT_EVENT_OBJECT.eventStartDate);
      let eventEndDate = new Date(this.CURRENT_EVENT_OBJECT.eventEndDate);
      let checkInsResponse;
      try {
        checkInsResponse = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/FETCH_CHECK_IN_COUNTS',
          this.$route.params.id
        );
      } catch {
        this.displayDialog(
          'Error',
          'This event does not have any data yet.',
          'Close',
          () => (this.dialog = false)
        );
        this.loading.CheckIn = false;
        return;
      }

      eventStartDate =
        eventStartDate.getMonth() +
        1 +
        '/' +
        eventStartDate.getDate() +
        '/' +
        eventStartDate.getFullYear();

      eventEndDate =
        eventEndDate.getMonth() +
        1 +
        '/' +
        eventEndDate.getDate() +
        '/' +
        eventEndDate.getFullYear();

      ExportTools.exportToCsv('event-checkins-export.csv', [
        ['Event Name', eventName],
        ['DMA', dma],
        ['Event Creator', createdByEmail],
        ['Store Number', storeNumber],
        ['Event Dates', eventStartDate + '-' + eventEndDate],
        ['', ''],
        ['Total Possible Check Ins', 'Actual Check Ins'],
        [
          checkInsResponse.data.totalPossibleCheckIns,
          checkInsResponse.data.checkIns
        ]
      ]);
      this.loading.CheckIn = false;
    },
    generateEventReport(type = 'Daily') {
      this.loading[type] = true;
      if (type !== 'Event') {
        this.showInteractionsForm = false;
      }
      this.$nextTick(async function() {
        let res;
        if (type === 'Event') {
          res = await this.$refs.eventReportGenerator.createEventReportCSV(
            this.startDate + ' ' + this.startTime,
            this.endDate + ' ' + this.endTime,
            type,
            this.CURRENT_EVENT_OBJECT.id
          );
        } else {
          res = await this.$refs.eventReportGenerator.createEventReportCSV(
            null,
            null,
            type,
            this.CURRENT_EVENT_OBJECT.id
          );
        }

        this.loading[type] = false;

        if (res === 'Success') {
          this.dialogTitle = 'Success';
          this.dialogMessage = 'Report Generation Successful.';
        } else if (res === 'No Results') {
          this.dialogTitle = 'No Results in Date Range';
          this.dialogMessage =
            'There are no events with activity in this date range.';
        } else if (res && res.errorMessage) {
          if (res.errorMessage === 'File Too Large') {
            this.dialogTitle = 'File Too Large';
            this.dialogMessage =
              'The file generated for the dates you selected is too large to download. Please limit your dates, or reach out for a custom report.';
          } else {
            this.dialogTitle = 'Error';
            this.dialogMessage = res.errorMessage;
          }
        } else {
          // unknown error but assume it's that the file is too large
          this.dialogTitle = 'File Too Large';
          this.dialogMessage =
            'The file generated for the dates you selected is too large to download. Please limit your dates, or reach out for a custom report.';
        }
        this.displayDialog(
          this.dialogTitle,
          this.dialogMessage,
          'Close',
          () => (this.dialog = false)
        );
      });
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    }
  },
  components: {
    EventReportGenerator
  },
  watch: {
    startDateMenu: function(val) {
      this.$refs.startDateField.validate();
      this.$refs.endDateField.validate();
    },
    endDateMenu: function(val) {
      this.$refs.startDateField.validate();
      this.$refs.endDateField.validate();
    }
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 35px;
  margin-top: 50px;
}

.download-buttons {
  margin-bottom: 35px;
}

.mr-2 {
  margin-bottom: 8px;
}
</style>
