<template>
  <v-container class="py-0">
    <EventReportGenerator ref="eventReportGenerator" />
    <h2>Interaction Reports</h2>
    <p>
      Select the dates you would like your report to reflect. The 'Total
      Interactions' column will always reflect the TOTAL number of interactions
      for an event. 'Interactions for Date Range' column will reflect the number
      of interactions an event received for the date range you enter below.
      Reported data will reflect Midnight, UTC for the dates selected.
      <i>Note: The Interaction Totals Report will display all events beginning
        with the start of the year. Zip Code Interaction Report will display
        data for events which had activity within the given time period. The
        allowable file size for this report is limited. If your requested date
        range produces a file too large, you will need to limit your date range
        further, or reach out for a custom report.</i>
    </p>
    <h3>Select Report Date Range</h3>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" md="6">
          <v-menu
            v-model="startDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="startDateField"
                v-model="startDate"
                label="Begin report as of Midnight, UTC:"
                :rules="[startDateRule]"
                append-icon="$Calendar"
                readonly
                v-bind="attrs"
                hide-details="auto"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              no-title
              @input="startDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="endDateField"
                v-model="endDate"
                label="End report as of Midnight, UTC:"
                :rules="[endDateRule]"
                append-icon="$Calendar"
                readonly
                v-bind="attrs"
                hide-details="auto"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              no-title
              @input="endDateMenu = false"
              :min="startDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="download-buttons" justify="center">
        <v-col cols="12" md="6" lg="4">
          <v-btn
            color="primary"
            elevation="2"
            tile
            block
            x-large
            :loading="loading"
            :disabled="!startDate || !endDate || !valid || zipLoading || translatedLoading"
            @click="generateEventReport(null)"
          >
            Interaction Totals Report
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-btn
            color="primary"
            elevation="2"
            tile
            block
            x-large
            :loading="zipLoading"
            :disabled="!startDate || !endDate || !valid || loading || translatedLoading"
            @click="generateEventReport('Zip')"
          >
            Zip Code Interaction Report
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-btn
            color="primary"
            elevation="2"
            tile
            block
            x-large
            :loading="translatedLoading"
            :disabled="!startDate || !endDate || !valid || loading || zipLoading"
            @click="generateEventReport('Translated')"
          >
            Translated Events Report
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" text class="px-6 btn__primary">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EventReportGenerator from '@/components/EventReportGenerator.vue';

export default {
  name: 'ReportsAction',
  data() {
    return {
      loading: false,
      zipLoading: false,
      translatedLoading: false,
      valid: false,
      startDateMenu: null,
      endDateMenu: null,
      startDate: null,
      endDate: null,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null
    };
  },
  methods: {
    generateEventReport(type) {
      if (!this.startDate || !this.endDate) {
        return;
      }
      switch (type) {
        case 'Zip':
          this.zipLoading = true;
          break;
        case 'Translated':
          this.translatedLoading = true;
          break;
        default:
          this.loading = true;
      }

      this.$nextTick(async function() {
        let res;
        res = await this.$refs.eventReportGenerator.createEventReportCSV(
          this.startDate + ' 00:00:00',
          this.endDate + ' 00:00:00',
          type
        );

        this.zipLoading = false;
        this.translatedLoading = false;
        this.loading = false;

        if (res === 'Success') {
          this.dialogTitle = 'Success';
          this.dialogMessage = 'Report Generation Successful.';
        } else if (res === 'No Results') {
          this.dialogTitle = 'No Results in Date Range';
          this.dialogMessage =
            'There are no events with activity in this date range.';
        } else if (res && res.errorMessage) {
          if (res.errorMessage === 'File Too Large') {
            this.dialogTitle = 'File Too Large';
            this.dialogMessage =
              'The file generated for the dates you selected is too large to download. Please limit your dates, or reach out for a custom report.';
          } else {
            this.dialogTitle = 'Error';
            this.dialogMessage = res.errorMessage;
          }
        } else {
          // unknown error but assume it's that the file is too large
          this.dialogTitle = 'File Too Large';
          this.dialogMessage =
            'The file generated for the dates you selected is too large to download. Please limit your dates, or reach out for a custom report.';
        }
        this.displayDialog(
          this.dialogTitle,
          this.dialogMessage,
          'Close',
          () => (this.dialog = false)
        );
      });
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },
    startDateRule(date) {
      if (this.startDateMenu) {
        return true;
      }
      if (!date) {
        return 'You must select a start date.';
      }
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        return 'Beginning date of report cannot be greater than ending date of report.';
      }
      return true;
    },
    endDateRule(date) {
      if (this.endDateMenu) {
        return true;
      }
      if (!date) {
        return 'You must select an end date.';
      }
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        return 'Beginning date of report cannot be greater than ending date of report.';
      }
      return true;
    }
  },
  components: {
    EventReportGenerator
  },
  watch: {
    startDateMenu: function(val) {
      this.$refs.startDateField.validate();
      this.$refs.endDateField.validate();
    },
    endDateMenu: function(val) {
      this.$refs.startDateField.validate();
      this.$refs.endDateField.validate();
    }
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 35px;
  margin-top: 50px;
}

.download-buttons {
  margin-bottom: 35px;
}
p {
  text-align: center;
}
::v-deep .v-input .mdi-alert-circle {
  display: none;
}
::v-deep .error--text .mdi-alert-circle {
  display: block;
}
</style>
