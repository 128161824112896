<template>
  <div class="checkin-form">
    <v-container v-if="CURRENT_EVENT_OBJECT.id == $route.params.id">
      <v-row justify="center">
        <v-col xs="12" sm="9" md="8">
          <div class="logo-wrapper">
            <router-link to="/dashboard">
              <img
                :src="
                  logoUrl(
                    CURRENT_EVENT_OBJECT.brand.includes('T-Mobile')
                      ? 't-mobile-logo-tight--white.png'
                      : 'metro-logo-tight--white.png'
                  )
                "
                class="brand-logo"
                :alt="
                  CURRENT_EVENT_OBJECT.brand.includes('T-Mobile')
                    ? 'T-Mobile'
                    : 'Metro by T-Mobile'
                "
              />
            </router-link>
          </div>
          <v-col
            v-if="CURRENT_EVENT_OBJECT.eventLanguage == 'bi'"
            xs="12"
            sm="12"
            md="12"
            class="language-buttons"
          >
            <v-radio-group
              v-model="selectedLanguage"
              row
              @change="forceRevalidate()"
            >
              <v-radio ref="englishLang" label="English" value="en"></v-radio>
              <v-radio ref="spanishLang" label="Español" value="es"></v-radio>
            </v-radio-group>
          </v-col>
          <div class="checkin-form__title text-center mt-6">
            <h1 class="event-title">
              {{
                selectedLanguage === 'es' && translation.event_name != ''
                  ? translation.event_name
                  : CURRENT_EVENT_OBJECT.name
              }}
            </h1>
            <h1>
              {{ selectedLanguage === 'es' ? 'Inscribirse' : 'Sign In' }}
            </h1>
            <h2>
              {{
                selectedLanguage === 'es' &&
                translation.abbreviatedPrizeDescription != ''
                  ? translation.abbreviatedPrizeDescription
                  : CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription
              }}
            </h2>
          </div>
          <v-form v-model="valid" ref="form" @submit="attemptSubmit($event)">
            <div class="checkin-form__wrap elevation-10">
              <div v-if="!showForm" class="checkin-form__buttons">
                <v-btn
                  class="btn__primary"
                  :loading="awaitingResponse"
                  block
                  x-large
                  @click="showForm = true"
                >
                  {{
                    selectedLanguage === 'es'
                      ? 'Me inscribí con anticipación'
                      : 'I Signed Up in Advance'
                  }}
                </v-btn>
                <v-btn
                  :loading="awaitingResponse"
                  outlined
                  block
                  color="primary"
                  x-large
                  :to="
                    `/engagements/register/${CURRENT_EVENT_OBJECT.id}?checkIn=true`
                  "
                >
                  <span class="black--text button-text">
                    {{
                      selectedLanguage === 'es'
                        ? 'Necesito inscribirme'
                        : 'I Need to Sign Up'
                    }}
                  </span>
                </v-btn>
              </div>
              <div v-if="showForm">
                <v-text-field
                  ref="email"
                  append-icon="mdi-asterisk"
                  :rules="emailRules"
                  v-model="engagement.email"
                  label="Email"
                ></v-text-field>
                <div class="text-center my-6">
                  <v-btn
                    class="btn__primary checkin-form__submit"
                    type="submit"
                    :disabled="!valid"
                    :loading="awaitingResponse"
                    block
                  >
                    {{ selectedLanguage == 'es' ? 'Enviar' : 'Submit' }}
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="text-center back-btn">
              <v-btn v-if="showForm" text @click="resetForm()">
                <v-icon
                  medium
                  :color="
                    CURRENT_EVENT_OBJECT.brand.includes('Metro')
                      ? '#46196E'
                      : '#E44475'
                  "
                >
                  mdi-chevron-left
                </v-icon>
                {{ selectedLanguage == 'es' ? 'Atrás' : 'Back' }}
              </v-btn>
            </div>
            <div
              v-if="!CURRENT_EVENT_OBJECT.abbreviatedLegal"
              class="checkin-form__legal"
            >
              <p class="px-2 text-center">
                <template v-if="selectedLanguage === 'es'">
                  Sin obligación de compra. Un artículo por persona hasta agotar
                  existencias y por orden de llegada.
                </template>
                <template v-else>
                  No purchase necessary. One item per person, while supplies
                  last, first come first served.
                </template>
              </p>
            </div>

            <div class="text-center checkin-form__legal">
              <p v-if="CURRENT_EVENT_OBJECT.abbreviatedLegal">
                <span class="legal">
                  {{ CURRENT_EVENT_OBJECT.abbreviatedLegal }}
                </span>
              </p>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="submitted"
      persistent
      max-width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="grey lighten-2 justify-center uppercase">{{
          dialogTitle
        }}</v-card-title>
        <v-card-text class="text-center mt-2">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="modal-button-outlined px-6"
            color="primary"
            outlined
            tile
            @click="
              submitted = false;
              resetForm(emailNotFound);
              emailNotFound = false;
            "
          >
            {{
              emailNotFound
                ? selectedLanguage === 'es'
                  ? 'Prueba otro email'
                  : 'Try another email'
                : selectedLanguage === 'es'
                ? 'Cerrar'
                : 'Close'
            }}
          </v-btn>
          <v-btn
            v-if="emailNotFound"
            class="modal-button px-6"
            color="primary"
            tile
            :to="
              `/engagements/register/${CURRENT_EVENT_OBJECT.id}?checkIn=true`
            "
          >
            {{ selectedLanguage === 'es' ? 'Inscríbete' : 'Sign Up' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      class="loading-hider"
      v-if="CURRENT_EVENT_OBJECT.id != $route.params.id"
    >
      <v-progress-circular
        v-if="errorMessage == null"
        :size="70"
        color="white"
        indeterminate
      ></v-progress-circular>
      <h1 v-if="errorMessage" class="event-error">
        {{ errorMessage }}
      </h1>
    </div>
  </div>
</template>

<script>
import ValidationRules from '@/utility/ValidationRules';
import ThemeSwitcher from '@/utility/ThemeSwitcher';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'EngagementForm',
  components: {},
  props: {
    preview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showForm: false,
      errorMessage: null,
      awaitingResponse: false,
      dialogTitle: '',
      dialogMessage: '',
      valid: false,
      submitted: false,
      emailNotFound: false,
      selectedLanguage: '',
      emailRules: [
        v =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          (this.selectedLanguage === 'es'
            ? 'Debes proporcionar una dirección de email válida'
            : 'Must provide valid email address')
      ]
    };
  },
  computed: {
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      engagement: 'engagement',
      translation: 'translations'
    }),
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    brandWatcher() {
      return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.brand
        ? this.CURRENT_EVENT_OBJECT.brand
        : 'T-Mobile';
    }
  },
  mounted() {
    if (this.selectedLanguage === '') {
      this.selectedLanguage = this.CURRENT_EVENT_OBJECT.defaultLanguage;
    }
  },
  async created() {
    await this.fetchTranslations(this.$route.params.id);
    if (this.userAttributes != null) {
      var loggedInResponse = await this.$store.dispatch(
        'EVENT_MANAGER_STORE/LOAD_EVENT',
        this.$route.params.id
      );
      if (!loggedInResponse.id) {
        this.errorMessage =
          loggedInResponse.errorMessage || loggedInResponse.message;
        return;
      }
    } else {
      var publicResponse = await this.$store.dispatch(
        'EVENT_MANAGER_STORE/LOAD_PUBLIC_EVENT',
        this.$route.params.id
      );
      if (!publicResponse.id) {
        this.errorMessage =
          publicResponse.errorMessage || publicResponse.message;
        return;
      }
    }

    if (this.selectedLanguage === '') {
      this.selectedLanguage = this.CURRENT_EVENT_OBJECT.defaultLanguage;
    }
  },
  watch: {
    brandWatcher: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.changeTheme(newVal);
        }
      }
    }
  },
  methods: {
    ...mapActions(['resetEngagement', 'fetchTranslations']),
    logoUrl(logo) {
      return require('../assets/' + logo);
    },
    forceRevalidate() {
      for (var key of Object.keys(this.engagement)) {
        if (this.$refs[key]) {
          this.$refs[key].validate();
        }
      }
    },
    nonEmptyRule(error) {
      return ValidationRules.NonEmptyRule(error);
    },
    getPolicyText(event) {
      let policyText = '';
      switch (this.selectedLanguage) {
        case 'en':
          policyText =
            'By registering you confirm you are 18 years or older and agree to our ';
          break;
        case 'es':
          policyText =
            'Al inscribirte, confirmas que tienes al menos 18 años de edad y que aceptas nuestra ';
          break;
        default:
          policyText =
            'By registering you confirm you are 18 years or older and agree to our ';
      }
      return policyText;
    },
    async attemptSubmit(e) {
      e.preventDefault();
      this.validate();
      if (!this.valid) {
        return;
      }
      try {
        var checkInResult = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/CHECK_IN',
          {
            eventId: this.CURRENT_EVENT_OBJECT.id,
            email: this.engagement.email
          }
        );
        checkInResult = JSON.parse(checkInResult);
        if (checkInResult.errorMessage) {
          this.dialogTitle =
            this.selectedLanguage === 'es' ? 'Lo sentimos.' : 'Uh oh!';
          this.dialogMessage = checkInResult.errorMessage;
          this.submitted = true;
          this.emailNotFound = true;
          this.awaitingResponse = false;
        } else {
          this.dialogTitle =
            this.selectedLanguage === 'es'
              ? '¡Está todo listo!'
              : "You're all set!";
          this.dialogMessage =
            this.selectedLanguage === 'es'
              ? 'Gracias por registrarte.'
              : 'Thanks for signing in.';
          this.submitted = true;
          this.$refs.form.resetValidation();
          this.resetEngagement();
          this.resetForm();
        }
      } catch (e) {
        console.log(e);
        this.dialogTitle =
          selectedLanguage === 'es' ? 'Lo sentimos.' : 'Uh oh!';
        // TODO:
        // if email not found then this.dialogMessage = "We didn't find that email address."
        // if email is already checked in then this.dialogMessage = 'That email address has already been checked in.'
        this.dialogMessage =
          e.response !== undefined
            ? e.response.data.errorMessage
            : selectedLanguage === 'es'
            ? 'No encontramos esa dirección de email.'
            : "We didn't find that email address.";
        this.submitted = true;
        this.emailNotFound = true;
        this.awaitingResponse = false;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    resetForm(showForm = false) {
      this.$refs.form.reset();
      this.showForm = showForm;
    },
    changeTheme(brand) {
      ThemeSwitcher.ChangeTheme(this, brand);
    }
  }
};
</script>

<style lang="scss" scoped>
.checkin-form {
  background: linear-gradient(
    to bottom,
    var(--v-primary-base) 60%,
    var(--v-registerBackground-base) 60%
  );
}
.checkin-form ::v-deep .v-text-field .v-icon.v-icon,
.checkin-form ::v-deep .v-select .v-icon.v-icon {
  color: var(--v-error-base);
  font-size: 10px;
}
.checkin-form__wrap {
  background: white;
  max-width: 600px;
  min-height: 338px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 60px;
  @media only screen and (max-width: 400px) {
    padding: 20px;
  }
}
.checkin-form__img {
  max-width: 440px;
  margin: 0 auto;
}
.checkin-form__buttons {
  width: 100%;
  max-width: 276px;
  margin: auto;
  .v-btn {
    height: 64px;
    &:first-child {
      margin-bottom: 50px;
    }
  }
}
.checkin-form__title h1,
.checkin-form__title h2 {
  font-weight: 700;
  font-size: 3.4em;
  margin: 0;
  color: white;
}

.checkin-form__title h2 {
  font-size: 1.42em;
  margin-bottom: 60px;
  text-transform: uppercase;
}

.checkin-form__title p {
  margin-bottom: 48px;
}
.checkin-form__legal {
  margin: 50px 0;
}
.checkin-form__submit {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  font-weight: bold;
}
.logo-wrapper {
  width: 100%;
  text-align: center;
  margin-top: 60px;
}
.brand-logo {
  width: 250px;
  max-width: 90%;
  margin: auto;
}

.language--error,
.legal--error {
  background-color: #fc9e03;
  padding: 5px;
}

.info-icon {
  margin-left: 5px;
  margin-right: 10px;
  margin-top: -20px;
}

.loading-hider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--v-primary-base);
}

.event-error {
  color: white;
  text-align: center;
  max-width: 90%;
  margin: auto;
}

.back-btn {
  margin: 0;
  margin-top: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.language-buttons
  ::v-deep
  .v-input
  .v-input__slot
  .v-input--radio-group__input {
  height: 62px;
  justify-content: center;
}

.language-buttons
  ::v-deep
  .v-input--radio-group.v-input--radio-group--row
  .v-radio {
  border: 1px solid;
  color: white;
  padding: 0 30px;

  .v-input--selection-controls__input {
    display: none;
  }
  label {
    color: white;
  }

  &.v-item--active {
    background: white;
    border: 1px solid var(--v-primary-base);
    label {
      color: var(--v-primary-base);
    }
  }
}
</style>
