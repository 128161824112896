<template>
  <v-container class="py-0">
    <h2>Download Your PDF</h2>
    <div class="download-buttons">
      <v-btn
        color="primary"
        elevation="0"
        dark
        tile
        :loading="englishFlyerLoading"
        @click="loadFlyer()"
      >
        Download Flyer
        <span v-if="translations.event_name && translations.event_name != ''">
          (English)
        </span>
      </v-btn>
      <v-btn
        v-if="translations.event_name && translations.event_name != ''"
        color="primary"
        elevation="0"
        dark
        tile
        :loading="spanishFlyerLoading"
        @click="loadSpanishFlyer()"
      >
        Download Flyer (Español)
      </v-btn>
      <v-btn
        color="primary"
        elevation="0"
        dark
        tile
        :loading="englishAFrameLoading"
        @click="loadAFrame()"
      >
        Download A-Frame
        <span v-if="translations.event_name && translations.event_name != ''">(English)</span>
      </v-btn>

      <v-btn
        v-if="translations.event_name && translations.event_name != ''"
        color="primary"
        elevation="0"
        dark
        tile
        :loading="spanishAFrameLoading"
        @click="loadSpanishAFrame()"
      >
        Download A-Frame (Español)
      </v-btn>
    </div>
    <PDFGenerator
      ref="generator"
      :cur-event="CURRENT_EVENT_OBJECT"
      @flyerGenerationComplete="flyerLoading = false"
      @aFrameGenerationComplete="aFrameLoading = false"
    />
  </v-container>
</template>

<script>
import PDFGenerator from '../../components/PDFGenerator.vue';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'DownloadQRAction',
  data() {
    return {
      englishFlyerLoading: false,
      englishAFrameLoading: false,
      spanishFlyerLoading: false,
      spanishAFrameLoading: false
    };
  },
  methods: {
    ...mapActions(['fetchTranslations']),
    async loadFlyer() {
      this.englishFlyerLoading = true;
      this.$nextTick(async function() {
        await this.$refs.generator.createFlyerPDF('en');
        this.englishFlyerLoading = false;
      });
    },
    async loadSpanishFlyer() {
      this.spanishFlyerLoading = true;
      this.$nextTick(async function() {
        await this.$refs.generator.createFlyerPDF('es');
        this.spanishFlyerLoading = false;
      });
    },
    async loadAFrame() {
      this.englishAFrameLoading = true;
      this.$nextTick(async function() {
        await this.$refs.generator.createAFramePDF('en');
        this.englishAFrameLoading = false;
      });
    },
    async loadSpanishAFrame() {
      this.spanishAFrameLoading = true;
      this.$nextTick(async function() {
        await this.$refs.generator.createAFramePDF('es');
        this.spanishAFrameLoading = false;
      });
    }
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    ...mapGetters(['translations'])
  },

  async created() {
    await this.fetchTranslations(this.$route.params.id);
  },
  components: {
    PDFGenerator
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 35px;
  margin-top: 50px;
}

.download-buttons {
  margin-bottom: 35px;
  button {
    margin-bottom: 5px;
    min-width: 40% !important;
  }
  button:nth-child(2n) {
    margin-left: 5px;
  }
}
</style>
