var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-data-table',{staticClass:"app-events-table ",attrs:{"headers":_vm.headers,"items":_vm.tableData,"loading":_vm.LOADING,"items-per-page":_vm.rowsPerPage,"page":_vm.C_PAGE},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.C_PAGE=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.resetFilters(_vm.filter)}}},[_vm._v(" No results found, reset filters. ")])]},proxy:true},{key:"loading",fn:function(){return [_c('v-progress-circular',{staticClass:"centered-circle",attrs:{"indeterminate":"","color":"primary"}})]},proxy:true},{key:"item.eventStartDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("humanTableTimeFormat")(item.eventStartDate,item.timezone)))])]}},{key:"item.eventEndDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("humanTableTimeFormat")(item.eventEndDate,item.timezone)))])]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(!item.organization || !item.organization.length),expression:"!item.organization || !item.organization.length"}]},[_vm._v("-")]),_vm._l((item.organization),function(o,idx){return _c('span',{key:o.id},[_vm._v(" "+_vm._s(_vm._f("humanOrganizationFilter")(o))),(idx < item.organization.length - 1)?[_vm._v(","),_c('br')]:_vm._e()],2)})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"event-name",attrs:{"to":'/translate/' + item.id}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-wrap justify-center d-flex flex-lg-nowrap"},[_c('app-button',{staticClass:"ma-1 flex-grow-1",attrs:{"to":("/translate/" + (item.id))}},[_vm._v(" "+_vm._s(_vm.filter == 'Pending' ? 'Translate' : 'View Translations')+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }