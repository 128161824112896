var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.duringAnEntryPeriod)?_c('div',{staticClass:"app-engagement-form",class:!_vm.CURRENT_EVENT_OBJECT || !_vm.CURRENT_EVENT_OBJECT.brand
      ? 'solid'
      : _vm.CURRENT_EVENT_OBJECT.brand.includes('Texture')
      ? _vm.CURRENT_EVENT_OBJECT.brand == 'T-Mobile Texture'
        ? 'tmobile__textured'
        : 'metro__textured'
      : 'solid'},[(_vm.CURRENT_EVENT_OBJECT.id == _vm.$route.params.id)?_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xs":"12","sm":"9","md":"8"}},[_c('div',{staticClass:"logo-wrapper"},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('img',{staticClass:"brand-logo",attrs:{"src":_vm.logoUrl(
                  !_vm.CURRENT_EVENT_OBJECT || !_vm.CURRENT_EVENT_OBJECT.brand
                    ? 't-mobile-logo-tight--white-v2.png'
                    : _vm.CURRENT_EVENT_OBJECT.brand.includes('T-Mobile')
                    ? 't-mobile-logo-tight--white-v2.png'
                    : 'metro-logo-tight--white.png'
                ),"alt":!_vm.CURRENT_EVENT_OBJECT || !_vm.CURRENT_EVENT_OBJECT.brand
                  ? 'T-Mobile'
                  : _vm.CURRENT_EVENT_OBJECT.brand.includes('T-Mobile')
                  ? 'T-Mobile'
                  : 'Metro by T-Mobile'}})])],1),_c('div',{staticClass:"mt-6 text-center app-engagement-form__title"},[_c('h1',{staticClass:"event-title"},[_vm._v(" "+_vm._s(_vm.engagement.language === 'es' ? _vm.translation.event_name && _vm.translation.event_name != '' ? _vm.translation.event_name : _vm.CURRENT_EVENT_OBJECT.name : _vm.CURRENT_EVENT_OBJECT.name)+" ")])]),_c('v-form',{ref:"form",on:{"submit":function($event){return _vm.attemptSubmit($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"py-12 app-engagement-form__wrap px-15 elevation-10"},[_c('div',{staticClass:"app-engagement-form__label"},[_c('span',{class:_vm.preview && _vm.CURRENT_EVENT_OBJECT.defaultLanguage == null
                    ? 'language--error'
                    : ''},[_vm._v(_vm._s(_vm.engagement.language === 'es' ? 'Idioma de preferencia' : 'Preferred Language')+" ")]),(_vm.preview && _vm.CURRENT_EVENT_OBJECT.defaultLanguage == null)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"info-icon",attrs:{"small":"","color":"#fc9e03"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,false,727984029)},[_c('span',[_vm._v("This field cannot be left blank.")])]):_vm._e()],1),_c('v-radio-group',{attrs:{"row":""},on:{"change":function($event){return _vm.forceRevalidate()}},model:{value:(_vm.engagement.language),callback:function ($$v) {_vm.$set(_vm.engagement, "language", $$v)},expression:"engagement.language"}},[_c('v-radio',{ref:"englishLang",attrs:{"label":"English","value":"en"}}),_c('v-radio',{ref:"spanishLang",attrs:{"label":"Español","value":"es"}})],1),_c('v-text-field',{ref:"firstName",attrs:{"append-icon":"mdi-asterisk","rules":[
                function (v) { return !!v ||
                  (_vm.engagement.language === 'es'
                    ? 'El nombre es requisito'
                    : 'First name is required'); }
              ],"label":_vm.engagement.language === 'es' ? 'Nombre' : 'First Name'},model:{value:(_vm.engagement.firstName),callback:function ($$v) {_vm.$set(_vm.engagement, "firstName", $$v)},expression:"engagement.firstName"}}),_c('v-text-field',{ref:"lastName",attrs:{"append-icon":"mdi-asterisk","rules":[
                function (v) { return !!v ||
                  (_vm.engagement.language === 'es'
                    ? 'El apellido es requisito'
                    : 'Last name is required'); }
              ],"label":_vm.engagement.language === 'es' ? 'Apellido' : 'Last Name'},model:{value:(_vm.engagement.lastName),callback:function ($$v) {_vm.$set(_vm.engagement, "lastName", $$v)},expression:"engagement.lastName"}}),_c('v-text-field',{ref:"email",attrs:{"type":"email","append-icon":"mdi-asterisk","rules":_vm.emailRules,"label":"Email"},model:{value:(_vm.engagement.email),callback:function ($$v) {_vm.$set(_vm.engagement, "email", $$v)},expression:"engagement.email"}}),(_vm.CURRENT_EVENT_OBJECT.attendeeFormBusinessName)?_c('v-text-field',{ref:"businessName",attrs:{"append-icon":"mdi-asterisk","rules":[
                function (v) { return !!v ||
                  (_vm.engagement.language === 'es'
                    ? 'Business name is required'
                    : 'Business name is required'); }
              ],"label":_vm.engagement.language === 'es'
                  ? 'Business Name'
                  : 'Business Name'},model:{value:(_vm.engagement.businessName),callback:function ($$v) {_vm.$set(_vm.engagement, "businessName", $$v)},expression:"engagement.businessName"}}):_vm._e(),_c('v-text-field',{ref:"phone",attrs:{"type":"tel","append-icon":"mdi-asterisk","rules":[
                function (v) { return !!v ||
                  (_vm.engagement.language === 'es'
                    ? 'El número de teléfono es requisito'
                    : 'Phone is required'); },
                function (v) { return /^[\d\(\)\-\s]+$/.test(v) ||
                  (_vm.engagement.language === 'es'
                    ? 'El número de teléfono solo puede estar compuesto por números, guiones y paréntesis'
                    : 'Phone can only be composed of numbers, hyphens, and parentheses'); }
              ],"label":_vm.engagement.language === 'es'
                  ? 'Teléfono celular'
                  : 'Mobile Phone'},model:{value:(_vm.engagement.phone),callback:function ($$v) {_vm.$set(_vm.engagement, "phone", $$v)},expression:"engagement.phone"}}),_c('v-text-field',{ref:"addressLine1",attrs:{"append-icon":"mdi-asterisk","rules":[
                function (v) { return !!v ||
                  (_vm.engagement.language === 'es'
                    ? 'Dirección Línea 1 es necesaria'
                    : 'Address Line 1 is required.'); }
              ],"label":_vm.engagement.language === 'es'
                  ? 'Dirección Línea 1'
                  : 'Address line 1'},model:{value:(_vm.engagement.addressLine1),callback:function ($$v) {_vm.$set(_vm.engagement, "addressLine1", $$v)},expression:"engagement.addressLine1"}}),_c('v-text-field',{ref:"addressLine2",attrs:{"label":_vm.engagement.language === 'es'
                  ? 'Dirección Línea 2'
                  : 'Address line 2'},model:{value:(_vm.engagement.addressLine2),callback:function ($$v) {_vm.$set(_vm.engagement, "addressLine2", $$v)},expression:"engagement.addressLine2"}}),_c('v-text-field',{ref:"city",attrs:{"label":_vm.engagement.language === 'es' ? 'Ciudad' : 'City'},model:{value:(_vm.engagement.city),callback:function ($$v) {_vm.$set(_vm.engagement, "city", $$v)},expression:"engagement.city"}}),_c('v-select',{ref:"state",attrs:{"rules":[_vm.stateRule()],"items":_vm.states,"label":_vm.engagement.language === 'es' ? 'Estado' : 'State',"append-icon":_vm.CURRENT_EVENT_OBJECT.contestStatesRequired
                  ? 'mdi-asterisk'
                  : ''},model:{value:(_vm.engagement.state),callback:function ($$v) {_vm.$set(_vm.engagement, "state", $$v)},expression:"engagement.state"}}),_c('v-text-field',{ref:"zip",attrs:{"append-icon":"mdi-asterisk","rules":[
                function (v) { return !!v ||
                  (_vm.engagement.language === 'es'
                    ? 'El código postal es requisito'
                    : 'Zip code is required'); },
                function (v) { return v == null ||
                  v.length <= 9 ||
                  (_vm.engagement.language === 'es'
                    ? 'El código postal no puede tener más de 9 caracteres'
                    : 'Zip code cannot have more than 9 characters'); },
                function (v) { return /^[\d\-]+$/.test(v) ||
                  (_vm.engagement.language === 'es'
                    ? 'El código postal solo puede estar compuesto por números, guiones y paréntesis'
                    : 'Zip code can only be composed of numbers, hyphens, and parentheses'); }
              ],"label":_vm.engagement.language === 'es' ? 'Código postal' : 'Zip Code'},model:{value:(_vm.engagement.zip),callback:function ($$v) {_vm.$set(_vm.engagement, "zip", $$v)},expression:"engagement.zip"}}),_c('v-select',{ref:"currentProvider",attrs:{"append-icon":"mdi-asterisk","rules":[
                function (v) { return !!v ||
                  (_vm.engagement.language === 'es'
                    ? 'El servicio móvil es requisito'
                    : 'Wireless provider is required'); }
              ],"items":_vm.WirelessProviders,"label":_vm.engagement.language === 'es'
                  ? 'Compañía actual de servicio móvil'
                  : 'Current Wireless Provider'},model:{value:(_vm.engagement.currentProvider),callback:function ($$v) {_vm.$set(_vm.engagement, "currentProvider", $$v)},expression:"engagement.currentProvider"}}),_c('v-checkbox',{ref:"contactAllowed",attrs:{"label":_vm.engagement.language === 'es' ? 'Al aceptar, [1] estás aceptando las Reglas Oficiales y [2] que podemos informarte sobre futuras ofertas.  No te preocupes, puedes cancelar tu suscripción en cualquier momento.' : 'By accepting, [1] you’re agreeing to the Official Rules and [2] that we can tell you about future offers.  Don’t worry, you can opt out at any time.',"rules":[
                _vm.nonEmptyRule(
                  _vm.engagement.language === 'es'
                    ? 'Debes marcar esta casilla para continuar.'
                    : 'You must check this box to proceed.'
                  ),
                  function (v) { return v == true ||
                    (_vm.engagement.language === 'es'
                      ? 'Debes marcar esta casilla para continuar.'
                      : 'You must check this box to proceed.'); } ]},model:{value:(_vm.engagement.contactAllowed),callback:function ($$v) {_vm.$set(_vm.engagement, "contactAllowed", $$v)},expression:"engagement.contactAllowed"}}),_c('p',[_vm._v(" "+_vm._s(_vm.getPolicyText(_vm.CURRENT_EVENT_OBJECT))+" "),_c('a',{attrs:{"href":_vm.engagement.language === 'es'
                    ? 'https://es.t-mobile.com/privacy-center/our-practices/privacy-policy'
                    : 'https://www.t-mobile.com/privacy-center/our-practices/privacy-policy',"target":"_blank"}},[_vm._v(_vm._s(_vm.engagement.language === 'es' ? 'Política de Privacidad.' : 'Privacy Notice.')+" ")])])],1),_c('div',{staticClass:"my-6 text-center"},[(_vm.userAttributes == null)?_c('GoogleRecaptcha',{ref:"recaptcha",attrs:{"on-verify":_vm.captchaOnVerify,"on-expired":_vm.captchaOnExpired}}):_vm._e(),(!_vm.preview)?_c('v-btn',{staticClass:"btn__primary app-engagement-form__submit",attrs:{"type":"submit","disabled":!_vm.valid || (_vm.userAttributes == null && _vm.captchaToken == null),"loading":_vm.engagementPostOut,"block":""}},[_vm._v(" "+_vm._s(_vm.engagement.language === 'es' ? 'Enviar' : 'Complete')+" ")]):_vm._e(),(_vm.preview)?_c('v-btn',{staticClass:"btn__primary app-engagement-form__submit",attrs:{"type":"submit","disabled":!_vm.valid || (_vm.userAttributes == null && _vm.captchaToken == null),"loading":_vm.engagementPostOut,"block":""}},[_vm._v(" "+_vm._s(_vm.engagement.language === 'es' ? 'No se puede enviar: solo la vista previa del evento' : 'Cannot Submit: Event Preview Only')+" ")]):_vm._e()],1),(!_vm.CURRENT_EVENT_OBJECT.abbreviatedLegal)?_c('div',{staticClass:"app-engagement-form__legal"},[_c('p',{staticClass:"px-2 text-center",domProps:{"innerHTML":_vm._s(
                _vm.helperFunctions.OutputLegal(
                  _vm.CURRENT_EVENT_OBJECT,
                  _vm.engagement.language
                )
              )}})]):_vm._e(),_c('div',{staticClass:"text-center"},[(_vm.CURRENT_EVENT_OBJECT.abbreviatedLegal)?_c('p',[(
                  _vm.preview &&
                    (_vm.CURRENT_EVENT_OBJECT.abbreviatedLegal == null ||
                      _vm.CURRENT_EVENT_OBJECT.abbreviatedLegal == '')
                )?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"info-icon",staticStyle:{"visibility":"hidden"},attrs:{"small":"","color":"#fc9e03"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,false,2768037684)},[_c('span',[_vm._v("This field cannot be left blank.")])]):_vm._e(),_c('span',{class:_vm.preview &&
                  (_vm.CURRENT_EVENT_OBJECT.abbreviatedLegal == null ||
                    _vm.CURRENT_EVENT_OBJECT.abbreviatedLegal == '')
                    ? 'legal--error'
                    : 'legal',domProps:{"innerHTML":_vm._s(
                  _vm.helperFunctions.OutputLegal(
                    _vm.CURRENT_EVENT_OBJECT,
                    _vm.engagement.language
                  )
                )}}),(
                  _vm.preview &&
                    (_vm.CURRENT_EVENT_OBJECT.abbreviatedLegal == null ||
                      _vm.CURRENT_EVENT_OBJECT.abbreviatedLegal == '')
                )?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"info-icon",attrs:{"small":"","color":"#fc9e03"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,false,727984029)},[_c('span',[_vm._v("You have indicated that custom legal is required, but have not supplied any.")])]):_vm._e()],1):_vm._e()])])],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"500","content-class":"app-modal-dialog"},model:{value:(_vm.submitted),callback:function ($$v) {_vm.submitted=$$v},expression:"submitted"}},[_c('v-card',{staticClass:"pa-8"},[_c('v-card-title',{staticClass:"justify-center text-center grey lighten-2 dialogResponseBox",domProps:{"innerHTML":_vm._s(_vm.dialogTitle)}}),_c('v-card-text',{staticClass:"mt-2 text-center"},[_vm._v(" "+_vm._s(_vm.dialogMessage)+" ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center pt-8"},[_c('v-btn',{staticClass:"px-6 modal-button-outlined",attrs:{"color":"primary","outlined":"","tile":""},on:{"click":function($event){_vm.submitted = false;
            if (_vm.isCheckIn) {
              _vm.returnToCheckInForm();
            }}}},[_vm._v("Dismiss ")])],1)],1)],1),(_vm.CURRENT_EVENT_OBJECT.id != _vm.$route.params.id)?_c('div',{staticClass:"loading-hider"},[(_vm.errorMessage == null)?_c('v-progress-circular',{attrs:{"size":70,"color":"white","indeterminate":""}}):_vm._e(),(_vm.errorMessage)?_c('h1',{staticClass:"event-error"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1):_vm._e()],1):_c('div',{staticClass:"no-entries-allowed"},[_c('div',{staticClass:"mt-6 text-center app-engagement-form__title"},[(_vm.engagement.language == 'es')?_c('h1',{staticClass:"event-title"},[_vm._v(" La participación está cerrada ahora, vuelve más tarde. ")]):_c('h1',{staticClass:"event-title"},[_vm._v(" Entries are not currently allowed at this time, please check back. ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }