<template>
  <div class="app-dashboard">
    <BannerTitle
      :title="`Welcome!`"
      description=""
      :loading="given_name === ''"
    />
    <EventActionOverlay
      :cur-action="curAction"
      @changeAction="curAction = $event"
    />
    <section class="py-12">
      <v-container :fluid="!this.lgBreakpoint">
        <v-col
          cols="12"
          md="6"
          lg="2"
          v-if="
            loaded &&
              !staffEvent.archived &&
              (staffEvent.contestType == 'Enter for a Chance to Win' ||
                staffEvent.contestType == 'Instant Win')
          "
        >
          <v-btn
            block
            class="btn__primary"
            :class="{ 'action-active': curAction == 'engagementForm' }"
            :loading="engagementForm"
            elevation="0"
            primary
            tile
            @click="
              staffAction(
                'engagementForm',
                goTo,
                'engagements/register/' + $route.params.id
              )
            "
          >
            Launch Data Capture Form
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="2"
          v-if="
            staffEvent.contestType == 'Sign Up/Check In' &&
              loaded &&
              !staffEvent.archived
          "
        >
          <v-btn
            block
            class="btn__primary"
            :class="{ 'action-active': curAction == 'displayQR' }"
            :loading="checkIn"
            elevation="0"
            dark
            tile
            @click="staffAction('checkIn', goTo, 'checkin/' + $route.params.id)"
          >
            Check In
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="2"
          v-if="
            loaded &&
              !staffEvent.archived &&
              staffEvent.contestType == 'Enter for a Chance to Win'
          "
        >
          <v-btn
            block
            class="btn__primary"
            :class="{ 'action-active': curAction == 'displayQR' }"
            :loading="viewRules"
            elevation="0"
            dark
            tile
            @click="
              staffAction('viewRules', goTo, 'event/rules/' + $route.params.id)
            "
          >
            View Event Rules
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="2"
          v-if="loaded && displayQRCodeButton(staffEvent)"
        >
          <v-btn
            block
            class="btn__primary"
            :class="{ 'action-active': curAction == 'displayQR' }"
            :loading="webQR"
            elevation="0"
            dark
            tile
            @click="
              staffAction(
                'webQR',
                goTo,
                staffEvent.published
                  ? 'webqr/' + $route.params.id
                  : 'webqr/preview/' + $route.params.id
              )
            "
          >
            {{ staffEvent.published ? 'Display' : 'Preview' }} Web QR Code
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="2"
          v-if="loaded && displayQRCodeButton(staffEvent)"
        >
          <v-btn
            block
            class="btn__primary"
            :class="{ 'action-active': curAction == 'EnglishPDF' }"
            :loading="englishPDF"
            elevation="0"
            dark
            tile
            @click="staffAction('englishPDF', loadFlyer, 'en')"
          >
            {{ staffEvent.eventLanguage == 'bi' ? 'English' : '' }} PDF QR Code
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="2"
          v-if="
            loaded &&
              displayQRCodeButton(staffEvent) &&
              staffEvent.eventLanguage == 'bi'
          "
        >
          <v-btn
            block
            class="btn__primary"
            :class="{ 'action-active': curAction == 'SpanisgPDF' }"
            :loading="spanishPDF"
            elevation="0"
            dark
            tile
            @click="staffAction('spanishPDF', loadFlyer, 'es')"
          >
            Spanish PDF QR Code
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="2"
          v-if="loaded && displayQRCodeButton(staffEvent)"
        >
          <v-btn
            block
            class="btn__primary"
            :class="{ 'action-active': curAction == 'EnglishAFrame' }"
            :loading="englishAFrame"
            elevation="0"
            dark
            tile
            @click="staffAction('englishAFrame', loadAFrame, 'en')"
          >
            {{ staffEvent.eventLanguage == 'bi' ? 'English' : '' }} AFrame QR
            Code
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="2"
          v-if="
            loaded &&
              displayQRCodeButton(staffEvent) &&
              staffEvent.eventLanguage == 'bi'
          "
        >
          <v-btn
            block
            class="btn__primary"
            :class="{ 'action-active': curAction == 'SpanishAFrame' }"
            :loading="spanishAFrame"
            elevation="0"
            dark
            tile
            @click="staffAction('spanishAFrame', loadAFrame, 'es')"
          >
            Spanish AFrame QR Code
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="2"
          v-if="loaded && staffEvent.contestType == 'Enter for a Chance to Win'"
        >
          <v-btn
            block
            class="btn__primary"
            :class="{ 'action-active': curAction == 'viewWinner' }"
            :loading="viewWinner"
            elevation="0"
            dark
            tile
            @click="staffAction('viewWinner', changeAction, 'staffViewWinner')"
          >
            View Winner
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" lg="2" v-if="loaded">
          <v-btn
            block
            elevation="0"
            tile
            class="btn__white"
            @click="$router.push('/eventstaffdashboard')"
            v-if="loaded"
          >
            Back to Event Code Entry
          </v-btn>
        </v-col>
      </v-container>
    </section>
    <v-dialog v-model="showDialog" width="500" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Event Not Found
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          The code you entered does not match any events in the system. Please
          check that your code is accurate and try again. If you continue having
          issues, please reach out to your agency or T-Mobile contact.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="btn_secondary"
            outlined
            tile
            @click="$router.push('/eventstaffdashboard')"
          >
            Exit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            @click="$router.push('/eventstaffdashboard')"
            text
            class="px-6 btn__primary"
          >
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PDFGenerator
      v-if="loaded"
      ref="generator"
      :cur-event="staffEvent"
      @flyerGenerationComplete="flyerLoading = false"
      @aFrameGenerationComplete="aFrameLoading = false"
    />
    <Footer />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import ThemeSwitcher from '@/utility/ThemeSwitcher';
import BannerTitle from '@/components/BannerTitle.vue';
import PDFGenerator from '@/components/PDFGenerator.vue';
import EventActionOverlay from '@/components/EventForm/EventActionOverlay.vue';

import ViewWinnersAction from '@/components/EventForm/ViewWinnersAction.vue';

export default {
  name: 'StaffActions',
  async mounted() {
    await this.fetchEventData();
    this.given_name = this.userAttributes.given_name;
    this.family_name = this.userAttributes.family_name;
  },
  data() {
    return {
      given_name: '',
      family_name: '',
      loaded: false,
      engagementForm: false,
      webQR: false,
      checkIn: false,
      viewRules: false,
      viewWinner: false,
      englishPDF: false,
      spanishPDF: false,
      englishAFrame: false,
      spanishAFrame: false,
      curAction: '',
      showDialog: false,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null
    };
  },
  computed: {
    ...mapGetters({
      staffEvent: 'staffEvent',
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`
    }),
    lgBreakpoint: function() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    brandWatcher() {
      return this.staffEvent && this.staffEvent.brand
        ? this.staffEvent.brand
        : 'T-Mobile';
    }
  },
  watch: {
    $route: 'fetchEventData',
    brandWatcher: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.changeTheme(newVal);
        }
      }
    }
  },
  methods: {
    ...mapActions(['staffFetchEvent', 'staffRefresh']),
    ...mapMutations('UI_STORE', ['TOGGLE_EXPANSION_PANEL'], 'staffSetEvent'),
    async fetchEventData() {
      try {
        this.curAction = null;
        this.loaded = false;
        let response = await this.staffFetchEvent(this.$route.params.id);
        if (response.errorMessage == 'Could not validate') {
          this.displayDialog(
            'Event Not Found',
            `The code you entered does not match any events in the system.
             Please check that your code is accurate and try again. If you
             continue having issues, please reach out to your agency or
             T-Mobile contact.`,
            'Close',
            () => (this.dialog = false)
          );
          return;
        } else if (response.errorMessage) {
          this.displayDialog(
            'Event Not Active',
            `The code you entered belongs to an event that is not active.
              Please check that your code is accurate and try again.
              If you continue having issues, please reach out to your agency
              or T-Mobile contact.`,
            'OK'
          );
        }
        this.loaded = true;
      } catch (e) {
        console.log(e);
      }
    },
    goTo(routerName) {
      let routeData = this.$router.resolve({ name: routerName });
      window.open(routeData.href + routeData.location.name, '_blank');
    },

    changeAction(actionName) {
      this.curAction = actionName;
      this.$emit('changeAction', actionName);
    },

    changeActionPanel(actionName) {
      this.TOGGLE_EXPANSION_PANEL('hide');
      this.$emit('changeAction', actionName);
    },

    async staffAction(actionName, action, params) {
      this[actionName] = true;
      let validate = await this.staffRefresh(this.$route.params.id);
      if (validate) {
        action(params);
      } else {
        this.showDialog = true;
        this[actionName] = false;
        return;
      }
      this[actionName] = false;
    },
    displayQRCodeButton: event => {
      // if today's date is before the event start date or after the event end date, show the preview button
      if (event.experience && event.experience === 'Gametime') {
        return false;
      } else {
        return true;
      }
    },
    getEventStatus() {
      if (this.staffEvent.status !== null) {
        return this.staffEvent.status;
      } else {
        return this.staffEvent.published == 1 ? 'Published' : 'Draft';
      }
    },
    async loadFlyer(language) {
      language == 'en'
        ? (this.englishFlyerLoading = true)
        : (this.spanishFlyerLoading = true);
      this.$nextTick(async function() {
        await this.$refs.generator.createFlyerPDF(language);
      });
    },
    async loadAFrame(language) {
      language == 'en'
        ? (this.englishAFrameLoading = true)
        : (this.spanishAFrameLoading = true);
      this.$nextTick(async function() {
        await this.$refs.generator.createAFramePDF(language);
      });
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },
    changeTheme(brand) {
      ThemeSwitcher.ChangeTheme(this, brand);
    }
  },
  components: {
    BannerTitle,
    PDFGenerator,
    EventActionOverlay
  }
};
</script>

<style scoped>
.container--fluid {
  /* max-width: 1100px; */
}
.app-dashboard__filters ::v-deep .v-label {
  color: #222;
}
.app-recent-events__titlewrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.app-recent-events__titlewrap a {
  text-decoration: none;
  color: black;
}

.app-recent-events__titlewrap h2 {
  font-size: 36px;
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.btn__primary,
.btn__white {
  display: block;
  width: fit-content;
  margin: 15px;
}

.btn__border {
  background: white !important;
  color: var(--v-primary-base);
  border: 1px solid var(--v-primary-base);
  box-shadow: none;
  border-radius: 0;
}

::v-deep .col-md-6 {
  margin: auto;
}

.back {
  background: white !important;
  border: 1px solid var(--v-primary-base);
}
</style>
