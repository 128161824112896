import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueCurrencyInput from 'vue-currency-input';
import { DateTime } from 'luxon';
import OptionData from '@/utility/OptionData';
Vue.use(VueCurrencyInput);

Vue.config.productionTip = false;

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);

Vue.prototype.$OptionData = OptionData;
//Example returned date format xx/xx/xxxx
/**
 *  humanTimeFormat - human readable time format
 * @param value = String
 * @param timezone = String
 * @returns {string} = xx/xx/xxxx
 **/
Vue.filter('humanTimeFormat', (value, timezone) => {
  if (!value) return '';
  let targetTimezone = !timezone ? 'America/New_York' : timezone;
  value = DateTime.fromISO(value);
  const retvalue = `${value.month}/${value.day}/${value.year}`;
  return retvalue;
});

/**
 *  humanTableTimeFormat - human readable time format
 * @param value = String
 * @param timezone = String
 * @returns {string} = xx/xx/xxxx
 **/
Vue.filter('humanTableTimeFormat', (value, timezone) => {
  if (!value) return '';
  let targetTimezone = !timezone ? 'America/New_York' : timezone;
  value = DateTime.fromISO(value, {
    zone: targetTimezone
  });
  const retvalue = `${value.month}/${value.day}/${value.year}`;
  return retvalue;
});

/**
 *  humanDateTimeCombo - human readable time format combo
 * @param value = String
 * @param timezone = String
 * @returns {string} = x/xx/xxxx, x:xx:xx xx
 **/
Vue.filter('humanDateTimeCombo', (value, timezone) => {
  if (!value) return '';
  let targetTimezone = timezone || 'America/New_York';
  if (typeof value === 'string') {
    value = new Date(value);
  }
  return value.toLocaleString(DateTime.DATETIME_MED, {
    timeZone: targetTimezone
  });
});

Vue.filter('humanDateTimeAsSelected', value => {
  if (!value) return '';
  if (typeof value === 'string') {
    value = new Date(value);
  }
  return value.toLocaleString(DateTime.DATETIME_MED);
});

/**
 * utcTimeFormatter - standardized time formatter
 * @param value = String or Date object (UTC)
 * @param expectedFormat = String (optional)
 * @param timezone = String (optional) default is America/New_York
 * @example in template brackets utcTimeFormatter('2020-01-01T00:00:00.000Z', 'MM/dd/yyyy')
 * {{ thedate | utcTimeFormatter('short', 'timezonestring') }}
 * @example in methods formattedUserCreationDate =
 *        this.$options.filters.utcTimeFormatter(
 *          singleUser.UserCreateDate
         );
 * @returns {string} = xx/xx/xxxx, x:xx:xx xx
 */
Vue.filter(
  'utcTimeFormatter',
  (rawDate, expectedFormat = 'short', timezone = 'America/New_York') => {
    const timeFormats = {
      short: 'MM/dd/yyyy',
      long: 'MM/dd/yyyy hh:mm:ss a'
    };
    let timeFormat = timeFormats[expectedFormat];
    if (!timeFormat) {
      throw new Error('TimeFormat is not defined');
    }
    if (!rawDate) return '';
    if (typeof rawDate === 'string') {
      rawDate = new Date(rawDate);
    }

    rawDate = DateTime.fromJSDate(rawDate, {
      zone: timezone
    }).toFormat(timeFormat);

    return rawDate;
  }
);

/**
 * UTCtoAMPM - Converts UTC to 12 hr format
 * @param value = String - XX:XX:XX
 * @returns {string} = XX:XX AM/PM
 **/
Vue.filter('UTCtoAMPM', value => {
  if (!value) return null;

  return DateTime.fromISO(value).toFormat('hh:mm a');
});

Vue.filter('humanOrganizationFilter', value => {
  if (!value) return null;

  switch (value) {
    case 'Event_Metro_Stores':
      return 'Metro Stores';
    case 'Event_T_Mobile_Stores':
      return 'T-Mobile Stores';
    case 'Event_Sponsorships':
      return 'Sponsorships';
    case 'Event_Community_Engagements_Trucks':
      return 'Community Engagement (Trucks)';
    case 'Event_Local_Marketing':
      return 'Local Marketing';
    case 'Event_TFB_IL':
      return 'TFB - IL';
    case 'Event_STR_Marketing_Development':
      return 'STR Marketing Development';
    case 'Event_Signature_Stores':
      return 'Signature Stores';
    case 'Event_Experience_Stores':
      return 'Experience Stores';
    case 'Event_Other':
      return 'Other';
    default:
      return `Other: ${value}`;
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
