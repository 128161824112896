import { API, Auth } from 'aws-amplify';
// initial state
const state = {
  TABLE_DATA: [],
  IS_PROXY: false,
  LOADING: false,
  ERROR: false,
  HAS_ERROR: false,
  ERROR_MESSAGE: null,
  NEW_PROXY_EMAIL: null,
  POPUP_TITLE: null,
  POPUP_DESCRIPTION: null,
  SHOW_POPUP: false
};

const getters = {
  PROXY_LIMIT_REACHED: state => state.TABLE_DATA.length >= 3
};

const actions = {
  async GET_PROXIES({ commit, state }, event_id) {
    try {
      commit('SET_ERROR_STATE', false);
      if (state.LOADING) return;
      commit('SET_LOADING', true);
      const apiName = 'TMOAmplifyApi';
      const path = `/proxy/${event_id}`;
      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      };
      const response = await API.get(apiName, path, myInit);

      commit('SET_TABLE_DATA', {
        data: response.data || []
      });
    } catch (e) {
      commit('SET_ERROR_STATE', true);
      if (e && e.response && e.response.data) {
        commit('SET_RESPONSE_MESSAGE', {
          message: e.response.data.message || 'Internal Error',
          helpText:
            e.response.data.helpText ||
            'Couldnt perform that action at this time.'
        });
      } else {
        commit('SET_RESPONSE_MESSAGE', {
          message: 'Internal Error',
          helpText: 'Couldnt perform that action at this time.'
        });
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async ADD_PROXY({ commit, state, dispatch }, event_id) {
    let response;
    try {
      commit('SET_ERROR_STATE', false);
      if (!state.NEW_PROXY_EMAIL) {
        commit('SET_ERROR_STATE', true);
        return commit(
          'SET_ERROR_MESSAGE',
          'An email is required to add a proxy.'
        );
      }

      if (state.LOADING) return;
      commit('SET_LOADING', true);
      const apiName = 'TMOAmplifyApi';
      const path = `/proxy/${event_id}/add`;
      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          email: state.NEW_PROXY_EMAIL
        }
      };
      response = await API.post(apiName, path, myInit);
      commit('SET_LOADING', false);
      commit('SET_RESPONSE_MESSAGE', {
        ...response.data
      });
    } catch (e) {
      commit('SET_ERROR_STATE', true);
      if (e && e.response && e.response.data) {
        commit('SET_RESPONSE_MESSAGE', {
          message: e.response.data.message || 'Internal Error',
          helpText:
            e.response.data.helpText ||
            'Couldnt perform that action at this time.'
        });
      } else {
        commit('SET_RESPONSE_MESSAGE', {
          message: 'Internal Error',
          helpText: 'Couldnt perform that action at this time.'
        });
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async REMOVE_PROXY({ commit, state, dispatch }, payload) {
    try {
      commit('SET_ERROR_STATE', false);
      let { event_id, remove_email } = payload;
      if (!remove_email) {
        commit('SET_ERROR_STATE', true);
        return commit('SET_RESPONSE_MESSAGE', {
          message: 'Validation Error',
          helpText: 'Please refresh your page and select that proxy again.'
        });
      }

      if (state.LOADING) return;
      commit('SET_LOADING', true);
      const apiName = 'TMOAmplifyApi';
      const path = `/proxy/${event_id}/remove`;
      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          email: remove_email
        }
      };
      const response = await API.post(apiName, path, myInit);
      //If response.data.
      commit('SET_RESPONSE_MESSAGE', {
        ...response.data
      });
      commit('SET_LOADING', false);
    } catch (e) {
      commit('SET_ERROR_STATE', true);
      if (e && e.response && e.response.data) {
        commit('SET_RESPONSE_MESSAGE', {
          message: e.response.data.message || 'Internal Error',
          helpText:
            e.response.data.helpText ||
            'Couldnt perform that action at this time.'
        });
      } else {
        commit('SET_RESPONSE_MESSAGE', {
          message: 'Internal Error',
          helpText: 'Couldnt perform that action at this time.'
        });
      }
    } finally {
      commit('SET_LOADING', false);
    }
  }
};
// // mutations
const mutations = {
  SET_LOADING: (state, payload) => {
    state.LOADING = payload || false;
  },
  SET_ERROR_STATE: (state, payload) => {
    state.HAS_ERROR = payload || false;
    state.ERROR = payload || false;
  },
  SET_ERROR_MESSAGE: (state, payload) => {
    state.LOADING = false;
    state.ERROR = true;
    state.ERROR_MESSAGE = payload || null;
  },
  SET_RESPONSE_MESSAGE: (state, payload) => {
    let {
      message = 'Internal Error',
      helpText = 'Couldnt perform that action at this time.'
    } = payload;
    state.LOADING = false;
    state.NEW_PROXY_EMAIL = null;
    state.POPUP_TITLE = message;
    state.POPUP_DESCRIPTION = helpText;
    state.SHOW_POPUP = true;
  },
  RESET_ALL: state => {
    state.LOADING = false;
    state.TABLE_DATA = [];
    state.ERROR = false;
    state.ERROR_MESSAGE = null;
    state.NEW_PROXY_EMAIL = null;
    state.POPUP_TITLE = null;
    state.POPUP_DESCRIPTION = null;
    state.SHOW_POPUP = false;
  },
  RESET_POPUP: (state, payload) => {
    state.POPUP_TITLE = null;
    state.POPUP_DESCRIPTION = null;
    state.SHOW_POPUP = false;
  },
  SET_PROXY_EMAIL: (state, payload) => {
    state.NEW_PROXY_EMAIL = payload || null;
  },
  SET_TABLE_DATA: (state, payload) => {
    state.TABLE_DATA = payload.data;
    state.LOADING = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
