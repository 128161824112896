<template>
  <v-form @submit.prevent="APPLY_TEXT_FILTER">
    <v-row
      dense
      no-gutters
      justify="space-between"
      align="center"
      class="events-search-form pa-2"
    >
      <v-col cols="12" sm="8" md="9">
        <v-text-field
          type="text"
          color="black"
          name="search"
          label="Search"
          dense
          hide-details
          class="d-flex align-center justify-sm-space-between"
          flat
          solo
          v-model="CURRENT_SEARCH_TEXT"
        >
          <v-icon slot="prepend" x-large color="#000">
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <app-button
          class="flex-grow-2 btn__primary events-search-form__submit px-12"
          type="submit"
          block
        >
          Search
        </app-button>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import AppButton from '../AppButton.vue';

export default {
  name: 'SearchForm',
  components: {
    AppButton
  },
  methods: {
    ...mapActions('EVENT_TABLE_STORE', ['APPLY_FILTER']),
    ...mapMutations('EVENT_TABLE_STORE', ['UPDATE_CURRENT_SEARCH_TEXT']),
    APPLY_TEXT_FILTER() {
      this.APPLY_FILTER({
        filter_key: 'name',
        filter_value: this.CURRENT_SEARCH_TEXT
      });
    }
  },
  computed: {
    CURRENT_SEARCH_TEXT: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.CURRENT_SEARCH_TEXT;
      },
      set(value) {
        this.UPDATE_CURRENT_SEARCH_TEXT(value);
      }
    }
  }
};
</script>

<style scoped>
.events-search-form {
  border: 2px solid #000;
  background-color: white;
}

/*
.events-search-form__submit {
  font-size: 1rem !important;
}
.events-search-form input[name='search'] {
  width: 90%;
  height: 38px;
  margin-left: 6px;
}
.events-search-form input[name='search']:focus {
  outline: none;
}
.events-search-form > div {
  width: 100%;
}
.events-search-form__textinput {
  padding: 0;
  margin: 0;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
} */
</style>
