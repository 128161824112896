import { Auth, API } from 'aws-amplify';

const sendEmail = async function({ dispatch, getters, commit }, emailTemplate) {
  if (!emailTemplate) {
    throw Error('ERROR::ACCOUNT_SIGN_IN: No parameters provided.');
  }
  let path = '/email';
  try {
    const currentSession = await Auth.currentSession();
    let payload = {
      body: emailTemplate,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    let req = await API.post('TMOAmplifyApi', path, payload);
    return req;
    // throw Error('Error sending email', req);
  } catch (error) {
    throw Error(error);
  }
};

export default sendEmail;
