<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card elevation="2" class="mx-auto">
          <div
            style="
              background: #f4f4f4;
              border-style: dashed;
              border-width: 4px;
              border-color: rgb(225, 231, 235);
            "
          >
            <div
              class="stage-area"
              style="
                padding-top: 20px;
                padding-bottom: 20px;
                min-height: 300px;
                position: relative;
                display: flex;
                justify-content: center;
              "
              ref="avstage"
            >
            <div class="watermark"  v-show="!CURRENT_EVENT_OBJECT['published']"></div>
              <component
                :is="GET_CURRENT_TEMPLATE_OBJECT['template_file_name']"
                v-bind="GET_CURRENT_TEMPLATE_OBJECT"
                :template-abbr-prize-description="templateAbbrPrizeDescription"
                :template-abbr-legal="templateAbbrLegal"
                :template-legal-arr="templateLegalArr"
                :selected-image="GET_CORRECT_IMAGE_URL"
                ref="thesvg"
              />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <v-btn
          type="button"
          class="v-btn v-btn--is-elevated v-btn--has-bg v-btn--tile theme--light elevation-2 v-size--x-large primary"
          style="z-index: 100;"
          block
          @click="saveSVGToPhoto"
          :disabled="checkIfHasImage"
          v-if="CURRENT_EVENT_OBJECT['published']"
          >{{checkIfHasImage ? 'Selecting an image is Required' : 'DOWNLOAD'}}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import HelperFunctions from '@/utility/HelperFunctions';
import noTemplateSelected from '@/components/AVECanvas/templates/noTemplateSelected';
import tmobileFamily300x600 from '@/components/AVECanvas/templates/tmobileFamily300x600';
import tmoDemo350x244 from '@/components/AVECanvas/templates/tmoDemo350x244';
import TMO_SP_160x600 from '@/components/AVECanvas/templates/TMO_SP_160x600';
import TMO_SP_160x600_ES from '@/components/AVECanvas/templates/TMO_SP_160x600_ES';
import TMO_SP_300x250 from '@/components/AVECanvas/templates/TMO_SP_300x250';
import TMO_SP_300x250_ES from '@/components/AVECanvas/templates/TMO_SP_300x250_ES';
import TMO_SP_728x90 from '@/components/AVECanvas/templates/TMO_SP_728x90';
import TMO_SP_728x90_ES from '@/components/AVECanvas/templates/TMO_SP_728x90_ES';
import TMO_SP_970x250_ES from '@/components/AVECanvas/templates/TMO_SP_970x250_ES';
import TMO_SP_970x250 from '@/components/AVECanvas/templates/TMO_SP_970x250';
import TMO_SP_300x600_ES from '@/components/AVECanvas/templates/TMO_SP_300x600_ES';
import TMO_SP_300x600 from '@/components/AVECanvas/templates/TMO_SP_300x600';
import { Canvg, presets } from 'canvg';

let MAX = Number.MAX_VALUE;
let tempWordHolder = [];
function saveSolution(p, n) {
  let k;
  if (p[n] == 1) k = 1;
  else k = saveSolution(p, p[n] - 1) + 1;
  tempWordHolder.push({ line: k, startIndex: p[n], endIndex: n });
  return k;
}

// l[] represents lengths of different words in input sequence.
// For example, l[] = {3, 2, 2, 5} is for a sentence like
// "aaa bb cc ddddd". n is size of l[] and M is line width
// (maximum no. of characters that can fit in a line)
function wrapWords(l, n, M) {
  let extras = new Array(n + 1);
  let lc = new Array(n + 1);
  for (let i = 0; i < n + 1; i++) {
    extras[i] = new Array(n + 1);
    lc[i] = new Array(n + 1);
    for (let j = 0; j < n + 1; j++) {
      extras[i][j] = 0;
      lc[i][j] = 0;
    }
  }
  let c = new Array(n + 1);
  let p = new Array(n + 1);
  for (let i = 1; i <= n; i++) {
    extras[i][i] = M - l[i - 1];
    for (let j = i + 1; j <= n; j++)
      extras[i][j] = extras[i][j - 1] - l[j - 1] - 1;
  }
  for (let i = 1; i <= n; i++) {
    for (let j = i; j <= n; j++) {
      if (extras[i][j] < 0) lc[i][j] = MAX;
      else if (j == n && extras[i][j] >= 0) lc[i][j] = 0;
      else lc[i][j] = extras[i][j] * extras[i][j];
    }
  }
  c[0] = 0;
  for (let j = 1; j <= n; j++) {
    c[j] = MAX;
    for (let i = 1; i <= j; i++) {
      if (c[i - 1] != MAX && lc[i][j] != MAX && c[i - 1] + lc[i][j] < c[j]) {
        c[j] = c[i - 1] + lc[i][j];
        p[j] = i;
      }
    }
  }
  tempWordHolder = [];
  saveSolution(p, n);
}

export default {
  name: 'AVERenderer',
  components: {
    noTemplateSelected,
    tmobileFamily300x600,
    tmoDemo350x244,
    TMO_SP_160x600,
    TMO_SP_160x600_ES,
    TMO_SP_300x250_ES,
    TMO_SP_300x250,
    TMO_SP_728x90,
    TMO_SP_728x90_ES,
    TMO_SP_970x250,
    TMO_SP_970x250_ES,
    TMO_SP_300x600_ES,
    TMO_SP_300x600
  },
  methods: {
    ...mapActions('AVE_CANVAS_STORE', ['RECORD_ACTION']),
    async saveSVGToPhoto() {
      let width = this.GET_CURRENT_TEMPLATE_OBJECT['t_output_width'];
      let height = this.GET_CURRENT_TEMPLATE_OBJECT['t_output_height'];
      const svg = this.$refs.thesvg.$el.outerHTML;
      const canvas = new OffscreenCanvas(width, height);
      const ctx = canvas.getContext('2d');

      const v = await Canvg.from(ctx, `${svg}`, presets.offscreen(), {
        anonymousCrossOrigin: true
      });
      await v.render();
      const blob = await canvas.convertToBlob();
      const pngUrl = URL.createObjectURL(blob);

      let temp_link = document.createElement('a');
      temp_link.href = pngUrl;
      temp_link.download = 'image.png';
      temp_link.style.opacity = '0';
      document.body.appendChild(temp_link);
      temp_link.click();
      document.body.removeChild(temp_link);

      await this.RECORD_ACTION({
        event_id: this.CURRENT_EVENT_OBJECT.id,
        template_id: this.SELECTED_TEMPLATE,
        promo_type: this.PROMOTION_TYPE,
        action: 'download',
        image: this.SELECTED_IMAGE
      });
    }
  },
  computed: {
    ...mapState('AVE_CANVAS_STORE', [
      'SELECTED_IMAGE',
      'SELECTED_TEMPLATE',
      'PROMOTION_TYPE',
      'CURRENT_LANGUAGE'
    ]),
    ...mapGetters('AVE_CANVAS_STORE', [
      'GET_CURRENT_TEMPLATE_OBJECT',
      'GET_CORRECT_IMAGE_URL'
    ]),
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    templateAbbrPrizeDescription() {
      return !this.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription
        ? '[PRIZE]'
        : this.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription.length <= 0
        ? '[PRIZE]'
        : this.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription.toUpperCase();
    },
    templateAbbrLegal() {
      let lang = (enText, esText) => {
        if (this.CURRENT_LANGUAGE === 'es') {
          return esText;
        }
        return enText;
      };

      let event = this.CURRENT_EVENT_OBJECT;

      if (event.abbreviatedLegal && event.abbreviatedLegal != '') {
        return event.abbreviatedLegal;
      }

      let legal = lang('No purchase necessary. ', 'Sin obligación de compra. ');
      if (event.contestStatesRequired) {
        legal += lang('Legal residents of ', 'Residentes legales de ');
        let length = event.eligibleStates.length;
        for (let i = 0; i < length; ++i) {
          // for each state display XX/XX/XX etc where XX is state
          legal += event.eligibleStates[i];
          if (i != length - 1) {
            legal += '/';
          }
        }
        legal += lang(', age 18+. ', ', mayores de 18 años. ');
      } else {
        legal += lang(
          '50 U.S., D.C., & P.R. 18+. ',
          '50 estados de EE. UU./D. C./PR. Para mayores de 18 años. '
        );
      }

      let startDate = this.helperFunctions.FormatDate(event.startDate);
      let endDate = this.helperFunctions.FormatDate(event.endDate);
      legal += `${startDate}-${endDate}. `;

      legal += lang(
        'See entry page for rules. ',
        'Ver reglamento en la página de participación. '
      );
      legal += lang('Void where prohibited. ', 'Nulo donde esté prohibido. ');

      return legal;
    },
    templateLegalArr() {
      let lang = (enText, esText) => {
        if (this.CURRENT_LANGUAGE === 'es') {
          return esText;
        }
        return enText;
      };

      let event = this.CURRENT_EVENT_OBJECT;

      if (event.abbreviatedLegal && event.abbreviatedLegal != '') {
        return event.abbreviatedLegal;
      }

      let legal = lang('No purchase necessary. ', 'Sin obligación de compra. ');
      if (event.contestStatesRequired) {
        legal += lang('Legal residents of ', 'Residentes legales de ');
        let length = event.eligibleStates.length;
        for (let i = 0; i < length; ++i) {
          // for each state display XX/XX/XX etc where XX is state
          legal += event.eligibleStates[i];
          if (i != length - 1) {
            legal += '/';
          }
        }
        legal += lang(', age 18+. ', ', mayores de 18 años. ');
      } else {
        legal += lang(
          '50 U.S., D.C., & P.R. 18+. ',
          '50 estados de EE. UU./D. C./PR. Para mayores de 18 años. '
        );
      }

      let startDate = this.helperFunctions.FormatDate(event.startDate);
      let endDate = this.helperFunctions.FormatDate(event.endDate);
      legal += `${startDate}-${endDate}. `;

      legal += lang(
        'See entry page for rules. ',
        'Ver reglamento en la página de participación. '
      );
      legal += lang('Void where prohibited. ', 'Nulo donde esté prohibido. ');

      let lineBrokenArr = [];
      let wordList = legal.split(' ');
      let l = legal.split(' ').map(x => x.length);
      let n = l.length;
      let M =
        this.GET_CURRENT_TEMPLATE_OBJECT?.max_legal_character_limit || 99999;

      let splitArr = wrapWords(l, n, M);
      if (tempWordHolder.length) {
        tempWordHolder.map(obj => {
          let newList = wordList
            .slice(obj.startIndex - 1, obj.endIndex)
            .join(' ');
          lineBrokenArr.push(newList);
        });
      }
      return lineBrokenArr;
    },
    helperFunctions: function() {
      return HelperFunctions;
    },
    checkIfHasImage() {
      if (
        this.GET_CURRENT_TEMPLATE_OBJECT.is_image_banner == null ||
        !this.GET_CURRENT_TEMPLATE_OBJECT.is_image_banner
      ) {
        return false;
      }
      return !this.GET_CORRECT_IMAGE_URL ? true : false;
    }
  }
};
</script>

<style>
.watermark {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-image: url('../../assets/demo.png');
  background-repeat: repeat;
  opacity: 25%;
}
</style>
