import { API, Auth, Storage } from 'aws-amplify';
import TMOEvent from '../../models/TMOEvent';
import Vue from 'vue';
import { DateTime } from 'luxon';
import { addConsoleHandler } from 'selenium-webdriver/lib/logging';

const state = {
  staffEvent: new TMOEvent()
};
const getters = {
  staffEvent: state => state.staffEvent
};

function convertToUTC(localDate, localTimezone) {
  return DateTime.fromISO(localDate.replace(' ', 'T'), {
    zone: localTimezone
  })
    .setZone('utc')
    .toFormat('yyyy-LL-dd HH:mm:ss');
}

function convertFromUTC(utcDate, localTimezone) {
  if (!localTimezone) {
    return utcDate;
  }
  return DateTime.fromISO(utcDate.replace(' ', 'T'), {
    zone: 'utc'
  })
    .setZone(localTimezone)
    .toFormat('yyyy-LL-dd HH:mm:ss');
}

const prepareDateForEditing = (
  theDate = null,
  Timezone = 'America/New_York'
) => {
  if (!theDate) return null;
  return DateTime.fromISO(theDate, {
    zone: Timezone
  });
  // return DateTime.fromISO(theDate);
};

const formatEventForEditing = ({
  description = null,
  timezone = null,
  storeNumber = null,
  state = null,
  region = null,
  rankWinners = null,
  published = null,
  numberOfWinners = null,
  excludePreviousParticipants = 0,
  eventType = null,
  organization = null,
  hqSponsorshipSegment = null,
  businessName = null,
  name = null,
  eventStartDate = null,
  eventEndDate = null,
  archived = false,
  defaultLanguage = null,
  eventLanguage = 'en',
  brand = 'T-Mobile',
  dma = null,
  msa = null,
  totalMarketingSpend = null,
  fullPrizeDescription = null,
  abbreviatedLegal = null,
  legalRequired = true,
  legalDetails = null,
  contestType = null,
  status = null,
  message = null,
  contestStatesRequired = false,
  eligibleStates = null,
  winnerSelectionDate = null,
  winnerSelectionTime = null,
  intervals = [],
  attendeeFormBusinessName = null,
  arv = null,
  eventLocation = null,
  address = null,
  district = null,
  postal_code = null,
  sweepstakesTitle = null,
  requiresAutomaticSelection = null,
  experience = null,
  legalPdfUrl = null,
  legalContract = null,
  abbreviatedPrizeDescription = null
}) => {
  let neweventObject = {
    eventLanguage: eventLanguage,
    eventDescription: description
  };
  if (numberOfWinners)
    neweventObject.numberOfWinners = parseInt(numberOfWinners);
  if (eventStartDate) {
    const convertedStartDateTime = prepareDateForEditing(
      eventStartDate,
      timezone
    );

    neweventObject.startDate = convertedStartDateTime.toISODate();
    neweventObject.startTime = convertedStartDateTime.toFormat('HH:mm:ss');
  }
  if (eventEndDate) {
    const convertedEndDateTime = prepareDateForEditing(eventEndDate, timezone);

    neweventObject.endDate = convertedEndDateTime.toISODate();
    neweventObject.endTime = convertedEndDateTime.toFormat('HH:mm:ss');
  }
  neweventObject.intervals = [];
  intervals.map(int => {
    let convertedintStartDate = prepareDateForEditing(int.startDate, timezone);
    let convertedintEndDate = prepareDateForEditing(int.endDate, timezone);
    let convertedwinSelectionDate = prepareDateForEditing(
      int.selectionDate,
      timezone
    );
    let completed = int.completed;
    let numberOfWinnersToSelect = int.numberOfWinnersToSelect;
    let event_id = int.event_id;
    let id = int.id;

    const fStartDate = convertedintStartDate.toISODate();
    const fStartTime = convertedintStartDate.toFormat('HH:mm:ss');
    const fEndDate = convertedintEndDate.toISODate();
    const fEndTime = convertedintEndDate.toFormat('HH:mm:ss');
    const fWSelectionDate = convertedwinSelectionDate.toISODate();
    const fWinSelectionTime = convertedwinSelectionDate.toFormat('HH:mm:ss');

    neweventObject.intervals.push({
      completed: completed,
      numberOfWinnersToSelect: numberOfWinnersToSelect,
      eventId: event_id,
      id: id,
      endDate: fEndDate,
      startDate: fStartDate,
      startTime: fStartTime,
      endTime: fEndTime,
      selectionDate: fWSelectionDate,
      winnerSelectionDate: fWSelectionDate,
      winnerSelectionTime: fWinSelectionTime
    });
  });

  if (typeof eligibleStates === 'string') {
    neweventObject.eligibleStates = eligibleStates.split(',');
    neweventObject.contestStatesRequired =
      neweventObject.eligibleStates.length < 52;
  }
  neweventObject.physicalEventAddress = address;
  neweventObject.physicalEventCity = district;
  neweventObject.physicalEventZip = postal_code;
  neweventObject.abbreviatedLegal = abbreviatedLegal;
  neweventObject.legalRequired =
    abbreviatedLegal != null || legalPdfUrl != null;
  return neweventObject;
};

const actions = {
  staffSetEvent({ commit }, event) {
    commit('staffSetEvent', event);
  },

  async staffGetAlternateWinner({ commit, dispatch }, winnerData) {
    console.log(winnerData);
    const apiName = 'TMOAmplifyApi';
    const path = `/staff/${winnerData.event_id}/winners`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: {
        replacedWinnerId: winnerData.replacedWinnerId,
        event_id: winnerData.event_id,
        interval_id: winnerData.interval_id,
        replaceWinnerRank: winnerData.replaceWinnerRank
      }
    };

    let response = {};

    try {
      response = await API.post(apiName, path, myInit);
    } catch (e) {
      response = e.response.data;
    }
    return JSON.stringify(response);
  },

  async staffFetchEvent({ commit, dispatch }, id) {
    const apiName = 'TMOAmplifyApi';
    const path = `/staff/${id}`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    let eventObject = {};
    try {
      const response = await API.get(apiName, path, myInit);
      if (response.data) {
        const checker = await formatEventForEditing(response.data);
        eventObject = Object.assign(response.data, checker);
      }
      return eventObject;
    } catch (err) {
      if (err && err.response && err.response.data) {
        return err.response.data;
      } else {
        return {
          status: 'error',
          errorMessage: 'Error loading event.'
        };
      }
    } finally {
      commit('staffSetEvent', eventObject);
    }
  },

  async staffFetchWinners({ commit, dispatch }, id) {
    const apiName = 'TMOAmplifyApi';
    const path = `/staff/${id}/winners`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    const response = await API.get(apiName, path, myInit);
    return response;
  },
  async staffRefresh({ commit, dispatch }, eventId) {
    const apiName = 'TMOAmplifyApi';
    const path = `/staff/${eventId}/refresh`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    try {
      await API.get(apiName, path, myInit);
      return true;
    } catch (e) {
      console.log(e.response.data);
      return false;
    }
  },
  async staffVerifyCode({ commit, dispatch }, code) {
    const apiName = 'TMOAmplifyApi';
    const path = '/staff/verify';
    const payload = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: {
        code: code
      }
    };
    try {
      let req = await API.post(apiName, path, payload);
      return req ? req.data : null;
    } catch (e) {
      console.log(e);
      return e && e.response ? e.response.data : null;
    }
  }
};
const mutations = {
  staffSetEvent: (state, staffEvent) => (state.staffEvent = staffEvent)
};

export default {
  state,
  getters,
  actions,
  mutations
};
