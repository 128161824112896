<template>
  <div class="app-forgot-password">
    <BannerImage
      image="svgs/tmo-metro-banner-white.svg"
      alt="T-Mobile Metro Login Banner"
    />
    <v-container fluid>
      <v-row justify="center">
        <v-col xs="12" sm="9" md="7" lg="5">
          <h1 class="app-forgot-password__title text-h1 mt-6">
            Forgot Password
          </h1>
          <p class="mt-5 text-center">
            Please enter your email to request an email to reset your password:
          </p>
          <ForgotPasswordForm class="mt-7" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ForgotPasswordForm from '@/components/Account/ForgotPasswordForm.vue';
import BannerImage from '@/components/BannerImage.vue';

export default {
  name: 'ForgotPassword',
  components: {
    ForgotPasswordForm,
    BannerImage
  },
  created() {}
};
</script>

<style scoped>
.app-forgot-password__title,
.app-forgot-password p {
  text-align: center;
}
.app-forgot-password__title {
  font-weight: 700;
}
</style>
