<template>
  <div class="app-login">
    <BannerImage
      image="svgs/tmo-metro-banner-white.svg"
      alt="T-Mobile Metro Banner"
    />
    <v-container>
      <v-row no-gutters justify="center">
        <v-col cols="12" sm="10" lg="6">
          <h1 class="app-login__title text-h1 text-center black--text mt-6">
            Event Staff Access
          </h1>
          <p class="text-body text-center mt-5 black--text">
            Enter the Event Code you have been provided to access the Event Details.
          </p>
          <EventCodeForm class="mt-7" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EventCodeForm from '@/components/EventCodeForm.vue';
import BannerImage from '@/components/BannerImage.vue';

export default {
  name: 'EventStaffDashboard',
  components: {
    EventCodeForm,
    BannerImage
  }
};
</script>

<style></style>
