<template>
  <div>
    <BannerImage
      image="svgs/tmo-metro-banner-white.svg"
      alt="T-Mobile Metro Login Banner"
    />
    <v-container>
      <v-row dense align="center">
        <v-col align="center">
          <v-sheet
            class=""
            color="grey"
            elevation="11"
            min-width="600"
            max-width="600"
          >
            <v-container v-if="!this.apiError">
              <v-row dense align="center">
                <v-col class="col-12" align="center">
                  <h1 class="mb-6">Approving User</h1>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col offset="2" class="col-3" align="start">
                  <p class="my-0">User Email:</p>
                </v-col>
                <v-col class="col-auto" align="start">
                  {{ this.userEmail }}
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col offset="2" class="col-3" align="start">
                  <p class="my-0">User Enabled:</p>
                </v-col>
                <v-col class="col-auto" align="center">
                  <v-checkbox
                    dense
                    :indeterminate="!this.userEnabled"
                    :value="this.userEnabled"
                    readonly
                    color="primary"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col offset="2" class="col-3" align="start">
                  <p class="my-0">User Added to Guest Group:</p>
                </v-col>
                <v-col class="col-auto" align="center">
                  <v-checkbox
                    dense
                    :indeterminate="!this.userAddedToGroup"
                    :value="this.userAddedToGroup"
                    readonly
                    color="primary"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col offset="2" class="col-10" align="start">
                  <v-treeview
                    v-if="this.userInfoTreeData"
                    dense
                    activatable
                    :items="this.userInfoTreeData"
                    color="primary"
                  ></v-treeview>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-if="this.apiError">
              <v-row dense align="center">
                <v-col class="col-12" align="center">
                  <h1 class="mb-6">Error</h1>
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col class="col-12" align="start">
                  <p>
                    {{ this.apiError }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BannerImage from '@/components/BannerImage.vue';

import { Auth, API } from 'aws-amplify';
export default {
  name: 'EnableUser',
  components: {
    BannerImage
  },
  data() {
    return {
      userEnabled: false,
      userAddedToGroup: false,
      userInfo: undefined,
      apiError: undefined,
      userEmail: ''
    };
  },
  mounted() {
    this.listUsers()
      .then(console.log)
      .catch(console.log);
  },
  computed: {
    username: function() {
      return this.$route.query.username.replace(/\s+/g, '+') || null;
    },
    groupname: function() {
      return this.$route.query.groupname;
    },
    requestHeaders: async () => {
      return {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      };
    },
    adminAction: function() {
      return this.$route.path.split('/').pop();
    },
    userInfoTreeData: function() {
      if (!this.userInfo) {
        return;
      }
      let treeFormat = Object.entries(this.userInfo).map((x, i) => {
        if (typeof x[1] === 'object') {
          let children = [];
          x[1].forEach((x, i) => {
            if (x.Name === 'email') {
              this.userEmail = x.Value;
            }
            children.push({ id: i + 50, name: `${x.Name} : ${x.Value}` });
          });
          return {
            id: i + 2,
            name: x.toString().split(',')[0],
            children
          };
        }
        return { id: i + 2, name: x.toString().replace(',', ' : ') };
      });
      return [{ id: 1, name: 'User Information', children: treeFormat }];
    }
  },
  async created() {
    // try {
    //   await this.getUser(this.username);
    //   await this.enableUser(this.username);
    //   await this.addUserToGroup(this.username, 'Guest');
    // } catch (error) {
    //   this.apiError = error;
    // }
  },
  methods: {
    enableUser: async function(username) {
      let path = '/enableUser';
      try {
        let payload = {
          body: {
            username
          },
          headers: await this.requestHeaders
        };
        let req = await API.post('AdminQueries', path, payload);
        if (req.message.split(' ')[0] === 'Enabled') {
          this.userEnabled = true;
          return true;
        }
        throw Error('Error enabling user', req);
      } catch (error) {
        throw Error(error);
      }
    },
    addUserToGroup: async function(username, groupname) {
      const path = '/addUserToGroup';
      let payload = {
        body: {
          username,
          groupname
        },
        headers: await this.requestHeaders
      };
      let req = await API.post('AdminQueries', path, payload);
      if (req.message.split(' ')[0] === 'Success') {
        this.userAddedToGroup = true;
        return true;
      }
      throw Error('Error enabling user', req);
    },
    removeUserFromGroup: async function(username, groupname) {
      const path = '/removeUserFromGroup';
      let payload = {
        body: {
          username,
          groupname
        },
        headers: await this.requestHeaders
      };
      let req = await API.post('AdminQueries', path, payload);
      if (req.message.split(' ')[0] === 'Removed') {
        return true;
      }
      throw Error('Error enabling user', req);
    },
    confirmUserSignUp: async function(username) {
      const path = '/confirmUserSignUp';
      let payload = {
        body: {
          username
        },
        headers: await this.requestHeaders
      };
    },
    disableUser: async function(username) {
      const path = '/disableUser';
      let payload = {
        body: {
          username
        },
        headers: await this.requestHeaders
      };
    },
    getUser: async function(username) {
      const path = '/getUser';
      try {
        let payload = {
          queryStringParameters: {
            username
          },
          headers: await this.requestHeaders
        };
        let req = await API.get('AdminQueries', path, payload);
        if (req && req.Username && req.UserAttributes) {
          this.userInfo = req;
          return true;
        }
        throw Error('Error fetching user information.');
      } catch (error) {
        throw Error(error);
      }
    },
    listUsers: async function() {
      const path = '/listUsers';
      try {
        let payload = {
          headers: await this.requestHeaders
        };
        let req = await API.get('AdminQueries', path, payload);
        if (req) {
          return true;
        }
        throw Error('Error fetching user information.');
      } catch (error) {
        throw Error(error);
      }
    },
    listGroups: async function() {
      const path = '/listGroups';
      let payload = {
        body: {},
        headers: await this.requestHeaders
      };
    },
    listGroupsForUser: async function(username) {
      const path = '/listGroupsForUser';
      let payload = {
        body: {
          username
        },
        headers: await this.requestHeaders
      };
    },
    listUsersInGroup: async function(groupname) {
      const path = '/listUsersInGroup';
      let payload = {
        body: {
          groupname
        },
        headers: await this.requestHeaders
      };
    }
  }
};
</script>
