<template>
  <v-container class="py-0 view-winners">
    <h2 class="pb-0">Duplicate Event</h2>
    <p class="pb-0">
      To create a new event using this event's details, enter the new event
      title and click 'Duplicate' below.
    </p>
    <p>
      <b>
        Duplicating an Enter for a Chance to Win or Sign Up/ Check In type event
        will require your event dates to be less than 365 days when duplicating.
        Event dates can be changed once the event is duplicated.
      </b>
    </p>
    <p>
      <b>
        The following details will not be duplicated for an Enter for a Chance
        to Win type event. Once you click 'Duplicate', you will need to click
        'View Event' and edit the following details:
      </b>
    </p>
    <ul>
      <li>Enter Period Dates/Times</li>
      <li>Winner Selection Dates/Times</li>
    </ul>
    <v-spacer></v-spacer>
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="NEW_EVENT_NAME"
                label="New Event name"
                required
                :rules="nameRules"
                :count="191"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <!-- end basic info column -->

        <!-- datetime options -->

        <!-- datetime options -->
        <!-- winner selection -->
        <v-col cols="12" md="6" lg="2">
          <div class="date-time-picker-wrap">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="startDateField"
                  v-model="NEW_EVENT_START_DATE"
                  label="Event Start Date"
                  append-icon="$Calendar"
                  readonly
                  v-bind="attrs"
                  hide-details="auto"
                  required
                  v-on="on"
                  :rules="[
                    nonEmptyRule('You must select a end date.'),
                    startTimeRule()
                  ]"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title
                v-model="NEW_EVENT_START_DATE"
                @change="menu2 = false"
                :allowed-dates="disablePastDates"
                :min="minStartDate"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="2">
          <div class="date-time-picker-wrap">
            <v-select
              v-model="NEW_EVENT_START_TIME"
              append-icon="$Clock"
              :rules="[
                nonEmptyRule('You must select a start time.'),
                startTimeRule()
              ]"
              :items="timePickerItems"
              hide-details="auto"
              label="Start Time"
            ></v-select>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="2">
          <div class="date-time-picker-wrap">
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="endDateField"
                  v-model="NEW_EVENT_END_DATE"
                  label="Event End Date"
                  append-icon="$Calendar"
                  readonly
                  v-bind="attrs"
                  hide-details="auto"
                  required
                  :rules="[
                    nonEmptyRule('You must select a end date.'),
                    endTimeRule(),
                    checkEndEventEndDate()
                  ]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="NEW_EVENT_END_DATE"
                no-title
                @input="endDateMenu = false"
                :allowed-dates="disableEndDatePasts"
                :max="maxEndDate"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="2">
          <div class="date-time-picker-wrap">
            <v-select
              ref="endTimeField"
              v-model="NEW_EVENT_END_TIME"
              append-icon="$Clock"
              :rules="[
                nonEmptyRule('You must select an end time.'),
                endTimeRule()
              ]"
              :items="timePickerEndItems"
              hide-details="auto"
              label="End Time"
            ></v-select>
          </div>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-select
            v-model="timezone"
            label="Time Zone"
            :rules="[nonEmptyRule('You must select a time zone.')]"
            :items="optionData.TimeZones()"
            hide-details="auto"
            append-icon="$Caret"
          ></v-select>
        </v-col>

        <!-- end winner selection-->
      </v-row>
      <v-row>
        <v-col cols="6" md="6" lg="6">
          <button
            type="button"
            class="exit-button v-btn v-btn--block v-btn--outlined v-btn--tile theme--light elevation-2 v-size--x-large primary--text"
            style="height: 50px;"
            @click.prevent="changeAction"
          >
            <span class="v-btn__content">Cancel </span>
          </button>
        </v-col>
        <v-col cols="6" md="6" lg="6">
          <v-btn
            :disabled="disableSaveButton"
            :depressed="disableSaveButton"
            @click.prevent="save"
            type="submit"
            class=" flex-grow-2 btn__primary events-search-form__submit v-btn v-btn--block v-btn--is-elevated v-btn--has-bg v-btn--tile theme--light v-size--default magenta app-button text-heading white--text"
            style="height: 50px;"
          >
            <span class="v-btn__content">Save </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="SHOW_POPUP" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ POPUP_TITLE }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ POPUP_DESCRIPTION }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8" v-if="newEventID">
          <v-btn @click="changeAction" text class="px-6 btn__secondary">
            Close
          </v-btn>
          <v-btn
            @click="viewNewEvent(newEventID)"
            text
            class="px-6 btn__primary"
          >
            View Event
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import OptionData from '@/utility/OptionData.js';
import ValidationRules from '@/utility/ValidationRules.js';
import { DateTime, Interval } from 'luxon';
import { mapActions, mapState, mapGetters } from 'vuex';

function convertToUTC(localDate, localTimezone) {
  return DateTime.fromISO(localDate.replace(' ', 'T'), {
    zone: localTimezone
  })
    .setZone('utc')
    .toFormat('yyyy-LL-dd HH:mm:ss');
}

export default {
  name: 'DuplicateEventAction',
  data() {
    return {
      menu2: false,
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 191) || 'Event must be less than 191 characters'
      ],
      startDateMenu: null,
      endDateMenu: null,
      timePickerItems: OptionData.TimepickerItems(),
      timePickerEndItems: OptionData.TimepickerEndItems(),
      isAdmin: false,
      picker: null,
      hasErrors: false,
      eventEndDateWarning: false
    };
  },
  mounted() {
    this.isAdmin = this.USER_GROUPS.indexOf('Admin') !== -1;
  },
  methods: {
    checkEndEventEndDate() {
      if (this.eventEndDateWarning) {
        return this.eventEndDateWarning;
      }
      return true;
    },
    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    disableEndDatePasts(val) {
      return val >= this.NEW_EVENT_START_DATE;
    },
    submitForm() {
      this.$refs.form.validate();
    },
    ...mapActions('DUPLICATE_EVENT_STORE', ['SAVE_DUPLICATE_EVENT']),
    async viewNewEvent(newEventId) {
      try {
        await this.$store.commit('DUPLICATE_EVENT_STORE/RESET_ALL');
        this.$store.commit('UI_STORE/TOGGLE_EXPANSION_PANEL', 'hide');
        this.$router.push({ path: `/event/edit/${newEventId}` });
        this.$emit('changeAction', null);
      } catch (e) {
        console.log(e);
      }
    },
    resetDialog() {
      this.$store.commit('DUPLICATE_EVENT_STORE/RESET_POPUP');
    },
    changeAction() {
      this.$store.commit('DUPLICATE_EVENT_STORE/RESET_ALL');
      this.$emit('changeAction', null);
    },
    async save() {
      try {
        //Check if dates are alright;
        this.eventEndDateWarning = false;
        if (
          (this.contestType &&
            this.contestType === 'Enter for a Chance to Win') ||
          this.contestType === 'SIGN UP/CHECK IN'
        ) {
          // check if over 365 days

          let sDate = new Date(this.NEW_EVENT_START_DATE);
          let eDate = new Date(this.NEW_EVENT_END_DATE);
          // To calculate the time difference of two dates
          let timeDifference = eDate.getTime() - sDate.getTime();

          // To calculate the no. of days between two dates
          let dayDifference = timeDifference / (1000 * 3600 * 24);

          if (dayDifference > 365) {
            return (this.eventEndDateWarning = `For ${this.contestType}, the end date must be within 365 days. If applicable additional intervals may be added on the next screen.`);
          }
        }
        //Handle the start and end time changes;
        if (!this.NEW_EVENT_START_TIME) {
          this.NEW_EVENT_START_TIME = '00:00:00';
        }
        if (!this.NEW_EVENT_END_TIME) {
          this.NEW_EVENT_END_TIME = '00:00:00';
        }

        let startDate = null;
        let endDate = null;

        if (this.NEW_EVENT_START_DATE) {
          startDate = new Date(
            this.NEW_EVENT_START_DATE.replace('.000Z', '') +
              'T' +
              this.NEW_EVENT_START_TIME
          );
          startDate =
            startDate.getFullYear() +
            '-' +
            ('00' + (startDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('00' + startDate.getDate()).slice(-2) +
            ' ' +
            ('00' + startDate.getHours()).slice(-2) +
            ':' +
            ('00' + startDate.getMinutes()).slice(-2) +
            ':' +
            ('00' + startDate.getSeconds()).slice(-2);
        }

        if (this.NEW_EVENT_END_DATE) {
          endDate = new Date(
            this.NEW_EVENT_END_DATE.replace('.000Z', '') +
              'T' +
              this.NEW_EVENT_END_TIME
          );
          endDate =
            endDate.getFullYear() +
            '-' +
            ('00' + (endDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('00' + endDate.getDate()).slice(-2) +
            ' ' +
            ('00' + endDate.getHours()).slice(-2) +
            ':' +
            ('00' + endDate.getMinutes()).slice(-2) +
            ':' +
            ('00' + endDate.getSeconds()).slice(-2);
        }

        //convert to utc;
        if (this.timezone) {
          startDate = convertToUTC(startDate, this.timezone);
          endDate = convertToUTC(endDate, this.timezone);
        }
        //Handle interval creation;
        let initialIntervalObject = [
          {
            completed: 0,
            endDate: endDate,
            startDate: startDate,
            selectionDate: endDate,
            event_id: this.$route.params.id,
            numberOfWinnersToSelect:
              this.$store.state.DUPLICATE_EVENT_STORE?.DUPLICATE_EVENT_OBJECT
                ?.numberOfWinnersToSelect || 1
          }
        ];
        this.$store.commit('DUPLICATE_EVENT_STORE/UPDATE_DUPLICATE_EVENT', {
          key: 'intervals',
          value: initialIntervalObject
        });
        this.$store.commit('DUPLICATE_EVENT_STORE/UPDATE_DUPLICATE_EVENT', {
          key: 'eventStartDate',
          value: startDate
        });
        this.$store.commit('DUPLICATE_EVENT_STORE/UPDATE_DUPLICATE_EVENT', {
          key: 'eventEndDate',
          value: endDate
        });
        this.$store.commit('DUPLICATE_EVENT_STORE/UPDATE_DUPLICATE_EVENT', {
          key: 'name',
          value: this.NEW_EVENT_NAME
        });

        await this.SAVE_DUPLICATE_EVENT();
      } catch (error) {
        console.log(error);
      }
    },
    startTimeRule() {
      let startDate;
      if (
        this.NEW_EVENT_START_DATE == null ||
        this.NEW_EVENT_START_TIME == null
      ) {
        return true;
      }

      if (this.timezone != null) {
        startDate = DateTime.fromISO(
          this.NEW_EVENT_START_DATE + 'T' + this.NEW_EVENT_START_TIME,
          {
            zone: this.timezone
          }
        );
      } else {
        startDate = DateTime.fromISO(
          this.NEW_EVENT_START_DATE + 'T' + this.NEW_EVENT_START_TIME
        );
      }
      let now = DateTime.now();
      let interval = Interval.fromDateTimes(now, startDate);
      if (startDate < now) {
        return 'Event start time cannot be in the past.';
      }
      if (!this.isAdmin && interval.length('days') < 1) {
        return 'Event start time cannot be within the next 24 hours.';
      }

      return true;
    },
    endTimeRule() {
      if (
        this.NEW_EVENT_START_DATE == null ||
        this.NEW_EVENT_START_TIME == null ||
        this.NEW_EVENT_END_DATE == null ||
        this.NEW_EVENT_END_TIME == null
      ) {
        return true;
      }
      let startDate = new Date(
        this.NEW_EVENT_START_DATE + 'T' + this.NEW_EVENT_START_TIME
      );
      let endDate = new Date(
        this.NEW_EVENT_END_DATE + 'T' + this.NEW_EVENT_END_TIME
      );
      return startDate < endDate
        ? true
        : 'Event end time must be after event start time.';
    },
    nonEmptyRule(error) {
      return ValidationRules.NonEmptyRule(error);
    },
    intervalLength(date1, date2) {
      var length = Interval.fromDateTimes(
        DateTime.fromISO(date1),
        DateTime.fromISO(date2)
      ).length('days');
      return length;
    }
  },
  computed: {
    ...mapGetters({
      USER_GROUPS: `account/USER_GROUPS`
    }),
    minStartDate() {
      var dt = new Date();
      dt.setDate(dt.getDate() + 2);

      return dt.toISOString().substr(0, 10);
    },
    maxEndDate() {
      if (this.NEW_EVENT_START_DATE == null) {
        return null;
      }
      if (
        (this.NEW_EVENT_START_DATE && this.contestType == 'Instant Win') ||
        this.contestType == null
      ) {
        return null;
      }
      var dt = new Date(this.NEW_EVENT_START_DATE);
      dt.setDate(dt.getDate() + 365);
      return dt.toISOString().substr(0, 10);
    },
    disableSaveButton() {
      if (
        !this.NEW_EVENT_START_DATE ||
        !this.NEW_EVENT_NAME ||
        !this.NEW_EVENT_END_DATE ||
        !this.NEW_EVENT_END_TIME
      ) {
        return true;
      }
      if (this.NEW_EVENT_NAME && this.NEW_EVENT_NAME.length <= 0) {
        return true;
      }
      return false;
    },
    ...mapState('DUPLICATE_EVENT_STORE', [
      'LOADING',
      'DUPLICATE_EVENT_OBJECT',
      'POPUP_TITLE',
      'POPUP_DESCRIPTION',
      'SHOW_POPUP',
      'NEW_EVENT_ID'
    ]),

    optionData: function() {
      return OptionData;
    },
    newEventID() {
      if (!this.NEW_EVENT_ID) {
        return null;
      }
      return this.NEW_EVENT_ID;
    },
    timezone: {
      get() {
        return this.$store.state.DUPLICATE_EVENT_STORE?.DUPLICATE_EVENT_OBJECT
          ?.timezone;
      },
      set(value) {
        this.$store.commit('DUPLICATE_EVENT_STORE/UPDATE_DUPLICATE_EVENT', {
          key: 'timezone',
          value: value
        });
      }
    },

    NEW_EVENT_NAME: {
      get() {
        return this.$store.state.DUPLICATE_EVENT_STORE.NEW_EVENT_NAME;
      },
      set(value) {
        this.$store.commit('DUPLICATE_EVENT_STORE/UPDATE_NEW_EVENT', {
          key: 'NEW_EVENT_NAME',
          value: value
        });
      }
    },

    NEW_EVENT_START_DATE: {
      get() {
        return this.$store.state.DUPLICATE_EVENT_STORE.NEW_EVENT_START_DATE;
      },
      set(value) {
        this.$store.commit('DUPLICATE_EVENT_STORE/UPDATE_NEW_EVENT', {
          key: 'NEW_EVENT_START_DATE',
          value: value
        });
      }
    },
    NEW_EVENT_START_TIME: {
      get() {
        return this.$store.state.DUPLICATE_EVENT_STORE.NEW_EVENT_START_TIME;
      },
      set(value) {
        this.$store.commit('DUPLICATE_EVENT_STORE/UPDATE_NEW_EVENT', {
          key: 'NEW_EVENT_START_TIME',
          value: value
        });
      }
    },
    NEW_EVENT_END_DATE: {
      get() {
        return this.$store.state.DUPLICATE_EVENT_STORE.NEW_EVENT_END_DATE;
      },
      set(value) {
        this.$store.commit('DUPLICATE_EVENT_STORE/UPDATE_NEW_EVENT', {
          key: 'NEW_EVENT_END_DATE',
          value: value
        });
      }
    },
    NEW_EVENT_END_TIME: {
      get() {
        return this.$store.state.DUPLICATE_EVENT_STORE.NEW_EVENT_END_TIME;
      },
      set(value) {
        this.$store.commit('DUPLICATE_EVENT_STORE/UPDATE_NEW_EVENT', {
          key: 'NEW_EVENT_END_TIME',
          value: value
        });
      }
    },

    contestType() {
      return this.$store.state.DUPLICATE_EVENT_STORE?.DUPLICATE_EVENT_OBJECT
        ?.contestType;
    },

    multipleEntryPeriodsCheck() {
      return (
        this.intervals &&
        Array.isArray(this.intervals) &&
        this.intervals.length > 1
      );
    }
  }
};
</script>
<style lang="scss">
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 30px;
  margin-top: 50px;
}

.view-winners {
  margin-bottom: 50px;
}

.button-text {
  font-weight: bold;
}

.whiteText {
  color: white !important;
}

.fluid {
  margin: 0;
  padding: 0;
}
</style>
