<template>
  <div class="app-signup">
    <BannerImage
      image="svgs/tmo-metro-banner-white.svg"
      alt="T-Mobile Metro Login Banner"
    />
    <v-container fluid>
      <v-row justify="center">
        <v-col xs="12" sm="9" md="7" lg="5">
          <h1 class="app-signup__title text-h1 mt-6">Sign Up</h1>
          <p class="mt-5">
            We’re excited for you to engage with consumers! Please use your
            T-Mobile email address to join below. If you do not have a T-Mobile
            email address, enter your email address and complete the questions
            that follow to request access.
          </p>
          <SignUpForm class="mt-7" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SignUpForm from '@/components/Account/SignUpForm.vue';
import BannerImage from '@/components/BannerImage.vue';

export default {
  name: 'SignUp',
  components: {
    SignUpForm,
    BannerImage
  }
};
</script>

<style scoped>
.app-signup__title,
.app-signup p {
  text-align: center;
}
.app-signup__title {
  font-weight: 700;
}
</style>
