<template>
  <div class="app-event-creation">
    <AlertBar></AlertBar>
    <BannerTitle title="Create Event" />
    <!-- <CreateEventForm /> -->
    <!-- {{ LOADING }}
    {{ CURRENT_EVENT_OBJECT }} -->
    <template v-if="!LOADING">
      <EventEditorForm :is-mobile="isMobile" ref="form">
        <template v-slot:screenbuttons>
          <div class="form-buttons">
            <v-row justify="center">
              <v-col cols="10" md="6">
                <v-btn
                  elevation="2"
                  tile
                  block
                  x-large
                  color="secondary"
                  :loading="API_CALL_IN_PROGRESS"
                  @click="saveDraft"
                  :disabled="validateDraftEvtObj.length > 0"
                >
                  <span> Save Draft </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center" v-show="validateSubmitToLegal.length < 1">
              <v-col cols="10" md="6">
                <v-btn
                  elevation="2"
                  tile
                  block
                  x-large
                  :loading="API_CALL_IN_PROGRESS"
                  color="primary"
                  @click="submitToLegalReview"
                  :disabled="validateSubmitToLegal.length > 0"
                >
                  <span> Submit to Legal </span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
      </EventEditorForm>
    </template>
    <template v-else>
      <div
        style="
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
        "
        class="text-center"
      >
        <v-progress-circular indeterminate color="red"></v-progress-circular>
      </div>
    </template>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
          <ul style="list-style: none; padding-left: 0">
            <li v-for="error in validateDraftEvtObj" :key="error.id">
              {{ error }}
            </li>
            <li v-for="error in currentFormErrors" :key="error.id">
              {{ error }}
            </li>
          </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" text class="px-6 btn__primary">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="invalidEntryPeriodDialogue"
      width="600"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Invalid Fields
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          <ul style="list-style: none; padding-left: 0">
            <li v-for="error in invalidIntervalArr" :key="error.id">
              {{
                `Please Fix entry period ${error.index + 1} :  ${error.message}`
              }}
            </li>
          </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            @click="invalidEntryPeriodDialogue = false"
            text
            class="px-6 btn__primary"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successDialog"
      width="600"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Event Updated
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ successDialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="returnToDash" text class="px-6 btn__secondary">
            Dashboard
          </v-btn>
          <v-btn @click="viewTheNewEvent" text class="px-6 btn__primary">
            View
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="untrackedChangesDialog"
      width="600"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Unsaved Changes
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          You have unsaved changes to your event. Would you like to save your
          changes before leaving?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            @click="continueWithoutSavingEvent"
            text
            class="px-6 btn__secondary"
          >
            Exit without saving
          </v-btn>
          <v-btn @click="continueEditingEvent" text class="px-6 btn__primary">
            Keep editing
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import BannerTitle from '@/components/BannerTitle.vue';
// import CreateEventForm from '@/components/EventForm/CreateEventForm.vue';
import EventEditorForm from '@/components/Forms/EventEditorForm.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import ThemeSwitcher from '@/utility/ThemeSwitcher';
import { TrackUnsavedChangesDialogControl } from '@/mixins/TrackUnsavedChangesDialogControl';
import AlertBar from '@/components/AlertBar.vue';
import {
  SubmitToTranslationSchema,
  SubmitToLegalEventSchema,
  DraftEventSchema,
  EnterToWinEventSchema,
} from '@/utility/ValidationSchemas';

import validateIntervals from '@/utility/ValidateEventIntervals';

const validate = (object, schema) =>
  Object.keys(schema)
    .filter((key) => !schema[key](object[key]))
    .map((key) => new Error(`${key} is invalid.`));

export default {
  name: 'EventCreate',
  mixins: [TrackUnsavedChangesDialogControl],
  components: {
    BannerTitle,
    EventEditorForm,
    AlertBar,
  },
  async mounted() {
    try {
      this.isAdmin = this.USER_GROUPS.indexOf('Admin') !== -1;
      this.$store.commit('EVENT_MANAGER_STORE/RESET_ALL');
      await this.$store.dispatch('AVE_CANVAS_STORE/GET_AVAILABLE_IMAGES');
      await this.fetchEventData();
      this.onResize();
      window.addEventListener('resize', this.onResize, { passive: true });
    } catch (e) {
      console.log(e);
    }
  },
  data() {
    return {
      isMobile: false,
      currentFormErrors: [],
      showInvalidDraftDialog: false,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null,
      valid: false,
      showDialog: false,
      newEventId: null,
      successDialog: false,
      successDialogMessage: null,
      untrackedChangesDialog: false,
      targetToRoute: false,
      invalidIntervalArr: [],
      invalidEntryPeriodDialogue: false,
      isAdmin: false,
    };
  },
  watch: {
    brandWatcher(brandValue) {
      this.changeTheme(brandValue);
    },
    $route() {
      this.fetchEventData();
    },
  },
  methods: {
    async fetchEventData() {
      await this.$store.dispatch('EVENT_MANAGER_STORE/LOAD_EVENT');
    },
    ...mapActions({
      sendEmail: 'admin/sendEmail',
    }),
    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
    returnToDash() {
      this.$router.push({ path: '/dashboard' });
    },
    viewTheNewEvent() {
      this.$router.push({ path: `/event/edit/${this.newEventId}` });
    },
    changeTheme(brand) {
      ThemeSwitcher.ChangeTheme(this, brand);
    },
    eventEndsBeforeItStarts() {
      // make sure that event end time >= start time
      let startDateTime = new Date(
        this.CURRENT_EVENT_OBJECT.startDate +
          ' ' +
          this.CURRENT_EVENT_OBJECT.startTime
      );
      let endDateTime = new Date(
        this.CURRENT_EVENT_OBJECT.endDate +
          ' ' +
          this.CURRENT_EVENT_OBJECT.endTime
      );
      return endDateTime <= startDateTime;
    },
    eventStartsWithin24Hours() {
      let now = new Date();
      let startDate = new Date(
        this.CURRENT_EVENT_OBJECT.startDate +
          ' ' +
          this.CURRENT_EVENT_OBJECT.startTime
      );
      let milliseconds = startDate - now;
      let hours = milliseconds / 1000 / 3600;
      return startDate <= now || (!this.isAdmin && hours < 24);
    },
    validateEventDates() {
      // make sure that event end time >= start time
      if (this.eventEndsBeforeItStarts()) {
        this.currentFormErrors.push(
          'Event end time cannot be before event start time.'
        );
      }
      // make sure event start is not within 48 hours
      if (this.eventStartsWithin24Hours()) {
        this.currentFormErrors.push('Event start date cannot be within 24 hours');
      }
      // reset interval errors array
      this.invalidIntervalArr = [];
      this.$store.commit('EVENT_MANAGER_STORE/SET_CURRENT_INTERVAL_ERROR_LIST');
      // validate interval dates
      if (
        this.CURRENT_EVENT_OBJECT.contestType !== 'Instant Win'
      ) {
        let areIntervalsValid = validateIntervals(
          this.CURRENT_EVENT_OBJECT.startDate,
          this.CURRENT_EVENT_OBJECT.startTime,
          this.CURRENT_EVENT_OBJECT.endDate,
          this.CURRENT_EVENT_OBJECT.endTime,
          this.CURRENT_EVENT_OBJECT.intervals
        );
        // run date checks on all of the dates if multiple intervals;
        if (areIntervalsValid.length) {
          this.invalidIntervalArr = areIntervalsValid;
          this.$store.commit(
            'EVENT_MANAGER_STORE/SET_CURRENT_INTERVAL_ERROR_LIST',
            areIntervalsValid
          );
        }
      }
      // check if eventType = 'HQ Sponsorship' and if it does, require a segment selection;
      if (
        this.CURRENT_EVENT_OBJECT.eventType === 'HQ Sponsorship' &&
        !this.CURRENT_EVENT_OBJECT.hqSponsorshipSegment
      ) {
        this.currentFormErrors.push(
          'You must select a value for HQ Sponsorship Segment.'
        );
      }
    },
    validateLegalAndLocation() {
      // validate abbreviated legal
      if (
        this.CURRENT_EVENT_OBJECT.legalRequired &&
        !this.CURRENT_EVENT_OBJECT.abbreviatedLegal
      ) {
        this.currentFormErrors.push(
          'You must provide legal language if you have indicated your event needs it.'
        );
      }
      if (
        this.CURRENT_EVENT_OBJECT.contestType === 'Enter for a Chance to Win' &&
        !this.CURRENT_EVENT_OBJECT.legalRequired
      ) {
        if (!this.CURRENT_EVENT_OBJECT.sweepstakesTitle)
          this.currentFormErrors.push('Sweepstakes title is required.');
        if (!this.CURRENT_EVENT_OBJECT.fullPrizeDescription)
          this.currentFormErrors.push('Full prize description is required.');
        if (!this.CURRENT_EVENT_OBJECT.arv)
          this.currentFormErrors.push('ARV is required.');
      }
      // validate event location
      if (
        this.CURRENT_EVENT_OBJECT.region !== 'National' &&
        !this.CURRENT_EVENT_OBJECT.state
      ) {
        this.currentFormErrors.push('State is required.');
      }
      if (this.CURRENT_EVENT_OBJECT.eventLocation === 'Online/Virtual Event') {
        if (!this.CURRENT_EVENT_OBJECT.region) {
          this.currentFormErrors.push('Region is required.');
        }
        if (this.CURRENT_EVENT_OBJECT.region !== 'National') {
          if (!this.CURRENT_EVENT_OBJECT.dma)
            this.currentFormErrors.push('DMA is required.');
        }
      } else {
        if (!this.CURRENT_EVENT_OBJECT.physicalEventAddress)
          this.currentFormErrors.push('Event Address is required.');
        if (!this.CURRENT_EVENT_OBJECT.physicalEventCity)
          this.currentFormErrors.push('Event City is required.');
        if (!this.CURRENT_EVENT_OBJECT.physicalEventZip)
          this.currentFormErrors.push('Event Zip is required.');
      }
    },
    async saveDraft() {
      try {
        this.currentFormErrors = [];
        if (this.validateDraftEvtObj.length > 0) {
          this.currentFormErrors.push(
            'One or more of your fields are invalid. Please fix these fields before proceeding.'
          );
        }
        this.validateEventDates();
        if (this.currentFormErrors.length > 0) {
          this.displayDialog('Invalid Fields', '', 'Close', () => {
            this.dialog = false;
          });
          return;
        }
        if (this.invalidIntervalArr.length > 0) {
          this.invalidEntryPeriodDialogue = true;
          return;
        }

        //Send draft email or show dialog
        let newEventId = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/CREATE_EVENT'
        );
        let { id = null } = newEventId;
        if (id) {
          this.newEventId = id;
          this.successDialogMessage =
            'Your draft has been created. Would you like to continue editing or return to the main dashboard?';
          this.successDialog = true;
        } else {
          let error = newEventId.errorMessage || newEventId.message;
          throw error;
        }
      } catch (error) {
        this.dialogTitle = 'Error';
        this.dialogMessage =
          error ||
          'We are experiencing unusually high traffic at this time, please try to save your event again or contact us if this continues occuring.';
        this.dialogActionText = 'Close';
        this.dialogAction = () => {
          this.dialog = false;
        };
        return (this.dialog = true);
      }
    },

    async submitToLegalReview() {
      try {
        this.currentFormErrors = [];
        // highlight errors
        this.$refs.form.validate();
        if (this.validateSubmitToLegal.length > 0) {
          this.currentFormErrors.push(
            'One or more of your fields are invalid. Please fix these fields before proceeding.'
          );
        }
        this.validateEventDates();
        this.validateLegalAndLocation();
        if (this.currentFormErrors.length > 0) {
          this.displayDialog('Invalid Fields', '', 'Close', () => {
            this.dialog = false;
          });
          return;
        }
        if (this.invalidIntervalArr.length > 0) {
          this.invalidEntryPeriodDialogue = true;
          return;
        }

        //Update the status to Legal Pending;
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: 'Legal Pending',
        });

        //Send draft email or show dialog
        let newEventId = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/CREATE_EVENT'
        );
        let { id = null } = newEventId;
        if (id) {
          this.newEventId = id;

          //Send Email;
          let eventName = this.CURRENT_EVENT_OBJECT.name;
          this.sendEmail(
            this.legalReviewEmail(process.env.VUE_APP_LEGAL_TO_EMAIL, eventName)
          );
          this.sendEmail(
            this.userEventSubmittedLegalEmail(
              this.CURRENT_EVENT_OBJECT.createdByEmail,
              eventName
            )
          );

          this.successDialogMessage =
            'Your event has been submitted to legal for review. Would you like to continue editing or return to the main dashboard?';
          this.successDialog = true;
        } else {
          let error = newEventId.errorMessage || newEventId.message;
          throw error;
        }
      } catch (error) {
        this.dialogTitle = 'Error';
        this.dialogMessage =
          error ||
          'We are experiencing unusually high traffic at this time, please try to save your event again or contact us if this continues occuring.';
        this.dialogActionText = 'Close';
        this.dialogAction = () => {
          this.dialog = false;
        };
        return (this.dialog = true);
      }
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },
    //emails start;
    legalReviewEmail(toAddress, eventName) {
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.userAttributes.given_name;
      let family_name = this.userAttributes.family_name;
      let email = this.userAttributes.email;
      let legalDetails = this.CURRENT_EVENT_OBJECT.legalDetails;
      let contract = this.CURRENT_EVENT_OBJECT.legalContract
        ? this.CURRENT_EVENT_OBJECT.legalContract
        : 'N/A';

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Event Review',
        greeting_text: `Hi, Legal Team,`,
        'body-1_text':
          'An event is ready to be reviewed for legal approval. Click the button below to review the event details.',
        'cta-1_href':
          'https://www.t-mobileengagementhub.com/#/legal/' + this.newEventId,
        'cta-1_text': 'Review Event',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>' +
          'Legal Details: ' +
          legalDetails +
          '<br>' +
          'Contract, if provided: ' +
          contract,
        subject_text: 'Legal Review Request for Event ID: ' + this.newEventId,
        'text-part_text': '',
      };
      let emailTemplate = {
        Source: 'Legal Review <donotreply@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`],
        },
        TemplateData: JSON.stringify(templateData, null, 2),
      };
      return emailTemplate;
    },
    userEventSubmittedLegalEmail(toAddress, eventName) {
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.userAttributes.given_name;
      let family_name = this.userAttributes.family_name;
      let email = this.userAttributes.email;
      let legalDetails = this.CURRENT_EVENT_OBJECT.legalDetails;
      let contract = this.CURRENT_EVENT_OBJECT.legalContract
        ? this.CURRENT_EVENT_OBJECT.legalContract
        : 'N/A';

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Legal Review Request',
        greeting_text: `Greetings,`,
        'body-1_text': 'Your event has been sent to be reviewed by legal.',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>' +
          'Legal Details: ' +
          legalDetails +
          '<br>' +
          'Contract, if provided: ' +
          contract,
        subject_text:
          'Legal Review Request Receipt for Event ID: ' + this.newEventId,
        'text-part_text': '',
      };
      let emailTemplate = {
        Source:
          'Legal Review Submission <donotreply@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`],
        },
        TemplateData: JSON.stringify(templateData, null, 2),
      };
      return emailTemplate;
    },
    translationReviewEmail: function (toAddress, eventName) {
      let eventId = this.CURRENT_EVENT_OBJECT.id;
      let eventStartDate = this.CURRENT_EVENT_OBJECT.eventStartDate;
      let eventEndDate = this.CURRENT_EVENT_OBJECT.eventEndDate;
      let given_name = this.userAttributes.given_name;
      let family_name = this.userAttributes.family_name;
      let email = this.userAttributes.email;

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Translation Request',
        greeting_text: `Greetings,`,
        'body-1_text':
          'An event is ready to be translated. Click the button below to review the event details and submit the translations.',
        'cta-1_href':
          'https://www.t-mobileengagementhub.com/#/translate/' + eventId,
        'cta-1_text': 'Translate Event',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email,
        subject_text: 'Spanish Translation Request for Event ID: ' + eventId,
        'text-part_text': '',
      };
      let emailTemplate = {
        Source: 'Translation Review <donotreply@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`],
        },
        TemplateData: JSON.stringify(templateData, null, 2),
      };
      return emailTemplate;
    },
    userEventCreatedTranslationEmail: function (toAddress, eventName) {
      let eventId = this.CURRENT_EVENT_OBJECT.id;
      let eventStartDate = this.CURRENT_EVENT_OBJECT.eventStartDate;
      let eventEndDate = this.CURRENT_EVENT_OBJECT.eventEndDate;
      let given_name = this.userAttributes.given_name;
      let family_name = this.userAttributes.family_name;
      let email = this.userAttributes.email;

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Spanish Translations',
        greeting_text: `Hi, ${given_name}`,
        'body-1_text':
          'Your event has been sent to The Translation Team. You will receive an email alerting you when they have added the translations to your event and submitted the event to legal.',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>',
        subject_text: 'Spanish Translations Requested for Event ID: ' + eventId,
        'text-part_text': '',
      };
      let emailTemplate = {
        Source: 'Translation Submission <donotreply@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`],
        },
        TemplateData: JSON.stringify(templateData, null, 2),
      };
      return emailTemplate;
    },

    //emails end;
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', [
      'CURRENT_EVENT_OBJECT',
      'LOADING',
      'API_CALL_IN_PROGRESS',
    ]),
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`,
    }),
    brandWatcher() {
      return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.brand
        ? this.CURRENT_EVENT_OBJECT.brand
        : 'T-Mobile';
    },
    validateDraftEvtObj() {
      let errorArr = [];
      const errors = validate(this.CURRENT_EVENT_OBJECT, DraftEventSchema);

      if (errors.length > 0) {
        for (const { message } of errors) {
          errorArr.push(message);
        }
      } else {
        errorArr = [];
      }
      return errorArr;
    },
    validateSubmitToLegal() {
      let errorArr = [];
      let enterToWin =
        this.CURRENT_EVENT_OBJECT.contestType === 'Enter for a Chance to Win';
      const errors = validate(
        this.CURRENT_EVENT_OBJECT,
        enterToWin ? EnterToWinEventSchema : SubmitToLegalEventSchema
      );

      if (errors.length > 0) {
        for (const { message } of errors) {
          errorArr.push(message);
        }
      } else {
        errorArr = [];
      }
      return errorArr;
    },
    validateSubmitToTranslations() {
      let errorArr = [];
      let enterToWin =
        this.CURRENT_EVENT_OBJECT.contestType === 'Enter for a Chance to Win';
      const errors = validate(
        this.CURRENT_EVENT_OBJECT,
        enterToWin ? EnterToWinEventSchema : SubmitToTranslationSchema
      );

      if (errors.length > 0) {
        for (const { message } of errors) {
          errorArr.push(message);
        }
      } else {
        errorArr = [];
      }
      return errorArr;
    },
    showTranslationButton() {
      return this.CURRENT_EVENT_OBJECT.eventLanguage === 'bi';
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.UNTRACKED_CHANGES_TO_EVENT) {
      this.untrackedChangesDialog = true;
      this.targetToRoute = to.fullPath || to.path;
    } else {
      next();
    }
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return;

    window.removeEventListener('resize', this.onResize, { passive: true });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .event-brand .v-input--selection-controls__input {
  display: none;
}

::v-deep .v-input--checkbox {
  margin-top: 0;
}

::v-deep .v-card__text {
  color: var(--v-greyDarken3-base) !important;
}

.date-time-picker-wrap .v-text-field {
  margin-top: 0px;
}

::v-deep .v-input--radio-group {
  margin-top: 0;
  padding-top: 0;
}

.form-buttons {
  margin-top: 50px;
  margin-bottom: 80px;
}
::v-deep .v-btn {
  font-family: 'Tele-Grotesk Next-Ultra';
  // font-size: 18px;
  letter-spacing: 0.5px;
}

::v-deep .app--metro .v-btn {
  font-family: 'TT-Norms Bold';
}

::v-deep .v-btn--outlined {
  border-width: 2px !important;
}

.preview-link {
  text-decoration: none;
}
</style>
