import { Auth, API } from 'aws-amplify';

const requestHeaders = async () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`
  };
};

const enableUser = async function({ dispatch, getters, commit }, username) {
  if (!username) {
    throw Error('ERROR::ACCOUNT_SIGN_IN: No parameters provided.');
  }
  let path = '/enableUser';
  try {
    let payload = {
      body: {
        username
      },
      headers: await requestHeaders()
    };
    let req = await API.post('AdminQueries', path, payload);
    if (req.message.split(' ')[0] === 'Enabled') {
      this.userEnabled = true;
      return req.message;
    }
    throw Error('Error enabling user', req);
  } catch (error) {
    throw Error(error);
  }
};

export default enableUser;
