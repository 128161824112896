<template>
  <v-container class="py-0">
    <h2>Batch Upload Attendees</h2>
    <v-file-input
      ref="fileInput"
      accept=".csv"
      v-model="participantFile"
      label="Attendee Import File"
      append-icon="mdi-alert-circle"
      @change="handleParticipantsFile"
      :rules="[
        nonEmptyRule('A .csv file is required.')
      ]"
    />
    <v-row class="download-buttons" justify="center">
      <v-col cols="12" md="6">
        <v-btn
          color="primary"
          elevation="0"
          tile
          block
          x-large
          :loading="loading"
          :disabled="!participantJson"
          @click="insertParticipants"
        >
          Upload
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" text class="px-6 btn__primary">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
const csv = require('csvtojson');

export default {
  name: 'BatchUploadAction',
  data() {
    return {
      loading: false,
      participantFile: null,
      participantJson: null,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null,
      nonEmptyRule(inputError) {
        return v => v === 0 || v === false || !!v || inputError;
      }
    };
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', [
      'CURRENT_EVENT_OBJECT',
      'LOADING',
      'API_CALL_IN_PROGRESS'
    ]),
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`
    })
  },
  methods: {
    async handleParticipantsFile(file) {
      try {
        let that = this;
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          csv({
            ignoreEmpty: true,
            nullObject: true
          })
            .fromString(reader.result)
            .then(function(json) {
              that.participantJson = json;
            });
        };
      } catch (e) {
        this.participantFile = null;
        this.participantJson = null;
        this.displayDialog(
          'Error Reading File',
          e,
          'Close',
          () => (this.dialog = false)
        );
      }
    },
    insertParticipants() {
      let id = this.CURRENT_EVENT_OBJECT.id;
      let email = this.userAttributes.email;
      if (!id || !this.validEmail(email)) {
        return !id ? 'event ID is null' : 'invalid email';
      }
      let payload = {
        id: id,
        participants: this.participantJson,
        email: email
      };
      this.loading = true;
      // don't want to await because it will take too long
      this.$store.dispatch(
        'EVENT_MANAGER_STORE/BATCH_INSERT_PARTICIPANTS',
        payload
      );
      this.loading = false;
      this.participantFile = null;
      this.participantJson = null;
      this.$refs.fileInput.resetValidation();
      this.displayDialog(
        'Started Batch Upload',
        'You will receive an email when it is complete.',
        'Close',
        () => (this.dialog = false)
      );
    },
    validEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    }
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 35px;
  margin-top: 50px;
}

.download-buttons {
  margin-bottom: 35px;
}
p {
  text-align: center;
}
::v-deep .v-input .mdi-alert-circle {
  display: none;
}
::v-deep .error--text .mdi-alert-circle {
  display: block;
}
</style>
