var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:_vm.$vuetify.theme.themes.light.primary == '#e20074'
      ? 'app--tmobile'
      : 'app--metro'},[(
      !(
        _vm.noHeaderFooterMap.includes(_vm.$route.name) ||
        _vm.noHeaderMap.includes(_vm.$route.name)
      )
    )?_c('Header',{attrs:{"show-menu":_vm.IS_LOGGED_IN &&
        _vm.$route.path !== '/login' &&
        _vm.$route.path !== '/password/reset/request/form' &&
        _vm.$route.path !== '/register'}}):_vm._e(),_c('v-main',[(_vm.renderView)?_c('router-view'):_vm._e()],1),_c('v-footer',_vm._b({},'v-footer',_vm.footerAttributes,false),[(!_vm.noHeaderFooterMap.includes(_vm.$route.name))?_c('Footer',{attrs:{"force-english":_vm.$route.name != 'Attendee Engagement' &&
          _vm.$route.name != 'Engagements Preview' &&
          _vm.$route.name != 'Check In' &&
          _vm.$route.name != 'Official Rules'}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }