<template>
  <div>
    <v-row
      no-gutters
      class="black action-bar action-bar--desktop"
      justify="center"
    >
      <v-col cols="12" lg="2">
        <router-link :to="'/resources'">
          <v-btn block color="#000000" elevation="0" dark tile>
            Edit Resources
          </v-btn>
        </router-link>
      </v-col>
      <v-col cols="12" lg="2">
        <v-btn
          block
          color="#000000"
          class="px-0"
          :class="{ 'action-active': curAction == 'generateReport' }"
          elevation="0"
          dark
          tile
          @click="changeAction('generateReport')"
        >
          Reports
        </v-btn>
      </v-col>
      <v-col cols="12" lg="2">
        <router-link :to="'/event/create/batch'">
          <v-btn block color="#000000" elevation="0" dark tile>
            Create Batch Import Event
          </v-btn>
        </router-link>
      </v-col>
      <v-col cols="12" lg="2">
        <v-btn
          block
          color="#000000"
          class="px-0"
          :class="{ 'action-active': curAction == 'setAlert' }"
          elevation="0"
          dark
          tile
          @click="changeAction('setAlert')"
        >
          Set Alert
        </v-btn>
      </v-col>
      <v-col cols="12" lg="2">
        <v-btn
          block
          color="#000000"
          class="px-0"
          elevation="0"
          dark
          tile
          @click="TOGGLE_AVE_DIALOG"
        >
          Radio Templates
        </v-btn>
      </v-col>
    </v-row>
    <v-expansion-panels
      accordion
      dark
      class="black action-bar action-bar--mobile"
      v-model="EXPANSION_PANEL_ACTIVE"
    >
      <v-expansion-panel dark class="black action-bar">
        <v-expansion-panel-header expand-icon="$Caret">
          <v-col cols="12" lg="2" class="expansion-header">Admin Actions</v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-col cols="12" lg="2">
            <router-link :to="'/resources'">
              <v-btn block color="#000000" elevation="0" dark tile>
                Edit Resources
              </v-btn>
            </router-link>
          </v-col>
          <v-col cols="12" lg="2">
            <v-btn
              block
              color="#000000"
              class="px-0"
              :class="{ 'action-active': curAction == 'generateReport' }"
              elevation="0"
              dark
              tile
              @click="changeAction('generateReport')"
            >
              Reports
            </v-btn>
          </v-col>
          <v-col cols="12" lg="2">
            <router-link :to="'/event/create/batch'">
              <v-btn block color="#000000" elevation="0" dark tile>
                Create Batch Import Event
              </v-btn>
            </router-link>
          </v-col>
          <v-col cols="12" lg="2">
            <v-btn
              block
              color="#000000"
              class="px-0"
              :class="{ 'action-active': curAction == 'setAlert' }"
              elevation="0"
              dark
              tile
              @click="changeAction('setAlert')"
            >
              Set Alert
            </v-btn>
          </v-col>
          <v-col cols="12" lg="2">
            <v-btn
              block
              color="#000000"
              class="px-0"
              elevation="0"
              dark
              tile
              @click="TOGGLE_AVE_DIALOG"
            >
              Radio Templates
            </v-btn>
          </v-col>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'AdminActionBar',
  props: {
    curAction: {
      type: String,
      default: null
    }
  },
  methods: {
    ...mapMutations('UI_STORE', ['TOGGLE_EXPANSION_PANEL']),
    ...mapMutations('AVE_CANVAS_STORE', ['TOGGLE_AVE_DIALOG']),
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    }
  },
  computed: {
    ...mapState('UI_STORE', ['EXPANSION_PANEL_ACTIVE']),

    EXPANSION_PANEL_ACTIVE: {
      get() {
        return this.$store.state.UI_STORE.EXPANSION_PANEL_ACTIVE;
      },
      set(value) {
        this.$store.commit('UI_STORE/TOGGLE_EXPANSION_PANEL', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn,
::v-deep .v-expansion-panels {
  font-family: 'Tele-Grotesk Next-Ultra', Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  height: 80px !important;
}

::v-deep .v-btn::before {
  color: var(--v-primary-base);
}

::v-deep .v-btn:hover::before,
::v-deep .v-btn.action-active::before {
  opacity: 1;
}

::v-deep .v-expansion-panel-header__icon {
  position: absolute;
  right: 24px;
}

::v-deep .v-expansion-panel-content__wrap > div {
  border-top: 1px solid white;
}

a {
  text-decoration: none;
}

.action-bar {
  min-height: 80px;
}

.expansion-header {
  font-size: 16px;
  text-align: center;
}

.v-expansion-panels {
  border-radius: 0;
}

@media only screen and (min-width: 1200px) {
  .action-bar--desktop {
    display: flex;
  }

  .action-bar--mobile {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .action-bar--desktop {
    display: none;
  }

  .action-bar--mobile {
    display: flex;
  }
}
</style>
