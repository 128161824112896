<template>
  <v-container class="py-0">
    <h2>Archive Event</h2>
    <p class="mt-2">
      This event will be archived, and will no longer appear in the Event
      Dashboard. To proceed please indicate why this event is being archived,
      then click the Archive Event button below.
    </p>
    <v-radio-group
      v-model="archiveReason"
      :rules="[nonEmptyRule('You must select a reason for archival.')]"
      hide-details="auto"
      row
      required
    >
      <v-row>
        <v-col cols="12">
          <v-radio
            value="eventCanceled"
            hide-details="auto"
            label="Event was canceled"
          >
          </v-radio>
        </v-col>
        <v-col cols="12">
          <v-radio value="testEvent" hide-details="auto" label="Test Event">
          </v-radio>
        </v-col>
        <v-col cols="12">
          <v-radio value="other" hide-details="auto">
            <template v-slot:label>
              <div>
                <v-text-field
                  v-model="archiveMessage"
                  class="otherText"
                  label="Other"
                  counter="100"
                ></v-text-field>
              </div>
            </template>
          </v-radio>
        </v-col>
      </v-row>
    </v-radio-group>
    <div class="archive-buttons">
      <v-btn
        class="px-6 mr-4 exit-btn"
        tile
        elevation="2"
        color="primary"
        outlined
        @click="changeAction(null)"
      >
        Exit
      </v-btn>
      <v-btn
        class="px-6 btn__primary"
        tile
        :disabled="
          archiveReason == null ||
            (archiveReason == 'other' &&
              (archiveMessage == '' || archiveMessage == null))
        "
        @click="confirmArchive = true"
      >
        Archive Event
      </v-btn>
    </div>
    <v-dialog
      v-model="confirmArchive"
      persistent
      max-width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">Confirm Archive</v-card-title>
        <v-card-text class="text-center">
          Are you sure you want to archive this event?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="px-6 modal-button-outlined"
            color="primary"
            outlined
            tile
            @click="confirmArchive = false"
            >No</v-btn>
          <v-btn
            class="px-6 btn__primary"
            text
            :loading="archiveCallOut"
            @click="archiveEventWrapper()"
            >Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="responseReceived"
      persistent
      max-width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">Archival Success</v-card-title>
        <v-card-text class="text-center">
          Your event has been archived.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-spacer></v-spacer>
          <v-btn
            class="px-6 modal-button-outlined"
            color="primary"
            outlined
            tile
            @click="redirectToHome()"
            >OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'ArchiveEventAction',

  data() {
    return {
      archiveReason: null,
      archiveMessage: null,
      confirmArchive: false,
      archiveCallOut: false,
      responseReceived: false,
      nonEmptyRule(inputError) {
        return v => v === 0 || v === false || !!v || inputError;
      }
    };
  },
  methods: {
    ...mapActions({
      sendEmail: 'admin/sendEmail'
    }),
    archiveEventWrapper: async function() {
      this.archiveCallOut = true;
      var realMessage = null;
      if (this.archiveReason == 'eventCanceled') {
        realMessage = 'Event was canceled.';
      } else if (this.archiveReason == 'testEvent') {
        realMessage = 'Test Event.';
      } else {
        realMessage = this.archiveMessage;
      }
      let response = await this.$store.dispatch(
        'EVENT_MANAGER_STORE/ARCHIVE_EVENT',
        { id: this.$route.params.id, archiveMessage: realMessage }
      );
      this.confirmArchive = false;
      this.archiveCallOut = false;
      this.responseReceived = true;
      // send event archived email if status is Legal Pending
      if (this.CURRENT_EVENT_OBJECT.status == 'Legal Pending') {
        let eventName = this.CURRENT_EVENT_OBJECT.name;
        this.sendEmail(
          this.eventArchivedEmail(process.env.VUE_APP_LEGAL_TO_EMAIL, eventName)
        );
      }
    },
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    },
    redirectToHome() {
      this.changeAction(null);
      this.$router.push('/');
    },
    eventArchivedEmail: function(toAddress, eventName) {
      let eventId = this.$route.params.id;
      let eventStartDate = this.CURRENT_EVENT_OBJECT.eventStartDate;
      let eventEndDate = this.CURRENT_EVENT_OBJECT.eventEndDate;
      let given_name = this.userAttributes.given_name;
      let family_name = this.userAttributes.family_name;
      let email = this.userAttributes.email;

      let templateData = {
        preheader_text: '',
        header_text: 'Legal Review Cancelled',
        greeting_text: `Hi, Legal Team,`,
        'body-1_text':
          'An event that had previously been submitted for review has been archived. Legal review of this event is no longer required.',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          '\
          Event ID: ' +
          eventId +
          '<br>' +
          '\
          Event Start Date: ' +
          eventStartDate +
          '<br>' +
          '\
          Event End Date: ' +
          eventEndDate +
          '<br>' +
          '\
          Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email,
        subject_text: 'Review Cancelled for Event ID: ' + eventId,
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'Do Not Reply <donotreply@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    }
  },
  computed: {
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`
    }),
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT', 'LOADING'])
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-top: 50px;
}
.archive-buttons {
  margin-bottom: 35px;
}
.exit-btn ::v-deep .v-btn__content {
  color: black;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}

.col:not(:last-child) {
  padding-top: 16px;
}

.col:last-child {
  padding-bottom: 16px;
}

.otherText {
  width: 50vw;
}
</style>
