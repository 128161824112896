<template>
  <vue-recaptcha
    :sitekey="this.sitekey"
    load-recaptcha-script
    type="login"
    @verify="onVerify"
    @expired="onExpired"
    @render="onRender"
    @error="onError"
    ref="recaptcha"
  >
  </vue-recaptcha>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  components: {
    VueRecaptcha
  },
  data() {
    return {
      sitekey: process.env.VUE_APP_SITE_KEY
    };
  },
  props: {
    onVerify: {
      type: Function,
      default: response => {}
    },
    onExpired: {
      type: Function,
      default: () => {}
    },
    onRender: {
      type: Function,
      default: id => {}
    },
    onError: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    reset() {
      this.$refs.recaptcha.reset();
    }
  }
};
</script>

<style></style>
