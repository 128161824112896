export default async function LOG_AUTH_STATE({ commit }, params0) {
  if (!params0) {
    return Promise.reject(
      new Error('ERROR::LOG_AUTH_STATE: No parameters provided.')
    );
  }
  // We can use this to t

  commit('SET_AUTH_MODULE_STATE', params0.event);
  if (params0.data && params0.data.message) {
    commit('SET_AUTH_ERROR_MESSAGE', params0.data.message);
  }
  return Promise.resolve();
}
