<template>
  <v-container class="py-0">
    <h2>Event Collateral</h2>
    <div class="my-4 v-container">
      <section class="form-section">
        <div class="">
          <div class="justify-center row">
            <div class="col-md-12 col-11">
              <fieldset class="event-organization">
                <legend class="gutter-padding">
                  <span>Display Web QR Code</span>
                </legend>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--light"
                />
              </fieldset>
              <div class="row">
                <div class="col-md-6 col-lg-6 col-6">
                  <router-link
                    :to="`/webqr/${$route.params.id}`"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <button
                      :href="href"
                      @click="navigate"
                      type="button"
                      class=" flex-grow-2 btn__primary events-search-form__submit v-btn v-btn--block v-btn--is-elevated v-btn--has-bg v-btn--tile theme--light v-size--default magenta app-button text-heading white--text"
                      style="height: 50px;"
                    >
                      <span class="v-btn__content">Display Web QR Code </span>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="form-section">
        <div class="">
          <div class="justify-center row">
            <div class="col-md-12 col-11">
              <fieldset class="event-organization">
                <legend class="gutter-padding">
                  <span>Printable QR Codes</span>
                </legend>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--light"
                />
              </fieldset>
              <div class="row">
                <div
                  class="col-md-6 col-lg-6 col-6"
                  v-for="opt in printableButtonList"
                  :key="opt.id"
                >
                  <button
                    type="button"
                    class=" flex-grow-2 btn__primary events-search-form__submit v-btn v-btn--block v-btn--is-elevated v-btn--has-bg v-btn--tile theme--light v-size--default magenta app-button text-heading white--text"
                    style="height: 50px;"
                    @click="downloadItem(opt.value)"
                  >
                    <span class="v-btn__content">{{ opt.text }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
            <section class="form-section">
        <div class="">
          <div class="justify-center row">
            <div class="col-md-12 col-11">
              <fieldset class="event-organization">
                <legend class="gutter-padding">
                  <span>Radio Web Banners</span>
                </legend>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--light"
                />
              </fieldset>
              <div class="row">
                <div
                  class="col-md-6 col-lg-6 col-6"
>
                  <button
                    type="button"
                    class=" flex-grow-2 btn__primary events-search-form__submit v-btn v-btn--block v-btn--is-elevated v-btn--has-bg v-btn--tile theme--light v-size--default magenta app-button text-heading white--text"
                    style="height: 50px;"
                     @click="TOGGLE_AVE_FLYOUT(true)"
                  >
                    <span class="v-btn__content">Open Generator</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="form-section">
        <div class="">
          <div class="justify-center row">
            <div class="col-md-12 col-11">
              <fieldset class="event-organization">
                <legend class="gutter-padding">
                  <span>Embeddable Registration Form</span>
                </legend>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--light"
                />
              </fieldset>
              <p>
                <b>
                  If you are working with a radio station and they want to
                  display the Engagement Hub Form on their website, copy the
                  below and send to your contact at the radio station.
                </b>
              </p>
              <div class="my-4">
                <code class="py-4">
                  {{ iframeUrl }}
                </code>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <PDFGenerator
      ref="generator"
      :cur-event="CURRENT_EVENT_OBJECT"
      @flyerGenerationComplete="flyerLoading = false"
      @aFrameGenerationComplete="aFrameLoading = false"
    />
  </v-container>
</template>

<script>
import PDFGenerator from '@/components/PDFGenerator.vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'EventCollateralAction',
  async created() {
    await this.fetchTranslations(this.$route.params.id);
  },
  components: {
    PDFGenerator
  },
  data() {
    return {
      generateNew: false,
      newCodeLoading: false,
      englishFlyerLoading: false,
      englishAFrameLoading: false,
      spanishFlyerLoading: false,
      spanishAFrameLoading: false
    };
  },
  methods: {
    ...mapActions(['fetchTranslations']),
    ...mapMutations('AVE_CANVAS_STORE', ['TOGGLE_AVE_FLYOUT']),
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    },
    async downloadItem(itemType) {
      console.log('Download target is', itemType);

      switch (itemType) {
        case 'englishFlyer':
          this.englishFlyerLoading = true;
          this.generateNew = true;
          await this.$refs.generator.createFlyerPDF('en');
          break;
        case 'englishAFrame':
          this.englishAFrameLoading = true;
          this.generateNew = true;
          await this.$refs.generator.createAFramePDF('en');
          break;
        case 'spanishFlyer':
          this.spanishFlyerLoading = true;
          this.generateNew = true;
          await this.$refs.generator.createFlyerPDF('es');
          break;
        case 'spanishAFrame':
          this.spanishAFrameLoading = true;
          this.generateNew = true;
          await this.$refs.generator.createAFramePDF('es');
          break;
        default:
          console.log('No action for', itemType);
      }
    },
    displayQRCodeButton: event => {
      if (event.experience && event.experience === 'Gametime') {
        return false;
      } else {
        return true;
      }
    },
    async loadFlyer() {
      this.englishFlyerLoading = true;
      this.$nextTick(async function() {
        await this.$refs.generator.createFlyerPDF('en');
        this.englishFlyerLoading = false;
      });
    },
    async loadSpanishFlyer() {
      this.spanishFlyerLoading = true;
      this.$nextTick(async function() {
        await this.$refs.generator.createFlyerPDF('es');
        this.spanishFlyerLoading = false;
      });
    },
    async loadAFrame() {
      this.englishAFrameLoading = true;
      this.$nextTick(async function() {
        await this.$refs.generator.createAFramePDF('en');
        this.englishAFrameLoading = false;
      });
    },
    async loadSpanishAFrame() {
      this.spanishAFrameLoading = true;
      this.$nextTick(async function() {
        await this.$refs.generator.createAFramePDF('es');
        this.spanishAFrameLoading = false;
      });
    }
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    ...mapGetters(['translations']),
    printableButtonList() {
      return this.CURRENT_EVENT_OBJECT.eventLanguage === 'bi'
        ? [
            { text: 'Download English Flyer', value: 'englishFlyer' },
            { text: 'Download English A-Frame', value: 'englishAFrame' },
            { text: 'Download Spanish Flyer', value: 'spanishFlyer' },
            { text: 'Download Spanish A-Frame', value: 'spanishAFrame' }
          ]
        : [
            { text: 'Download Flyer', value: 'englishFlyer' },
            { text: 'Download A-Frame', value: 'englishAFrame' }
          ];
    },
    iframeUrl() {
      //Will prob have to acount for checkin type events?
      return `<iframe title="Engagement Hub" frameborder="0" allowfullscreen src="https://www.t-mobileengagementhub.com/#/engagements/register/${this.$route.params.id}"></iframe>`;
    }
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 35px;
  margin-top: 50px;
}

.download-buttons {
  margin-bottom: 35px;
  button {
    margin-bottom: 5px;
    min-width: 40% !important;
  }
  button:nth-child(2n) {
    margin-left: 5px;
  }
}
</style>
