<template>
  <v-container class="py-0">
    <h2>Event Codes for On-Site Event Staff</h2>
    <h3>What is an Event Code for On-Site Staff?</h3>
    <p>
      When your event has on-site event staff who do not have full access to The
      T-Mobile Engagement Hub, they can create an Event Staff account and use an
      Event Code to access limited event features, including the ability to view
      winners and select alternate winners. Event codes will be active once an
      event begins until the event is complete.
    </p>
    <h3>Generate Event Code for On-Site Event Staff</h3>
    <p>
      Generate Event Code' button below and provide the code to your event staff
      and/or event staffing agency. All event staff should use the same code.
      Generating a new code will de-activate the previously generated code.
    </p>
    <div class="download-buttons">
      <v-btn
        color="primary"
        elevation="0"
        dark
        tile
        @click="changeAction('viewAccessCodeAction')"
      >
        Generate Access Code
      </v-btn>
    </div>
    <h3>Current Access Code:</h3>
    <p>
      {{
        ( CURRENT_EVENT_OBJECT.event_code && CURRENT_EVENT_OBJECT.event_code != '' )
        ? CURRENT_EVENT_OBJECT.event_code
        : 'None'
      }}
    </p>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'AccessCodeAction',
  data() {
    return {
      englishFlyerLoading: false,
      englishAFrameLoading: false,
      spanishFlyerLoading: false,
      spanishAFrameLoading: false
    };
  },
  methods: {
    ...mapMutations('UI_STORE', ['TOGGLE_EXPANSION_PANEL']),
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    }
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT'])
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 35px;
  margin-top: 50px;
}

.download-buttons {
  margin-bottom: 35px;
  button {
    margin-bottom: 5px;
    min-width: 40% !important;
  }
  button:nth-child(2n) {
    margin-left: 5px;
  }
}
</style>
