<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>4b.Checkbox/Deselected</title>
      <g id="Dev-Pack" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-781.000000, -262.000000)" id="Group">
              <g transform="translate(781.000000, 262.000000)">
                  <rect id="Rectangle" stroke="#46196E" stroke-width="2" fill="#FFFFFF" x="1" y="1" width="18" height="18"></rect>
                  <path d="M3.8948891,14.1494696 L8.03182257,9.98746384 L3.8948891,5.85053038 C3.3683703,5.32401157 3.3683703,4.44648023 3.8948891,3.8948891 C4.44648023,3.3683703 5.32401157,3.3683703 5.85053038,3.8948891 L9.98746384,8.03182257 L14.1494696,3.8948891 C14.6759884,3.3683703 15.5535198,3.3683703 16.1051109,3.8948891 C16.6316297,4.44648023 16.6316297,5.32401157 16.1051109,5.85053038 L11.9431051,9.98746384 L16.1051109,14.1494696 C16.6316297,14.6759884 16.6316297,15.5535198 16.1051109,16.1051109 C15.5535198,16.6316297 14.6759884,16.6316297 14.1494696,16.1051109 L9.98746384,11.9431051 L5.85053038,16.1051109 C5.32401157,16.6316297 4.44648023,16.6316297 3.8948891,16.1051109 C3.3683703,15.5535198 3.3683703,14.6759884 3.8948891,14.1494696 Z" id="Path" fill="#9646C3"></path>
              </g>
          </g>
      </g>
  </svg>
</template>

<script>
export default {
  name: 'MetroFullCheckbox'
};
</script>