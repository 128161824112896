import { Auth, API } from 'aws-amplify';

const requestHeaders = async () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`
  };
};

const getUser = async function({ dispatch, getters, commit }, username) {
  if (!username) {
    throw Error('ERROR::ACCOUNT_SIGN_IN: No parameters provided.');
  }
  let path = '/getUser';
  try {
    let payload = {
      queryStringParameters: {
        username
      },
      headers: await requestHeaders()
    };
    let req = await API.get('AdminQueries', path, payload);
    if (req && req.Username && req.UserAttributes) {
      // TODO: Set User Info to store for display
      return req;
    }
    throw Error('Error fetching user information.');
  } catch (error) {
    throw Error(error);
  }
};

export default getUser;
