<template tag="nav" class="app-navigation app-navigation__nav">
  <div class="app-navigation">
    <nav class="justify-end app-navigation__nav d-flex">
      <ul class="d-none d-md-none d-md-flex">
        <li v-if="!isAmbassadorRoute">
          <router-link
            :class="
              currentRoutePath == '/dashboard' ||
              currentRoutePath == '/legaldashboard' ||
              currentRoutePath == '/translationdashboard'
                ? 'active'
                : ''
            "
            :to="checkGroup()"
          >
            My Dashboard
          </router-link>
        </li>
        <li v-if="checkGroup() === '/dashboard'">
          <router-link
            :class="currentRoutePath == '/allevents' ? 'active' : ''"
            to="/allevents"
          >
            All Events
          </router-link>
        </li>
        <li v-if="checkGroup() === '/dashboard'">
          <router-link
            :class="currentRoutePath == '/event/create' ? 'active' : ''"
            to="/event/create"
          >
            Create Event
          </router-link>
        </li>
        <li v-if="!isAmbassadorRoute">
          <router-link
            v-if="!isTranslator"
            :class="currentRoutePath == '/resources' ? 'active' : ''"
            to="/resources"
          >
            Resources
          </router-link>
        </li>
        <li v-if="isInAdminGroup">
          <router-link
            :class="currentRoutePath == '/admin' ? 'active' : ''"
            to="/admin"
          >
            Admin
          </router-link>
        </li>
        <li>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                color="transparent"
                class="pl-0 pr-0 app-navigation__user"
                v-on="on"
              >
                <v-icon x-large color="primary"> mdi-account-circle </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in currentProfileDropdownMenu"
                :key="item.id"
              >
                <v-list-item-title>
                  <router-link :to="item.uri">{{ item.title }}</router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </li>
      </ul>
      <!-- stubbing this mobile dropdown here for now until discusssion is made about mobile -->
      <v-menu offset-y min-width="250px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="transparent"
            v-bind="attrs"
            class="pl-0 pr-0 app-navigation__mobile-menu d-md-none"
            v-on="on"
          >
            <v-icon x-large color="#E44475"> mdi-menu </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="!isAmbassadorRoute">
            <v-list-item-title>
              <router-link
                :class="
                  currentRoutePath == '/dashboard' ||
                  '/legaldashboard' ||
                  '/translationdashboard'
                    ? 'active'
                    : ''
                "
                :to="checkGroup()"
              >
                My Dashboard
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="checkGroup() === '/dashboard'">
            <v-list-item-title>
              <router-link
                :class="currentRoutePath == '/allevents' ? 'active' : ''"
                to="/allevents"
              >
                All Events
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="checkGroup() === '/dashboard'">
            <v-list-item-title>
              <router-link
                :class="currentRoutePath == '/event/create' ? 'active' : ''"
                to="/event/create"
              >
                Create Event
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isAmbassadorRoute">
            <v-list-item-title v-if="!isTranslator">
              <router-link
                :class="currentRoutePath == '/resources' ? 'active' : ''"
                to="/resources"
              >
                Resources
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isInAdminGroup">
            <v-list-item-title>
              <router-link
                :class="currentRoutePath == '/admin' ? 'active' : ''"
                to="/admin"
              >
                Admin
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title v-if="!isAmbassadorRoute">
              <router-link
                :class="currentRoutePath == '/profile' ? 'active' : ''"
                to="/profile"
              >
                Profile
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <router-link
                :class="currentRoutePath == '/logout' ? 'active' : ''"
                to="/logout"
              >
                Sign Out
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MainNavigation',
  computed: {
    currentRoutePath() {
      return this.$route.path;
    },
    currentProfileDropdownMenu() {
      return this.isAmbassadorRoute ? this.ambassadorNav : this.userNav;
    },
    isAmbassadorRoute() {
      let path = this.$route.path;
      return path === '/eventstaffdashboard' || path.includes('staffactions');
    },
    ...mapGetters({
      USER_GROUPS: `account/USER_GROUPS`
    })
  },
  data() {
    return {
      userNav: [
        {
          id: 1,
          title: 'My Profile',
          uri: '/profile'
        },
        {
          id: 2,
          title: 'Log Out',
          uri: '/logout'
        }
      ],
      ambassadorNav: [
        {
          id: 123,
          title: 'Log Out',
          uri: '/logout'
        }
      ],
      isInAdminGroup: false,
      isTranslator: false
    };
  },
  created() {
    this.isInAdminGroup = this.USER_GROUPS.indexOf('Admin') !== -1;
    this.isTranslator = this.USER_GROUPS.indexOf('SLTranslator') !== -1;
  },
  methods: {
    checkGroup() {
      if (this.USER_GROUPS.indexOf('Legal') !== -1) {
        return '/legaldashboard';
      } else if (this.USER_GROUPS.indexOf('SLTranslator') !== -1) {
        return '/translationdashboard';
      } else if (this.USER_GROUPS.indexOf('Ambassador') !== -1) {
        return '/eventstaffdashboard';
      } else {
        return '/dashboard';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 960px) {
  .app-navigation__mobile-menu {
    display: none;
  }
}

.app-navigation__nav {
  white-space: nowrap;
}

.app-navigation__mobile-menu,
.app-navigation__user {
  box-shadow: none;
}

.v-application a {
  text-decoration: none;
  color: #000;
}

.app-navigation ul {
  display: flex;
  list-style: none;
  align-items: center;
}

.app-navigation ul > li {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}

.app-navigation ul > li:last-child {
  margin-right: 0px;
}

.app-navigation ul > li > a,
.v-application > .v-menu__content .v-list a {
  text-decoration: none;
  color: black;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.app-navigation ul > li > a.active:after {
  content: '';
  height: 6px;
  background: var(--v-secondary-base);
  position: absolute;
  bottom: calc(-100% - 4px);
  width: 100%;
  left: 0;
}
/* we want to offset the position since the logo changes size at 1024px */
@media only screen and (max-width: 1024px) {
  .app-navigation ul > li > a.active:after {
    bottom: calc(-100% + 1px);
  }
}
/* mobile nav menu */
.v-application > .v-menu__content {
  text-align: center;
}

.v-application > .v-menu__content .v-list a {
  display: inline-block;
  width: 100%;
}

::v-deep .app-navigation__mobile-menu {
  background: white;
  z-index: 99;
  .v-btn__content {
    background: white;
  }
  &[aria-expanded='true'] {
    box-shadow: 0px -5px 5px -5px rgb(0 0 0 / 20%),
      0px -2px 5px 1px rgb(0 0 0 / 14%), 0px -5px 14px -5px rgb(0 0 0 / 12%);
  }
}
</style>
