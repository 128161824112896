<template>
  <div>
    <v-form class="event-form" ref="form" v-model="valid">
      <div class="grey" v-if="!isEditForm">
        <EventFormSectionWrapper :card="false" :is-mobile="isMobile" wide>
          <div slot="section-content">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <EventFormSectionWrapper
                    :card="false"
                    :is-mobile="isMobile"
                    wide
                    compact
                  >
                    <span slot="section-header">Brand Selection</span>
                    <div slot="section-content">
                      <BrandFields
                        :is-event-archived="isEventArchived"
                        :row="false"
                      />
                    </div>
                  </EventFormSectionWrapper>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </EventFormSectionWrapper>
      </div>
      <div class="wide-container">
        <EventFormSectionWrapper :is-mobile="isMobile" wide>
          <div slot="section-content">
            <v-container>
              <v-row>
                <v-col cols="0" md="1" class="py-0 my-0"> </v-col>
                <v-col cols="12" md="5" sm="12" v-if="isEditForm">
                  <EventFormSectionWrapper
                    :card="false"
                    :is-mobile="isMobile"
                    wide
                    compact
                  >
                    <span slot="section-header">Brand Selection</span>
                    <div slot="section-content">
                      <BrandFields
                        :is-event-archived="isEventArchived"
                        :row="false"
                      />
                    </div>
                  </EventFormSectionWrapper>
                </v-col>
                <v-col cols="12" :md="isEditForm ? 5 : 12" sm="12">
                  <EventFormSectionWrapper
                    :card="false"
                    :is-mobile="isMobile"
                    wide
                    compact
                  >
                    <span slot="section-header">Organizations</span>
                    <div slot="section-content">
                      <OrganizationFields
                        :is-event-archived="isEventArchived"
                        wide
                      />
                    </div>
                  </EventFormSectionWrapper>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </EventFormSectionWrapper>
        <EventFormSectionWrapper wide>
          <div slot="section-content">
            <v-container>
              <EventFormSectionWrapper :card="false" wide compact>
                <span slot="section-header">Basic Information</span>
                <div slot="section-content">
                  <InfoFields
                    :is-event-archived="isEventArchived"
                    :batch="isBatchEvent"
                    wide
                  />
                  <!-- <InfoFields wide /> -->
                </div>
              </EventFormSectionWrapper>
            </v-container>
          </div>
        </EventFormSectionWrapper>
        <EventFormSectionWrapper wide>
          <div slot="section-content">
            <v-container>
              <EventFormSectionWrapper :card="false" wide compact>
                <span slot="section-header">Date &amp; Location</span>
                <div slot="section-content">
                  <DateLocationFields
                    :is-event-archived="isEventArchived"
                    :batch="isBatchEvent"
                    wide
                  />
                </div>
              </EventFormSectionWrapper>
            </v-container>
          </div>
        </EventFormSectionWrapper>
        <EventFormSectionWrapper wide v-if="hasWinnerSelectionSection">
          <div slot="section-content">
            <v-container>
              <EventFormSectionWrapper :card="false" wide compact>
                <span slot="section-header">Winner Selection</span>
                <div slot="section-content">
                  <!-- <InfoFields wide /> -->
                  <WinnerFields :is-event-archived="isEventArchived" wide />
                </div>
              </EventFormSectionWrapper>
            </v-container>
          </div>
        </EventFormSectionWrapper>
        <div style="display: none">
        <EventFormSectionWrapper wide v-if="!isBatchEvent">
          <div slot="section-content">
            <v-container>
              <EventFormSectionWrapper :card="false" wide compact>
                <span slot="section-header">Experiences</span>
                <div slot="section-content">
                  <ExperienceFields :is-event-archived="isEventArchived" />
                </div>
              </EventFormSectionWrapper>
            </v-container>
          </div>
        </EventFormSectionWrapper>
        </div>

        <EventFormSectionWrapper wide v-if="!isBatchEvent">
          <div slot="section-content">
            <v-container>
              <EventFormSectionWrapper :card="false" wide compact>
                <span slot="section-header">Promotional &amp; Legal</span>
                <div slot="section-content">
                  <PromotionalLegalFields
                    :is-event-archived="isEventArchived"
                  />
                </div>
              </EventFormSectionWrapper>
            </v-container>
          </div>
        </EventFormSectionWrapper>
        <!-- event preview dialog -->
        <AVEFlyout />
        <!-- end event preview dialog -->

        <!-- buttons-->
        <div class="mt-4 mb-8 form-buttons">
          <slot name="screenbuttons" />
        </div>
        <!-- end buttons-->
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EventFormSectionWrapper from '@/components/Forms/EventFormSectionWrapper';
import BrandFields from '@/components/Forms/BrandFields';
import InfoFields from '@/components/Forms/InfoFields';
import ExperienceFields from '@/components/Forms/ExperienceFields';
import OrganizationFields from '@/components/Forms/OrganizationFields';
import DateLocationFields from '@/components/Forms/DateLocationFields';
import WinnerFields from '@/components/Forms/WinnerFields';
import PromotionalLegalFields from '@/components/Forms/PromotionalLegalFields';
import AVEFlyout from '@/components/AVECanvas/AVEFlyout';

export default {
  name: 'EventEditorForm',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isEditForm: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EventFormSectionWrapper,
    BrandFields,
    InfoFields,
    ExperienceFields,
    OrganizationFields,
    DateLocationFields,
    WinnerFields,
    PromotionalLegalFields,
    AVEFlyout
  },
  mounted() {
    this.resetValidation();
  },
  data() {
    return {
      valid: true
    };
  },
  watch: {
    $route() {
      this.resetValidation();
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      return this.valid;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT', 'LOADING']),
    isEventArchived() {
      return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.archived
        ? true
        : false;
    },
    hasWinnerSelectionSection() {
      return (
        this.CURRENT_EVENT_OBJECT.contestType === 'Enter for a Chance to Win'
      );
    },
    isBatchEvent() {
      return (
        this.CURRENT_EVENT_OBJECT &&
        this.CURRENT_EVENT_OBJECT.contestType === 'Batch Import Event'
      );
    }
  }
};
</script>
