export default class TMOTranslations {
  constructor(constructObj = {}) {
    //internal values, used by devs and APIs
    this.id = constructObj.id || null;
    this.event_id = constructObj.event_id || null;
    this.language = constructObj.language || null;
    this.eventLanguage = constructObj.eventLanguage || 'en';
    this.event_name = constructObj.event_name || null;
    this.fullPrizeDescription = constructObj.fullPrizeDescription || null;
    this.sweepstakesTitle = constructObj.sweepstakesTitle || null;
    this.abbreviatedPrizeDescription =
      constructObj.abbreviatedPrizeDescription || null;
    this.abbreviatedLegal = constructObj.abbreviatedLegal || null;
  }

  copy() {
    var newObj = Object.assign({}, this);
    return new TMOTranslations(newObj);
  }
}
