export default class ExportTools {
  static exportToCsv(fName, rows) {
    var csv = '';
    for (var i = 0; i < rows.length; i++) {
      var row = rows[i];
      for (var j = 0; j < row.length; j++) {
        var val =
          row[j] === null || typeof row[j] === 'undefined'
            ? ''
            : row[j].toString();
        val = val.replace(/\t/gi, ' ');
        if (j > 0) csv += '\t';
        csv += val;
      }
      csv += '\n';
    }

    // this allows support for multiple languages
    var cCode,
      bArr = [];
    bArr.push(255, 254);
    for (var i = 0; i < csv.length; ++i) {
      cCode = csv.charCodeAt(i);
      bArr.push(cCode & 0xff);
      bArr.push((cCode / 256) >>> 0);
    }

    var blob = new Blob([new Uint8Array(bArr)], {
      type: 'text/csv;charset=UTF-16LE;'
    });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fName);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        var url = window.URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    }
  }
}
