<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    height="500"
    width="500"
  >
    <rect width="100%" height="100%" style="fill:#e20074de;opacity:0.5" />
    <text fill="#fbfbfb" font-size="18" transform="translate(175 250)">
      <tspan>No template Selected</tspan>
    </text>
  </svg>
</template>

<script>
export default {
  name: 'NoTemplateSelected',
  inheritAttrs: false
};
</script>
