<template>
  <v-dialog v-model="IS_OPEN" class="PhotoLibraryDialog" max-width="1000px">
    <v-card>
      <v-tabs v-show="GET_PHOTO_LIBRARY_DIALOG_TABS">
        <v-tab
          v-for="tab in GET_PHOTO_LIBRARY_DIALOG_TABS"
          :key="tab.id"
          @click="SET_ACTIVE_DIALOG_COMPONENT(tab.component)"
          >{{ tab.text }}</v-tab
        >
      </v-tabs>
      <component :is="ACTIVE_DIALOG_COMPONENT" @CHANGE_ACTIVE_DIALOG_COMPONENT="SET_ACTIVE_DIALOG_COMPONENT($event)" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="TOGGLE_AVE_DIALOG(false)"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import AVEPhotoLibrary from '@/components/AVECanvas/AVEPhotoLibrary';
import AVEPhotoManager from '@/components/AVECanvas/AVEPhotoManager';
import AVEUploadForm from '@/components/AVECanvas/AVEUploadForm';
import AVEEditForm from '@/components/AVECanvas/AVEEditForm';
import AVEDeleteForm from '@/components/AVECanvas/AVEDeleteForm';

export default {
  name: 'AVEDialog',
  components: {
    AVEPhotoLibrary,
    AVEPhotoManager,
    AVEUploadForm,
    AVEEditForm,
    AVEDeleteForm
  },
  methods: {
    ...mapMutations('AVE_CANVAS_STORE', [
      'TOGGLE_AVE_DIALOG',
      'SET_ACTIVE_DIALOG_COMPONENT'
    ])
  },
  computed: {
    ...mapState('AVE_CANVAS_STORE', ['DIALOG_OPEN', 'ACTIVE_DIALOG_COMPONENT']),
    ...mapGetters('AVE_CANVAS_STORE', ['GET_PHOTO_LIBRARY_DIALOG_TABS']),
    IS_OPEN: {
      get() {
        return this.DIALOG_OPEN;
      },
      set(value) {
        this.TOGGLE_AVE_DIALOG(value);
      }
    }
  }
};
</script>

<style lang="css">
.PhotoLibraryDialog {
  max-width: calc(100% - 80px);
}
</style>
