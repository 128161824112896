<template>
  <v-container class="py-0">
    <div v-if="newCodeLoading" class="loading-overlay">
      <v-progress-circular
        class="loading-spinner my-10"
        :size="100"
        color="var(--v-primary-base)"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!newCodeLoading && generateNew">
      <h2 :loading="newCodeLoading">Success!</h2>
      <h3 :loading="newCodeLoading">New Access Code:</h3>
    </div>

    <div v-if="!generateNew" class="download-buttons">
      <div>
        <h2>Replace Existing Event Code</h2>
        <p>
          Generating a new event code will deactivate all existing event codes.
          Anyone with an existing event code will no longer be able to access
          your event details using the existing code. Do you wish to continue?
        </p>
        <v-btn @click="changeAction(null)">Cancel</v-btn>
        <v-btn
          color="primary"
          elevation="0"
          dark
          tile
          :loading="newCodeLoading"
          @click="newAccessCode()"
        >
          Generate Access Code
        </v-btn>
      </div>
      <h3>Current Access Code:</h3>
    </div>

    <p>
      {{
        CURRENT_EVENT_OBJECT.event_code || ''
      }}
    </p>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AccessCodeAction',
  data() {
    return {
      generateNew: false,
      newCodeLoading: false
    };
  },
  mounted() {
    this.checkAccessCode();
  },
  methods: {
    checkAccessCode() {
      if (!this.CURRENT_EVENT_OBJECT.event_code) {
        this.newCodeLoading = true;
        this.generateNew = true;
        this.$nextTick(async function() {
          await this.$store.dispatch(
            'EVENT_MANAGER_STORE/UPDATE_ACCESS_CODE',
            this.CURRENT_EVENT_OBJECT.id
          );
          this.newCodeLoading = false;
        });
      } else {
        this.generateNew = false;
      }
    },
    newAccessCode() {
      this.newCodeLoading = true;
      this.$nextTick(async function() {
        await this.$store.dispatch(
          'EVENT_MANAGER_STORE/UPDATE_ACCESS_CODE',
          this.CURRENT_EVENT_OBJECT.id
        );
        this.newCodeLoading = false;
        this.generateNew = true;
        //this.changeAction('viewAccessCodeDescription');
      });
    },
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    }
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT'])
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 35px;
  margin-top: 50px;
}

.download-buttons {
  margin-bottom: 35px;
  button {
    margin-bottom: 5px;
    min-width: 40% !important;
  }
  button:nth-child(2n) {
    margin-left: 5px;
  }
}
</style>
