<template>
  <div class="app-event-creation">
    <TranslationActionBar
      :cur-action="curAction"
      @changeAction="curAction = $event"
      :loaded="loaded"
    />
    <AlertBar></AlertBar>
    <BannerTitle
      :title="`Translation Review of ${CURRENT_EVENT_OBJECT.name}`"
      :loading="!loaded"
    />
    <template v-if="loaded">
      <TranslationSummaryForm />
    </template>
  </div>
</template>

<script>
import AlertBar from '@/components/AlertBar.vue';
import BannerTitle from '@/components/BannerTitle.vue';
import TranslationSummaryForm from '@/components/EventForm/TranslationSummaryForm.vue';
import TranslationActionBar from '@/components/EventForm/TranslationActionBar.vue';
import ThemeSwitcher from '@/utility/ThemeSwitcher';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'TranslationSummary',
  components: {
    AlertBar,
    BannerTitle,
    TranslationSummaryForm,
    TranslationActionBar
  },
  data() {
    return {
      loaded: false,
      curAction: null
    };
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    brandWatcher() {
      return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.brand
        ? this.CURRENT_EVENT_OBJECT.brand
        : 'T-Mobile';
    }
  },
  watch: {
    brandWatcher: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.changeTheme(newVal);
        }
      }
    }
  },
  async created() {
    this.loaded = false;
    let eventId = this.$route.params.id;
    await this.$store.dispatch('EVENT_MANAGER_STORE/LOAD_EVENT', eventId);
    this.loaded = true;
  },
  methods: {
    changeTheme(brand) {
      ThemeSwitcher.ChangeTheme(this, brand);
    }
  }
};
</script>

<style scoped>
.loading-row {
  padding: 32px;
  text-align: center;
}
</style>
