<template>
  <div>
    <BannerTitle title="Your email has been confirmed!" description="" />

    <section class="py-12">
      <v-container>
        <v-row>
          <v-col>
            <p>
              Thank you for confirming your email address. An email has been
              sent to the system administrator to approve your request for
              access. You will receive an email to notify you if your request
              for access has been approved or denied. Please allow up to 2
              business days.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import BannerTitle from '@/components/BannerTitle.vue';

export default {
  name: 'PendingApproval',
  components: {
    BannerTitle
  }
};
</script>

<style></style>
