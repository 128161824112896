import { API, Auth } from 'aws-amplify';
// initial state
const state = {
  TABLE_DATA: [],
  FILTERS: {
    brand: null,
    language: null,
    isTranslated: null,
    startingBeforeDate: null,
    startingAfterDate: null,
    endingBeforeDate: null,
    endingAfterDate: null,
    organization: null,
    region: null,
    state: null,
    dma: null,
    eventType: null,
    archived: '0',
    name: null,
    createdByEmail: null,
    status: null,
    published: null,
    orderByField: 'legal_lastModified',
    orderByDirection: 'DESC'
  },
  FILTERARR: [
    {
      name: 'name',
      value: null,
      label: 'Event Name'
    },
    {
      name: 'createdByEmail',
      value: null,
      label: 'User'
    },
    {
      name: 'brand',
      value: null,
      label: 'Brand'
    },
    {
      name: 'eventType',
      value: null,
      label: 'Promotion Type'
    },
    {
      name: 'language',
      value: null,
      label: 'Language'
    },
    {
      name: 'archived',
      value: '0',
      label: 'Archived Events'
    },
    {
      name: 'startingBeforeDate',
      value: null,
      label: 'Starting Before'
    },
    {
      name: 'startingAfterDate',
      value: null,
      label: 'Starting After'
    },
    {
      name: 'endingBeforeDate',
      value: null,
      label: 'Ending Before'
    },
    {
      name: 'endingAfterDate',
      value: null,
      label: 'Ending After'
    },
    {
      name: 'organization',
      value: null,
      label: 'Organization'
    },
    {
      name: 'region',
      value: null,
      label: 'Region'
    },
    {
      name: 'state',
      value: null,
      label: 'State'
    },
    {
      name: 'dma',
      value: null,
      label: 'DMA'
    },
    {
      name: 'status',
      value: null,
      label: 'Status'
    },
    {
      name: 'published',
      value: null,
      label: 'Published'
    }
  ],
  CURRENT_SEARCH_TEXT: null,
  RESULTS_PER_PAGE: 25,
  CURRENT_PAGE: 1,
  NUMBER_OF_PAGES: null,
  LOADING: false,
  LEGAL: false
};

const getters = {
  filters: state => {
    let o = Object.fromEntries(
      Object.entries(state.FILTERS).filter(([_, v]) => v != null)
    );
    return o;
  },
  filtersArr: state => state.FILTERARR.map(filter => filter.value),
  C_PAGE: state => state.CURRENT_PAGE
};

const actions = {
  async GET_EVENTS({ commit, state, rootState, getters }) {
    //Get current user role;
    let userAttributes = rootState.account.USER_ATTRIBUTES;
    let userGroups = rootState.account.USER_GROUPS;
    try {
      let apiFilters = `?`;
      let uEmail = [userAttributes.email] || '';

      //Prevent from calling the api twice while loading
      if (state.LOADING) return;
      commit('SET_LOADING', true);
      commit('SET_CURRENT_PAGE', 0);
      // if not in the admin or legal group, only show events createdByEmail
      if (
        typeof userGroups != 'undefined' &&
        userGroups.includes('Guest') &&
        !userGroups.includes('Admin') &&
        !userGroups.includes('Legal')
      ) {
        apiFilters += `createdByEmail=${encodeURIComponent(uEmail)}&`;
      }
      //Loop through finalized object to construct uri
      for (const [key, value] of Object.entries(getters.filters)) {
        if (key == null) return;
        apiFilters += `${key}=${encodeURIComponent(value)}&`;
      }
      // create uri based on filters;

      //Preform api request;
      const apiName = 'TMOAmplifyApi';
      const path = '/events' + apiFilters;

      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      };

      const response = await API.get(apiName, path, myInit);

      //If response.data.
      if (!response.data) return;

      commit('SET_TABLE_DATA', {
        data: response.data
      });
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async APPLY_FILTER({ commit, dispatch }, payload) {
    let filterArr = Array.isArray(payload) ? payload : [payload];
    try {
      for (let i = 0; i < filterArr.length; ++i) {
        const { filter_key = null, filter_value = null } = filterArr[i];
        commit('UPDATE_FILTER', {
          key: filter_key,
          filter_value: filter_value
        });
        //Check if "name" filter
        if (filter_key === 'name') {
          commit('UPDATE_CURRENT_SEARCH_TEXT', null);
        }
      }
      await dispatch('GET_EVENTS');
    } catch (e) {
      console.log(e);
    }
  },
  CHANGE_ITEMS_PER_PAGE({ commit }, payload) {
    try {
      commit('SET_ITEMS_PER_PAGE', payload);
    } catch (e) {
      console.log(e);
    }
  },
  async RESET_FILTERS({ dispatch, commit }, reload = true) {
    try {
      commit('RESET_ALL_FILTERS');
      if (reload) {
        await dispatch('GET_EVENTS');
      }
    } catch (e) {
      console.log(e);
    }
  },
  APPLY_LEGAL({ commit }, payload) {
    try {
      commit('SET_LEGAL', payload);
    } catch (e) {
      console.log(e);
    }
  },
  APPLY_TRANSLATION({ commit }, payload) {
    try {
      commit('SET_TRANSLATION', payload);
    } catch (e) {
      console.log(e);
    }
  }
};
// // mutations
const mutations = {
  SET_LOADING: (state, payload) => {
    state.LOADING = payload || false;
  },
  SET_LEGAL: (state, payload) => {
    state.LEGAL = payload || false;
    state.FILTERS.status = state.LEGAL ? 'Legal Pending' : null;
  },
  SET_TRANSLATION: (state, payload) => {
    state.TRANSLATION = payload || false;
    state.FILTERS.status = state.TRANSLATION ? 'Translation Pending' : null;
  },
  UPDATE_FILTER: (state, payload) => {
    state.FILTERS[payload.key] = payload.filter_value;
  },
  UPDATE_CURRENT_SEARCH_TEXT: (state, payload) => {
    state.CURRENT_SEARCH_TEXT = payload;
  },
  SET_TABLE_DATA: (state, payload) => {
    state.TABLE_DATA = payload.data;
  },
  SET_CURRENT_PAGE: (state, page_number) => {
    state.CURRENT_PAGE = page_number;
  },
  SET_ITEMS_PER_PAGE: (state, payload) => {
    state.RESULTS_PER_PAGE = payload;
  },
  RESET_ALL_FILTERS: state => {
    let FilterBaseObject = {
      brand: null,
      language: null,
      isTranslated: null,
      startingBeforeDate: null,
      startingAfterDate: null,
      endingBeforeDate: null,
      endingAfterDate: null,
      organization: null,
      region: null,
      state: null,
      dma: null,
      eventType: null,
      archived: '0',
      name: null,
      createdByEmail: null,
      status: state.LEGAL ? 'Legal Pending' : null,
      published: null,
      orderByField: 'legal_lastModified',
      orderByDirection: 'DESC'
    };
    state.CURRENT_SEARCH_TEXT = null;
    state.FILTERS = Object.assign(state.FILTERS, FilterBaseObject);
  },
  RESET_ALL: state => {
    state.LOADING = false;
    state.TABLE_DATA = [];
    let FilterBaseObject = {
      brand: null,
      language: null,
      isTranslated: null,
      startingBeforeDate: null,
      startingAfterDate: null,
      endingBeforeDate: null,
      endingAfterDate: null,
      organization: null,
      region: null,
      state: null,
      dma: null,
      eventType: null,
      archived: '0',
      name: null,
      createdByEmail: null,
      status: state.LEGAL ? 'Legal Pending' : null,
      published: null,
      orderByField: 'legal_lastModified',
      orderByDirection: 'DESC'
    };
    state.FILTERS = Object.assign(state.FILTERS, FilterBaseObject);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
