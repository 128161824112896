<template>
  <v-row >
    <v-col class="d-flex" cols="12" sm="4" v-if="isTranslated">
      <v-radio-group v-model="SELECTED_LANGUAGE" hide-details="auto" row>
        <v-radio value="en" label="English" hide-details="auto"></v-radio>
        <v-radio value="es" label="Spanish" hide-details="auto"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col class="d-flex" cols="12" :sm="isTranslated ? 3 : 5" style="z-index: 100;">
      <v-select
        :items="PROMOTION_TYPES"
        item-text="text"
        item-value="value"
        v-model="SELECTED_PROMOTION_TYPE"
        label="Select Radio Promotion Type"
      ></v-select>
    </v-col>
    <v-col class="d-flex" cols="12" :sm="isTranslated ? 3 : 5" style="z-index: 100;">
      <v-select
        :items="GET_TEMPLATES_BY_PROMO_TYPE"
        item-text="text"
        item-value="template_id"
        label="Templates"
        v-model="ACTIVE_TEMPLATE_SELECTED"
      ></v-select>
    </v-col>
    <v-col class="d-flex" cols="12" sm="2" style="z-index: 100;">
      <v-btn
        type="button"
        class="v-btn v-btn--outlined v-btn--tile theme--light elevation-2 v-size--x-large primary--text"
        outlined
        block
        @click="TOGGLE_AVE_DIALOG"
        v-show="HIDE_SELECT_IMAGE_BUTTON"
        >Select Image</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'AVEOptions',
  methods: {
    ...mapMutations('AVE_CANVAS_STORE', [
      'TOGGLE_AVE_DIALOG',
      'UPDATE_PROMOTION_TYPE',
      'UPDATE_SELECTED_TEMPLATE',
      'SET_CURRENT_LANGUAGE'
    ])
  },
  computed: {
    ...mapState('AVE_CANVAS_STORE', [
      'PROMOTION_TYPE',
      'SELECTED_TEMPLATE',
      'SELECTED_IMAGE',
      'SELECTED_BRAND',
      'PROMOTION_TYPES',
      'AVAILABLE_TEMPLATES',
      'AVAILABLE_IMAGES',
      'CURRENT_LANGUAGE'
    ]),
    ...mapGetters('AVE_CANVAS_STORE', [
      'GET_TEMPLATES_BY_PROMO_TYPE',
      'GET_CURRENT_TEMPLATE_OBJECT'
    ]),
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    HIDE_SELECT_IMAGE_BUTTON() {
      return this.GET_CURRENT_TEMPLATE_OBJECT['is_image_banner'] ? true : false;
    },
    SELECTED_PROMOTION_TYPE: {
      get() {
        return this.PROMOTION_TYPE;
      },
      set(value) {
        this.UPDATE_PROMOTION_TYPE(value);
      }
    },
    ACTIVE_TEMPLATE_SELECTED: {
      get() {
        return this.SELECTED_TEMPLATE;
      },
      set(value) {
        this.UPDATE_SELECTED_TEMPLATE(value);
      }
    },
    SELECTED_LANGUAGE: {
      get() {
        return this.CURRENT_LANGUAGE;
      },
      set(value) {
        this.SET_CURRENT_LANGUAGE(value);
      }
    },
    isTranslated: function() {
      return (
        this.CURRENT_EVENT_OBJECT.published &&
        this.CURRENT_EVENT_OBJECT.isTranslated
      );
    }
  }
};
</script>

<style scoped>
::v-deep .v-radio label {
  color: black;
}
</style>
