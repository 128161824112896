import { Auth } from 'aws-amplify';

import ACCOUNT_SIGN_IN from './actions/AccountSignIn';
import ACCOUNT_SIGN_UP from './actions/AccountSignUp';
import LOG_AUTH_STATE from './actions/LogAuthState';
import FORGOT_PASSWORD from './actions/ForgotPassword';
import RESET_PASSWORD_W_CODE from './actions/ResetPasswordWithCode';
import CHANGE_PASSWORD from './actions/ChangePassword';
import COMPLETE_PASSWORD_CHALLENGE from './actions/CompletePasswordChallenge';
import UPDATE_USER_ATTRIBUTES from './actions/UpdateUserAttributes';

/**
 * State
 */
const state = () => ({
  CodeDeliveryDetails: {},
  FORGOT_PASSWORD_ERROR: null,
  ACCOUNT_DATA: {},
  USER: {},
  USER_GROUPS: [],
  USER_NAME: null,
  USER_ATTRIBUTES: null,
  AUTH_MODULE_STATE: [],
  AUTH_ERROR_MESSAGE: null,
  SIGN_UP_DETAILS: null,
  SIGN_UP_ERROR: null,
  PASSWORD_RECOVERY_DETAILS: null,
  ACCOUNT_DATA: null,
  CURRENT_SESSION: null,
  IS_LOGGED_IN: false
});

/**
 * Getters
 */
const getters = {
  IS_LOGGED_IN: state => {
    return state.IS_LOGGED_IN;
  },
  ACCOUNT_DATA: state => {
    return state.ACCOUNT_DATA;
  },
  AUTH_ERROR_MESSAGE: state => {
    return state.AUTH_ERROR_MESSAGE;
  },
  USER_NAME: state => {
    return state.USER_NAME;
  },
  USER: state => {
    return state.USER;
  },
  PASSWORD_RECOVERY_DETAILS: state => {
    return state.PASSWORD_RECOVERY_DETAILS;
  },
  AUTH_MODULE_STATE: state => {
    return state.AUTH_MODULE_STATE;
  },
  LATEST_AUTH_MODULE_STATE: state => {
    return [...state.AUTH_MODULE_STATE].pop();
  },
  FORGOT_PASSWORD_ERROR: state => {
    return state.FORGOT_PASSWORD_ERROR;
  },
  CodeDeliveryDetails: state => {
    return state.CodeDeliveryDetails;
  },
  USER_ATTRIBUTES: state => {
    return state.USER_ATTRIBUTES;
  },
  USER_GROUPS: state => {
    return state.USER_GROUPS;
  }
};

/**
 * Actions
 */
const actions = {
  VERIFY_EMAIL({ commit }, { userName, code }) {
    return Auth.confirmSignUp(userName, code)
      .then(data => {
        return data;
      })
      .catch(err => {
        return err;
      });
  },
  /**
   * Sign into Cognito account.
   */
  ACCOUNT_SIGN_IN,
  /**
   * Sign up for account.
   */
  ACCOUNT_SIGN_UP,
  LOG_AUTH_STATE,
  FORGOT_PASSWORD,
  RESET_PASSWORD_W_CODE,
  CHANGE_PASSWORD,
  COMPLETE_PASSWORD_CHALLENGE,
  UPDATE_USER_ATTRIBUTES,
  ACCOUNT_SIGN_OUT: ({ commit }) => {
    // TODO: Do i need to move this? --Zach
    // TODO: Handle signOut here?
    return Auth.signOut({ global: true })
      .then(data => {
        commit('SET_IS_LOGGED_IN', false);
      })
      .catch(err => {});
  },
  UPDATE_CURRENT_SESSION: ({ commit }) => {
    return Auth.currentSession()
      .then(data => {
        commit('SET_CURRENT_SESSION', data);
        return data;
      })
      .catch(err => {
        return err;
      });
  },
  UPDATE_USER_DATA: ({ commit }) => {
    return Auth.currentAuthenticatedUser()
      .then(data => {
        commit('SET_USER', data);
        return data;
      })
      .catch(err => {
        return err;
      });
  },
  UPDATE_USER_INFO: async ({ commit, dispatch }) => {
    try {
      let currentUserInfo = await Auth.currentUserInfo();
      let currentUser = await dispatch('CURRENT_USER');
      commit('SET_USER_INFO', currentUserInfo);
      return currentUser;
    } catch (e) {
      return e;
    }
  },
  CURRENT_USER: async ({ commit }) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await commit('SET_USER', currentUser);
      return currentUser;
    } catch (error) {
      return error;
    }
  }
};

/**
 * Mutations
 */
const mutations = {
  SET_IS_LOGGED_IN(state, status) {
    state.IS_LOGGED_IN = status;
  },
  SET_CURRENT_SESSION(state, session) {
    state.CURRENT_SESSION = session;
  },
  SET_ACCOUNT_DATA(state, userData) {
    // We should only keep what we need to.
    // Amplify Auth should keep track of our keys and groups
    // Maybe only pull stuff like userName and group name or
    // set some auth flag for restricted pages
    state.ACCOUNT_DATA = userData;
  },
  SET_AUTH_ERROR_MESSAGE(state, message) {
    state.AUTH_ERROR_MESSAGE = message;
  },
  SET_USER_GROUPS(state, groups) {
    state.USER_GROUPS = groups;
  },
  SET_USER_NAME(state, name) {
    state.USER_NAME = name;
  },
  SET_USER_INFO(state, info) {
    state.USER_INFO = info;
    if (info && info.attributes) {
      state.USER_ATTRIBUTES = info.attributes;
      state.IS_LOGGED_IN = true;
    } else {
      state.IS_LOGGED_IN = false;
    }
  },
  SET_AUTH_MODULE_STATE(state, authState) {
    state.AUTH_MODULE_STATE.push(authState);
  },
  SET_SIGN_UP_DETAILS(state, signUpDetails) {
    state.SIGN_UP_DETAILS = signUpDetails;
  },
  SET_USER_CONFIRMED(state, userConfirmed) {
    state.USER_CONFIRMED = userConfirmed;
  },
  SET_USER(state, user) {
    state.USER_GROUPS =
      user.signInUserSession.accessToken.payload['cognito:groups'];
    state.USER = user;
  },
  SET_SIGN_UP_ERROR(state, error) {
    state.SIGN_UP_ERROR = error;
  },
  SET_PASSWORD_RECOVERY_DETAILS(state, detailsObject) {
    state.PASSWORD_RECOVERY_DETAILS = detailsObject;
  },
  SET_CodeDeliveryDetails(state, detailsObject) {
    state.CodeDeliveryDetails = detailsObject;
  },
  SET_FORGOT_PASSWORD_ERROR(state, error) {
    state.FORGOT_PASSWORD_ERROR = error;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
