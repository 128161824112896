<template>
  <div class="app-dashboard">
    <AlertBar></AlertBar>
    <BannerTitle title="Welcome, Admin!" />
    <AdminActionBar
      :cur-action="curAction"
      @changeAction="curAction = $event"
    />
    <AdminActionOverlay
      :cur-action="curAction"
      @changeAction="curAction = $event"
    />
    <section class="py-12">
      <v-container :fluid="!this.lgBreakpoint">
        <v-row align="center" class="flex-column">
          <v-col cols="12" class="admin-lockup">
            <h1>User Management</h1>
            <p>Manage current users, review and confirm new user accounts.</p>
            <button
              :class="{ active: !filterPending }"
              @click="showOnlyPendingUsers(false)"
              :disabled="loading"
            >
              All Users
            </button>
            <button
              :class="{ active: filterPending }"
              @click="showOnlyPendingUsers(true)"
              :disabled="loading"
            >
              Pending Approval
            </button>
            <v-btn
              class="px-12 btn__primary"
              tile
              :disabled="loadingUserReportGenerator || loading"
              @click="exportUserReport"
            >
              Download User List
            </v-btn>
            <v-divider />
          </v-col>
          <v-col cols="12">
            <v-form @submit.prevent="filterUsers">
              <label for="search">Search</label>
              <v-row
                dense
                no-gutters
                justify="start"
                align="center"
                class="admin-search-form"
              >
                <v-col cols="8">
                  <v-text-field
                    type="text"
                    color="black"
                    name="search"
                    dense
                    hide-details
                    class="d-flex align-center justify-sm-space-between"
                    flat
                    solo
                    v-model="searchText"
                  />
                </v-col>
                <v-col cols="1">
                  <v-btn
                    class="px-12 btn__primary"
                    type="submit"
                    block
                    :disabled="loading"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12">
            <button
              @click="advancedSearchActive = !advancedSearchActive"
              :disabled="loading"
            >
              <span>Advanced Search</span>
              <v-icon medium color="#E44475">
                {{
                  advancedSearchActive ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}
              </v-icon>
            </button>
            <div class="admin-advanced-search" v-show="advancedSearchActive">
              <v-row justify="center" align="center">
                <v-col>
                  <v-select
                    v-model="selectedOrganization"
                    :items="organizationOptions"
                    label="Organization"
                    append-icon="$Caret"
                    @change="filterUsers"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="selectedDepartment"
                    :items="departmentOptions"
                    label="Department"
                    append-icon="$Caret"
                    @change="filterUsers"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" v-if="hasActiveFilters()">
            <v-row>
              <v-col cols="1">
                <v-btn
                  class="px-12 btn__primary"
                  block
                  :disabled="loading"
                  @click="resetFilters"
                >
                  Reset Filters
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <UsersTable
              :table-data="users"
              :rows-per-page="10"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            @click="dialog = false"
            tile
            outlined
            color="primary"
            class="px-6"
          >
            Close
          </v-btn>
          <v-btn
            @click="dialogAction"
            tile
            class="px-6 btn__primary"
            v-if="dialogAction"
            :loading="loading"
          >
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AVEDialog />
    <Footer />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BannerTitle from '@/components/BannerTitle.vue';
import AdminActionBar from '@/components/Admin/AdminActionBar.vue';
import AdminActionOverlay from '@/components/Admin/AdminActionOverlay.vue';
import AlertBar from '@/components/AlertBar.vue';
import UsersTable from '@/components/Admin/UsersTable.vue';
import OptionData from '@/utility/OptionData.js';
import ExportTools from '@/utility/ExportTools.js';
import AVEDialog from '@/components/AVECanvas/AVEDialog';
let createDateUtil = date => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('_');
};

export default {
  name: 'AdminDashboard',
  async created() {
    // redirect to /dashboard if not admin
    if (this.USER_GROUPS.indexOf('Admin') === -1) {
      this.$router.push('/dashboard');
      return;
    }
    window.scrollTo(0, 0);
    await this.$store.dispatch('AVE_CANVAS_STORE/GET_AVAILABLE_IMAGES');
    await this.getUsers();
  },
  components: {
    BannerTitle,
    AdminActionBar,
    AdminActionOverlay,
    UsersTable,
    AlertBar,
    AVEDialog
  },
  data() {
    return {
      loading: true,
      filterPending: false,
      advancedSearchActive: false,
      users: [],
      userMap: new Map(),
      searchText: '',
      organizationOptions: OptionData.employeeOrgOptions(),
      departmentOptions: OptionData.employeeDepartmentOptions(),
      selectedOrganization: null,
      selectedDepartment: null,
      curAction: null,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogAction: null,
      dialogActionText: '',
      loadingUserReportGenerator: false,
      fullUserList: []
    };
  },
  methods: {
    ...mapActions({
      listUsers: 'admin/listUsers',
      listGroups: 'admin/listGroups',
      listUsersInGroup: 'admin/listUsersInGroup',
      sendEmail: 'admin/sendEmail',
      confirmUserSignUp: 'admin/confirmUserSignUp',
      enableUser: 'admin/enableUser',
      disableUser: 'admin/disableUser',
      FORGOT_PASSWORD: 'account/FORGOT_PASSWORD'
    }),
    async exportUserReport() {
      let csvName = '';
      let formattedUserCreationDate;
      if (this.loadingUserReportGenerator) return;
      this.loadingUserReportGenerator = true;
      try {
        csvName = `TEH_UserList_${createDateUtil(Date.now())}.csv`;

        let userArr = [
          [
            'First Name',
            'Last Name',
            'Email',
            'Organization',
            'Department',
            'User Status',
            'User Created Date',
            'Role'
          ]
        ];
        for (let singleUser of this.fullUserList) {
          //Do not add non-tmo emails;
          if (singleUser.email.indexOf('@t-mobile.com') === -1) continue;
          formattedUserCreationDate = singleUser.UserCreateDate;
          formattedUserCreationDate = this.$options.filters.utcTimeFormatter(
            singleUser.UserCreateDate
          );
          let user = [
            singleUser.given_name || '-',
            singleUser.family_name || '-',
            singleUser.email || '-',
            singleUser.signUpOrganization || '-',
            singleUser.signupDepartment || '-',
            singleUser.UserStatus || '-',
            formattedUserCreationDate || '-',
            singleUser.group || '-'
          ];
          userArr.push(user);
        }

        ExportTools.exportToCsv(csvName, userArr);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingUserReportGenerator = false;
      }
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },
    async getUsers() {
      this.loading = true;
      this.users = [];
      this.userMap = new Map();
      try {
        let limit = 60;
        let token = null;
        let payload = {
          limit: limit,
          token: token
        };
        let groups = await this.listGroups(payload);
        token = null;
        for (let i = 0; i < groups.Groups.length; ++i) {
          let groupname = groups.Groups[i].GroupName;
          do {
            payload = {
              groupname: groupname,
              limit: limit,
              token: token
            };
            let req = await this.listUsersInGroup(payload);
            this.formatUserData(req.Users, groupname);
            token = req.NextToken;
          } while (token);
        }
        this.users = Array.from(this.userMap.values());
        this.fullUserList = Array.from(this.userMap.values());
      } catch (e) {
        e = typeof e == 'string' ? JSON.parse(e) : e;
        this.displayDialog('Error Getting Users', e.message ?? e, '', null);
      } finally {
        this.loading = false;
      }
    },
    toCapitalCase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    formatUserData(users, groupname) {
      // instead of having an attributes array of objects,
      // put the key value pairs in the top level user object
      // also remove 'custom:'
      for (let i = 0; i < users.length; ++i) {
        let user = users[i];
        let data = {};
        for (let j = 0; j < user.Attributes.length; ++j) {
          let attribute = user.Attributes[j];
          let name = attribute.Name.replace('custom:', '');
          data[name] = attribute.Value;
        }
        data.Enabled = user.Enabled;
        data.UserCreateDate = user.UserCreateDate;
        data.UserLastModifiedDate = user.UserLastModifiedDate;
        data.UserStatus = this.toCapitalCase(user.UserStatus);
        data.Username = user.Username;
        data.group = groupname;
        // use map for users with multiple groups
        if (this.userMap.has(data.Username)) {
          this.userMap.get(data.Username).group += `, ${data.group}`;
        } else {
          this.userMap.set(data.Username, data);
        }
      }
    },
    showOnlyPendingUsers(value) {
      this.filterPending = value;
      this.filterUsers();
    },
    filterUsers() {
      if (this.loading) {
        return;
      }
      this.users = Array.from(this.userMap.values());
      if (this.filterPending) {
        this.users = this.users.filter(user => {
          return user.UserStatus == 'Unconfirmed';
        });
      }
      if (this.searchText) {
        let searchText = this.searchText;
        this.users = this.users.filter(user => {
          return (
            (user.given_name + user.family_name + user.email)
              .toLowerCase()
              .indexOf(searchText) !== -1
          );
        });
      }
      if (this.selectedOrganization) {
        let organization = this.selectedOrganization;
        this.users = this.users.filter(user => {
          return user.signUpOrganization == organization;
        });
      }
      if (this.selectedDepartment) {
        let department = this.selectedDepartment;
        this.users = this.users.filter(user => {
          return user.signupDepartment == department;
        });
      }
    },
    hasActiveFilters() {
      return (
        this.filterPending ||
        this.selectedOrganization ||
        this.selectedDepartment ||
        this.searchText != ''
      );
    },
    resetFilters() {
      this.filterPending = false;
      this.selectedOrganization = null;
      this.selectedDepartment = null;
      this.searchText = '';
      this.users = Array.from(this.userMap.values());
    }
  },
  computed: {
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`
    }),
    lgBreakpoint: function() {
      return this.$vuetify.breakpoint.lgAndUp;
    }
  }
};
</script>

<style lang="scss" scoped>
.admin-lockup {
  h1 {
    font-size: 3rem;
  }
  button {
    margin-right: 16px;
    &.active {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  hr.v-divider {
    background-color: #d8d8d8;
    border-width: 1px;
    margin-top: 24px;
  }
}
.admin-search-form {
  height: 40px;
  padding: 0;
  > div {
    height: 100%;
  }
}
::v-deep .admin-search-form .v-input {
  border: 1px solid #d8d8d8;
  border-radius: 0;
}
::v-deep .admin-search-form .v-btn {
  height: 100%;
}
::v-deep .admin-advanced-search .v-icon__component {
  color: var(--v-primary-base);
}
</style>
