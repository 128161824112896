<template>
  <v-btn
    :disabled="this.disabled"
    :color="this.color"
    tile
    :outlined="this.outlined"
    :class="`app-button px-5 text-heading ${this.textColorClass}`"
    height="50px"
    :href="this.href"
    :loading="this.loading"
    :block="this.block"
    @click="this.onClick"
    :to="this.to"
    :type="this.type"
    :small="this.small"
    :target="target"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    type: {
      type: String,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'magenta'
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    text: {
      type: String,
      required: false,
      default: 'Call To Action'
    },
    textColor: {
      type: String,
      required: false,
      default: 'white'
    },
    href: {
      type: String || Object,
      required: false,
      default: undefined
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    block: {
      type: Boolean,
      required: false,
      default: false
    },
    onClick: {
      type: Function,
      required: false,
      default: event => {}
    },
    to: {
      type: String,
      required: false,
      default: undefined
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    target: {
      type: String,
      default: null
    }
  },
  computed: {
    textColorClass: function() {
      if (this.outlined) {
        return `black--text`;
      } else {
        return `${this.textColor}--text`;
      }
    }
  }
};
</script>

<style lang="scss">
.app-button {
  @include app-button-font;
  &.black--text {
    .v-btn__loader,
    .v-btn__content {
      color: black !important;
    }
  }
}
</style>
