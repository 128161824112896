export default class TMOEngagement {
  constructor(constructObj = {}) {
    this.id = constructObj.id;
    this.firstName = constructObj.firstName;
    this.lastName = constructObj.lastName;
    this.email = constructObj.email;
    this.eventId = constructObj.eventId;
    this.phone = constructObj.phone;
    this.addressLine1 = constructObj.addressLine1;
    this.addressLine2 = constructObj.addressLine2;
    this.city = constructObj.city;
    this.state = constructObj.state;
    this.zip = constructObj.zip;
    this.language = constructObj.language;
    this.currentProvider = constructObj.currentProvider;
    this.contactAllowed = constructObj.contactAllowed;
    this.createdByFirstName = constructObj.createdByFirstName;
    this.createdByEmail = constructObj.createdByEmail;
    this.createdByLastName = constructObj.createdByLastName;
    this.businessName = constructObj.businessName;
  }

  copy() {
    var newObj = Object.assign({}, this);
    return new TMOEngagement(newObj);
  }
}
