<template>
  <div>
    <v-form
      v-model="valid"
      ref="form"
      class="app-login-form pa-6"
      @submit.prevent="validateCode"
    >
      <v-text-field
        :rules="[v => !!v || 'Code is required.']"
        v-model="code"
        label="Event Code"
        required
        class="mt-8"
      ></v-text-field>
      <div class="app-login-form__submit mt-8">
        <v-row align="center" class="flex-column">
          <v-col cols="12" sm="10" md="8">
            <app-button
              :disabled="!valid"
              ref="buttonLogin"
              block
              :type="'submit'"
              :loading="loading"
            >
              Submit
            </app-button>
          </v-col>
        </v-row>
      </div>
    </v-form>

    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" text class="px-6 btn__primary">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Auth, API } from 'aws-amplify';
import AppButton from '@/components/AppButton.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'EventCodeForm',
  components: {
    AppButton
  },
  props: {},
  data() {
    return {
      loading: false,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null,
      valid: false,
      code: null
    };
  },
  methods: {
    ...mapActions(['staffVerifyCode']),
    async validateCode() {
      this.loading = true;
      let req = await this.staffVerifyCode(this.code);
      if (!req) {
        this.displayDialog(
          'Error',
          'An error occurred.',
          'Close',
          () => (this.dialog = false)
        );
        this.loading = false;
        return;
      }
      req = typeof req == 'string' ? JSON.parse(req) : req;
      if (req.errorMessage) {
        switch (req.errorMessage) {
          case 'Event Not Found':
            this.dialogTitle = 'Event Not Found';
            this.dialogMessage = `The code you entered does not match any events in the system.
               Please check that your code is accurate and try again. If you
               continue having issues, please reach out to your agency or
               T-Mobile contact.`;
            break;
          case 'Event Not Active':
            this.dialogTitle = 'Event Not Active';
            this.dialogMessage = `The code you entered belongs to an event that is not active.
              Please check that your code is accurate and try again.
              If you continue having issues, please reach out to your agency
              or T-Mobile contact`;
            break;
          default:
            this.dialogTitle = 'Error';
            this.dialogMessage = req.errorMessage;
            break;
        }
        this.displayDialog(
          this.dialogTitle,
          this.dialogMessage,
          'Close',
          () => (this.dialog = false)
        );
        this.loading = false;
        return;
      }
      this.loading = false;
      this.$router.push('/staffactions/' + req.id);
    },

    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },
    validate() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style scoped>
.app-login-form {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-login-form a {
  text-decoration: none;
}
.app-login-form__submit {
  text-align: center;
}
.app-login-form__signup {
  width: 80%;
}
::v-deep .app-login-form__forgotpassword.v-btn--plain .v-btn__content,
::v-deep .app-login-form__support .v-btn__content {
  opacity: 1 !important;
}
.v-btn--outlined {
  border-width: 2px;
}
.app-login-form__submit .v-btn,
.app-login-form__support .v-btn {
  font-weight: 700;
}
</style>
