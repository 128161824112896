import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import CloseX from '../assets/svgs/CloseX.vue';
import TMobileEmptyCheckbox from '../assets/svgs/TMobileEmptyCheckbox.vue';
import TMobileFullCheckbox from '../assets/svgs/TMobileFullCheckbox.vue';
import MetroFullCheckbox from '../assets/svgs/MetroFullCheckbox.vue';
import Calendar from '../assets/svgs/Calendar.vue';
import Clock from '../assets/svgs/Clock.vue';
import Caret from '../assets/svgs/Caret.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#e20074', // magenta
        secondary: '#861b54', // dark magenta
        tertiary: '#861b54',
        unselected: '#861b54',
        selected: '#e20074',
        metroComplimentory: '#E37501', // orange
        metroYellow: '#F29D33',
        tag: '#861b54',
        grey: '#e0e0e0',
        error: '#E37501',
        light: '#fcf5fa',
        backgroundgrey: '#e8e8e8',
        greyLighten3: colors.grey.lighten3,
        greyDarken3: colors.grey.darken3,
        registerBackground: '#ffffff',
        magenta: '#e20074',
        darkMagenta: '#861B54',
        lightPink: '#FCF5FA',
        lightGrey: '#e8e8e8',
        mediumGrey: '#9b9b9b',
        darkGrey: '#6a6a6a',
        purple: '#46196e',
        royal: '#6e32a0',
        orchid: '#9646c3',
        gold: '#fa9d28',
        darkGold: '#e37501'
      }
    },
    options: { customProperties: true }
  },
  icons: {
    values: {
      CloseX: {
        component: CloseX
      },
      TMobileEmptyCheckbox: {
        component: TMobileEmptyCheckbox
      },
      TMobileFullCheckbox: {
        component: TMobileFullCheckbox
      },
      MetroFullCheckbox: {
        component: MetroFullCheckbox
      },
      Calendar: {
        component: Calendar
      },
      Clock: {
        component: Clock
      },
      Caret: {
        component: Caret
      }
    }
  }
});
