export const TrackUnsavedChangesDialogControl = {
  data() {
    return {
      untrackedChangesDialog: false,
      targetToRoute: null
    };
  },
  methods: {
    continueWithoutSavingEvent() {
      this.$store.commit('EVENT_MANAGER_STORE/RESET_ALL');
      this.$store.commit('EVENT_MANAGER_STORE/SET_CHANGE_TRACKED_STATE', false);
      this.$router.push(this.targetToRoute);
    },
    continueEditingEvent() {
      this.untrackedChangesDialog = false;
    }
  },
  computed: {
    UNTRACKED_CHANGES_TO_EVENT() {
      return this.$store.state.EVENT_MANAGER_STORE.UNTRACKED_CHANGES_TO_EVENT;
    }
  }
};
