<template>
  <v-btn
    fab
    dark
    small
    color="black"
    class="close-overlay"
    elevation="0"
    @click="changeAction(null)"
  >
    <v-icon large>$vuetify.icons.CloseX</v-icon>
  </v-btn>
</template>

<script>
import TMOEvent from '../../models/TMOEvent.js';

export default {
  name: 'DownloadQRAction',
  methods: {
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    }
  }
};
</script>
<style lang="scss" scoped>
.close-overlay {
  position: absolute;
  top: 15px;
  right: 40px;
}
</style>
