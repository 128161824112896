<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="40px" height="40px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve"
>
<g>
	<g id="Dev-Pack">
		<g id="Group" transform="translate(-499.000000, -577.000000)">
			<g id="Shape" transform="translate(493.000000, 573.000000)">
				<path class="st0" d="M21.1,22v3.3h-3.3V22H21.1 M27.8,22v3.3h-3.3V22H27.8 M34.5,22v3.3h-3.3V22H34.5 M37.8,10.3
					c1.8,0,3.3,1.5,3.3,3.3V37c0,1.8-1.5,3.3-3.3,3.3H14.5c-1.8,0-3.3-1.5-3.3-3.3V13.6c0-1.8,1.5-3.3,3.3-3.3h1.7V7h3.3v3.3h13.3V7
					h3.3v3.3H37.8 M37.8,37V18.6H14.5V37H37.8 M21.1,28.6V32h-3.3v-3.3H21.1 M27.8,28.6V32h-3.3v-3.3H27.8 M34.5,28.6V32h-3.3v-3.3
					H34.5z"
/>
			</g>
		</g>
	</g>
	<rect class="st1" width="40" height="40"/>
</g>
</svg>

</template>

<script>
export default {
  name: 'Calendar'
};
</script>
<style lang="scss" scoped>
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: currentColor;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
}
</style>
