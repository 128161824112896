<template>
  <div class="app-all-events">
    <AlertBar></AlertBar>
    <BannerTitle title="All Events" />
    <section class="pt-10 pb-8 lightGrey">
      <v-container :fluid="!this.lgBreakpoint">
        <AllEventsSearchForm />
        <v-row justify="start">
          <v-col cols="12">
            <div class="mt-3 app-all-events__showsearch">
              <button href="#" @click.prevent="toggleAdvancedSearchForm()">
                <span>Show Advanced Search</span>
                <v-icon medium color="#E44475">
                  {{
                    advancedSearchActive ? 'mdi-chevron-up' : 'mdi-chevron-down'
                  }}
                </v-icon>
              </button>
            </div>
          </v-col>
        </v-row>
        <AllEventsAdvancedSearchOptions />
      </v-container>
    </section>

    <section class="py-12">
      <v-container :fluid="!this.lgBreakpoint">
        <v-row align="center" class="flex-column">
          <v-col cols="12">
            <AllEventsActiveSearchFilters />
            <AllEventsTable
              :table-data="TABLE_DATA"
              :rows-per-page="RESULTS_PER_PAGE"
            />
            <!--
            <div class="app-all-events__pagination-wrap">
              <AllEventsPagination
                class="mt-6"
                :data="TABLE_DATA"
                :max-pages="Math.ceil(TABLE_DATA.length / RESULTS_PER_PAGE)"
                :items-per-page="RESULTS_PER_PAGE"
              />
              <div class="app-all-events__itemsperpage">
                <div>View:</div>
                <div>
                  <a
                    v-for="option in rowsPerPageOptions"
                    :key="option.id"
                    :class="[option.value === RESULTS_PER_PAGE ? 'active' : '']"
                    @click.prevent="CHANGE_ITEMS_PER_PAGE(option.value)"
                  >
                    {{ option.text }}
                  </a>
                </div>
              </div>
            </div>
            -->
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertBar from '@/components/AlertBar.vue';
import BannerTitle from '@/components/BannerTitle.vue';
import AllEventsSearchForm from '@/components/Search/AllEventsSearchForm';
import AllEventsAdvancedSearchOptions from '@/components/Search/AllEventsAdvancedSearchOptions';
import AllEventsActiveSearchFilters from '@/components/Search/AllEventsActiveSearchFilters';
import AllEventsTable from '@/components/AllEventsTable';

export default {
  name: 'AllEvents',
  components: {
    AlertBar,
    AllEventsTable,
    BannerTitle,
    AllEventsSearchForm,
    AllEventsAdvancedSearchOptions,
    AllEventsActiveSearchFilters
  },
  data() {
    return {
      advancedSearchActive: false,
      rowsPerPageOptions: [
        {
          text: '25',
          value: 25
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ]
    };
  },
  async created() {
    this.GET_EVENTS();
  },
  computed: {
    ...mapState('EVENT_TABLE_STORE', [
      'TABLE_DATA',
      'LOADING',
      'RESULTS_PER_PAGE'
    ]),
    lgBreakpoint: function() {
      return this.$vuetify.breakpoint.lgAndUp;
    }
  },
  methods: {
    ...mapActions('EVENT_TABLE_STORE', ['GET_EVENTS', 'CHANGE_ITEMS_PER_PAGE']),

    next(page) {
      this.$store.commit('EVENT_TABLE_STORE/SET_CURRENT_PAGE', page);
    },
    toggleAdvancedSearchForm() {
      this.advancedSearchActive = !this.advancedSearchActive;
      if (
        !document
          .getElementsByClassName('app-events-advanced-search')[0]
          .classList.contains('active')
      ) {
        document
          .getElementsByClassName('app-events-advanced-search')[0]
          .classList.add('active');
        document
          .getElementsByClassName('app-all-events__showsearch')[0]
          .getElementsByTagName('span')[0].innerHTML = 'Hide Advanced Search';
      } else {
        document
          .getElementsByClassName('app-events-advanced-search')[0]
          .classList.remove('active');
        document
          .getElementsByClassName('app-all-events__showsearch')[0]
          .getElementsByTagName('span')[0].innerHTML = 'Show Advanced Search';
      }
    }
  }
};
</script>

<style scoped lang="scss">
// .container--fluid {
//   // max-width: 1200px;
// }
.app-all-events__pagination-wrap {
  display: flex;
  justify-content: space-between;
}
.app-all-events__itemsperpage {
  display: flex;
  margin-top: 32px;
}
.app-all-events__itemsperpage a {
  text-decoration: none;
  font-weight: 700;
  color: #000;
  margin-left: 5px;
  margin-right: 5px;
}
.app-all-events__itemsperpage a.active {
  color: var(--v-primary-base);
}
.app-all-events__showsearch > a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.v-pagination {
  align-items: flex-start !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 48px;
}
</style>
