/* eslint-disable */
import { API, Auth, Storage } from 'aws-amplify';

/**
 * Utility
 */
function uuidv4() {
  return 'xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function checkForDuplicates(theArray = [], targetKey) {
  const uniqueList = new Set()
  return theArray.filter(function(item) {
    if (!uniqueList.has(item[targetKey])) {
      uniqueList.add(item[targetKey])
      return true
    }
  })
}

async function putImageInStorage(image) {
  let fileType = image.type.split('image/')[1];
  const result = await Storage.put(
    `radioimage-${uuidv4()}.${fileType}`,
    image,
    {
      contentType: image.type,
      ACL: 'public-read',
      visibility: 'public',
      level: 'public',
      cacheControl: 'no-cache'
    }
  );
  var fullUrl = await Storage.get(result.key);
  return fullUrl.split('?')[0];
}

function parseError(error) {
  if (error.response && error.response.data) {
    return JSON.parse(error.response.data).errorMessage;
  }
  return error;
}

/**
 * API Setup
 */
const initApi = async () => {
  try {
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return myInit;
  } catch (error) {
    return error;
  }
};
//Endpoints - todo should seperate legal updates etc.
let apiName = 'TMOAmplifyApi';
const buildPath = (resource = null) => {
  return !resource ? '/radioimages' : `/radioimage/${resource}`;
};

/**
 * Configuration
 * @type {Object}
 * @property {Array} PHOTO_LIBRARY_TABS - Tabs for photo library if user is admin
 */
const AVECANVASCONFIG = {
  PHOTO_LIBRARY_ADMIN_TABS: [
    { text: 'Photo Library', component: 'AVEPhotoLibrary' },
    { text: 'Manage Photos', component: 'AVEPhotoManager' },
  ],
};

const state = {
  PROMOTION_TYPE: 'single_prize_contest', // what promotion type is selected
  FLYOUT_OPEN: false, // is flyout open
  SELECTED_TEMPLATE: null, // what asset type/size/template
  SELECTED_IMAGE: null, // image for canvas
  SELECTED_BRAND: 'tmobile', // default selection
  CURRENT_LANGUAGE: 'en', // defines which template vs should show
  PROMOTION_TYPES: [
    // { text: 'Family - Coming Soon!', value: 'family' },
    // { text: 'Small Business - Coming Soon!', value: 'small_business' },
    // { text: 'Local Heroes - Coming Soon!', value: 'local_heros' },
    { text: 'Single Prize Contest', value: 'single_prize_contest' },
  ],
  AVAILABLE_TEMPLATES: [
    {
      text: 'TMO_SP_160x600',
      promo_type: 'single_prize_contest',
      brand: 'tmobile',
      is_image_banner: true,
      t_output_height: 600,
      t_output_width: 160,
      template_id: 'TMO_SP_160x600',
      template_file_name: 'TMO_SP_160x600',
      placeholder_image_height: 224,
      placeholder_image_width: 160,
      language: 'en',
      max_legal_character_limit: 39,
    },
    {
      text: 'TMO_SP_728x90',
      promo_type: 'single_prize_contest',
      brand: 'tmobile',
      is_image_banner: false,
      t_output_height: 90,
      t_output_width: 728,
      template_id: 'TMO_SP_728x90',
      template_file_name: 'TMO_SP_728x90',
      language: 'en',
    },
    {
      text: 'TMO_SP_728x90_ES',
      promo_type: 'single_prize_contest',
      brand: 'tmobile',
      is_image_banner: false,
      t_output_height: 90,
      t_output_width: 728,
      template_id: 'TMO_SP_728x90',
      template_file_name: 'TMO_SP_728x90_ES',
      language: 'es',
    },

    {
      text: 'TMO_SP_160x600_ES',
      promo_type: 'single_prize_contest',
      brand: 'tmobile',
      is_image_banner: true,
      t_output_height: 600,
      t_output_width: 160,
      template_id: 'TMO_SP_160x600',
      template_file_name: 'TMO_SP_160x600_ES',
      placeholder_image_height: 224,
      placeholder_image_width: 160,
      language: 'es',
      max_legal_character_limit: 39,
    },
    {
      text: 'TMO_SP_300x250',
      promo_type: 'single_prize_contest',
      brand: 'tmobile',
      is_image_banner: false,
      t_output_height: 250,
      t_output_width: 300,
      template_id: 'TMO_SP_300x250',
      template_file_name: 'TMO_SP_300x250',
      language: 'en',
    },
    {
      text: 'TMO_SP_300x250_ES',
      promo_type: 'single_prize_contest',
      brand: 'tmobile',
      is_image_banner: false,
      t_output_height: 250,
      t_output_width: 300,
      template_id: 'TMO_SP_300x250',
      template_file_name: 'TMO_SP_300x250_ES',
      language: 'es',
    },
    {
      text: 'TMO_SP_970x250',
      promo_type: 'single_prize_contest',
      brand: 'tmobile',
      is_image_banner: true,
      t_output_height: 250,
      t_output_width: 970,
      template_id: 'TMO_SP_970x250',
      template_file_name: 'TMO_SP_970x250',
      language: 'en',
      placeholder_image_height: 257,
      placeholder_image_width: 290,
    },
    {
      text: 'TMO_SP_970x250',
      promo_type: 'single_prize_contest',
      brand: 'tmobile',
      is_image_banner: true,
      t_output_height: 250,
      t_output_width: 970,
      template_id: 'TMO_SP_970x250',
      template_file_name: 'TMO_SP_970x250_ES',
      language: 'es',
      placeholder_image_height: 257,
      placeholder_image_width: 290,
    },
    {
      text: 'TMO_SP_300x600',
      promo_type: 'single_prize_contest',
      brand: 'tmobile',
      is_image_banner: true,
      t_output_height: 600,
      t_output_width: 300,
      template_id: 'TMO_SP_300x600',
      template_file_name: 'TMO_SP_300x600',
      language: 'en',
      placeholder_image_height: 257,
      placeholder_image_width: 300,
      max_legal_character_limit: 75,
    },
    {
      text: 'TMO_SP_300x600_ES',
      promo_type: 'single_prize_contest',
      brand: 'tmobile',
      is_image_banner: true,
      t_output_height: 600,
      t_output_width: 300,
      template_id: 'TMO_SP_300x600',
      template_file_name: 'TMO_SP_300x600_ES',
      language: 'es',
      placeholder_image_height: 257,
      placeholder_image_width: 300,
      max_legal_character_limit: 75,
    },
  ],
  AVAILABLE_IMAGES: [],
  // Moving ui ux components and controls
  DIALOG_OPEN: false, // controls whether or not dialog is open
  ACTIVE_DIALOG_COMPONENT: 'AVEPhotoLibrary', // controls which dialog component is open
  PHOTO_LIBRARY_OPEN: false, // whether or not photo lib dialog is open
  // Image Uploading
  NEW_IMAGE_PROMO_TYPE: null, // currently selected promo type admin wants to setup a new image for
  NEW_IMAGE_UPLOAD_ARR: [], // array of images to send to api end point
};

const getters = {
  GET_PHOTO_LIBRARY_DIALOG_TABS: (state, getters, rootState) => {
    let userGroups = rootState.account.USER_GROUPS || [];
    if (userGroups.includes('Admin')) {
      return AVECANVASCONFIG.PHOTO_LIBRARY_ADMIN_TABS;
    }
    return null;
  },
  GET_TEMPLATES_BY_PROMO_TYPE: (state) => {
    let filtered = [...state.AVAILABLE_TEMPLATES];

    if (state.PROMOTION_TYPE) {
      filtered = filtered.filter(
        (template) => template.promo_type === state.PROMOTION_TYPE
      );
    }

    if (state.SELECTED_BRAND) {
      filtered = filtered.filter(
        (template) => template.brand === state.SELECTED_BRAND
      );
    }

    if (state.CURRENT_LANGUAGE) {
      filtered = filtered.filter(
        (template) => template.language === state.CURRENT_LANGUAGE
      );
    }

    return filtered;
  },
  GET_IMAGES_BY_PROMO_TYPE: (state) => {
    let filtered = [...state.AVAILABLE_IMAGES];

    if (state.PROMOTION_TYPE) {
      filtered = filtered.filter(
        (image) => image.promo_type === state.PROMOTION_TYPE
      );
    }

    filtered = checkForDuplicates(filtered, 'group_id')
    
    return filtered;
  },
  GET_CURRENT_TEMPLATE_OBJECT: (state) => {
    const selectedTemplateObj = state.AVAILABLE_TEMPLATES.find(
      (template) => {
        return (
          template['template_id'] === state.SELECTED_TEMPLATE &&
          template['language'] === state.CURRENT_LANGUAGE
        );
      }
    );
    if (!selectedTemplateObj)
      return {
        t_output_height: 160,
        t_output_width: 600,
        template_file_name: 'no-template-selected',
      };
    return selectedTemplateObj;
  },
  GET_CORRECT_IMAGE_URL: (state) => {
    let correct_image_url = null;
    const selectedTemplateObj = state.AVAILABLE_TEMPLATES.find(
      (template) => template['template_id'] === state.SELECTED_TEMPLATE
    );
    const selectedImageObject = state.AVAILABLE_IMAGES.find(
      (img) => img['url'] === state.SELECTED_IMAGE
    );

    if (
      !selectedTemplateObj ||
      !selectedImageObject ||
      !selectedImageObject['group_id'] ||
      !selectedTemplateObj['template_id']
    )
      return correct_image_url;

    correct_image_url = state.AVAILABLE_IMAGES.find((img) => {
      return (
        img['group_id'] === selectedImageObject['group_id'] &&
        img['template_id'] === selectedTemplateObj['template_id']
      );
    });

    if (!correct_image_url || !correct_image_url['url'])
      return correct_image_url;

    return correct_image_url['url'];
  },
};

const actions = {
  async GET_AVAILABLE_IMAGES({ commit, state }) {
    let response = {};
    try {
      if (state.LOADING) {
        console.log('Still loading previous request');
        return;
      }
      commit('SET_LOADING', true);
      let path = buildPath();
      let initAuth = await initApi();
      response = await API.get(apiName, path, initAuth);
      let images = response.data;
      commit('SET_AVAILABLE_IMAGES', images);
      return response;
    } catch (error) {
      throw parseError(error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  // Change Image Upload type
  CHANGE_IMAGE_UPLOAD_PROMO_TYPE({ commit, state }, promoType) {
    let newImageFormArr = [],
      new_image_group_id = '';
    if (!promoType) return commit('RESET_IMAGE_UPLOAD_FORM');
    new_image_group_id = uuidv4();
    newImageFormArr = state.AVAILABLE_TEMPLATES.filter((image) => {
      return (
        image.promo_type === promoType &&
        image.language !== 'es' &&
        image.is_image_banner
      );
    }).map((img) => {
      return {
        template_id: img.template_id,
        group_id: new_image_group_id,
        promo_type: img.promo_type,
        url: null,
        placeholder_image_height: img.placeholder_image_height,
        placeholder_image_width: img.placeholder_image_width,
        valid_dimensions: false
      };
    });

    // GET AND SETUP IMAGE UPLOAD FORM BASED ON PROMO TYPE
    // COMMIT NEW ARR TO STATE
    console.log('SET_NEW_IMAGE_UPLOAD_FORM_ARR', newImageFormArr);
    commit('SET_NEW_IMAGE_UPLOAD_FORM', newImageFormArr);
    commit('SET_NEW_IMAGE_PROMO_TYPE', promoType);
  },

  SETUP_IMAGE_EDITING({ commit, state }, editImage) {
    if (!editImage) return commit('RESET_IMAGE_UPLOAD_FORM');
    let newImageFormArr = state.AVAILABLE_TEMPLATES.filter((image) => {
      return (
        image.promo_type === editImage.promo_type &&
        image.language !== 'es' &&
        image.is_image_banner
      );
    }).map((img) => {
      return {
        id: null,
        template_id: img.template_id,
        group_id: editImage.group_id,
        promo_type: img.promo_type,
        url: null,
        placeholder_image_height: img.placeholder_image_height,
        placeholder_image_width: img.placeholder_image_width,
        valid_dimensions: false,
        changed: false,
        image: null
      };
    });

    newImageFormArr.forEach((image) => {
      let prevImage = state.AVAILABLE_IMAGES.find((img) => {
        return img.group_id === image.group_id && img.template_id === image.template_id
      });
      image.id = prevImage.id;
      image.url = prevImage.url;
    });
    commit('SET_NEW_IMAGE_UPLOAD_FORM', newImageFormArr);
  },

  async UPLOAD_IMAGES({ commit, state, dispatch }) {
    try {
      if (state.LOADING) {
        console.log('Still loading previous request');
        return;
      }
      commit('SET_LOADING', true);
      let initAuth = await initApi();
      state.NEW_IMAGE_UPLOAD_ARR.forEach(async (image) => {
        image.url = await putImageInStorage(image.url);
        initAuth.body = image;
        await API.post(apiName, '/radioimage', initAuth);
      });
      commit('RESET_IMAGE_UPLOAD_FORM');
      commit('SET_LOADING', false);
      await dispatch('GET_AVAILABLE_IMAGES');
    } catch (error) {
      throw parseError(error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async EDIT_IMAGES({ commit, state, dispatch }) {
    try {
      if (state.LOADING) {
        console.log('Still loading previous request');
        return;
      }
      commit('SET_LOADING', true);
      let initAuth = await initApi();
      state.NEW_IMAGE_UPLOAD_ARR.forEach(async (image) => {
        if (image.changed) {
          image.url = await putImageInStorage(image.image);
          initAuth.body = image;
          let path = buildPath(image.id);
          await API.patch(apiName, path, initAuth);
        }
      });
      commit('RESET_IMAGE_UPLOAD_FORM');
      commit('SET_LOADING', false);
      await dispatch('GET_AVAILABLE_IMAGES');
    } catch (error) {
      throw parseError(error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async DELETE_IMAGES({ commit, state, dispatch }) {
    try {
      if (state.LOADING) {
        console.log('Still loading previous request');
        return;
      }
      commit('SET_LOADING', true);
      let initAuth = await initApi();
      let group_id = state.NEW_IMAGE_UPLOAD_ARR[0].group_id;
      let path = buildPath(group_id);
      await API.del(apiName, path, initAuth);
      commit('RESET_IMAGE_UPLOAD_FORM');
      commit('SET_LOADING', false);
      await dispatch('GET_AVAILABLE_IMAGES');
    } catch (error) {
      throw parseError(error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async RECORD_ACTION({ commit, state }, payload) {
    try {
      if (state.LOADING) {
        console.log('Still loading previous request');
        return;
      }
      commit('SET_LOADING', true);
      let initAuth = await initApi();
      let path = buildPath('record');
      initAuth.body = payload;
      await API.post(apiName, path, initAuth);
    } catch (error) {
      console.log('Error recording radio action: ' + error);
    } finally {
      commit('SET_LOADING', false);
    }
  }
};
// // mutations
const mutations = {
  SET_LOADING: (state, payload) => {
    state.LOADING = payload || false;
  },
  RESET_ALL: (state) => {
    state.LOADING = false;
    state.ACTIVE_DIALOG_COMPONENT = 'AVEPhotoLibrary';
    state.NEW_IMAGE_PROMO_TYPE = null;
  },
  TOGGLE_AVE_DIALOG: (state, payload) => {
    state.DIALOG_OPEN = payload;
  },
  SET_SELECTED_IMAGE: (state, payload) => {
    if (!payload) return (state.SELECTED_IMAGE = null);
    state.SELECTED_IMAGE = payload;
  },
  SET_ACTIVE_DIALOG_COMPONENT: (state, payload) => {
    if (!payload) return (state.ACTIVE_DIALOG_COMPONENT = 'AVEPhotoLibrary');
    state.ACTIVE_DIALOG_COMPONENT = payload;
  },
  UPDATE_PROMOTION_TYPE: (state, payload) => {
    if (!payload) return (state.PROMOTION_TYPE = null);
    state.PROMOTION_TYPE = payload;
  },
  UPDATE_SELECTED_TEMPLATE: (state, payload) => {
    if (!payload) return (state.SELECTED_TEMPLATE = null);
    state.SELECTED_TEMPLATE = payload;
  },
  TOGGLE_AVE_FLYOUT: (state, payload) => {
    state.FLYOUT_OPEN = payload;
  },
  SET_AVAILABLE_IMAGES: (state, payload) => {
    state.AVAILABLE_IMAGES = payload;
  },
  RESET_IMAGE_UPLOAD_FORM: (state) => {
    state.NEW_IMAGE_UPLOAD_ARR.forEach((image) => {
      if (image.changed) {
        let URL = window.URL || window.webkitURL;
        URL.revokeObjectURL(image.url);
      }
    });
    state.NEW_IMAGE_PROMO_TYPE = null;
    state.NEW_IMAGE_UPLOAD_ARR = [];
    // state.ACTIVE_DIALOG_COMPONENT = 'AVEPhotoManager';
  },
  SET_NEW_IMAGE_PROMO_TYPE: (state, payload) => {
    state.NEW_IMAGE_PROMO_TYPE = payload;
  },
  SET_NEW_IMAGE_UPLOAD_FORM: (state, payload) => {
    if (!payload) return (state.NEW_IMAGE_UPLOAD_ARR = []);
    state.NEW_IMAGE_UPLOAD_ARR = payload;
  },
  SET_CURRENT_LANGUAGE: (state, payload) => {
    if (!payload) return (state.CURRENT_LANGUAGE = 'en');
    state.CURRENT_LANGUAGE = payload;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
