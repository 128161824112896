<template>
  <div v-if="SETTINGS.alert_message != null && closeAlertAction === false">
    <v-alert class="app-alert-bar" dark>
      {{ SETTINGS.alert_message }}
      <!--<v-btn @click="closeAlertAction = true" fab small id="alert-close">
        <v-icon large>$vuetify.icons.CloseX</v-icon>
      </v-btn>-->
    </v-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'AlertBar',
  async created() {
    await this.fetchSettings();
  },
  data() {
    return {
      closeAlertAction: false
    };
  },
  methods: {
    ...mapActions(['fetchSettings'])
  },
  computed: {
    ...mapGetters(['SETTINGS'])
  }
};
</script>

<style lang="scss" scoped>
.app-alert-bar {
  text-align: center;
  padding: 20px 0;
  background-color: var(--v-error-base);
  color: white;
  text-transform: uppercase;
  margin: 0;
  border-radius: 0px !important;
}
#alert-close {
  background-color: transparent !important;
  box-shadow: none;
  position: absolute;
  right: 2;
  display: block;
  right: 25px;
  top: 11px;
}
::v-deep .v-alert {
  position: relative !important;
}
</style>
