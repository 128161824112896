/* eslint-disable */
export default class HelperFunctions {
  static RenderLinks(text) {
    if (text) {
      let regex = new RegExp(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
      );
      return text.replace(regex, match => {
        return `<a href="${match}" target="_blank">${match}</a>`;
      });
    } else {
      return 'ABBREVIATED LEGAL DESCRIPTION GOES HERE';
    }
  }
  static BrandLegal(brand, language) {
    if (language == 'es') {
      return ' T-Mobile, el logotipo de la T, Magenta y el color magenta son marcas comerciales registradas de Deutsche Telekom AG. © 2021 T-Mobile USA, Inc.';
    } else {
      if (brand.includes('Metro')) {
        return ' Metro by T-Mobile is a registered trademark of T-Mobile USA, Inc. © 2021 T-Mobile USA, Inc.';
      } else {
        return ' T-Mobile, the T logo, Magenta and the magenta color are registered trademarks of Deutsche Telekom AG. © 2021 T-Mobile USA, Inc.';
      }
    }
  }
  static OutputLegal(event, language) {
    if (!event) {
      return '';
    }
    if (event.abbreviatedLegal && event.abbreviatedLegal != '') {
      return (
        this.RenderLinks(event.abbreviatedLegal) +
        this.BrandLegal(event.brand, language) +
        (language == 'es'
          ? ' Consulta el Reglamento oficial completo en <a href="'
          : ' See complete Official Rules available at <a href="') +
        window.location.origin +
        '/#/event/rules/' +
        event.id +
        '" target="_blank">' +
        window.location.origin +
        '/#/event/rules/' +
        event.id +
        '</a>.'
      );
    }
    let legal = '';
    if (event.contestType == 'Enter for a Chance to Win') {
      legal =
        language == 'es'
          ? 'Sin obligación de compra. Nulo donde esté prohibido.'
          : 'No purchase necessary. Void where prohibited.';
      // if states are restricted
      if (event.contestStatesRequired) {
        legal +=
          language == 'es' ? ' Residentes legales de ' : ' Legal residents of ';
        let length = event.eligibleStates.length;
        for (let i = 0; i < length; ++i) {
          // for each state display XX/XX/XX etc where XX is state
          legal += event.eligibleStates[i];
          if (i != length - 1) {
            legal += '/';
          }
        }
        legal += language == 'es' ? ', mayores de 18 años.' : ', age 18+.';
      } else {
        if (event.brand.includes('Metro')) {
          legal +=
            language == 'es'
              ? ' Abierto a 50 U.S. y D.C. 18+.'
              : ' Open to 50 U.S. & D.C. 18+.';
        } else {
          legal +=
            language == 'es'
              ? ' Abierto para mayores de 18 años en los 50 estados de EE. UU., D.C. y Puerto Rico.'
              : ' Open to 50 U.S., D.C., & Puerto Rico 18+.';
        }
      }
      // entry periods
      if (event.intervals && event.intervals.length > 0) {
        let startDate = this.FormatDate(event.startDate);
        let endDate = this.FormatDate(event.endDate);
        let startTime = this.FormatTime(event.startTime);
        let endTime = this.FormatTime(event.endTime);
        let timezone = this.FormatTimezone(event.timezone);

        legal +=
          language == 'es'
            ? event.intervals.length > 1
              ? ` Períodos de participación: diversos desde`
              : ` Período de participación: desde`
            : event.intervals.length > 1
            ? ` Entry Periods: Multiple between`
            : ` Entry Period: between`;

        legal +=
          language == 'es'
            ? ` ${startDate} ${startTime} ${timezone} y`
            : ` ${startDate} ${startTime} ${timezone} and`;

        legal += ` ${endDate} ${endTime} ${timezone}.`;
      }
      // event location
      let rulesUrl = window.location.origin + '/#/event/rules/' + event.id;
      legal +=
        language == 'es'
          ? ` Consulta el Reglamento oficial completo en ${rulesUrl}.`
          : ` See complete Official Rules available at ${rulesUrl}.`;
    } else if (event.contestType == 'Sign Up/Check In') {
      legal =
        language == 'es'
          ? 'Sin obligación de compra. Un artículo por persona hasta agotar existencias y por orden de llegada.'
          : 'No purchase necessary. One item per person, while supplies last, first come first served.';
    } else if (event.contestType == 'Instant Win') {
      legal =
        language == 'es'
          ? 'Sin obligación de compra. Una participación por persona, mayores de 18 años. Premios hasta agotar existencias y por orden de llegada. Nulo donde esté prohibido.'
          : 'No purchase necessary. One play per person, 18+. Prizes while supplies last, first come first served. Void where prohibited.';
    } else {
      if (event.brand.includes('Metro')) {
        legal =
          language == 'es'
            ? 'No se requiere hacer una compra. Abierto para mayores de 18 años en los 50 estados de EE. UU., D.C. y Puerto Rico. Detalles en las reglas impresas. Nulo donde esté prohibido.'
            : 'No purchase necessary. Open to 50 U.S. & D.C. 18+. See printed rules for details. Void where prohibited.';
      } else {
        legal =
          language == 'es'
            ? 'Abierto para mayores de 18 años en los 50 estados de EE. UU., D.C. y Puerto Rico.Detalles en las reglas impresas. Nulo donde esté prohibido.'
            : 'No purchase necessary. Open to 50 U.S., D.C., & Puerto Rico 18+. See printed rules for details. Void where prohibited.';
      }
    }

    return this.RenderLinks(legal) + this.BrandLegal(event.brand, language);
  }
  static FormatDate(date) {
    if (date == null) {
      return 'No Date Set';
    }
    // yyyy-mm-dd to mm/dd/yyyy
    return date.slice(5, 7) + '/' + date.slice(-2) + '/' + date.slice(0, 4);
  }
  static FormatTime(time) {
    if (time == null) {
      return 'No Time Set';
    }
    // convert to hours 1-12 and remove seconds
    let hour = Number(time.slice(0, 2));
    let meridiem = hour > 11 ? ' p.m.' : ' a.m.';
    // hour 0 becomes 12, hours 13-23 become 1-11
    hour = hour == 0 ? 12 : hour > 12 ? hour - 12 : hour;
    // convert to string, adding leading 0 if hour 1-9
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();
    return hour + ':' + time.slice(3, 5) + meridiem;
  }
  static FormatTimezone(timezone) {
    switch (timezone) {
      case 'US/Hawaii':
        return 'HAST';
      case 'US/Alaska':
        return 'AK';
      case 'America/Los_Angeles':
        return 'PT';
      case 'America/Denver':
        return 'MT';
      case 'America/Chicago':
        return 'CT';
      case 'America/New_York':
        return 'ET';
      default:
        return '';
    }
  }
  /**
   * @param {array} Array of Objects
   * @return {object} Object with keys as keys and arrays of grouped objects as their values
   * @memberof EventHelper
   * @static
   * @desc Groups an array of objects where values are the same for the target property
   * @example
   * let a = [{ foo : 'b', bar : 'a' }, { foo : 'cat' , bar : 'bat' }, { foo : 'bar' , bar : 'a' }, { foo : 'bar' , bar : 'c' }];
   * let b = EventHelper.GroupObjectsByMatchingProperties(a, 'foo');
   * =>
   * {
   * 'b' : [{ foo : 'b' , bar : 'a' }],
   * 'cat' : [{ foo : 'cat' , bar : 'bat'  }],
   * 'bar' : [{ foo : 'bar' , bar : 'a' }, { foo : 'bar' , bar : 'c'  }]
   * }
   **/
  static GroupObjectsByMatchingProperties(arrayOfObjects, targetProperty) {
    return arrayOfObjects.reduce((acc, obj) => {
      let k = obj[targetProperty];
      if (!acc[k]) {
        acc[k] = [];
      }
      acc[k].push(obj);
      return acc;
    }, {});
  }
}
