<template>
  <div class="app-dashboard">
    <BannerTitle :title="bannerTitle" :description="description" />
    {{ adminActionError }}
    <Footer />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import BannerTitle from '@/components/BannerTitle.vue';

export default {
  name: 'UserApproval',
  async created() {
    try {
      this.user = await this.getUser(this.username);
      if (!this.user) throw Error('Unable to read user information');
      if (this.approved) {
        this.userApproved(this.username);
      } else {
        this.userDenied(this.username);
      }
    } catch (error) {
      this.bannerTitle = 'Error';
    }
  },
  data() {
    return {
      bannerTitle: '',
      adminActionError: '',
      user: undefined,
      description:
        'This user will receive an email alerting them of their status.'
    };
  },
  computed: {
    username: function() {
      return this.$route.query.username.replace(/\s+/g, '+') || null;
    },
    approved: function() {
      return this.$route.path.toLowerCase().includes('/admin/userapproved')
        ? true
        : false;
    }
  },
  methods: {
    ...mapActions({
      addToGroup: 'admin/addUserToGroup',
      disableUser: 'admin/disableUser',
      enableUser: 'admin/enableUser',
      getUser: 'admin/getUser',
      removeFromGroup: 'admin/removeUserFromGroup',
      sendEmail: 'admin/sendEmail'
    }),
    userInfo(userObject) {
      if (!userObject) return;
      return Object.assign(
        {},
        ...userObject.UserAttributes.map((x, arr) => {
          return { [x.Name]: x.Value };
        })
      );
    },
    approvedEmail: function(toAddress, givenName) {
      let templateData = {
        preheader_text: '',
        header_text: 'Congratulations!',
        greeting_text: `Hi, ${givenName},`,
        'body-1_text':
          'Your request for access to the T-Mobile Data Capture Tool has been approved. Click the button below to login.',
        'cta-1_href': 'https://www.t-mobileengagementhub.com',
        'cta-1_text': 'Login',
        'body-2_text': '',
        subject_text:
          'Your Request for Access to the T-Mobile Data Capture Tool',
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'SignUp <SignUp@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    deniedEmail: function(toAddress, givenName) {
      let templateData = {
        preheader_text: '',
        header_text: '',
        greeting_text: `Hi, ${givenName},`,
        'body-1_text':
          'Your request for access to the T-Mobile Data Capture Tool has not been approved. Please reach out to the T-Mobile employee you are working with for more information.',
        'body-2_text': '',
        subject_text:
          'Your Request for Access to the T-Mobile Data Capture Tool',
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'SignUp <SignUp@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    userApproved: async function(username) {
      this.bannerTitle = 'User Approved!';
      try {
        let info = this.userInfo(await this.getUser(username));
        await this.addToGroup({ username, groupname: 'Guest' });
        await this.removeFromGroup({ username, groupname: 'Pending' });
        await this.enableUser(username);
        await this.sendEmail(
          this.approvedEmail(info.email, info['given_name'])
        );
        return 'Success';
      } catch (error) {
        this.adminActionError = error;
        throw error;
      }
    },
    userDenied: async function(username) {
      this.bannerTitle = 'User Denied!';
      try {
        let info = this.userInfo(await this.getUser(username));
        await this.addToGroup({ username, groupname: 'Pending' });
        await this.removeFromGroup({ username, groupname: 'Guest' });
        await this.disableUser(username);
        await this.sendEmail(this.deniedEmail(info.email, info['given_name']));
      } catch (error) {
        this.adminActionError = error;
        throw error;
      }
    }
  },
  components: {
    BannerTitle
  }
};
</script>

<style scoped></style>
