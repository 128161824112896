import addUserToGroup from '@/store/modules/admin/actions/addUserToGroup';
import disableUser from '@/store/modules/admin/actions/disableUser';
import enableUser from '@/store/modules/admin/actions/enableUser';
import confirmUserSignUp from '@/store/modules/admin/actions/confirmUserSignUp';
import getUser from '@/store/modules/admin/actions/getUser';
import listUsers from '@/store/modules/admin/actions/listUsers';
import listGroups from '@/store/modules/admin/actions/listGroups';
import listGroupsForUser from '@/store/modules/admin/actions/listGroupsForUser';
import listUsersInGroup from '@/store/modules/admin/actions/listUsersInGroup';
import removeUserFromGroup from '@/store/modules/admin/actions/removeUserFromGroup';
import updateUserAttributes from '@/store/modules/admin/actions/updateUserAttributes';
import sendEmail from '@/store/modules/admin/actions/sendEmail';

/**
 * State
 */
const state = () => ({});

/**
 * Getters
 */
const getters = {
  // IS_LOGGED_IN: state => {
  //   return state.IS_LOGGED_IN;
  // },
};

/**
 * Actions
 */
const actions = {
  addUserToGroup,
  disableUser,
  enableUser,
  confirmUserSignUp,
  getUser,
  listUsers,
  listGroups,
  listGroupsForUser,
  listUsersInGroup,
  removeUserFromGroup,
  updateUserAttributes,
  sendEmail
};

/**
 * Mutations
 */
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
