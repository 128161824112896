<template>
  <div class="app-events-advanced-search">
    <v-row justify="center" align="center">
      <v-col v-if="isInAdminGroup()">
        <v-text-field
          v-model="createdByEmail"
          append-icon="mdi-account-plus"
          hide-details="true"
          label="Creator"
          @blur="applyEmailFilter($event)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="brand"
          :items="brandItems"
          label="Brand"
          item-value=""
          append-icon="$Caret"
          hide-details="true"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="eventType"
          :items="eventTypeItems"
          label="Event Segment"
          append-icon="$Caret"
          hide-details="true"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="language"
          :items="languageItems"
          label="Language"
          append-icon="$Caret"
          hide-details="true"
        ></v-select>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="archived"
          off-icon="$TMobileEmptyCheckbox"
          :on-icon="'$TMobileFullCheckbox'"
          hide-details="true"
        >
          <span slot="label" class="black--text">Display Archived Events</span>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-menu
          v-model="startingBeforeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startingBeforeDate"
              label="Starting Before"
              append-icon="$Calendar"
              readonly
              v-bind="attrs"
              class="app-events-advanced-search__calendarpicker"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startingBeforeDate"
            no-title
            :landscape="true"
            :show-current="false"
            @input="startingBeforeMenu = false"
            @click:date="beforeDateAction"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="startingAfterMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startingAfterDate"
              label="Starting After"
              append-icon="$Calendar"
              readonly
              v-bind="attrs"
              class="app-events-advanced-search__calendarpicker"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startingAfterDate"
            no-title
            :landscape="true"
            :show-current="false"
            @input="startingAfterMenu = false"
            @click:date="afterDateAction"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-select
          v-model="organization"
          :items="tmusItems"
          label="Organization"
          append-icon="$Caret"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="region"
          :items="regionItems"
          label="Region"
          append-icon="$Caret"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="state"
          :items="stateItems"
          label="State"
          append-icon="$Caret"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="dma"
          :items="dmaItems"
          label="DMA"
          append-icon="$Caret"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OptionData from '../../utility/OptionData.js';

export default {
  name: 'AdvancedSearchForm',
  data() {
    return {
      userFilter: '',
      brandItems: ['T-Mobile', 'Metro'],
      eventTypeItems: OptionData.SegmentAudienceItems(),
      languageItems: [
        { value: 'en', text: 'English' },
        { value: 'bi', text: 'English and Spanish' }
      ],
      sortItems: [],
      startingAfterDateZ: '',
      startingBeforeDataZ: '',
      tmusItems: OptionData.Organizations(),
      regionItems: OptionData.Regions(),
      stateItems: OptionData.States(),
      dmaItems: OptionData.DMAs(),
      modal: false,
      startingAfterMenu: false,
      startingBeforeMenu: false,
      localCreatedByEmail: null
    };
  },
  methods: {
    ...mapActions('EVENT_TABLE_STORE', ['APPLY_FILTER']),
    ...mapActions('EVENT_MANAGER_STORE', ['SET_FILTER']),
    isInAdminGroup() {
      return this.USER_GROUPS.indexOf('Admin') !== -1;
    },
    beforeDateAction(data) {
      this.SET_FILTER({
        label: 'Starting Before Date',
        value: data + ' 00:00:00',
        name: 'startingBeforeDate'
      });
    },
    afterDateAction(data) {
      this.SET_FILTER({
        label: 'Starting After Date',
        value: data + ' 00:00:00',
        name: 'startingAfterDate'
      });
    },
    applyEmailFilter() {
      this.APPLY_FILTER({
        filter_key: 'createdByEmail',
        filter_value: this.localCreatedByEmail
      });
    }
  },
  computed: {
    ...mapGetters({
      USER_GROUPS: `account/USER_GROUPS`,
      FILTER_INPUTS: `FILTER_INPUTS`
    }),
    brand: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['brand'];
      },
      set(value) {
        this.APPLY_FILTER({ filter_key: 'brand', filter_value: value });
      }
    },
    language: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['language'];
      },
      set(value) {
        this.APPLY_FILTER({ filter_key: 'language', filter_value: value });
      }
    },
    startingAfterDate: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['startingAfterDate'];
      },
      set(value) {
        this.APPLY_FILTER({
          filter_key: 'startingAfterDate',
          filter_value: value
        });
      }
    },
    startingBeforeDate: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS[
          'startingBeforeDate'
        ];
      },
      set(value) {
        this.APPLY_FILTER({
          filter_key: 'startingBeforeDate',
          filter_value: value
        });
      }
    },
    organization: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['organization'];
      },
      set(value) {
        this.APPLY_FILTER({ filter_key: 'organization', filter_value: value });
      }
    },
    region: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['region'];
      },
      set(value) {
        this.APPLY_FILTER({ filter_key: 'region', filter_value: value });
      }
    },
    state: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['state'];
      },
      set(value) {
        this.APPLY_FILTER({ filter_key: 'state', filter_value: value });
      }
    },
    dma: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['dma'];
      },
      set(value) {
        this.APPLY_FILTER({ filter_key: 'dma', filter_value: value });
      }
    },
    eventType: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['eventType'];
      },
      set(value) {
        this.APPLY_FILTER({ filter_key: 'eventType', filter_value: value });
      }
    },
    archived: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['archived'] == '0'
          ? false
          : true;
      },
      set(value) {
        // set to null if true to include archived events with other results
        value = value ? null : '0';
        this.APPLY_FILTER({ filter_key: 'archived', filter_value: value });
      }
    },
    name: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['name'];
      },
      set(value) {
        this.APPLY_FILTER({ filter_key: 'name', filter_value: value });
      }
    },
    createdByEmail: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.FILTERS['createdByEmail'];
      },
      set(value) {
        this.localCreatedByEmail = value;
      }
    }
  }
};
</script>

<style lang="scss">
.app-events-advanced-search .v-icon.v-icon {
  color: var(--v-secondary-base);
}
.app-events-advanced-search {
  display: none;
  transition: opacity 1s ease-out;
  opacity: 0;
}
.app-events-advanced-search .v-input__slot {
  margin-bottom: 0;
}
.app-events-advanced-search.active {
  opacity: 1;
  display: block;
}

@media only screen and (max-width: 767px) {
  .app-events-advanced-search .row {
    flex-direction: column;
  }
}
</style>
