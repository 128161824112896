<template>
  <div>
    <EngagementForm :preview="true" />
  </div>
</template>

<script>
import EngagementForm from '@/components/EngagementForm.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AttendeeEngagement',
  components: {
    EngagementForm
  }
};
</script>
