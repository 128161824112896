<template>
  <div class="app-pagination">
    <v-pagination
      v-model="currentPage"
      class="app-pagination__pagination main-pagination"
      :length="maxPages || 0"
      :total-visible="maxPages"
    ></v-pagination>
    <v-row class="mobile-pagination">
      <button
        @click="
          page--;
          SET_CURRENT_PAGE(page);
        "
      >
        <v-icon v-if="page > 1" large color="primary">
          mdi-chevron-left
        </v-icon>
      </button>
      <button
        @click="
          page--;
          SET_CURRENT_PAGE(page);
        "
      >
        <div v-if="page > 1" class="page-num">{{ page - 1 }}</div>
      </button>
      <button>
        <div class="page-num page-num--selected">{{ page }}</div>
      </button>
      <button
        @click="
          page++;
          SET_CURRENT_PAGE(page);
        "
      >
        <div v-if="page < maxPages" class="page-num">{{ page + 1 }}</div>
      </button>
      <button
        @click="
          page++;
          SET_CURRENT_PAGE(page);
        "
      >
        <v-icon v-if="page < maxPages" large color="primary">
          mdi-chevron-right
        </v-icon>
      </button>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'Pagination',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    maxPages: {
      type: Number,
      default: 10
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  methods: {
    ...mapMutations('EVENT_TABLE_STORE', ['SET_CURRENT_PAGE']),
    //...mapActions(['setEvents', 'SET_CURRENT_PAGE']),
    getPageCount(data, itemsPerPage) {
      if (data !== null) {
        return Math.ceil(data.length / itemsPerPage);
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.EVENT_TABLE_STORE.CURRENT_PAGE;
      },
      set(value) {
        this.SET_CURRENT_PAGE(value);
      }
    }
  },
  updated() {
    this.page = this.currentPage;
  },
  created() {
    this.page = this.currentPage;
  },
  data() {
    return {
      page: 1
    };
  }
};
</script>

<style lang="scss" scoped>
.app-pagination {
  width: 75%;
}
::v-deep .v-pagination {
  justify-content: flex-start;
}

::v-deep .v-pagination > li:first-child button,
::v-deep .v-pagination > li:last-child button {
  box-shadow: none !important;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  ::v-deep .v-pagination > li:first-child,
  ::v-deep .v-pagination > li:last-child {
    display: none;
  }
}

::v-deep .v-pagination__item {
  background-color: transparent !important;
  box-shadow: none;
  height: auto;
  // font-size: 1.15rem;
  font-weight: 700;
  min-width: auto;
}
::v-deep .v-pagination__item:focus {
  outline: none;
}
::v-deep .v-pagination .v-pagination__item--active {
  color: var(--v-primary-base) !important;
  background-color: transparent !important;
}

.mobile-pagination {
  align-items: center;
}

.main-pagination {
  display: none;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .mobile-pagination {
    display: none;
  }
  .main-pagination {
    display: block;
  }
}

.page-num {
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
}

.page-num--selected {
  color: var(--v-primary-base);
}
</style>
