<template>
  <v-row justify="center" class="my-0">
    <!-- <v-col cols="11" :md="wide ? 12 : 8" :class="{ 'elevation-6': card }"> -->
    <v-col
      :cols="isMobile ? 10 : 9"
      :sm="isMobile ? 10 : 9"
      :class="{ 'elevation-6 my-10': card }"
    >
      <section class="form-section" :class="{ 'form-section--spaced': card }">
        <div :class="{ 'form-inner': !compact }">
          <v-row justify="center">
            <v-col
              :cols="isMobile ? 12 : 11"
              :md="wide || isMobile ? 12 : 9"
              :class="{ 'px-0': !wide && !isMobile }"
            >
              <fieldset class="event-organization">
                <legend v-if="$slots['section-header']" class="gutter-padding">
                  <slot name="section-header">Section Header</slot>
                </legend>
                <v-divider v-if="$slots['section-header']"></v-divider>
                <div class="gutter-padding">
                  <slot name="section-content">Section Content</slot>
                </div>
              </fieldset>
            </v-col>
          </v-row>
        </div>
      </section>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'EventFormSection',
  components: {},
  props: {
    card: {
      type: Boolean,
      default: true
    },
    wide: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMobile: window.innerWidth < 768
    };
  },
  methods: {
    onWindowResize() {
      this.isMobile = window.innerWidth < 768;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onWindowResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  }
};
</script>

<style lang="scss">
.form-section {
  &.form-section--spaced {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .form-inner {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  fieldset {
    border: 0;
    padding: 0;
  }

  fieldset legend {
    font-size: 40px;
    width: 100%;
    font-weight: 700;
  }

  hr.v-divider {
    background-color: var(--v-primary-base);
    border-width: 2.5px;
    margin-bottom: 20px;
  }

  .gutter-padding {
    padding: 0 $grid-gutter / 2;
  }

  .form-label {
    display: block;
    font-weight: 700;
    transform-origin: top left;
  }
  .form-label:not(:first-child) {
    margin-top: 20px;
  }

  .v-input--radio-group + .v-text-field {
    margin-top: 20px;
  }

  .v-input .v-input__icon--append .v-icon {
    color: var(--v-primary-base);
  }

  .v-input .mdi-alert-circle {
    display: none;
  }

  .error--text .mdi-alert-circle {
    display: block;
  }
  .v-input--checkbox .v-icon {
    color: var(--v-unselected-base);
  }

  @media only screen and (max-width: 767px) {
    fieldset legend {
      font-size: 32px;
    }

    .col-9 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }

    .row.my-0 > div {
      padding: 0;
    }
  }
}
</style>
