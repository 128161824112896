import { Auth } from 'aws-amplify';

export default async function UpdateUserAttributes({ commit }, updateParams) {
  // Check for required params
  if (
    !updateParams ||
    !updateParams.given_name ||
    !updateParams.family_name ||
    !updateParams.email
  ) {
    return Promise.reject(
      new Error('ERROR::UPDATE_USER_ATTRIBUTES: No parameters provided.')
    );
  }

  const user = await Auth.currentAuthenticatedUser();
  return Auth.updateUserAttributes(user, {
    given_name: updateParams.given_name,
    family_name: updateParams.family_name,
    email: updateParams.email
  });
}
