<template>
  <div class="event-brand">
    <v-radio-group
      v-model="brand"
      :rules="[nonEmptyRule('You must select a brand.')]"
      hide-details="auto"
      row
      required
    >
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-radio
            value="T-Mobile"
            hide-details="auto"
            class="brand-radio"
            :class="{ 'brand-radio--mobile': $vuetify.breakpoint.smAndDown }"
            :readonly="isEventArchived"
          >
            <span slot="label" class="brand-logo-wrapper">
              <img
                :src="logoUrl('t-mobile-logo-tight.png')"
                class="brand-logo"
                :class="[{ 'brand-logo--selected': isTmoSelected }]"
                alt="T-Mobile"
              />
            </span>
          </v-radio>
        </v-col>
        <v-col cols="12" sm="12">
          <v-radio
            value="Metro"
            hide-details="auto"
            class="brand-radio"
            :class="{ 'brand-radio--mobile': $vuetify.breakpoint.smAndDown }"
            :readonly="isEventArchived"
          >
            <span slot="label" class="brand-logo-wrapper">
              <img
                :src="logoUrl('metro-logo-tight.png')"
                class="brand-logo"
                :class="[{ 'brand-logo--selected': !isTmoSelected }]"
                alt="Metro"
              />
            </span>
          </v-radio>
        </v-col>
      </v-row>
    </v-radio-group>
  </div>
</template>
<script>
import ValidationRules from '../../utility/ValidationRules.js';
import { mapState } from 'vuex';

export default {
  name: 'BrandFields',
  props: {
    row: {
      type: Boolean,
      default: true
    },
    isEventArchived: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    logoUrl(logo) {
      return require('../../assets/' + logo);
    },
    nonEmptyRule(error) {
      //return true;
      return ValidationRules.NonEmptyRule(error);
    }
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    brand: {
      get() {
        return this.$store.state.EVENT_MANAGER_STORE?.CURRENT_EVENT_OBJECT
          ?.brand;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'brand',
          value: value
        });
      }
    },
    isTmoSelected() {
      return (
        this.brand === 'T-Mobile' ||
        this.brand === 'T-Mobile Solid' ||
        this.brand === 'T-Mobile Texture'
      );
    }
  }
};
</script>
<style scoped>
.brand-logo {
  filter: contrast(0);
  transform: scale(0.95);
  transition: all 0.5s;
  width: 95%;
  max-width: 350px;
}

.brand-logo:hover,
.brand-logo--selected {
  transform: scale(1);
  filter: contrast(1);
}
</style>
