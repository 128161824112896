<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="(column, headerIndex) in columns" :key="headerIndex">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="rows != null">
            <tr v-for="(row, index) in rows" :key="index">
              <td v-for="(column, colIndex) in activeColumns" :key="colIndex">
                {{ row[column.source] }}
                <slot :name="column.source + '-row' + index"></slot>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <template v-if="rows == null">
      <div class="loading-row">
        <v-progress-circular
          class="centered-circle"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PastelTable',
  computed: {
    activeColumns: function() {
      return this.columns.filter(c => c.source !== 'actions');
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
th {
  background-color: black;
  color: white !important;
  text-align: center !important;
}

td {
  border-right: solid rgba(232, 232, 232, 0.6) 1px;
  border-bottom: solid rgba(232, 232, 232, 0.6) 1px;
  border-top: none;
  border-left: none;
  text-align: center !important;
}

tr:first-child td {
  border-top: solid rgba(232, 232, 232, 0.6) 1px;
}

td:first-child {
  border-left: solid rgba(232, 232, 232, 0.6) 1px;
}

tr:nth-child(even) {
  background-color: var(--v-light-base);
}

.loading-row {
  padding: 16px;
  text-align: center;
  background-color: var(--v-light-base);
}
</style>
