export default class TMOEvent {
  constructor(constructObj = {}) {
    //internal values, used by devs and APIs
    this.id = constructObj.id;
    //user-set values
    this.brand = constructObj.brand || 'T-Mobile';
    if (constructObj.organization != null) {
      this.organization = constructObj.organization;
    } else {
      this.organization = [];
    }
    this.defaultLanguage = constructObj.defaultLanguage || 'en';
    this.name = constructObj.name || null;
    this.eventLanguage = constructObj.eventLanguage || 'en';
    this.askBusinessName =
      constructObj.askBusinessName ||
      constructObj.attendeeFormBusinessName ||
      false;
    this.eventType = constructObj.eventType || null;
    this.hqSponsorshipSegment = constructObj.hqSponsorshipSegment || null;
    this.businessName = constructObj.businessName;
    this.eventDescription = constructObj.eventDescription;
    this.visualBranding = constructObj.visualBranding;
    this.storeNumber = constructObj.storeNumber || null;
    this.totalMarketingSpend = constructObj.totalMarketingSpend;
    this.marketType = constructObj.marketType;
    this.eventStartDate = constructObj.eventStartDate;
    this.startDate = constructObj.startDate || null;
    this.endDate = constructObj.endDate || null;
    this.eventEndDate = constructObj.eventEndDate;
    this.startTime = constructObj.startTime;
    this.endTime = constructObj.endTime;
    this.createdByEmail = constructObj.createdByEmail;
    this.timezone = constructObj.timezone || null;
    this.region = constructObj.region;
    this.state = constructObj.state;
    this.dma = constructObj.dma;
    this.msa = constructObj.msa;
    this.numberOfWinners = constructObj.numberOfWinners || 1;
    this.rankWinners = !!(constructObj.rankWinners || false);
    this.excludePreviousParticipants = !!(
      constructObj.excludePreviousParticipants || false
    );
    this.winnerStateReq = constructObj.winnerStateReq;
    this.winnerState = constructObj.winnerState;
    this.published = !!(constructObj.published || false);
    this.fullPrizeDescription = constructObj.fullPrizeDescription || null;
    this.abbreviatedLegal = constructObj.abbreviatedLegal || null;
    this.engagements = constructObj.engagements || 0;
    this.contactableEngagements = constructObj.contactableEngagements || 0;
    this.archived = !!(constructObj.archived || false);
    this.updatedAt = constructObj.updatedAt;
    this.legalRequired = !!constructObj.legalRequired || false;
    this.createdByFirstName = constructObj.createdByFirstName || null;
    this.createdByLastName = constructObj.createdByLastName || null;
    this.createdByEmail = constructObj.createdByEmail || null;
    this.archiveMessage = constructObj.archiveMessage;
    this.contestType = constructObj.contestType || null;
    this.status = constructObj.status || null;
    this.attendeeFormBusinessName =
      !!constructObj.attendeeFormBusinessName || false;
    this.eventLocation = constructObj.eventLocation || null;
    this.physicalEventAddress = constructObj.physicalEventAddress || null;
    this.physicalEventCity = constructObj.physicalEventCity || null;
    this.physicalEventZip = constructObj.physicalEventZip || null;
    this.sweepstakesTitle = constructObj.sweepstakesTitle || null;
    this.requiresAutomatedSelection = true;
    this.entryStartDate = constructObj.entryStartDate || [];
    this.entryStartTime = constructObj.entryStartTime || [];
    this.entryEndDate = constructObj.entryEndDate || [];
    this.entryEndTime = constructObj.entryEndTime || [];
    this.winnerSelectionDate = constructObj.winnerSelectionDate || [];
    this.winnerSelectionTime = constructObj.winnerSelectionTime || [];
    this.intervalId = constructObj.intervalId || [];
    this.eligibleStates = constructObj.eligibleStates || [
      'AK',
      'AL',
      'AR',
      'AS',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'GU',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MP',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OK',
      'OR',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UM',
      'UT',
      'VA',
      'VI',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
      'JLO'
    ];
    if (constructObj.contestStatesRequired != null) {
      this.contestStatesRequired = constructObj.contestStatesRequired;
    } else if (this.eligibleStates.length >= 52) {
      this.contestStatesRequired = false;
    } else {
      this.contestStatesRequired = true;
    }
    this.experience = constructObj.experience || null;
    this.abbreviatedPrizeDescription =
      constructObj.abbreviatedPrizeDescription || null;
    this.arv = constructObj.arv || null;
    this.legalDetails = constructObj.legalDetails || null;
    this.eligibleStateTerritory = !!constructObj.eligibleStateTerritory || [];
    this.legalDenyReason = !!constructObj.legalDenyReason || null;
    this.legalPdfUrl = constructObj.legalPdfUrl || null;
    this.legalContract = constructObj.legalContract || null;
    this.intervals = constructObj.intervals || [];
    this.event_code = constructObj.event_code || null;
    this.isTranslated = constructObj.isTranslated || null;
  }

  organizationAsDataArray() {
    var orgArr = [];
    Object.keys(this.organization).forEach(key => {
      if (this.organization[key] === true) orgArr.push(key);
    });
    return orgArr;
  }

  organizationAsHumanArray() {
    var orgArr = this.organizationAsDataArray();
    return orgArr.map(this.getOrganizationLabel);
  }

  getOrganizationLabel(key) {
    switch (key) {
      case 'metroStores':
        return 'Metro Stores';
      case 'tmobileStores':
        return 'T-Mobile Stores';
      case 'sponsorships':
        return 'Sponsorships';
      case 'communityEngagement':
        return 'Community Engagement (Trucks)';
      case 'rstr':
        return 'R/STR';
      case 'tfbIl':
        return 'TFB IL';
      case 'signatureStores':
        return 'Signature Stores';
      case 'experienceStores':
        return 'Experience Stores';
      case 'other':
        return 'Other';
      default:
        return `Other: ${key}`;
    }
  }

  copy() {
    var newObj = Object.assign({}, this);
    return new TMOEvent(newObj);
  }
}
