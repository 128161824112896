<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :height="t_output_height"
    :width="t_output_width"
  >
    <image
      xmlns="http://www.w3.org/2000/svg"
      :width="t_output_width"
      :height="t_output_height"
      href="./inside.png"
    />
    <switch>
      <foreignObject
        fill="#fbfbfb"
        font-size="18"
        transform="translate(0 121)"
        :width="t_output_width"
        height="150"
      >
        <p
          xmlns="http://www.w3.org/1999/xhtml"
          style="color:white;text-align:center;"
        >
          {{templateAbbrPrizeDescription}}
        </p>
      </foreignObject>
      <text
        fill="#fbfbfb"
        font-size="18"
        transform="translate(27 121)"
        width="200"
        height="150"
      >
        <tspan x="17.558" y="21">{{templateAbbrPrizeDescription}}</tspan>
      </text>
    </switch>
    <!-- replace this image with x y width height and :href="SELECTED_IMAGE" -->
    <image
x="0" y="187" width="302" height="248"
         :href="selectedImage"
></image>
  </svg>
</template>

<script>
export default {
  name: 'TmobileFamily300x600',
  props: {
    selectedImage: {
      type: String,
      default: ''
    },
    templateAbbrPrizeDescription: {
      type: String,
      default: ''
    },
    t_output_height: {
      type: [Number, String],
      default: 300
    },
    t_output_width: {
      type: [Number, String],
      default: 600
    }
  }
};
</script>
