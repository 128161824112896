<template>
  <v-app
    :class="
      $vuetify.theme.themes.light.primary == '#e20074'
        ? 'app--tmobile'
        : 'app--metro'
    "
  >
    <Header
      v-if="
        !(
          noHeaderFooterMap.includes($route.name) ||
          noHeaderMap.includes($route.name)
        )
      "
      :show-menu="
        IS_LOGGED_IN &&
          $route.path !== '/login' &&
          $route.path !== '/password/reset/request/form' &&
          $route.path !== '/register'
      "
    />
    <v-main>
      <router-view v-if="renderView" />
    </v-main>
    <!-- // prettier-ignore -->
    <v-footer v-bind="footerAttributes">
      <Footer
        v-if="!noHeaderFooterMap.includes($route.name)"
        :force-english="
          $route.name != 'Attendee Engagement' &&
            $route.name != 'Engagements Preview' &&
            $route.name != 'Check In' &&
            $route.name != 'Official Rules'
        "
      />
    </v-footer>
  </v-app>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapGetters } from 'vuex';
import ThemeSwitcher from './utility/ThemeSwitcher';

export default {
  name: 'AuthStateApp',
  components: {
    Header,
    Footer
  },
  data() {
    // test comment 5
    return {
      noHeaderFooterMap: ['Web QR Code', 'Web QR Code Preview'],
      noHeaderMap: [
        'Attendee Engagement',
        'Engagement Preview',
        'Check In',
        'Official Rules'
      ],
      currentRoute: '',
      renderView: true
    };
  },
  computed: {
    ...mapGetters({
      IS_LOGGED_IN: `account/IS_LOGGED_IN`
    }),

    footerAttributes: () => {
      const attrs = {};
      attrs.absolute = false;
      attrs.fixed = false;
      attrs.app = false;
      attrs.padless = true;
      return attrs;
    }
  },
  created() {},

  beforeDestroy() {},
  updated() {},
  watch: {
    $route(to, from) {
      if (from.path === '/login') return;
      // react to route changes...
      ThemeSwitcher.ChangeTheme(this, 't-mobile');
    }
  },
  methods: {
    isLoggedIn() {
      return this.IS_LOGGED_IN;
    }
  }
};
</script>
<style lang="scss">
// body {
//   // background: white;
//   color: #111;
// }
// .container--fluid {
//   max-width: 1200px;
// }
// #app {
//   // line-height: 1.4;
//   // font-size: 1.2rem;
// }
// .app--tmobile {
//   // font-family: $mainBodyFont;
// }
// .app--metro {
//   // font-family: $mainMetroBodyFont;
// }
// h1,
// h2,
// h3,
// h4,
// h5,
// fieldset legend {
//   // font-family: $mainHeaderFont;
//   // font-weight: bold;
// }
.app--metro {
  h1,
  h2,
  h3,
  h4,
  h5,
  fieldset legend {
    font-family: $mainMetroHeaderFont;
  }
}
// .app-header {
//   padding-left: 40px !important;
//   padding-right: 40px !important;
// }
img {
  width: 100%;
}
.c-error {
  color: var(--v-error-base);
}
.bg-secondary-light {
  background-color: var(--v-light-base);
}
.bg-grey-lighten3 {
  background-color: var(--v-greyLighten3-base);
}
.btn__primary {
  background-color: var(--v-primary-base) !important;
  color: white !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.btn__secondary {
  background-color: var(--v-secondary-base) !important;
  color: white !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.btn__white {
  border: 1px solid var(--v-primary-base);
  background-color: transparent !important;
  color: var(--v-primary-base) !important;
}
.btn__exit {
  background-color: var(--v-metroComplimentory-base) !important;
  box-shadow: none !important;
}
.btn__exit .v-btn__content {
  color: white;
}
.btn__filter {
  background-color: var(--v-secondary-base) !important;
  color: white !important;
  border-radius: 18px !important;
  padding: 0 12px !important;
  height: 32px !important;
}
.v-btn {
  letter-spacing: 0em;
}
.white--text .v-btn__content,
.btn__primary .v-btn__content {
  color: white !important;
}
.v-btn__content {
  text-transform: initial;
  font-weight: bold;
}
.v-radio i {
  color: var(--v-unselected-base) !important;
}
.v-radio.v-item--active i {
  color: var(--v-selected-base) !important;
}
.v-dialog .v-card__text {
  font-size: 1rem;
}
.app-tooltip {
  width: 18px;
  position: absolute;
  left: -28px;
  top: 50%;
}
.app-tooltip .v-icon.v-icon {
  font-size: 18px;
}
.app-tooltip-textfield {
  position: relative;
}
// main dialog
.app-modal-dialog {
  border-top: 5px solid var(--v-primary-base);
}
.app-modal-dialog.v-dialog > .v-card > .v-card__title {
  padding: 0;
  font-size: 1.2em;
  font-weight: bold;
}
.app-modal-dialog .modal-button-outlined .v-btn__content {
  color: black;
  font-size: 1.3em;
  font-family: 'Tele-Grotesk Next-Ultra';
  letter-spacing: 0.5px;
}
.app-modal-dialog .app--metro .v-btn .v-btn__content {
  font-family: 'TT-Norms Bold';
}
</style>

<style lang="scss">
.app-events-table {
  border: 1px solid var(--v-greyLighten3-base);
  -webkit-box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.1);
}

.app-events-table thead tr {
  background: black;
}

.app-events-table thead th {
  color: white !important;
  white-space: nowrap;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: white !important;
  opacity: 1 !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  opacity: 1 !important;
}

.app-events-table tbody a {
  font-weight: bold;
}

.loading-row {
  padding: 13px;
  text-align: center;
  background-color: var(--v-light-base);
}
.text-cell {
  font-size: 14px !important;
  line-height: 18px;
}
.text-cell-heading {
  font-size: 12px !important;
  line-height: 12px;
}
.app-events-table {
  td {
    padding: 2px !important;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      padding: 10px !important;
    }
  }
}

.archived-event,
.archived-event td .event-name {
  color: var(--v-darkGrey-base);
}

::v-deep .archived-event .v-btn {
  background-color: var(--v-darkGrey-base) !important;
}
.v-data-table-header {
  background: black;
}

tbody tr:nth-child(even) {
  background-color: var(--v-light-base);
  color: var(--v-darkGrey-base);
}

.theme--light.v-chip:not(.v-chip--active) {
  background: var(--v-magenta-base) !important;
  color: white !important;
}

.v-data-table-header-mobile__wrapper {
  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: white;
  }

  .v-data-table-header th.active .v-data-table-header__icon,
  .v-data-table-header th:hover .v-data-table-header__icon {
    opacity: 1 !important;
  }

  .v-input__append-inner {
    .v-input__icon .v-input__icon--append {
      background: var(--v-magenta-base) !important;
      .v-icon .notranslate .mdi .mdi-menu-down .theme--light {
        // color: white !important;
        opacity: 1 !important;
      }
    }
  }
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon,
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon,
.v-icon {
  opacity: 1 !important;
}

::v-deep .text-cell.sortable.active.desc > i {
  color: #fff !important;
  opacity: 1 !important;
}

::v-deep
  i.v-icon.notranslate.v-data-table-header__icon.mdi.mdi-arrow-up.theme--light {
  color: #fff !important;
  opacity: 1 !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon,
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon,
.v-icon {
  opacity: 1 !important;
}

::v-deep .text-cell.sortable.active.desc > i {
  color: #fff !important;
  opacity: 1 !important;
}

::v-deep
  i.v-icon.notranslate.v-data-table-header__icon.mdi.mdi-arrow-up.theme--light {
  color: #fff !important;
  opacity: 1 !important;
}
.winners-events-table {
  -webkit-box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.1);
}

.winners-events-table thead tr {
  background: black;
}

.winners-events-table thead th {
  color: white !important;
  white-space: nowrap;
}

.small-table-text {
  font-size: 0.8rem !important;
}
</style>
