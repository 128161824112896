import { Auth } from 'aws-amplify';
import { Validator } from 'jsonschema';
import AccountSignUpSchema from '../schema/ACCOUNT_SIGN_UP.schema.json';

/**
 * @typedef {import('vuex').Commit} Commit
 */

/**
 * @param  { Commit } commit - Vuex Commit
 * @param  { object } params -  User signup params
 * @see AccountSignUpSchema
 */
export default function ACCOUNT_SIGN_UP({ commit }, params) {
  // Reject if no params are provided
  if (!params) {
    return Promise.reject(
      new Error('ERROR::ACCOUNT_SIGN_IN: No parameters provided.')
    );
  }
  // Validate request against json schema
  const paramValidator = new Validator();
  if (paramValidator.validate(params, AccountSignUpSchema).valid) {
    return Auth.signUp({ ...params })
      .then(accountData => {
        if (
          accountData.userConfirmed == false ||
          accountData.userConfirmed == 'false'
        ) {
          commit('SET_SIGN_UP_DETAILS', accountData.codeDeliveryDetails);
          return accountData;
        } else {
          commit('SET_USER_CONFIRMED', accountData.userConfirmed);
          commit('SET_USER', accountData.user);
          return accountData;
        }
      })
      .catch(err => {
        // TODO: Handle individual responses for signup
        commit('SET_SIGN_UP_ERROR', err);
        return err;
      });
  } else {
    // Reject if params do not pass validation
    return Promise.reject(
      new Error(
        'ERROR::ACCOUNT_SIGN_IN: Sign in parameters did not pass validation.'
      )
    );
  }
}
