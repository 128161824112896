import { API, Auth } from 'aws-amplify';

const state = {
  CURRENT_LEGAL_OBJECT: {}
};

const actions = {
  async FETCH_LEGAL({ commit, dispatch }, eventId) {
    const apiName = 'TMOAmplifyApi';
    const path = `/event/${eventId}/legal`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
    let response = {};
    try {
      response = await API.get(apiName, path, myInit);
    } catch (e) {
      response = e.response.data;
    }
    if (response && response.data && response.data.length > 0) {
      commit('SET_LEGAL', response.data[0]);
    }
    return response;
  },
  async APPROVE_LEGAL({ commit, dispatch }, eventId) {
    let response = {};
    try {
      const apiName = 'TMOAmplifyApi';
      const path = `/event/${eventId}/legal`;
      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          approved: 1
        }
      };

      response = await API.patch(apiName, path, myInit);
    } catch (e) {
      response = e.response.data;
    }
    if (response && response.data && response.data.length > 0) {
      commit('SET_LEGAL', response.data[0]);
    }
    return response;
  },
  async DENY_LEGAL({ commit, dispatch }, data) {
    let response = {};
    try {
      const apiName = 'TMOAmplifyApi';
      const path = `/event/${data.id}/legal`;
      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          approved: 0,
          message: data.message
        }
      };
      response = await API.patch(apiName, path, myInit);
    } catch (error) {
      if (error.response && error.response.data) {
        response = error.response.data;
      }
    }
    if (response && response.data && response.data.length > 0) {
      commit('SET_LEGAL', response.data[0]);
    }
    return response;
  }
};
// // mutations
const mutations = {
  SET_LEGAL: (state, payload) => {
    let legalObj = payload || {};
    state.CURRENT_LEGAL_OBJECT = Object.assign(
      {},
      state.CURRENT_LEGAL_OBJECT,
      legalObj
    );
  },
  RESET_LEGAL: state => {
    state.CURRENT_LEGAL_OBJECT = {};
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
