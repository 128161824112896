<template>
  <div class="app-footer pt-8 pb-8">
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <a href="https://www.t-mobile.com/privacy-center" target="_blank">
            T-Mobile Privacy Center
          </a>
          <br />
          {{ getFooterCopyright() }}
          <br />
          © 2021 T-Mobile USA, Inc.
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapGetters(['engagement']),
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT'])
  },
  methods: {
    getFooterCopyright() {
      if (
        this.CURRENT_EVENT_OBJECT &&
        this.CURRENT_EVENT_OBJECT.brand &&
        this.CURRENT_EVENT_OBJECT.brand.includes('Metro') &&
        (this.$route.name === 'Attendee Engagement' ||
          this.$route.name === 'Check In')
      ) {
        if (this.engagement.language == 'es') {
          return 'T-Mobile, el logotipo T, Magenta y el color magenta son marcas comerciales registradas de Deutsche Telekom AG.';
        } else if (
          this.engagement.language == 'en' ||
          this.forceEnglish ||
          this.CURRENT_EVENT_OBJECT == null ||
          !Object.keys(this.CURRENT_EVENT_OBJECT).length
        ) {
          return 'Metro by T-Mobile is a registered trademark of T-Mobile USA, Inc.';
        } else if (this.CURRENT_EVENT_OBJECT.defaultLanguage == 'es') {
          return 'T-Mobile, el logotipo T, Magenta y el color magenta son marcas comerciales registradas de Deutsche Telekom AG.';
        } else {
          return 'Metro by T-Mobile is a registered trademark of T-Mobile USA, Inc.';
        }
      } else {
        if (this.engagement.language == 'es') {
          return 'T-Mobile, el logotipo T, Magenta y el color magenta son marcas comerciales registradas de Deutsche Telekom AG.';
        } else if (
          this.engagement.language == 'en' ||
          this.forceEnglish ||
          this.CURRENT_EVENT_OBJECT == null ||
          !Object.keys(this.CURRENT_EVENT_OBJECT).length
        ) {
          return 'T-Mobile, the T logo, Magenta and the magenta color are registered trademarks of Deutsche Telekom AG.';
        } else if (this.CURRENT_EVENT_OBJECT.defaultLanguage == 'es') {
          return 'T-Mobile, el logotipo T, Magenta y el color magenta son marcas comerciales registradas de Deutsche Telekom AG.';
        } else {
          return 'T-Mobile, the T logo, Magenta and the magenta color are registered trademarks of Deutsche Telekom AG.';
        }
      }
    }
  },
  props: {
    forceEnglish: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.app-footer {
  width: 100%;
  text-align: center;
  background-color: #262626;
  color: white;
}
.app-footer a {
  color: white !important;
}
</style>
