<template>
  <div>
    <v-row justify="end" class="user-actions">
      <v-col cols="6" md="2">
        <v-select
          v-model="userActionText"
          @change="userAction(selectedUsers, userActionText)"
          :items="userOptions()"
          label="Actions"
          outlined
          dense
          append-icon="$Caret"
          :disabled="selectedUsers.length < 1"
        />
      </v-col>
    </v-row>
    <div>
      <v-data-table
        :headers="headers"
        :items="tableData"
        class="app-events-table "
        :loading="loading || actionLoading"
        :items-per-page="rowsPerPage"
        :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
        @page-count="pageCount = $event"
        :page.sync="currentPage"
        header-props.sort-icon="mdi-chevron"
        show-select
        v-model="selectedUsers"
        item-key="Username"
      >
        <template v-slot:no-data>
          No results match your current filters, please try again.
        </template>
        <template #loading>
          <v-progress-circular
            class="centered-circle"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
        <template v-slot:item.email="{ item }">
          <router-link :to="'/admin/user/edit/' + item.Username">
            {{ item.email }}
          </router-link>
        </template>
        <template v-slot:item.signUpOrganization="{ item }">
          <span>{{ item.signUpOrganization }}</span>
        </template>
        <template v-slot:item.signupDepartment="{ item }">
          <span>{{ item.signupDepartment }}</span>
        </template>
        <template v-slot:item.group="{ item }">
          <span>
            {{
              item.group
                .replace('Guest', 'General')
                .replace('SLTranslator', 'Translator')
                .replace('Ambassador', 'Event Staff')
            }}
          </span>
        </template>
        <template v-slot:item.UserCreateDate="{ item }">
          <span>{{ item.UserCreateDate | humanTimeFormat(null) }}</span>
        </template>
        <template v-slot:item.UserStatus="{ item }">
          <span>{{ item.Enabled ? item.UserStatus : 'Disabled' }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-row class="table-actions">
            <v-col cols="12" lg="6">
              <v-btn
                class="ma-1 flex-grow-1"
                :to="'/admin/user/edit/' + item.Username"
                tile
                color="primary"
                large
                :disabled="actionLoading"
              >
                Edit
              </v-btn>
            </v-col>
            <v-col cols="12" lg="6">
              <v-btn
                v-if="displayConfirmButton(item)"
                class="ma-1 flex-grow-1"
                @click="userAction([item], 'Confirm')"
                tile
                color="primary"
                large
                :disabled="actionLoading"
              >
                Confirm
              </v-btn>
              <v-btn
                v-if="displayDisableButton(item)"
                class="ma-1 flex-grow-1"
                @click="userAction([item], 'Suspend')"
                tile
                color="primary"
                large
                :disabled="actionLoading"
              >
                Suspend
              </v-btn>
              <v-btn
                v-if="displayEnableButton(item)"
                class="ma-1 flex-grow-1"
                @click="userAction([item], 'Unsuspend')"
                tile
                color="primary"
                large
                :disabled="actionLoading"
              >
                Unsuspend
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary">
            No results found.
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialog = false" tile outlined color="primary" class="px-6">
            Close
          </v-btn>
          <v-btn @click="dialogAction" tile class="px-6 btn__primary" v-if="dialogAction" :loading="actionLoading">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UsersTable',
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: true
    },
    rowsPerPage: {
      type: Number,
      default: 25
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Email',
          value: 'email',
          align: 'left',
          width: '15%'
        },
        {
          text: 'Organization',
          value: 'signUpOrganization',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Department',
          value: 'signupDepartment',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Role',
          value: 'group',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Created Date',
          value: 'UserCreateDate',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Account Status',
          value: 'UserStatus',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: '25%'
        }
      ],
      sortBy: null,
      sortDesc: false,
      currentSort: null,
      currentSortDirection: 'down',
      currentPage: 1,
      selectedUsers: [],
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogAction: null,
      dialogActionText: '',
      userActionText: null,
      actionLoading: false
    };
  },
  computed: {
    ...mapGetters({
      FORGOT_PASSWORD_ERROR: 'account/FORGOT_PASSWORD_ERROR'
    })
  },
  methods: {
    ...mapActions({
      sendEmail: 'admin/sendEmail',
      confirmUserSignUp: 'admin/confirmUserSignUp',
      enableUser: 'admin/enableUser',
      disableUser: 'admin/disableUser',
      addUserToGroup: 'admin/addUserToGroup',
      removeUserFromGroup: 'admin/removeUserFromGroup',
      updateUserAttributes: 'admin/updateUserAttributes',
      FORGOT_PASSWORD: 'account/FORGOT_PASSWORD'
    }),
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },
    sortByt(event, fieldName) {
      if (this.currentSort == fieldName) {
        this.currentSortDirection =
          this.currentSortDirection == 'down' ? 'up' : 'down';
      } else {
        this.currentSort = fieldName;
        this.currentSortDirection = 'up';
      }
      this.sortDesc = !this.sortDesc;
    },
    toggleOrder() {
      this.sortDesc = !this.sortDesc;
    },
    nextSort() {
      let index = this.headers.findIndex(h => h.value === this.sortBy);
      index = (index + 1) % this.headers.length;
      this.sortBy = this.headers[index].value;
    },
    displayConfirmButton: user => {
      if (user.UserStatus == 'Unconfirmed') {
        return true;
      }
      return false;
    },
    displayDisableButton: user => {
      if (user.Enabled && user.UserStatus !== 'Unconfirmed') {
        return true;
      }
      return false;
    },
    displayEnableButton: user => {
      if (!user.Enabled) {
        return true;
      }
      return false;
    },
    userOptions() {
      return ['Confirm', 'Suspend', 'Unsuspend', 'Reset Password'];
    },
    async userAction(users, action) {
      let count = users.length;
      let text = users.length > 1 ? `these ${count} users?` : `this user?`;
      switch (action) {
        case 'Confirm':
          this.displayDialog(
            'Confirm User',
            `Are you sure you want to confirm ${text}`,
            'Confirm',
            () => this.confirmUser(users)
          );
          break;
        case 'Suspend':
          this.displayDialog(
            'Suspend User',
            `Are you sure you want to suspend ${text}`,
            'Suspend',
            () => this.suspendUser(users)
          );
          break;
        case 'Unsuspend':
          this.displayDialog(
            'Unsuspend User',
            `Are you sure you want to unsuspend ${text}`,
            'Unsuspend',
            () => this.unsuspendUser(users)
          );
          break;
        case 'Reset Password':
          this.displayDialog(
            'Reset Password',
            `Are you sure you want to reset password for ${text}`,
            'Reset Password',
            () => this.resetPassword(users)
          );
          break;
        default:
          break;
      }
      this.$nextTick(() => (this.userActionText = null));
    },
    enabledEmail(toAddress, givenName) {
      let templateData = {
        preheader_text: '',
        header_text: 'Congratulations!',
        greeting_text: `Hi, ${givenName},`,
        'body-1_text':
          'Your access to the T-Mobile Data Capture Tool has been enabled. Click the button below to login.',
        'cta-1_href': 'https://www.t-mobileengagementhub.com',
        'cta-1_text': 'Login',
        'body-2_text': '',
        subject_text: 'T-Mobile Data Capture Tool Access Enabled',
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'SignUp <SignUp@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    disabledEmail(toAddress, givenName) {
      let templateData = {
        preheader_text: '',
        header_text: '',
        greeting_text: `Hi, ${givenName},`,
        'body-1_text':
          'Your access to the T-Mobile Data Capture Tool has been disabled. Please reach out to the T-Mobile employee you are working with for more information.',
        'body-2_text': '',
        subject_text: 'T-Mobile Data Capture Tool Access Disabled',
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'SignUp <SignUp@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    async confirmUser(users) {
      let unconfirmedUsers = users.filter(
        user => user.UserStatus == 'Unconfirmed'
      );
      let count = unconfirmedUsers.length;
      if (count < 1) {
        this.displayDialog(
          'Cannot Confirm Users',
          'There are no unconfirmed users selected.',
          '',
          null
        );
        return;
      }
      let text = count > 1 ? `${count} Users.` : `User.`;
      this.actionLoading = true;
      try {
        for (let i = 0; i < unconfirmedUsers.length; ++i) {
          let username = unconfirmedUsers[i].Username;
          let email = unconfirmedUsers[i].email;
          let name = unconfirmedUsers[i].given_name;
          await this.updateUserAttributes({
            username,
            attrName: 'custom:confirmed',
            attrValue: '1'
          });
          await this.confirmUserSignUp(username);
          await this.updateUserAttributes({
            username,
            attrName: 'email_verified',
            attrValue: 'true'
          });
          await this.removeUserFromGroup({ username, groupname: 'Pending' });
          unconfirmedUsers[i].UserStatus = 'Confirmed';
          unconfirmedUsers[i].Enabled = true;
          unconfirmedUsers[i].email_verified = 'true';
          unconfirmedUsers[i].confirmed = '1';
          unconfirmedUsers[i].group = 'Guest';
          this.sendEmail(this.enabledEmail(email, name));
        }
        this.displayDialog('Success', `Confirmed ${text}`, '', null);
      } catch (e) {
        e = typeof e == 'string' ? JSON.parse(e) : e;
        this.displayDialog('Error', e.message ?? e, '', null);
      } finally {
        this.actionLoading = false;
      }
    },
    async suspendUser(users) {
      let enabledUsers = users.filter(user => user.Enabled == true);
      let count = enabledUsers.length;
      if (count < 1) {
        this.displayDialog(
          'Cannot Suspend Users',
          'There are no unsuspended users selected.',
          '',
          null
        );
        return;
      }
      let text = count > 1 ? `${count} Users.` : `User.`;
      this.actionLoading = true;
      try {
        for (let i = 0; i < enabledUsers.length; ++i) {
          let username = enabledUsers[i].Username;
          let email = enabledUsers[i].email;
          let name = enabledUsers[i].given_name;
          await this.disableUser(username);
          enabledUsers[i].Enabled = false;
          this.sendEmail(this.disabledEmail(email, name));
        }
        this.displayDialog('Success', `Disabled ${text}`, '', null);
      } catch (e) {
        e = typeof e == 'string' ? JSON.parse(e) : e;
        this.displayDialog('Error', e.message ?? e, '', null);
      } finally {
        this.actionLoading = false;
      }
    },
    async unsuspendUser(users) {
      let disabledUsers = users.filter(user => user.Enabled == false);
      let count = disabledUsers.length;
      if (count < 1) {
        this.displayDialog(
          'Cannot Unsuspend Users',
          'There are no suspended users selected.',
          '',
          null
        );
        return;
      }
      let text = count > 1 ? `${count} Users.` : `User.`;
      this.actionLoading = true;
      try {
        for (let i = 0; i < disabledUsers.length; ++i) {
          let username = disabledUsers[i].Username;
          let email = disabledUsers[i].email;
          let name = disabledUsers[i].given_name;
          await this.enableUser(username);
          disabledUsers[i].Enabled = true;
          this.sendEmail(this.enabledEmail(email, name));
        }
        this.displayDialog('Success', `Enabled ${text}`, '', null);
      } catch (e) {
        e = typeof e == 'string' ? JSON.parse(e) : e;
        this.displayDialog('Error', e.message ?? e, '', null);
      } finally {
        this.actionLoading = false;
      }
    },
    async resetPassword(users) {
      let count = users.length;
      let text = count > 1 ? `Emails to ${count} Users.` : `Email to User.`;
      this.actionLoading = true;
      try {
        for (let i = 0; i < users.length; ++i) {
          let username = users[i].Username;
          await this.FORGOT_PASSWORD(username);
          if (this.FORGOT_PASSWORD_ERROR) {
            throw this.FORGOT_PASSWORD_ERROR;
          }
        }
        this.displayDialog('Success', `Sent Reset Password ${text}`, '', null);
      } catch (e) {
        e = typeof e == 'string' ? JSON.parse(e) : e;
        this.displayDialog('Error', e.message ?? e, '', null);
      } finally {
        this.actionLoading = false;
      }
    }
  },
  watch: {
    tableData: function() {
      // reset selection when applying filters
      this.selectedUsers = [];
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep th .v-simple-checkbox .v-icon {
  color: white;
}
::v-deep .v-simple-checkbox {
  display: flex;
  justify-content: center;
}
::v-deep .user-actions .v-input,
::v-deep .user-actions .v-btn {
  width: 100%;
}
::v-deep .v-select {
  border-radius: 0;
}
::v-deep .user-actions .v-select:not(.v-input--is-disabled) fieldset {
  border-color: var(--v-primary-base);
}
::v-deep .user-actions .v-select label {
  font-weight: bold;
  font-size: 14px;
}
::v-deep .user-actions .v-select:not(.v-input--is-disabled) label {
  color: black;
}
::v-deep th span,
td span,
td a {
  font-size: 14px;
}
.table-actions .v-btn {
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .table-actions .col-12 {
    &:first-child {
      padding-right: 4px;
    }
    &:last-child {
      padding-left: 4px;
    }
  }
}
@media screen and (max-width: 1199px) {
  .table-actions .col-12 {
    &:first-child {
      padding-bottom: 2px;
    }
    &:last-child {
      padding-top: 2px;
    }
  }
}
</style>
