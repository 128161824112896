import Vuex from 'vuex';
import Vue from 'vue';
import staff from './modules/staff';
import translations from './modules/translations';
import engagements from './modules/engagements';
import account from './modules/account';
import admin from './modules/admin';
import settings from './modules/settings';
import EVENT_TABLE_STORE from './modules/EventTableStore';
import PROXY_STORE from './modules/ProxyStore';
import DUPLICATE_EVENT_STORE from './modules/DuplicateEventStore';
import EVENT_MANAGER_STORE from './modules/EventManagerStore';
import EVENT_HISTORY_STORE from './modules/EventHistoryStore';
import LEGAL_STORE from './modules/LegalStore';
import AVE_CANVAS_STORE from './modules/AVECStore';
//import EMAIL_STORE from './modules/EmailStore';
import UI_STORE from './modules/UIControl';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    staff,
    translations,
    engagements,
    account,
    admin,
    settings,
    EVENT_TABLE_STORE,
    PROXY_STORE,
    DUPLICATE_EVENT_STORE,
    UI_STORE,
    EVENT_MANAGER_STORE,
    LEGAL_STORE,
    EVENT_HISTORY_STORE,
    AVE_CANVAS_STORE
  }
});

export default store;
