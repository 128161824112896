<template>
  <div class="event-action--wrapper">
    <div
      class="event-action elevation-12"
      :class="{ 'event-action--active': curAction != null }"
    >
      <ReportsAction
        v-if="curAction == 'generateReport'"
        @changeAction="changeAction($event)"
      />
      <SetAlert
        v-if="curAction == 'setAlert'"
        @changeAction="changeAction($event)"
      />
      <OverlayCloseButton
        v-if="curAction != null"
        @changeAction="changeAction($event)"
      />
    </div>
  </div>
</template>

<script>
import ReportsAction from '@/components/Admin/ReportsAction';
import OverlayCloseButton from '@/components/EventForm/OverlayCloseButton.vue';
import SetAlert from '@/components/Admin/SetAlert';
export default {
  name: 'AdminActionOverlay',
  components: {
    ReportsAction,
    OverlayCloseButton,
    SetAlert
  },
  props: {
    curAction: {
      type: String,
      default: null
    }
  },
  methods: {
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    }
  }
};
</script>

<style lang="scss" scoped>
.event-action--wrapper {
  position: relative;
}

.event-action {
  position: absolute;
  display: none;
  background-color: white;
  width: 100%;
  z-index: 10;
}

.event-action--active {
  display: block;
}
</style>
