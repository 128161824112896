<template>
  <div class="app-tooltip">
    <v-tooltip
      bottom
      color="primary"
      nudge-top="-15"
      content-class="app-tmo-tooltip"
      max-width="350"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
          <slot></slot>
        </div>
      </template>
      <div v-if="!html" class="app-tmo-tooltip__content pa-3">{{ text }}</div>
      <div
        v-if="html"
        class="app-tmo-tooltip__content pa-3"
        v-html="text"
      ></div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'TMOToolTip',
  props: {
    text: {
      type: String,
      default: ''
    },
    html: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.app-tmo-tooltip__content {
  font-size: 1.4em;
}
.app-tmo-tooltip__content:before {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-bottom: 20px solid var(--v-primary-base);
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
