<template>
  <div>
    <v-row
      no-gutters
      class="black action-bar action-bar--desktop"
      justify="center"
    >
      <template
        v-if="CURRENT_EVENT_OBJECT.contestType !== 'Batch Import Event'"
      >
        <v-col
          cols="12"
          lg="2"
          v-if="
            loaded &&
              CURRENT_EVENT_OBJECT.contestType == 'Sign Up/Check In' &&
              getEventStatus() !== 'Legal Pending' &&
              loaded &&
              !CURRENT_EVENT_OBJECT.archived
          "
        >
          <router-link :to="'/checkin/' + $route.params.id">
            <v-btn
              block
              color="#000000"
              :class="{ 'action-active': curAction == 'displayQR' }"
              elevation="0"
              dark
              tile
              @click="changeAction(null)"
            >
              Check In
            </v-btn>
          </router-link>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          v-if="
            loaded &&
              !CURRENT_EVENT_OBJECT.published &&
              !CURRENT_EVENT_OBJECT.archived
          "
        >
          <router-link
            :to="'/engagements/preview/' + $route.params.id"
            target="_blank"
          >
            <v-btn
              block
              color="#000000"
              :class="{ 'action-active': curAction == 'displayQR' }"
              elevation="0"
              dark
              tile
              @click="changeAction(null)"
            >
              Preview Data Capture Form
            </v-btn>
          </router-link>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          v-if="
            getEventStatus() !== 'Legal Pending' &&
              loaded &&
              CURRENT_EVENT_OBJECT.published &&
              CURRENT_EVENT_OBJECT.contestType != 'Sign Up/Check In'
          "
        >
          <v-btn
            block
            color="#000000"
            :class="{ 'action-active': curAction == 'viewWinner' }"
            elevation="0"
            dark
            tile
            @click="changeAction('viewWinner')"
          >
            View Winner
          </v-btn>
        </v-col>
        <!-- <v-col
          cols="12"
          lg="2"
          v-if="
            getEventStatus() !== 'Legal Pending' &&
              loaded &&
              displayQRCodeButton(CURRENT_EVENT_OBJECT)
          "
        >
          <router-link
            :to="
              (CURRENT_EVENT_OBJECT.published ? '/webqr/' : '/webqr/preview/') +
                $route.params.id
            "
            target="_blank"
          >
            <v-btn
              block
              color="#000000"
              :class="{ 'action-active': curAction == 'displayQR' }"
              elevation="0"
              dark
              tile
              @click="changeAction(null)"
            >
              {{ CURRENT_EVENT_OBJECT.published ? 'Display' : 'Preview' }} Web
              QR Code
            </v-btn>
          </router-link>
        </v-col> -->
        <!-- <v-col
          cols="12"
          lg="2"
          v-if="
            getEventStatus() !== 'Legal Pending' &&
              loaded &&
              CURRENT_EVENT_OBJECT.published &&
              displayQRCodeButton(CURRENT_EVENT_OBJECT)
          "
        >
          <v-btn
            block
            color="#000000"
            class="px-0"
            :class="{ 'action-active': curAction == 'downloadQR' }"
            elevation="0"
            dark
            tile
            @click="changeAction('downloadQR')"
          >
            Printable QR Code
          </v-btn>
        </v-col> -->

        <v-col
          cols="12"
          lg="2"
          v-if="loaded && CURRENT_EVENT_OBJECT.published && displayQRCodeButton"
        >
          <v-btn
            block
            color="#000000"
            :class="{ 'action-active': curAction == 'EventCollateralAction' }"
            elevation="0"
            dark
            tile
            @click="changeActionPanel('EventCollateralAction')"
          >
            Event Collateral
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          v-if="
            loaded && !CURRENT_EVENT_OBJECT.published && displayQRCodeButton
          "
        >
          <router-link
            :to="'/webqr/preview/' + $route.params.id"
            target="_blank"
          >
            <v-btn
              block
              color="#000000"
              :class="{ 'action-active': curAction == 'displayQR' }"
              elevation="0"
              dark
              tile
              @click="changeAction(null)"
            >
              Preview Web QR Code
            </v-btn>
          </router-link>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          v-if="
            getEventStatus() !== 'Legal Pending' &&
              loaded &&
              CURRENT_EVENT_OBJECT.published
          "
        >
          <v-btn
            block
            color="#000000"
            :class="{ 'action-active': curAction == 'runReports' }"
            elevation="0"
            dark
            tile
            @click="changeAction('runReports')"
          >
            Run Reports
          </v-btn>
        </v-col>

        <v-col cols="12" lg="2" v-if="loaded && !hideDuplicateButton">
          <v-btn
            block
            color="#000000"
            :class="{ 'action-active': curAction == 'DuplicateEventAction' }"
            elevation="0"
            dark
            tile
            @click="activateDuplicateForm('DuplicateEventAction')"
          >
            Duplicate Event
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          v-if="
            loaded &&
              showOwnerFunctions() &&
              !CURRENT_EVENT_OBJECT.archived &&
              !hideArchiveButton
          "
        >
          <v-btn
            block
            color="#000000"
            :class="{ 'action-active': curAction == 'archiveEvent' }"
            elevation="0"
            dark
            tile
            @click="changeAction('archiveEvent')"
          >
            Archive Event
          </v-btn>
        </v-col>

        <v-col cols="12" lg="2" v-if="loaded && !hideProxyButton">
          <v-btn
            block
            color="#000000"
            :class="{ 'action-active': curAction == 'viewProxy' }"
            elevation="0"
            dark
            tile
            @click="changeAction('viewProxy')"
            v-if="loaded"
          >
            Event Proxy
          </v-btn>
        </v-col>
        <v-col cols="12" lg="2" v-if="loaded && !CURRENT_EVENT_OBJECT.archived">
          <v-btn
            block
            color="#000000"
            :class="{
              'action-active': curAction == 'viewAccessCodeDescription'
            }"
            elevation="0"
            dark
            tile
            @click="changeAction('viewAccessCodeDescription')"
            v-if="loaded"
          >
            Event Access Code
          </v-btn>
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="12"
          lg="2"
          v-if="
            loaded && showOwnerFunctions() && !CURRENT_EVENT_OBJECT.archived
          "
        >
          <v-btn
            block
            color="#000000"
            :class="{ 'action-active': curAction == 'batchUpload' }"
            elevation="0"
            dark
            tile
            @click="changeAction('batchUpload')"
          >
            Upload Attendees
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          v-if="
            loaded &&
              showOwnerFunctions() &&
              !CURRENT_EVENT_OBJECT.archived &&
              !hideArchiveButton
          "
        >
          <v-btn
            block
            color="#000000"
            :class="{ 'action-active': curAction == 'archiveEvent' }"
            elevation="0"
            dark
            tile
            @click="changeAction('archiveEvent')"
          >
            Archive Event
          </v-btn>
        </v-col>
      </template>
    </v-row>
    <v-expansion-panels
      accordion
      dark
      class="black action-bar action-bar--mobile"
      v-model="EXPANSION_PANEL_ACTIVE"
    >
      <v-expansion-panel dark class="black action-bar">
        <v-expansion-panel-header expand-icon="$Caret">
          <v-col cols="12" lg="2" class="expansion-header">Event Actions</v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template
            v-if="CURRENT_EVENT_OBJECT.contestType !== 'Batch Import Event'"
          >
            <v-col
              cols="12"
              lg="2"
              v-if="
                loaded &&
                  !CURRENT_EVENT_OBJECT.published &&
                  !CURRENT_EVENT_OBJECT.archived
              "
              @click="changeActionPanel(null)"
            >
              <router-link
                :to="'/engagements/preview/' + $route.params.id"
                target="_blank"
              >
                <v-btn
                  block
                  color="#000000"
                  :class="{ 'action-active': curAction == 'displayQR' }"
                  elevation="0"
                  dark
                  tile
                >
                  Preview Data Capture Form
                </v-btn>
              </router-link>
            </v-col>

            <v-col
              cols="12"
              lg="2"
              v-if="
                getEventStatus() !== 'Legal Pending' &&
                  loaded &&
                  CURRENT_EVENT_OBJECT.published &&
                  CURRENT_EVENT_OBJECT.contestType != 'Sign Up/Check In'
              "
            >
              <v-btn
                block
                color="#000000"
                :class="{ 'action-active': curAction == 'viewWinner' }"
                elevation="0"
                dark
                tile
                @click="changeActionPanel('viewWinner')"
              >
                View Winner
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              v-if="
                loaded &&
                  displayQRCodeButton(CURRENT_EVENT_OBJECT) &&
                  !CURRENT_EVENT_OBJECT.published
              "
            >
              <router-link
                :to="
                  (CURRENT_EVENT_OBJECT.published
                    ? '/webqr/'
                    : '/webqr/preview/') + $route.params.id
                "
                target="_blank"
              >
                <v-btn
                  block
                  color="#000000"
                  :class="{ 'action-active': curAction == 'displayQR' }"
                  elevation="0"
                  dark
                  tile
                  @click="changeActionPanel(null)"
                >
                  Preview Web QR Code
                </v-btn>
              </router-link>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              v-if="
                loaded &&
                  CURRENT_EVENT_OBJECT.published &&
                  displayQRCodeButton(CURRENT_EVENT_OBJECT)
              "
            >
              <v-btn
                block
                color="#000000"
                class="px-0"
                :class="{
                  'action-active': curAction == 'EventCollateralAction'
                }"
                elevation="0"
                dark
                tile
                @click="changeActionPanel('EventCollateralAction')"
              >
                Event Collateral
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              v-if="loaded && CURRENT_EVENT_OBJECT.published"
            >
              <v-btn
                block
                color="#000000"
                :class="{ 'action-active': curAction == 'runReports' }"
                elevation="0"
                dark
                tile
                @click="changeActionPanel('runReports')"
              >
                Run Reports
              </v-btn>
            </v-col>
            <v-col cols="12" lg="2" v-if="loaded && !hideDuplicateButton">
              <v-btn
                block
                color="#000000"
                :class="{
                  'action-active': curAction == 'DuplicateEventAction'
                }"
                elevation="0"
                dark
                tile
                @click="activateDuplicateForm('DuplicateEventAction')"
              >
                Duplicate Event
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              v-if="loaded && !CURRENT_EVENT_OBJECT.archived"
            >
              <v-btn
                block
                color="#000000"
                :class="{
                  'action-active': curAction == 'viewAccessCodeDescription'
                }"
                elevation="0"
                dark
                tile
                @click="changeActionPanel('viewAccessCodeDescription')"
              >
                Access Code
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              v-if="
                loaded &&
                  showOwnerFunctions() &&
                  !CURRENT_EVENT_OBJECT.archived &&
                  !hideArchiveButton
              "
            >
              <v-btn
                block
                color="#000000"
                :class="{ 'action-active': curAction == 'archiveEvent' }"
                elevation="0"
                dark
                tile
                @click="changeActionPanel('archiveEvent')"
              >
                Archive Event
              </v-btn>
            </v-col>

            <v-col cols="12" lg="2" v-if="loaded && !hideProxyButton">
              <v-btn
                block
                color="#000000"
                :class="{ 'action-active': curAction == 'viewProxy' }"
                elevation="0"
                dark
                tile
                @click="changeActionPanel('viewProxy')"
                v-if="loaded"
              >
                Event Proxy
              </v-btn>
            </v-col>
          </template>
          <template v-else>
            <v-col
              cols="12"
              lg="2"
              v-if="
                loaded && showOwnerFunctions() && !CURRENT_EVENT_OBJECT.archived
              "
            >
              <v-btn
                block
                color="#000000"
                :class="{ 'action-active': curAction == 'batchUpload' }"
                elevation="0"
                dark
                tile
                @click="changeAction('batchUpload')"
              >
                Upload Attendees
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              v-if="
                loaded &&
                  showOwnerFunctions() &&
                  !CURRENT_EVENT_OBJECT.archived &&
                  !hideArchiveButton
              "
            >
              <v-btn
                block
                color="#000000"
                :class="{ 'action-active': curAction == 'archiveEvent' }"
                elevation="0"
                dark
                tile
                @click="changeAction('archiveEvent')"
              >
                Archive Event
              </v-btn>
            </v-col>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';

export default {
  name: 'EventActionBar',
  props: {
    curAction: {
      type: String,
      default: null
    },
    loaded: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    try {
      //  await this.$store.commit('PROXY_STORE/RESET_ALL');
      await this.GET_PROXIES(this.$route.params.id);
      this.updateUser().then(data => {
        this.attributes = this.USER_ATTRIBUTES;
      });
    } catch (e) {}
  },
  data() {
    return {
      attributes: null
    };
  },
  methods: {
    ...mapActions({
      CHANGE_PASSWORD: 'account/CHANGE_PASSWORD',
      SET_USER: 'account/SET_USER',
      UPDATE_USER_ATTRIBUTES: 'account/UPDATE_USER_ATTRIBUTES',
      updateUser: 'account/UPDATE_USER_INFO'
    }),
    ...mapActions('PROXY_STORE', ['GET_PROXIES']),
    ...mapMutations('UI_STORE', ['TOGGLE_EXPANSION_PANEL']),
    displayQRCodeButton: event => {
      if (event.experience && event.experience === 'Gametime') {
        return false;
      } else {
        return true;
      }
    },
    getEventStatus() {
      if (this.CURRENT_EVENT_OBJECT.status !== null) {
        return this.CURRENT_EVENT_OBJECT.status;
      } else {
        return this.CURRENT_EVENT_OBJECT.published == 1 ? 'Published' : 'Draft';
      }
    },
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    },
    changeActionPanel(actionName) {
      this.TOGGLE_EXPANSION_PANEL('hide');
      this.$emit('changeAction', actionName);
    },
    activateDuplicateForm(actionName) {
      let MODIFIED_EVENT_OBJECT = {
        ...this.CURRENT_EVENT_OBJECT
      };

      delete MODIFIED_EVENT_OBJECT.id;
      MODIFIED_EVENT_OBJECT['createdByEmail'] = this.USER_ATTRIBUTES['email'];
      MODIFIED_EVENT_OBJECT['createdByFirstName'] = this.USER_ATTRIBUTES[
        'given_name'
      ];
      MODIFIED_EVENT_OBJECT['createdByLastName'] = this.USER_ATTRIBUTES[
        'family_name'
      ];
      MODIFIED_EVENT_OBJECT['archived'] = false;
      MODIFIED_EVENT_OBJECT['published'] = false;
      MODIFIED_EVENT_OBJECT['status'] = 'Draft';
      MODIFIED_EVENT_OBJECT['eventStartDate'] = null;
      MODIFIED_EVENT_OBJECT['eventEndDate'] = null;
      MODIFIED_EVENT_OBJECT['eventStartTime'] = null;
      MODIFIED_EVENT_OBJECT['eventEndTime'] = null;
      MODIFIED_EVENT_OBJECT['entryStartDate'] = null;
      MODIFIED_EVENT_OBJECT['entryStartTime'] = null;
      MODIFIED_EVENT_OBJECT['entryEndDate'] = null;
      MODIFIED_EVENT_OBJECT['entryEndTime'] = null;
      MODIFIED_EVENT_OBJECT['winnerSelectionDate'] = null;
      MODIFIED_EVENT_OBJECT['winnerSelectionTime'] = null;
      MODIFIED_EVENT_OBJECT['intervals'] = [];

      this.$store.commit(
        'DUPLICATE_EVENT_STORE/SET_SELECTED_EVENT',
        MODIFIED_EVENT_OBJECT
      );
      this.TOGGLE_EXPANSION_PANEL('hide');
      this.$emit('changeAction', actionName);
    },
    showOwnerFunctions() {
      if (this.attributes == null || this.CURRENT_EVENT_OBJECT == null) {
        return false;
      }
      if (this.USER_GROUPS.indexOf('Admin') !== -1) {
        return true;
      } else {
        return (
          this.attributes.email == this.CURRENT_EVENT_OBJECT.createdByEmail
        );
      }
    }
  },
  computed: {
    ...mapGetters({
      USER: 'account/USER',
      USER_ATTRIBUTES: 'account/USER_ATTRIBUTES',
      USER_GROUPS: 'account/USER_GROUPS'
    }),
    ...mapState('PROXY_STORE', ['TABLE_DATA']),
    ...mapState('UI_STORE', ['EXPANSION_PANEL_ACTIVE']),
    ...mapState('EVENT_MANAGER_STORE', [
      'CURRENT_EVENT_OBJECT',
      'LOADING',
      'BITLY_LINK',
      'API_CALL_IN_PROGRESS'
    ]),

    EXPANSION_PANEL_ACTIVE: {
      get() {
        return this.$store.state.UI_STORE.EXPANSION_PANEL_ACTIVE;
      },
      set(value) {
        this.$store.commit('UI_STORE/TOGGLE_EXPANSION_PANEL', value);
      }
    },
    hideArchiveButton() {
      let hideTheArchiveButton = true;
      if (!this.USER_ATTRIBUTES['email']) return hideTheArchiveButton;
      if (this.USER_GROUPS.indexOf('Admin') !== -1) {
        return false;
      }
      return !!this.TABLE_DATA.filter(
        proxy =>
          proxy.users_email === this.USER_ATTRIBUTES['email'].toLowerCase()
      ).length;
    },
    hideDuplicateButton() {
      if (!this.USER_ATTRIBUTES['email']) return true;
      if (
        this.CURRENT_EVENT_OBJECT['createdByEmail'] &&
        this.CURRENT_EVENT_OBJECT['createdByEmail'] ===
          this.USER_ATTRIBUTES['email']
      )
        return false;
      if (this.USER_GROUPS.indexOf('Admin') !== -1) {
        return false;
      }

      return !this.TABLE_DATA.filter(
        proxy =>
          proxy.users_email === this.USER_ATTRIBUTES['email'].toLowerCase()
      ).length;
    },
    hideProxyButton() {
      if (!this.USER_ATTRIBUTES['email']) return true;
      if (
        this.CURRENT_EVENT_OBJECT['createdByEmail'] &&
        this.CURRENT_EVENT_OBJECT['createdByEmail'] ===
          this.USER_ATTRIBUTES['email']
      )
        return false;
      if (this.USER_GROUPS.indexOf('Admin') !== -1) {
        return false;
      }

      return !this.TABLE_DATA.filter(
        proxy =>
          proxy.users_email === this.USER_ATTRIBUTES['email'].toLowerCase()
      ).length;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn,
::v-deep .v-expansion-panels {
  font-family: 'Tele-Grotesk Next-Ultra', Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  height: 80px !important;
}

::v-deep .v-btn::before {
  color: var(--v-primary-base);
}

::v-deep .v-btn:hover::before,
::v-deep .v-btn.action-active::before {
  opacity: 1;
}

::v-deep .v-expansion-panel-header__icon {
  position: absolute;
  right: 24px;
}

::v-deep .v-expansion-panel-content__wrap > div {
  border-top: 1px solid white;
}

a {
  text-decoration: none;
}

.action-bar {
  min-height: 80px;
  z-index: 2;
}

.expansion-header {
  font-size: 16px;
  text-align: center;
}

.v-expansion-panels {
  border-radius: 0;
}

@media only screen and (min-width: 1200px) {
  .action-bar--desktop {
    display: flex;
  }

  .action-bar--mobile {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .action-bar--desktop {
    display: none;
  }

  .action-bar--mobile {
    display: flex;
  }
}
</style>
