<template>
  <v-card flat>
    <v-container fluid>
      <v-row>
        <v-col cols="10">
          <v-card-title> Photo Manager </v-card-title>
          <v-card-subtitle> Add or delete photos as an admin. </v-card-subtitle>
        </v-col>
        <v-col cols="2">
          <v-btn
class="v-btn v-btn--outlined  theme--light elevation-2  theme--light primary--text v-btn v-btn--outlined" type="button"
          @click="$emit('CHANGE_ACTIVE_DIALOG_COMPONENT', 'AVEUploadForm')"
>
            Add New
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          v-for="photo in AVAILABLE_IMAGES"
          :key="photo.title"
          :cols="photo.flex"
        >
          <v-card>
            <v-img
              height="200px"
              :src="photo.url"
              :lazy-src="photo.url"
              aspect-ratio="16/9"
              max-height="300"
              contain
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            >
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-card-title v-text="photo.promo_type"></v-card-title>
            </v-img>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="v-btn v-btn--outlined  theme--light elevation-2  theme--light primary--text v-btn v-btn--outlined"
                @click="showForm(photo, 'AVEDeleteForm')"
              >
                Delete
              </v-btn>
              <v-btn
                class=" btn__primary v-btn v-btn--text theme--light v-size--default v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default"
                @click="showForm(photo, 'AVEEditForm')"
              >
                Edit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'AVEPhotoManager',
  computed: {
    ...mapState('AVE_CANVAS_STORE', ['AVAILABLE_IMAGES'])
  },
  methods: {
    ...mapActions('AVE_CANVAS_STORE', ['SETUP_IMAGE_EDITING']),
    showForm(image, form) {
      this.SETUP_IMAGE_EDITING(image);
      this.$emit('CHANGE_ACTIVE_DIALOG_COMPONENT', form);
    }
  }
};
</script>
