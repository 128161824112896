import { API, Auth } from 'aws-amplify';
// initial state
const state = {
  DUPLICATE_EVENT_OBJECT: {},
  IS_PROXY: false,
  LOADING: false,
  ERROR: false,
  ERROR_MESSAGE: null,
  NEW_PROXY_EMAIL: null,
  POPUP_TITLE: null,
  POPUP_DESCRIPTION: null,
  SHOW_POPUP: false,
  NEW_EVENT_START_DATE: null,
  NEW_EVENT_START_TIME: null,
  NEW_EVENT_END_DATE: null,
  NEW_EVENT_END_TIME: null,
  NEW_EVENT_ID: null,
  NEW_EVENT_NAME: null
};

const actions = {
  async SELECT_EVENT({ commit }, event_object) {
    commit('SET_DUPLICATE_EVENT_OBJECT', event_object);
  },
  async SAVE_DUPLICATE_EVENT({ commit, state, rootState }) {
    let response = {};
    let userAttributes = rootState.account.USER_ATTRIBUTES;
    try {
      let postObject = Object.assign(
        {
          eventDescription: null,
          timezone: null,
          storeNumber: null,
          state: null,
          region: null,
          rankWinners: null,
          published: null,
          numberOfWinners: null,
          excludePreviousParticipants: null,
          eventType: null,
          organization: null,
          hqSponsorshipSegment: null,
          businessName: null,
          name: null,
          eventStartDate: null,
          eventEndDate: null,
          archived: null,
          defaultLanguage: null,
          createdByEmail: userAttributes.email,
          brand: null,
          dma: null,
          totalMarketingSpend: null,
          fullPrizeDescription: null,
          legal: null,
          legalDetails: null,
          legalRequired: null,
          contestType: null,
          status: null,
          message: null,
          contestStatesRequired: null,
          eligibleStates: null,
          winnerSelectionDate: null,
          winnerSelectionTime: null,
          intervals: null,
          attendeeFormBusinessName: null,
          arv: null,
          eventLocation: null,
          physicalEventAddress: null,
          physicalEventCity: null,
          physicalEventZip: null,
          sweepstakesTitle: null,
          requiresAutomatedSelection: null,
          experience: null,
          legalPdfUrl: null,
          legalContract: null,
          abbreviatedPrizeDescription: null
        },
        state.DUPLICATE_EVENT_OBJECT
      );

      const apiName = 'TMOAmplifyApi';
      const path = '/event';
      const myInit = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: postObject
      };

      response = await API.post(apiName, path, myInit);

      if (response.data.id) {
        commit('UPDATE_NEW_EVENT', {
          key: 'NEW_EVENT_ID',
          value: response.data.id
        });
        return commit('SET_RESPONSE_MESSAGE', {
          message: 'Success',
          helpText:
            'Successfully created the duplicate event. Do you want to view and edit it now?'
        });
      }

      return commit('SET_RESPONSE_MESSAGE', {
        message: 'Error',
        helpText: 'Error creating the duplicate event'
      });
    } catch (e) {
      commit('SET_RESPONSE_MESSAGE', {
        message: e.message || 'Internal Error',
        helpText: e.helpText || 'Couldnt perform that action at this time.'
      });
    } finally {
      commit('SET_LOADING', false);
    }
  },
  RESET_DUPLICATE_EVENT({ commit }) {
    commit('SET_SELECTED_EVENT');
  }
};
// // mutations
const mutations = {
  SET_LOADING: (state, payload) => {
    state.LOADING = payload || false;
  },
  SET_SELECTED_EVENT: (state, payload) => {
    let target_event = payload || {};
    state.DUPLICATE_EVENT_OBJECT = Object.assign(
      state.DUPLICATE_EVENT_OBJECT,
      target_event
    );
  },
  SET_RESPONSE_MESSAGE: (state, payload) => {
    let {
      message = 'Internal Error',
      helpText = 'Couldnt perform that action at this time.'
    } = payload;
    state.LOADING = false;
    state.POPUP_TITLE = message;
    state.POPUP_DESCRIPTION = helpText;
    state.SHOW_POPUP = true;
  },
  RESET_ALL: state => {
    state.LOADING = false;
    state.DUPLICATE_EVENT_OBJECT = {};
    state.ERROR = false;
    state.ERROR_MESSAGE = null;
    state.POPUP_TITLE = null;
    state.POPUP_DESCRIPTION = null;
    state.SHOW_POPUP = false;
    state.NEW_EVENT_END_DATE = null;
    state.NEW_EVENT_END_TIME = null;
    state.NEW_EVENT_START_DATE = null;
    state.NEW_EVENT_START_TIME = null;
    state.NEW_EVENT_NAME = null;
  },
  RESET_POPUP: state => {
    state.POPUP_TITLE = null;
    state.POPUP_DESCRIPTION = null;
    state.SHOW_POPUP = false;
  },
  UPDATE_DUPLICATE_EVENT(state, payload) {
    let { key = null, value = null } = payload;
    if (!key) return null;
    state.DUPLICATE_EVENT_OBJECT[key] = value;
  },
  UPDATE_NEW_EVENT(state, payload) {
    let { key = null, value = null } = payload;

    if (!key) return null;
    state[key] = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
