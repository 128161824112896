<template>
  <div class="app-banner-title">
    <v-container>
      <v-row justify="center" align="center">
        <v-col justify="center" align="center" cols="12" sm="8">
          <div v-if="loading" class="loading-overlay">
            <v-progress-circular
              class="loading-spinner my-10"
              :size="100"
              color="white"
              indeterminate
            ></v-progress-circular>
          </div>
          <h1 v-if="!loading" class="mt-8 white--text text-h1">{{ title }}</h1>
          <p v-if="showDescription" class="mt-5 mb-8 white--text text-p">
            {{ description }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'BannerTitle',
  props: {
    title: {
      type: String,
      default: 'T-Mobile Engagement Hub'
    },
    description: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showDescription: function() {
      return !this.loading || this.description !== '';
    }
  }
};
</script>

<style scoped lang="scss">
.app-banner-title {
  background-color: var(--v-primary-base);
  min-height: 150px;
}
.banner-title {
  // text-align: center;
  // padding: 50px 0;
  // background-color: var(--v-primary-base);
  // color: white;
  // position: relative;
  // min-height: 200px;
}
.banner-title h1 {
  // font-size: 56px;
  // line-height: 56px;
  // letter-spacing: -1.1px;
  // margin-bottom: 25px;
  // text-align: center;
}
.banner-title p {
  // max-width: 600px;
  // margin: 0 auto;
  // font-family: Helvetica, sans-serif;
  // font-size: 16px;
  // line-height: 24px;
}

.loading-overlay {
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  background-color: var(--v-primary-base);
  // text-align: center;
}

.loading-spinner {
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%) translateX(-50%);
}
</style>
