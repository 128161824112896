<template>
  <div></div>
</template>

<script>
import ExportTools from '@/utility/ExportTools.js';
import { Auth, API } from 'aws-amplify';

export default {
  name: 'EventReportGenerator',
  methods: {
    async createEventReportCSV(startDate, endDate, type, eventId = null) {
      this.allEvents = await this.getInteractions(
        startDate,
        endDate,
        type,
        eventId
      );
      if (!this.allEvents || this.allEvents.errorMessage) {
        return this.allEvents;
      } else if (!this.allEvents.length) {
        return 'No Results';
      }
      var eventArr = [];
      eventArr.push(Object.keys(this.allEvents[0]));
      for (var singleEvent of this.allEvents) {
        eventArr.push(Object.values(singleEvent));
      }
      var curDate = new Date();
      let title = 'EngagementHubInteractions';
      switch (type) {
        case 'Zip':
          title = 'EngagementHubInteractionsByZipCode';
          break;
        case 'Translated':
          title = 'EngagementHubTranslatedEvents';
          break;
        case 'Legal':
          title = 'EngagementHubLegalReport';
          break;
        case 'Event':
          title = `Event${eventId || ''}_InteractionsCustomDates_`;
          break;
        case 'Periods':
          title = `Event${eventId || ''}_EntryPeriodInteractions_`;
          break;
        case 'Daily':
          title = `Event${eventId || ''}_InteractionsPerDay_`;
          break;
        default:
          title = 'EngagementHubInteractions';
          break;
      }
      ExportTools.exportToCsv(
        title +
          curDate.getUTCFullYear() +
          '-' +
          (curDate.getUTCMonth() + 1) +
          '-' +
          curDate.getUTCDate() +
          '_' +
          curDate.getUTCHours() +
          '_' +
          curDate.getUTCMinutes() +
          '_' +
          curDate.getUTCSeconds() +
          'UTC.csv',
        eventArr
      );
      return 'Success';
    },
    async getInteractions(startDate, endDate, type, eventId = null) {
      const apiName = 'TMOAmplifyApi';
      const path = '/events/interactions';

      const payload = {
        response: true,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          startDate,
          endDate,
          type,
          eventId
        }
      };
      try {
        let req = await API.post(apiName, path, payload);
        req = typeof req == 'string' ? JSON.parse(req) : req;
        return req.data;
      } catch (e) {
        console.log(e);
        return e.response?.data;
      }
    }
  }
};
</script>

<style></style>
