<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="40px" height="40px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve"
>

<g>
	<g id="Dev-Pack">
		<g id="Group" transform="translate(-566.000000, -647.000000)">
			<g id="Path" class="caret" transform="translate(566.000000, 639.000000)">
				<polygon class="st0" points="20.3,38.1 21.7,36.7 35.3,23.8 31.1,19.2 20.3,29.4 9.2,19.2 5.3,23.8 18.9,36.7 				"/>
			</g>
		</g>
	</g>
	<rect class="st1" width="40" height="40"/>
</g>
</svg>
</template>

<script>
export default {
  name: 'Calendar'
};
</script>
<style lang="scss" scoped>
.caret {
  fill: currentColor;
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: currentColor;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
}
</style>
