import { API, Auth, Storage } from 'aws-amplify';
import Vue from 'vue';

const state = {
  SETTINGS: {}
};
const getters = {
  SETTINGS: state => state.SETTINGS
};

const actions = {
  async fetchSettings({ commit, dispatch }, settings) {
    const apiName = 'TMOAmplifyApi';
    const path = `/appsettings`;
    const payload = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: {
        settings
      }
    };
    const response = await API.get(apiName, path, payload).catch(error => {
      return error.response.data.message;
    });

    commit('SET_SETTINGS', response.data.settings[0] || {});

    return response;
  },
  async setSettings({ commit, dispatch }, settings) {
    const apiName = 'TMOAmplifyApi';
    const path = `/appsettings`;
    const payload = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: {
        settings
      }
    };
    const response = await API.patch(apiName, path, payload).catch(error => {
      return error.response.data.message;
    });
    console.log(response);
    commit('SET_SETTINGS', response.data.settings[0] || {});

    return response;
  }
};
const mutations = {
  SET_SETTINGS: (state, settings) => (state.SETTINGS = settings)
};

export default {
  getters,
  actions,
  state,
  mutations
};
