var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"web-qr-brand-bg",class:'brand-bg--' + _vm.CURRENT_EVENT_OBJECT.brand},[(_vm.loaded)?[_c('h1',[_vm._v(" "+_vm._s(_vm.CURRENT_EVENT_OBJECT.contestType == 'Sign Up/Check In' ? _vm.selectedLanguage == 'es' ? 'Incríbete y Gana un Regalo' : 'Sign Up for a Free Gift' : _vm.CURRENT_EVENT_OBJECT.contestType == 'Enter for a Chance to Win' ? _vm.selectedLanguage == 'es' ? 'Participa y Gana' : 'Enter for a Chance to Win' : _vm.selectedLanguage == 'es' ? 'Gana al Instante' : 'Instant Win')+" ")]),_c('h2',{staticStyle:{"margin-top":"50px"}},[(
          _vm.preview &&
            (_vm.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription == null ||
              _vm.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription == '')
        )?_c('v-icon',{staticClass:"info-icon",staticStyle:{"visibility":"hidden"},attrs:{"small":"","color":"#fc9e03"}},[_vm._v(" mdi-information ")]):_vm._e(),_c('span',{class:!_vm.preview ||
          (_vm.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription != null &&
            _vm.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription != '')
            ? 'prize'
            : 'prize--error'},[_vm._v(" "+_vm._s(!_vm.preview || (_vm.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription != null && _vm.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription != '') ? _vm.selectedLanguage == 'es' && _vm.translations.abbreviatedPrizeDescription && _vm.translations.abbreviatedPrizeDescription != '' ? _vm.translations.abbreviatedPrizeDescription : _vm.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription : _vm.selectedLanguage == 'en' ? 'PRIZE DESCRIPTION GOES HERE' : 'PRIZE DESCRIPTION GOES HERE')+" ")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(
              _vm.preview &&
                (_vm.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription == null ||
                  _vm.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription == '')
            )?_c('v-icon',_vm._g(_vm._b({staticClass:"info-icon",attrs:{"small":"","color":"#fc9e03"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")]):_vm._e()]}}],null,false,1690062034)},[(_vm.selectedLanguage == 'es')?_c('span',[_vm._v(" This field cannot be left blank. ")]):_c('span',[_vm._v(" This field cannot be left blank.")])])],1),(_vm.bitlyLink)?_c('img',{staticClass:"qr-image qr-image--actual",attrs:{"src":_vm.qrURL()}}):_c('v-skeleton-loader',{staticClass:"qr-image skeleton-qr",attrs:{"type":"image"}}),_c('h2',{staticClass:"scan"},[(_vm.selectedLanguage == 'es')?_c('span',[_vm._v(" ESCANEA PARA PARTICIPAR O VISITA ")]):_c('span',[_vm._v("Scan to enter or Visit")]),_c('br'),(_vm.bitlyLink == null)?_c('v-skeleton-loader',{staticClass:"mx-auto bitly-placeholder",attrs:{"type":"text","max-width":"200"}}):_vm._e(),_c('span',{staticClass:"short-url"},[_vm._v(_vm._s(_vm.bitlyLink))])],1),_c('img',{staticClass:"logo-image",attrs:{"src":_vm.logoUrl(_vm.CURRENT_EVENT_OBJECT)}}),_c('p',{staticClass:"legal mt-4",domProps:{"innerHTML":_vm._s(_vm.helperFunctions.OutputLegal(_vm.CURRENT_EVENT_OBJECT, _vm.selectedLanguage))}}),((_vm.CURRENT_EVENT_OBJECT.eventLanguage = 'bi'))?_c('v-col',{staticClass:"language-buttons",attrs:{"xs":"12","sm":"12","md":"12"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.selectedLanguage),callback:function ($$v) {_vm.selectedLanguage=$$v},expression:"selectedLanguage"}},[_c('v-radio',{ref:"englishLang",attrs:{"label":"English","value":"en"}}),_c('v-radio',{ref:"spanishLang",attrs:{"label":"Español","value":"es"}})],1)],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }