<template>
  <div>
    <PDFGenerator
      v-if="loaded"
      ref="generator"
      :cur-event="CURRENT_EVENT_OBJECT"
      @flyerGenerationComplete="flyerLoading = false"
      @aFrameGenerationComplete="aFrameLoading = false"
    />
    <v-row no-gutters class="black action-bar action-bar--desktop" justify="center">
      <v-col cols="12" lg="2" v-if="loaded">
        <router-link to="/translationdashboard">
          <v-btn
            block
            color="#000000"
            elevation="0"
            dark
            tile
            @click="changeAction(null)"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
            Back to Dashboard
          </v-btn>
        </router-link>
      </v-col>
      <v-col cols="12" lg="2" v-if="loaded">
        <router-link :to="'/webqr/preview/' + $route.params.id" target="_blank">
          <v-btn
            block
            color="#000000"
            :class="{ 'action-active': curAction == 'displayQR' }"
            elevation="0"
            dark
            tile
            @click="changeAction(null)"
          >
            QR Code Web Page
          </v-btn>
        </router-link>
      </v-col>
      <v-col cols="12" lg="2" v-if="loaded">
        <v-btn
          block
          color="#000000"
          class="px-0"
          :class="{ 'action-active': flyerLoading }"
          elevation="0"
          dark
          tile
          :loading="flyerLoading"
          @click="loadFlyer()"
        >
          QR Code Flyer
        </v-btn>
      </v-col>
      <v-col cols="12" lg="2" v-if="loaded">
        <v-btn
          block
          color="#000000"
          class="px-0"
          :class="{ 'action-active': aFrameLoading }"
          elevation="0"
          dark
          tile
          :loading="aFrameLoading"
          @click="loadAFrame()"
        >
          QR Code A-Frame
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        v-if="
          loaded &&
            CURRENT_EVENT_OBJECT.contestType === 'Enter for a Chance to Win'
        "
      >
        <router-link :to="'/event/rules/' + $route.params.id" target="_blank">
          <v-btn
            block
            color="#000000"
            :class="{ 'action-active': curAction == 'displayRules' }"
            elevation="0"
            dark
            tile
            @click="changeAction(null)"
          >
            Official Sweepstakes Rules
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-expansion-panels
      accordion
      dark
      class="black action-bar action-bar--mobile"
      v-model="EXPANSION_PANEL_ACTIVE"
    >
      <v-expansion-panel dark class="black action-bar">
        <v-expansion-panel-header expand-icon="$Caret">
          <v-col cols="12" lg="2" class="expansion-header">Event Actions</v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-col cols="12" lg="2" v-if="loaded">
            <router-link to="/translationdashboard">
              <v-btn
                block
                color="#000000"
                elevation="0"
                dark
                tile
                @click="changeAction(null)"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
                Back to Dashboard
              </v-btn>
            </router-link>
          </v-col>
          <v-col cols="12" lg="2" v-if="loaded">
            <router-link :to="'/webqr/preview/' + $route.params.id" target="_blank">
              <v-btn
                block
                color="#000000"
                :class="{ 'action-active': curAction == 'displayQR' }"
                elevation="0"
                dark
                tile
                @click="changeAction(null)"
              >
                QR Code Web Page
              </v-btn>
            </router-link>
          </v-col>
          <v-col cols="12" lg="2" v-if="loaded">
            <v-btn
              block
              color="#000000"
              class="px-0"
              :class="{ 'action-active': flyerLoading }"
              elevation="0"
              dark
              tile
              :loading="flyerLoading"
              @click="loadFlyer()"
            >
              QR Code Flyer
            </v-btn>
          </v-col>
          <v-col cols="12" lg="2" v-if="loaded">
            <v-btn
              block
              color="#000000"
              class="px-0"
              :class="{ 'action-active': aFrameLoading }"
              elevation="0"
              dark
              tile
              :loading="aFrameLoading"
              @click="loadAFrame()"
            >
              QR Code A-Frame
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="2"
            v-if="
              loaded &&
                CURRENT_EVENT_OBJECT.contestType === 'Enter for a Chance to Win'
            "
          >
            <router-link :to="'/event/rules/' + $route.params.id" target="_blank">
              <v-btn
                block
                color="#000000"
                :class="{ 'action-active': curAction == 'displayRules' }"
                elevation="0"
                dark
                tile
                @click="changeAction(null)"
              >
                Official Sweepstakes Rules
              </v-btn>
            </router-link>
          </v-col>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import PDFGenerator from '../../components/PDFGenerator.vue';

export default {
  name: 'TranslationActionBar',
  props: {
    curAction: {
      type: String,
      default: null
    },
    loaded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      attributes: null,
      flyerLoading: false,
      aFrameLoading: false
    };
  },
  created() {
    this.updateUser().then(data => {
      this.attributes = this.USER_ATTRIBUTES;
    });
  },

  components: {
    PDFGenerator
  },
  computed: {
    ...mapGetters({
      USER: 'account/USER',
      USER_ATTRIBUTES: 'account/USER_ATTRIBUTES',
      USER_GROUPS: 'account/USER_GROUPS'
    }),
    ...mapState('UI_STORE', ['EXPANSION_PANEL_ACTIVE']),
    ...mapState('EVENT_MANAGER_STORE', [
      'CURRENT_EVENT_OBJECT',
      'LOADING',
      'API_CALL_IN_PROGRESS'
    ]),
    EXPANSION_PANEL_ACTIVE: {
      get() {
        return this.$store.state.UI_STORE.EXPANSION_PANEL_ACTIVE;
      },
      set(value) {
        this.$store.commit('UI_STORE/TOGGLE_EXPANSION_PANEL', value);
      }
    }
  },
  methods: {
    ...mapActions({
      CHANGE_PASSWORD: 'account/CHANGE_PASSWORD',
      SET_USER: 'account/SET_USER',
      UPDATE_USER_ATTRIBUTES: 'account/UPDATE_USER_ATTRIBUTES',
      updateUser: 'account/UPDATE_USER_INFO'
    }),

    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    },
    showOwnerFunctions() {
      if (this.attributes == null || this.CURRENT_EVENT_OBJECT == null) {
        return false;
      }
      if (this.USER_GROUPS.indexOf('Admin') !== -1) {
        return true;
      } else {
        return (
          this.attributes.email == this.CURRENT_EVENT_OBJECT.createdByEmail
        );
      }
    },
    async loadFlyer() {
      this.flyerLoading = true;
      this.$nextTick(async function() {
        await this.$refs.generator.createFlyerPDF();
      });
    },
    async loadAFrame() {
      this.aFrameLoading = true;
      this.$nextTick(async function() {
        await this.$refs.generator.createAFramePDF();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn,
::v-deep .v-expansion-panels {
  font-family: 'Tele-Grotesk Next-Ultra', Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  height: 80px !important;
}

::v-deep .v-btn::before {
  color: var(--v-primary-base);
}

::v-deep .v-btn:hover::before,
::v-deep .v-btn.action-active::before {
  opacity: 1;
}

::v-deep .v-expansion-panel-header__icon {
  position: absolute;
  right: 24px;
}

::v-deep .v-expansion-panel-content__wrap > div {
  border-top: 1px solid white;
}

a {
  text-decoration: none;
}

.action-bar {
  min-height: 80px;
  z-index: 2;
}

.expansion-header {
  font-size: 16px;
  text-align: center;
}

.v-expansion-panels {
  border-radius: 0;
}

@media only screen and (min-width: 1200px) {
  .action-bar--desktop {
    display: flex;
  }

  .action-bar--mobile {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .action-bar--desktop {
    display: none;
  }

  .action-bar--mobile {
    display: flex;
  }
}
</style>
