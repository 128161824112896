import { Auth, API } from 'aws-amplify';

const requestHeaders = async () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`
  };
};

const removeUserFromGroup = async function(
  { dispatch, getters, commit },
  { username, groupname }
) {
  if (!username) {
    throw Error('ERROR::ACCOUNT_SIGN_IN: No parameters provided.');
  }
  let path = '/removeUserFromGroup';
  try {
    let payload = {
      body: {
        username,
        groupname
      },
      headers: await requestHeaders()
    };
    let req = await API.post('AdminQueries', path, payload);
    if (req.message.split(' ')[0] !== 'Removed') {
      throw Error('Error enabling user', req);
    }
    return req.message;
  } catch (error) {
    throw Error(error);
  }
};

export default removeUserFromGroup;
