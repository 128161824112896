<template>
  <v-container class="py-0">
    <div class="download-buttons">
      <div>
        <h2>Manage Alert Bar</h2>
        <v-col cols="12">
          <p>
            Alert messages will be displayed at the top of all Engagement Hub
            internal pages. To set an alert message, enter your text in the
            field below, then click ‘Publish Alert’. The alert will remain
            active until disabled. Once your alert is published, it will be
            displayed below. To turn off the alert bar and/or set a new message,
            click ‘Disable Alert’.
          </p>
        </v-col>
        <h3>
          <span v-if="!SETTINGS.alert_message">Create New </span><span v-else>Active </span>Alert
        </h3>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="alert_message"
            v-if="!SETTINGS.alert_message"
            hide-details="auto"
            required
            auto-grow
          ></v-textarea>
          <p v-else>{{ SETTINGS.alert_message }}</p>
        </v-col>

        <v-progress-circular
          v-if="loading"
          class="loading-spinner my-10"
          :size="100"
          color="pink"
          indeterminate
        ></v-progress-circular>

        <v-btn
          color="primary"
          v-if="!SETTINGS.alert_message && !loading"
          @click="loadSetSettings({ alert_message: alert_message })"
        >
          Publish Alert
        </v-btn>
        <v-btn
          color="primary"
          v-if="SETTINGS.alert_message && !loading"
          @click="loadSetSettings({ alert_message: null })"
        >
          Disable Alert
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'SetAlert',
  async created() {
    await this.fetchSettings();
  },
  data() {
    return {
      alert_message: '',
      loading: false
    };
  },
  methods: {
    ...mapActions(['setSettings', 'fetchSettings']),
    ...mapMutations('UI_STORE', ['SET_SETTINGS']),
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    },
    async loadSetSettings(settings) {
      this.loading = true;
      await this.setSettings(settings);
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters(['SETTINGS']),
    alert_message_set: {
      get() {
        return '';
      },
      set(value) {
        alert_message = value;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 35px;
  margin-top: 50px;
}

.download-buttons {
  margin-bottom: 35px;
  button {
    margin-bottom: 5px;
    min-width: 40% !important;
  }
  button:nth-child(2n) {
    margin-left: 5px;
  }
}
</style>
