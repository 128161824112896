<template>
  <v-container
    tag="header"
    :fluid="!this.lgBreakpoint"
    class="app-header"
    role="banner"
  >
    <v-row justify="space-between" align="center" class="flex-nowrap">
      <v-col class="pb-0 pt-0" cols="10" lg="6">
        <router-link class="app-header__logo-link" :to="checkGroup()">
          <img
            src="@/assets/logo.svg"
            alt="Data Capture Tool"
            class="app-header__logo"
          />
          <p class="text-h2 my-0 mx-5 d-none d-lg-block">
            T-Mobile Engagement Hub
          </p>
          <!-- <p class="app-header__logo-text ">T-Mobile Engagement Hub</p> -->
        </router-link>
      </v-col>
      <v-col class="pb-0 pt-0" cols="2" lg="6">
        <!-- <div class="m-auto" v-if="true"> -->
        <div class="m-auto" v-if="showMenu === true">
          <MainNavigation />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainNavigation from '@/components/MainNavigation.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  components: {
    MainNavigation
  },
  props: {
    showMenu: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    lgBreakpoint: function() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    ...mapGetters({
      USER_GROUPS: `account/USER_GROUPS`
    })
  },
  methods: {
    checkGroup() {
      if (this.USER_GROUPS.indexOf('Legal') !== -1) {
        return '/legaldashboard';
      } else if (this.USER_GROUPS.indexOf('SLTranslator') !== -1) {
        return '/translationdashboard';
      } else {
        return '/dashboard';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.app-header {
  // padding: 0 20px;
}

.app-header__logo {
  height: 60px;
  width: auto;
  margin: 10px 0;
}
.app-header__logo-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  text-decoration: none;
  font-family: 'Tele-Grotesk Next-Ultra';

  font-size: 40px;
  letter-spacing: -0.22px;
  line-height: 44px;

  & :hover {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 30px;
    letter-spacing: -0.1px;
  }
}
.app-header__logo-text {
  text-decoration: none;
  color: black;
  text-decoration-color: black;
  margin-bottom: 0;
  margin-left: 20px;
  display: none;
}

@media only screen and (min-width: 1000px) {
  .app-header__logo-text {
    display: block;
    font-size: 0.7em;
  }
}
@media only screen and (min-width: 1100px) {
  .app-header__logo-text {
    display: block;
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 1024px) {
  .app-header__logo {
    height: 50px;
    width: auto;
    margin: 10px 0;
  }
}
</style>
