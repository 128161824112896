<template>
  <div class="app-event-creation">
    <BannerTitle title="Create Batch Import Event" />
    <template v-if="!LOADING">
      <EventEditorForm :is-mobile="isMobile">
        <template v-slot:screenbuttons>
          <div class="form-buttons">
            <v-row justify="center">
              <v-col cols="10" md="6">
                <h2>Import Attendee File</h2>
                <p>
                  Your file must be saved as a CSV and match our headers exactly.
                  <a href="/sampleparticipants.csv" download>Download a Sample File.</a>
                </p>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10" md="6">
                <v-file-input
                  ref="fileInput"
                  accept=".csv"
                  v-model="participantFile"
                  label="Attendee Import File"
                  append-icon="mdi-alert-circle"
                  @change="handleParticipantsFile"
                  :rules="[
                    nonEmptyRule('A .csv file is required.')
                  ]"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10" md="6">
                <v-btn
                  elevation="2"
                  tile
                  block
                  x-large
                  :loading="API_CALL_IN_PROGRESS"
                  color="primary"
                  @click="submitBatchImportEvent"
                  :disabled="!validateSubmitBatchEvent.length <= 0"
                >
                  <span>
                    Submit
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
      </EventEditorForm>
    </template>
    <template v-else>
      <div
        style="height:100%;width:100%;display:flex;justify-content:center;"
        class="text-center"
      >
        <v-progress-circular indeterminate color="red"></v-progress-circular>
      </div>
    </template>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
          <ul>
            <li v-for="error in validateSubmitBatchEvent" :key="error.id">
              {{ error }}
            </li>
          </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" text class="px-6 btn__primary">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successDialog"
      width="600"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Batch Import Event Created
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ successDialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="returnToDash" text class="px-6 btn__primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="untrackedChangesDialog"
      width="600"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Unsaved Changes
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          You have unsaved changes to your event. Would you like to save your
          changes before leaving?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            @click="continueWithoutSavingEvent"
            text
            class="px-6 btn__secondary"
          >
            Exit without saving
          </v-btn>
          <v-btn @click="continueEditingEvent" text class="px-6 btn__primary">
            Keep editing
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BannerTitle from '@/components/BannerTitle.vue';
import EventEditorForm from '@/components/Forms/EventEditorForm.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import ThemeSwitcher from '@/utility/ThemeSwitcher';
import { TrackUnsavedChangesDialogControl } from '@/mixins/TrackUnsavedChangesDialogControl';
import { BatchImportEventSchema } from '@/utility/ValidationSchemas';
const csv = require('csvtojson');

const validate = (object, schema) =>
  Object.keys(schema)
    .filter(key => !schema[key](object[key]))
    .map(key => new Error(`${key} is invalid.`));

export default {
  name: 'EventCreate',
  mixins: [TrackUnsavedChangesDialogControl],
  components: {
    BannerTitle,
    EventEditorForm
  },
  created() {
    this.isAdmin = this.USER_GROUPS.indexOf('Admin') !== -1;
    if (!this.isAdmin) {
      this.$router.push('/dashboard');
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('EVENT_MANAGER_STORE/LOAD_EVENT');
      this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
        key: 'eventLanguage',
        value: 'en'
      });
      this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
        key: 'defaultLanguage',
        value: 'en'
      });
      this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
        key: 'contestType',
        value: 'Batch Import Event'
      });
      this.onResize();
      window.addEventListener('resize', this.onResize, { passive: true });
    } catch (e) {
      console.log(e);
    }
  },
  data() {
    return {
      isMobile: false,
      currentFormErrors: [],
      showInvalidDraftDialog: false,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null,
      valid: false,
      showDialog: false,
      newEventId: null,
      successDialog: false,
      successDialogMessage: null,
      untrackedChangesDialog: false,
      targetToRoute: false,
      participantFile: null,
      participantJson: null,
      nonEmptyRule(inputError) {
        return v => v === 0 || v === false || !!v || inputError;
      }
    };
  },
  watch: {
    brandWatcher: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.changeTheme(newVal);
        }
      }
    }
  },
  methods: {
    ...mapActions({
      sendEmail: 'admin/sendEmail'
    }),
    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
    returnToDash() {
      this.$store.commit('EVENT_MANAGER_STORE/RESET_ALL');
      this.$router.push({ path: '/dashboard' });
    },
    viewTheNewEvent() {
      this.$store.commit('EVENT_MANAGER_STORE/RESET_ALL');
      this.$router.push({ path: `/event/edit/${this.newEventId}` });
    },
    changeTheme(brand) {
      ThemeSwitcher.ChangeTheme(this, brand);
    },
    async submitBatchImportEvent() {
      try {
        if (this.validateSubmitBatchEvent.length > 0) {
          this.dialogTitle = 'Invalid Fields';
          this.dialogMessage =
            'One or more of your fields are invalid. Please fix these fields before proceeding.';
          this.dialogActionText = 'Close';
          this.dialogAction = () => {
            this.dialog = false;
          };
          this.dialog = true;
        }

        // Update the status to Published
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: 'Published'
        });

        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'published',
          value: '1'
        });

        let newEventId = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/CREATE_EVENT'
        );
        let { id = null } = newEventId;
        if (id) {
          this.newEventId = id;
          let error = this.insertParticipants();
          if (error) {
            this.displayDialog(
              'Error uploading attendees: ' + error,
              'Your event has been created, but no attendees were uploaded. You will be redirected to the event edit page.',
              'Close',
              this.viewTheNewEvent
            );
            return;
          } else {
            this.successDialogMessage =
              'You will receive an email when the attendee upload is complete.';
            this.successDialog = true;
          }
        } else {
          let error = newEventId.errorMessage || newEventId.message;
          throw error;
        }
      } catch (error) {
        this.dialogTitle = 'Error';
        this.dialogMessage =
          error ||
          'We are experiencing unusually high traffic at this time, please try to save your event again or contact us if this continues occuring.';
        this.dialogActionText = 'Close';
        this.dialogAction = () => {
          this.dialog = false;
        };
        return (this.dialog = true);
      }
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },
    async handleParticipantsFile(file) {
      try {
        let that = this;
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          csv({
            ignoreEmpty: true,
            nullObject: true
          })
            .fromString(reader.result)
            .then(function(json) {
              that.participantJson = json;
            });
        };
      } catch (e) {
        console.log(e);
        this.participantFile = null;
        this.participantJson = null;
        this.displayDialog(
          'Error',
          'Error reading file.',
          'Close',
          () => (this.dialog = false)
        );
      }
    },
    insertParticipants() {
      if (!this.newEventId || !this.validEmail(this.userAttributes.email)) {
        return !this.newEventId ? 'event ID is null' : 'invalid email';
      }
      let payload = {
        id: this.newEventId,
        participants: this.participantJson,
        email: this.userAttributes.email
      };
      // don't want to await because it will take too long
      this.$store.dispatch(
        'EVENT_MANAGER_STORE/BATCH_INSERT_PARTICIPANTS',
        payload
      );
      this.participantFile = null;
      this.participantJson = null;
      this.$refs.fileInput.resetValidation();
      return null;
    },
    validEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    }
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', [
      'CURRENT_EVENT_OBJECT',
      'LOADING',
      'API_CALL_IN_PROGRESS'
    ]),
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`
    }),
    brandWatcher() {
      return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.brand
        ? this.CURRENT_EVENT_OBJECT.brand
        : 'T-Mobile';
    },
    validateSubmitBatchEvent() {
      let errorArr = [];
      const errors = validate(
        this.CURRENT_EVENT_OBJECT,
        BatchImportEventSchema
      );

      if (errors.length > 0) {
        for (const { message } of errors) {
          errorArr.push(message);
        }
      } else {
        errorArr = [];
      }
      return errorArr;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.UNTRACKED_CHANGES_TO_EVENT) {
      this.untrackedChangesDialog = true;
      this.targetToRoute = to.fullPath || to.path;
    } else {
      next();
    }
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('resize', this.onResize, { passive: true });
  }
};
</script>

<style lang="scss" scoped>
::v-deep .event-brand .v-input--selection-controls__input {
  display: none;
}

::v-deep .v-input--checkbox {
  margin-top: 0;
}

::v-deep .v-card__text {
  color: var(--v-greyDarken3-base) !important;
}

.date-time-picker-wrap .v-text-field {
  margin-top: 0px;
}

::v-deep .v-input--radio-group {
  margin-top: 0;
  padding-top: 0;
}

.form-buttons {
  margin-top: 50px;
  margin-bottom: 80px;
}
::v-deep .v-btn {
  font-family: 'Tele-Grotesk Next-Ultra';
  // font-size: 18px;
  letter-spacing: 0.5px;
}

::v-deep .app--metro .v-btn {
  font-family: 'TT-Norms Bold';
}

::v-deep .v-btn--outlined {
  border-width: 2px !important;
}

.preview-link {
  text-decoration: none;
}
::v-deep .v-input .mdi-alert-circle {
  display: none;
}
::v-deep .error--text .mdi-alert-circle {
  display: block;
}
</style>
