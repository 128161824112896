<template>
  <div v-if="true" class="app-events-advanced-search__activefilters">
    <v-row>
      <v-col>
        <div
          class="app-events-advanced-search__activefilters-title"
          v-if="Object.keys(filters).length !== 0"
        >
          <span> Active Filters </span>
        </div>
        <div class="app-events-advanced-search__activefilters-wrap">
          <template v-for="(value, name, index) of filters">
            <div v-if="showLabel(name)" :key="`parent-${index}`">
              <div>{{ humanLabelName(name) }}</div>
              <div class="app-events-advanced-search__filter">
                <v-btn
                  class="ma-2 white--text btn__filter"
                  @click="
                    APPLY_FILTER({ filter_key: name, filter_value: null })
                  "
                >
                  {{ humanValueName(value) }}
                  <v-icon small right color="white">
                    mdi-close-circle
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AllEventsActiveSearchfilters',
  methods: {
    ...mapActions('EVENT_TABLE_STORE', ['APPLY_FILTER']),
    humanLabelName(label) {
      if (label == null) return;
      switch (label) {
        case 'brand':
          return 'Brand';
        case 'language':
          return 'Language';
        case 'eventType':
          return 'Event Segment';
        case 'communityEngagement':
          return 'Community Engagement (Trucks)';
        case 'createdByEmail':
          return 'Created By';
        case 'startingBeforeDate':
          return 'Starting Before';
        case 'startingAfterDate':
          return 'Starting After';
        case 'organization':
          return 'Organization';
        case 'region':
          return 'Region';
        case 'state':
          return 'State';
        case 'dma':
          return 'DMA';
        case 'archived':
          return 'Archived';
        default:
          return `Contains `;
      }
    },
    humanValueName(v) {
      if (v == null) return;
      switch (v) {
        case 'metroStores':
          return 'Metro Stores';
        case 'tmobileStores':
          return 'T-Mobile Stores';
        case 'en':
          return 'English';
        case 'bi':
          return 'English and Spanish';
        case 'AL':
          return 'Alabama';
        case 'AK':
          return 'Alaska';
        case 'AZ':
          return 'Arizona';
        case 'AR':
          return 'Arkansas';
        case 'CA':
          return 'California';

        case 'CO':
          return 'Colorado';
        case 'CT':
          return 'Connecticut';
        case 'DE':
          return 'Delaware';
        case 'DC':
          return 'District Of Columbia';
        case 'FL':
          return 'Florida';
        case 'GA':
          return 'Georgia';
        case 'HI':
          return 'Hawaii';
        case 'ID':
          return 'Idaho';
        case 'IL':
          return 'Illinois';

        case 'IN':
          return 'Indiana';
        case 'IA':
          return 'Iowa';
        case 'KS':
          return 'Kansas';
        case 'KY':
          return 'Kentucky';
        case 'LA':
          return 'Louisiana';
        case 'ME':
          return 'Maine';
        case 'ME':
          return 'Maine';
        case 'MD':
          return 'Maryland';
        case 'MA':
          return 'Massachusetts';
        case 'MI':
          return 'Michigan';
        case 'MN':
          return 'Minnesota';
        case 'MS':
          return 'Mississippi';
        case 'MO':
          return 'Missouri';
        case 'MT':
          return 'Montana';
        case 'NE':
          return 'Nebraska';
        case 'NV':
          return 'Nevada';
        case 'NH':
          return 'New Hampshire';
        case 'NJ':
          return 'New Jersey';
        case 'NM':
          return 'New Mexico';
        case 'NY':
          return 'New York';
        case 'NC':
          return 'North Carolina';
        case 'ND':
          return 'North Dakota';
        case 'OH':
          return 'Ohio';
        case 'OK':
          return 'Oklahoma';
        case 'OR':
          return 'Oregon';
        case 'PA':
          return 'Pennsylvania';
        case 'PR':
          return 'Puerto Rico';
        case 'RI':
          return 'Rhode Island';
        case 'SC':
          return 'South Carolina';
        case 'SD':
          return 'South Dakota';
        case 'TN':
          return 'Tennessee';
        case 'TX':
          return 'Texas';
        case 'UT':
          return 'Utah';
        case 'VT':
          return 'Vermont';
        case 'VA':
          return 'Virginia';
        case 'WA':
          return 'Washington';
        case 'WV':
          return 'West Virginia';
        case 'WI':
          return 'Wisconsin';
        case 'WY':
          return 'Wyoming';
        case 'Event_TFB_IL':
          return 'TFB - IL';
        case 'Event_Sponsorships':
          return 'Sponsorships';
        case 'Event_Community_Engagements_Trucks':
          return 'Community Engagement (Trucks)';
        case 'Event_Metro_Stores':
          return 'Metro Stores';
        case 'Event_T_Mobile_Stores':
          return 'T-Mobile Stores';
        case 'Event_Signature_Stores':
          return 'Signature Stores';
        case 'Event_Local_Marketing':
          return 'Local Marketing';
        case 'Event_Experience_Stores':
          return 'Experience Stores';
        case 'Event_Other':
          return 'Other';
        default:
          return `${v}`;
      }
    },
    showLabel(l) {
      let showLabel = false;
      if (
        typeof l == null ||
        l === 'archived' ||
        l === 'status' ||
        l === 'orderByField' ||
        l === 'orderByDirection'
      )
        return showLabel;

      showLabel = true;

      return showLabel;
    }
  },
  computed: {
    ...mapGetters('EVENT_TABLE_STORE', ['filters'])
  }
};
</script>

<style>
.app-events-advanced-search__activefilters .container--fluid {
  /* max-width: 1100px; */
}
.app-events-advanced-search__activefilters-title {
  font-weight: 700;
}
.app-events-advanced-search__activefilters-wrap {
  display: flex;
}
.app-events-advanced-search__activefilters-wrap > div {
  display: flex;
  align-items: center;
  margin-right: 14px;
}
</style>
